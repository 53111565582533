<template>
  <section>
    <!-- 增值服务报表 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnExport"
          >导出</el-button
        >
      </el-row>
      <el-table
        :data="tableOption.tableData"
        :header-cell-style="{ background: '#FAFAFA' }"
        :border="true"
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="450"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'orderno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row, 'orderno')"
              >
                <span class="link">{{ row.orderno }}</span>
              </el-link>
            </div>
            <div v-else-if="column.prop == 'relatedorder'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row, 'relatedorder')"
              >
                <span class="link">{{ row.relatedorder }}</span>
              </el-link>
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="tableOption.page.currentPage"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableOption.page.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog
      v-model="valueAddDialogVisible"
      title="增值服务"
      width="70%"
      class="dialog-min-width"
      :close-on-click-modal="false"
    >
      <add-value
        :addvalueformInitData="valueAddDetailInfo"
        :isEdit="false"
        :showPrice="false"
        :showOrder="true"
        :boundtype="orderType"
        :sncodeqty="sncodeqty"
        :insuredshow="orderType == 1"
        @showDetail="getOrderDetail"
      >
        <template #closeBtn>
          <el-button @click="valueAddDialogVisible = false">关闭</el-button>
        </template>
      </add-value>
    </el-dialog>

    <!-- orderType="1" 1为入库 2为出库 -->
    <order-detail-dialog
      :orderType="orderType"
      :id="relatedorderid"
      :dialogVisible="dialogVisible"
      @openDialog="openDialog"
    >
    </order-detail-dialog>
  </section>
</template>

<script setup>
import { valueaddfeeListApi, valueaddfeereportApi } from "@/api/report";
import { detailApi } from "@/api/addservers";
import { tableOption } from "./column";
import { onMounted } from "vue";
import dayjs from "dayjs";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { useRoute } from "vue-router";

defineOptions({
  name: "ReportsValueadd",
});

const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "完成时间:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions: pickerOptions()
    },
    {
      label: "增值单号:",
      type: "input",
      value: "",
      prop: "orderno",
      maxlength: 20,
      placeholder: "请输入增值单号",
    },
    {
      label: "关联单号:",
      type: "input",
      value: "",
      prop: "relatedorder",
      maxlength: 30,
      placeholder: "请输入关联单号",
    },
  ],
});

const params = ref({
  // 筛选参数
  starttime: dayjs().add(-1, "month").format("YYYY-MM-DD"), // 开始时间
  endtime: dayjs().format("YYYY-MM-DD"), // 结束时间
  pagenum: 1, // 请求 某页 数据
  pageSize: 10,
});

const valueAddDialogVisible = ref(false); // 增值服务 弹框
const valueAddDetailInfo = ref({}); // 增值服务详情
const sncodeqty = ref(0); // 增值服务 扫描的个数

const dialogVisible = ref(false); // 关联订单详情 弹框
const currentTbRow = ref({});
const orderType = ref(1); // 1为入库 2为出库
const relatedorderid = ref(""); // 关联的订单id

const lastMonth = ref(""); // 上月
const nowMonth = ref(""); // 下月
/**
 * 时间筛选 限制在12月内
 */
const dateRange = ref([]); // 选择的时间范围
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};

/**
 * 获取数据列表
 */
const getList = () => {
  valueaddfeeListApi(params.value).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  console.log(obj);
  obj.starttime = dateRange.value[0];
  obj.endtime = dateRange.value[1];
  params.value = {
    ...obj,
    pagenum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 清空按钮
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item["value"] = "";
  });
  dateRange.value = [lastMonth.value, nowMonth.value];
  params.value = {
    pagenum: 1,
    pageSize: params.value.pageSize,
    starttime: lastMonth.value,
    endtime: nowMonth.value,
  };
  handleCurrentChange(1);
};
/**
 * 表格上方的操作按钮(这里只有导出按钮)
 */
const operationBtnExport = () => {
  valueaddfeereportApi(params.value).then((res) => {
    DOWNLOAD_FILE(res);
  });
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pagenum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pagenum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 详情
 * @param {*} row  行数据
 * @param {*} type  详情标识 orderno:增值服务订单号，relatedorder：关联订单号
 */
const showDetail = (row, type) => {
  currentTbRow.value = row;
  switch (type) {
    case "orderno":
      orderType.value = row.type;
      getValueAddNoDetail(row.id);
      break;
    case "relatedorder":
      orderType.value = row.type;
      relatedorderid.value = row.relatedorderid;
      dialogVisible.value = true;
      break;
    default:
      break;
  }
};
const openDialog = (val) => {
  dialogVisible.value = val;
};
/**
 * 从 增值服务详情 里面 点击 获取关联订单详情
 */
const getOrderDetail = () => {
  orderType.value = currentTbRow.value.type;
  relatedorderid.value = currentTbRow.value.relatedorderid;
  dialogVisible.value = true;
};
/**
 *  增值服务订单 详情
 * @param {*} id
 */
const getValueAddNoDetail = (id) => {
  detailApi({ id }).then((res) => {
    valueAddDetailInfo.value = res;
    valueAddDetailInfo.value.filerecordList = res.valueaddorderVo.files?.map((item) => {
      return {
        filename: item.filename,
        filelink: item.filelink,
        url: item.filelink
      };
    });
    sncodeqty.value = res.valueaddorderRequiredBo?.sncodeqty || 0;
    valueAddDialogVisible.value = true;
  });
};
/**
 * 获取默认时间段
 */
const getDate = () => {
  const last = dayjs().add(-1, "month").format("YYYY-MM-DD"); // 获取上月
  const now = dayjs().format("YYYY-MM-DD"); // 当月
  lastMonth.value = last;
  nowMonth.value = now;
  dateRange.value = [last, now];
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "增值服务计费报表",
  });
};
onMounted(() => {
  getDate();
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>

const buttonPermission = {
    mounted(el, binding) {
        const permission = binding.value
        const permissionList = JSON.parse(localStorage.getItem('buttonPermission'))
        if (!permission) return;
        if (permissionList && !permissionList.includes(permission)) {
            el.style.display = 'none'
        }
    }
}
export default buttonPermission

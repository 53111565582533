<template>
  <section class="outbound-create text-[--title-color]">
    <!-- 仓库信息 + 包裹信息 -->
    <el-row :gutter="16" class="mb-[16px]">
      <el-col :span="12">
        <el-card shadow="never" id="storehouseInfo" class="bg-white">
          <h2 class="mb-[16px]">仓库信息</h2>
          <el-form
            ref="storehouseInfo"
            label-position="top"
            class="form-section"
            :rules="storehouseRules"
            :model="storehouseInfo"
          >
            <el-form-item prop="storehouseId" label="出货仓库">
              <el-select
                value-key="namecn"
                style="width: 100%"
                placeholder="请选择出货仓库"
                v-model="storehouseInfo.storehouseId"
                @change="selectStorehouse"
              >
                <el-option
                  v-for="(item, index) in storeOptions"
                  :key="index"
                  :label="item.namecn"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never" class="bg-white">
          <h2 class="mb-[16px]">包裹信息</h2>
          <el-form
            ref="packInfo"
            label-position="top"
            class="form-section"
            :model="packForm"
          >
            <el-form-item prop="remarkno" label="参考号">
              <el-input
                v-model.trim="packForm.remarkno"
                :maxlength="50"
                placeholder="请输入参考号"
              />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- Takealot信息 -->
    <el-row class="mb-[16px]">
      <el-col :span="24">
        <el-card shadow="never">
          <el-row type="flex" class="justify-between align-center mb-[16px]">
            <h2>Takealot信息</h2>
          </el-row>
          <el-form
            ref="takelotInfoRef"
            :rules="takelotRules"
            :model="takealotVO"
            :validate-on-rule-change="false"
            label-position="top"
            class="form-section form-information"
          >
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item prop="takealotStorehouseId" label="Takealot仓库">
                  <el-select
                    value-key="namecn"
                    style="width: 100%"
                    placeholder="请选择Takealot仓库"
                    v-model="takealotVO.takealotStorehouseId"
                    @change="selectTakelotStorehouse"
                  >
                    <el-option
                      v-for="(item, index) in takelotStoreOptions"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item id="postcode" prop="postcode" label="邮编">
                  <el-input
                    disabled
                    style="width: 100%"
                    v-model="takealotVO.postcode"
                    placeholder="请输入邮编"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="suburb" label="Suburb">
                  <el-input
                    style="width: 100%"
                    v-model="takealotVO.suburb"
                    placeholder="请输入城市/Suburb"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item
                  id="receiveaddress"
                  prop="takealotAddress"
                  label="地址"
                  style="width: 100%"
                >
                  <el-input
                    v-trim
                    v-model="takealotVO.takealotAddress"
                    clearable
                    placeholder="请输入地址"
                    :maxlength="200"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="thirdNumber" label="预订参考号">
                  <el-input
                    v-trim
                    v-model="takealotVO.thirdNumber"
                    clearable
                    :maxlength="45"
                    placeholder="请输入预订参考号"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item prop="appointmentDate" label="预订日期">
                  <span class="absolute -top-8.3 left-20">
                    <el-tooltip placement="top">
                      <template #content>
                        <div>
                          同一城市：提前2个工作日预订。
                          <br />不同城市：提前4个工作日预订。
                        </div>
                      </template>
                      <el-icon :size="16"
                        ><img src="~@/assets/images/QuestionFilled.png" alt="?"
                      /></el-icon>
                    </el-tooltip>
                  </span>
                  <el-date-picker
                    style="width: 100%"
                    class="block"
                    type="date"
                    placeholder="请选择预订日期"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    v-model="takealotVO.appointmentDate"
                    :disabled-date="pickerOptionsHandle"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="appointmentTime" label="预订时间段">
                  <el-select
                    clearable
                    style="width: 100%"
                    placeholder="请选择预订时间段"
                    v-model="takealotVO.appointmentTime"
                  >
                    <el-option
                      v-for="(item, index) in takelotTimeOptions"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="预订证明" prop="bookConfirmFile">
                  <div class="flex align-items w-[100%]">
                    <el-upload
                        ref="uploadRef"
                        class="pic-card"
                        accept="image/*,.doc,.docx,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :show-file-list="false"
                        :disabled="isLoading"
                        :action="uploadAction"
                        :headers="uploadHeaders"
                        :multiple="false"
                        :file-list="fileList"
                        :before-upload="beforeUpload"
                        :on-success="handleSuccess"
                        :on-error="handleError"
                        :on-change="handleChange"
                      >
                        <el-button
                          class="aliPay-btn"
                          type="primary"
                          icon="Upload"
                          :loading="isLoading"
                          >上传附件</el-button
                        >
                      </el-upload>
                    <!-- <input
                      type="file"
                      ref="bookingUploadRef"
                      class="hidden"
                      accept=""
                      @change="bookingUploadFileHandle"
                    />
                    <el-button
                      type="primary"
                      icon="Upload"
                      style="margin-right: 10px"
                      :loading="isLoading"
                      @click="showUploadHandle"
                      >上传附件</el-button
                    > -->
                    <div class="flex w-[60%]">
                      <a
                        class="font-size-mini link mx-[5px] clamp1"
                        target="_blank"
                        :href="takealotVO.url"
                        >{{ takealotVO.bookConfirmFileName }}</a
                      >
                      <span
                        v-if="takealotVO.bookConfirmFileName"
                        style="margin-top: 2px; color: #8f8f8f"
                        @click="handleRemove"
                        ><el-icon
                          ><CircleCloseFilled></CircleCloseFilled></el-icon
                      ></span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 产品信息 -->
    <el-row class="mb-[16px]">
      <el-col :span="24">
        <el-card shadow="never" id="goodInfo">
          <h2 class="mb-[16px]">产品信息</h2>
          <el-form
            ref="goodItem"
            :rules="goodItemRules"
            :model="goodItem"
            label-position="top"
          >
            <el-row :gutter="16">
              <el-col :span="5">
                <el-form-item prop="sku" label="SKU">
                  <el-select
                    style="width: 100%"
                    ref="select"
                    v-model="goodItem.sku"
                    value-key="sku"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请选择SKU"
                    :teleported="false"
                    :remote-method="getSku"
                    @change="selectSku"
                  >
                    <el-option
                      v-for="item in skuList"
                      :key="item.sku"
                      :label="item.sku"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item prop="quantity" label="数量">
                  <el-input
                    v-trim
                    v-model="goodItem.quantity"
                    maxlength="50"
                    placeholder="请输入数量"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="单位">
                  <el-input disabled v-model="goodItem.unit"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="可用库存">
                  <el-input disabled v-model="goodItem.availablestock" />
                </el-form-item>
              </el-col>
              <el-col :span="4" class="mt-[30px]">
                <el-form-item>
                  <el-button type="primary" icon="Plus" @click="addGoods"
                    >添加</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            border
            :data="goodList"
            :header-cell-style="{ background: '#FAFAFA' }"
            style="margin: 10px 0"
            :height="tableOption.height"
          >
            <el-table-column
              v-for="(item, index) in tableOption.tableColumn"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.minWidth"
              :width="item.width"
              :align="item.align"
            >
              <template #default="scope">
                <el-button
                  v-if="item.prop == 'operation'"
                  link
                  @click="deleteItem(scope.row)"
                  ><el-icon><Delete /></el-icon
                ></el-button>
                <span v-else> {{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <!-- 空数据 展示 -->
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!-- 增值服务下拉 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card id="addvaluecard" shadow="never">
          <el-collapse model-value="1" ref="addvaluecard">
            <el-collapse-item id="addvaluecarditem" name="1">
              <template #title>
                <h2>增值服务</h2>
              </template>
              <add-value
                @getAddValueForm="addvaluechange"
                ref="addvalueform"
                :addvalueformInitData="addvalueformInitData"
                :sncodeqty="sncodeqty"
                :create="true"
              ></add-value>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card shadow="never" class="justify_end mt-md">
          <el-button type="primary" plain @click="draftInfo">暂存</el-button>
          <el-button type="primary" @click="SaveOrder">下单</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- 下单确认弹框 -->
    <el-dialog
      class="action-dialog"
      title="信息及费用确认"
      v-model="actionDialog"
      width="70%"
      :close-on-click-modal="false"
    >
      <expense-confirm
        :storehouseName="storehouseName"
        :arrivedWarehouseName="arrivedWarehouseName"
        :receiverInfo="takealotVO"
        :orderPreview="orderPreview"
      ></expense-confirm>
      <template #footer>
        <el-button class="btn" @click="actionDialog = false"> 取消 </el-button>
        <el-button class="btn" type="primary" @click="confirmInfo">
          保存
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { getstoreselectApi, getSkulistApi } from "@/api/common.js";
import {
  outboundcreateApi,
  outboundgetBySkuApi,
  createPreviewApi,
  outboundDraftApi,
  outboundDraftDataApi,
  getTakealotDCListApi,
  getPickupStorehuseWithNewAddressApi,
} from "@/api/outbound.js";
import addValue from "@/components/add-value/index.vue";
import expenseConfirm from "./components/expense-confirm.vue";
import comNoData from "@/components/com-no-data/index.vue";
import { ElMessage, ElMessageBox, dayjs } from "element-plus";
import { tableOption } from "./column";
// import { baseImgURL } from "@/utils/baseURL";
import { useRoute } from "vue-router";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
export default {
  name: "OutboundTakealot",
  components: { addValue, expenseConfirm, comNoData },
  setup() {
    const trackingInfo = useTrackingInfo();
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间
    const route = useRoute();
    const curpath = route.path;
    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "Take a lot",
      });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });
    const uploadAction = computed(() => {
      return import.meta.env.VITE_BASE_URL + "/outbound/uploadFile/takeALot";
    });
    const uploadHeaders = computed(() => {
      const headers = {
        "Buffalo-ticket": getToken(),
      };
      return headers;
    });
    return {
      trackingInfo,
      tracking,
      uploadHeaders,
      uploadAction,
    };
  },
  data() {
    return {
      // accessTime: Date.now(),
      storeOptions: [], // 仓库列表
      storehouseName: "", // 仓库名字
      arrivedWarehouseName: "", // 跨国移库的仓库
      country: 0, // 仓库所在国家 0： 南非 1 中国
      storehouseInfo: {
        storehouseId: "", // 仓库id
      },

      packForm: {
        remarkno: "", // 参考号
      },

      ZAStoreList: [], // 跨国移库 仓库列表

      addressTotal: 0, // 总数
      addressList: [], // 常用收件人地址列表  结束

      goodList: [], // 产品列表
      outboundOrderId: 0, //出库单id，暂存后会有
      tableOption, // 表格 column
      goodItem: {
        sku: "",
        chinesename: "",
        englishname: "",
        id: "",
        productbarcode: "",
        productimgurl: "",
        quantity: "",
        fillweight: "",
        unit: "",
        itemId: "",
      }, //添加的一列商品

      flag: 0,

      receiverInfo: {
        // 收件人信息

        receiver: "",
        receivecompany: "",
        receivetelephone: "",
        receivecity: "",
        receiveemail: "",
        citySuburb: "",
        receivepostcode: "",
        receivesuburb: "",
        receiveaddress: "",
        receiveaddress2: "",
        remark: "",
      },
      pagenum: 1, //邮编和城市页数
      parameter: {
        search: "",
        page: 1,
      },
      searchCitySuburbParameter: {
        search: "",
        page: 1,
      },
      citySuburbPageInfo: {
        currentPagenum: 1,
        totalPageCount: 0,
      },
      SuburbOptions: [],
      postcodeOptions: [],
      skuList: [],
      skuTotalPageNum: 0, // 总的分页
      loading: false,
      actionDialog: false, //订单确认弹框
      //   订单预览
      orderPreview: {
        deliveryfee: "0.00",
        outboundfee: "0.00",
        balance: "0.00",
        creditline: "0.00",

        deliveryfee_unpaid: "0.00",
        orderqty_undelivery: "0",

        orderqty_unoutbound: "0",
        outboundfee_unpaid: "0.00",

        rechargecost: "0.00",

        valueaddprice: "0.00",
        valueadd_price_unpaid: "0.00",
        valueadd_qty: "0",
      },
      //   增值服务
      isLoading: false,
      valueaddorderVo: {
        files: [],
        labelchoose: 0,
        labelnum: 0,
        labelremark: "",
        reboardchoose: 0,
        reboardnum: 0,
        reboardremark: "",
        reboxchoose: 0,
        reboxednum: 0,
        reboxedremark: "",
      },
      //增值服务初始数据
      addvalueformInitData: {
        labelchoose: 0, //贴标选择
        reboardchoose: 0, //打板选择
        reboxchoose: 0, //打包选择
        insuredchoose: 0, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        month: "", //报价持续月份
        sncodeqty: "", // sn code 服务数量
      },
      orderOptions: {}, // 点击订单 所需要的参数

      takelotStoreOptions: [],
      pickupStoreID: "", // takealot ,获取发件人仓库
      takelotTimeOptions: [
        { value: "8:00 ~ 9:00", label: "8:00 ~ 9:00" },
        { value: "9:00 ~ 10:00", label: "9:00 ~ 10:00" },
        { value: "10:00 ~ 11:00", label: "10:00 ~ 11:00" },
        { value: "11:00 ~ 12:00", label: "11:00 ~ 12:00" },
        { value: "12:00 ~ 13:00", label: "12:00 ~ 13:00" },
        { value: "13:00 ~ 14:00", label: "13:00 ~ 14:00" },
        { value: "14:00 ~ 15:00", label: "14:00 ~ 15:00" },
        { value: "15:00 ~ 16:00", label: "15:00 ~ 16:00" },
        { value: "16:00 ~ 17:00", label: "16:00 ~ 17:00" },
        { value: "17:00 ~ 18:00", label: "17:00 ~ 18:00" },
        { value: "18:00 ~ 19:00", label: "18:00 ~ 19:00" },
        { value: "18:00 ~ 20:00", label: "18:00 ~ 20:00" },
      ],
      //   takelot 入参
      takealotVO: {
        receiver: "", // takelot仓库 名
        takealotStorehouseId: "", // takealot仓库id
        postcode: "", // 邮编
        suburb: "", // suburb
        takealotAddress: "", // take a lot地址
        appointmentDate: "", // 预约日期
        appointmentTime: "", // 预约时间段
        thirdNumber: "", // 预订参考号
        bookConfirmFile: "", // 上传的文件路径
        bookConfirmFileName: "", // 上传的文件名
      },
      fileList: [],
      timer: null
    };
  },

  mounted() {
    this.getstoreselect();
    this.getTakealotDCList();
    this.getDraftDetail();
  },
  methods: {
    /**
     * 获取草稿 详情
     */
    getDraftDetail() {
      //编辑草稿-initData
      const orderid = this.$route.query ? this.$route.query.id : 0;
      if (orderid && orderid > 0) {
        outboundDraftDataApi({ outboundId: orderid }).then((res) => {
          if (res && res.outboundOrderId && res.outboundOrderId > 0) {
            this.packForm.remarkno = res.remarkno;
            this.takealotVO = {...res.takealotBO, url: res.takealotBO?.bookConfirmFile};
            const storehouse = this.takelotStoreOptions.find((item) => {
              return item.id == res.takealotBO.takealotStorehouseId;
            });
            this.takealotVO.receiver = storehouse?.name || null;

            if (res.skulist) {
              this.goodList = res.skulist;
            }
            if (res.outboundstorehouseid > 0) {
              this.storehouseInfo.storehouseId = res.outboundstorehouseid + "";
              // 待写
              // 获取对应仓库是否属于 cn 若为cn 仓库 , 则展示出 跨国移库 按钮
              const warehouseInfo = this.storeOptions.find((item) => {
                return item.id == res.outboundstorehouseid;
              });
              this.country = warehouseInfo.country; // 仓库所在国家 0： 南非za 1 中国cn
            }
            this.outboundOrderId = res.outboundOrderId; // 出库订单id

            if (res.valueaddorderVo) {
              const filerecordList = res.valueaddorderVo?.files.map((item) => {
                return { ...item, url: item.filelink };
              });

              this.addvalueformInitData.filerecordList = filerecordList;
              this.addvalueformInitData.labelchoose =
                res.valueaddorderVo.labelchoose &&
                res.valueaddorderVo.labelchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.reboardchoose =
                res.valueaddorderVo.reboardchoose &&
                res.valueaddorderVo.reboardchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.reboxchoose =
                res.valueaddorderVo.reboxchoose &&
                res.valueaddorderVo.reboxchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.insuredchoose =
                res.valueaddorderVo.insuredchoose &&
                res.valueaddorderVo.insuredchoose == 1
                  ? true
                  : false;

              this.addvalueformInitData.labelnum =
                res.valueaddorderVo.labelnum || "";
              this.addvalueformInitData.labelremark =
                res.valueaddorderVo.labelremark || "";

              this.addvalueformInitData.reboardnum =
                res.valueaddorderVo.reboardnum || "";
              this.addvalueformInitData.reboardremark =
                res.valueaddorderVo.reboardremark || "";

              this.addvalueformInitData.reboxednum =
                res.valueaddorderVo.reboxednum || "";
              this.addvalueformInitData.reboxedremark =
                res.valueaddorderVo.reboxedremark || "";

              this.addvalueformInitData.insuranceamount =
                res.valueaddorderVo.insuranceamount || "";
              this.addvalueformInitData.month = res.valueaddorderVo.month || "";
              this.addvalueformInitData.sncodeqty =
                res.valueaddorderRequiredBo?.sncodeqty || 0;
              this.$refs.addvalueform.calcprice();
            }
          }
        });
      }
    },
    /**
     * 上传附件
     */
     beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        ElMessage.error("文件大小不超过10M");
        return  false
      }
      this.isLoading = true;
      this.timer = setTimeout(() => {
        this.isLoading = false;
        this.$refs.uploadRef.clearFiles();
        this.$refs.uploadRef.abort();
        ElMessage.error("上传超时");
      }, 30000);
    },
    handleChange(file) {
      file.url =  URL.createObjectURL(file.raw);
    },
    handleSuccess(response, file, fileList) {
      this.isLoading = false;
      clearTimeout(this.timer);
      if (response.status !== "00000") {
        this.$refs.uploadRef.clearFiles();
        if(response.code === 3000012) {
          removeToken();
          router.replace("/login");
        }
        return ElMessage.error(response.message);
      } else {
        
        // 只允许上传一张， 多了直接覆盖
        this.takealotVO.url = file.url
        this.takealotVO.bookConfirmFile = response.data.url;
        this.takealotVO.bookConfirmFileName = file.name;

        this.$refs.takelotInfoRef.clearValidate(['bookConfirmFile'])
        // this.fileList = [this.uploadImg];
      }
    },
    handleError() {
      this.isLoading = false;
      clearTimeout(this.timer);
      return this.$message.error("上传失败");
    },
    handleRemove() {
      this.fileList = []
      this.takealotVO.bookConfirmFile = "";
      this.takealotVO.bookConfirmFileName = "";
    },
   
    /**
     * takelot 预订时间限制
     * @param time
     */
    pickerOptionsHandle(time) {
      let takealotWareHouseItem = {};

      // takealotStorehouseId : takelot 仓库id
      if (!!this.takealotVO.takealotStorehouseId) {
        takealotWareHouseItem = this.takelotStoreOptions.find(
          (item) => item.id == this.takealotVO.takealotStorehouseId,
        );
      }

      //仓库发件仓库和收件仓库相同,从JNB-JNB,CPT-CPT 日期只能选择除周六周日的2天后， 否则是除周六周日的4天后
      let isSimilarStore =
        !!this.pickupStoreID &&
        !!takealotWareHouseItem &&
        this.pickupStoreID == takealotWareHouseItem.pickupStoreID;
      time = dayjs(time)
      //  是否周六周日
      const isWeekendDay = time.get('day') === 0 || time.get('day') === 6 
      let curentDate = dayjs();
      if (isSimilarStore) {
        //两天后
        if (curentDate.get("day") == 4 || curentDate.get("day") == 5) {
          curentDate = curentDate.add(4, "d");
        } else if (curentDate.get("day") == 6) {
          // 周6
          curentDate = curentDate.add(3, "d");
        } else if (curentDate.get("day") == 0) {
          // 周日
          curentDate = curentDate.add(2, "d");
        } else {
          curentDate = curentDate.add(2, "d");
        }
      } else {
        // 四天后
        const days = [2, 3, 4, 5];
        if (days.includes(curentDate.get("day"))) {
          curentDate = curentDate.add(6, "d");
        } else if (curentDate.get("day") == 6) {
          // 周6
          curentDate = curentDate.add(5, "d");
        }  else {
          // 周日 周一
          curentDate = curentDate.add(4, "d");
        }
      }

      return isWeekendDay || time.isBefore(curentDate);
      //   const weekendDay = time.getDay() == 0 || time.getDay() == 6;
      //   const date = new Date().getDay();
      //   //  同城，除去周六周日，工作日往后推2天
      //   let similarForb =
      //     weekendDay ||
      //     (date == 1 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 2) ||
      //     (date == 2 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 2) ||
      //     (date == 3 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 2) ||
      //     (date == 4 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 4) ||
      //     (date == 5 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 4);
      //   /**不同城，除去周六周日，工作日往后推4天*/
      //   let diffForb =
      //     weekendDay ||
      //     (date == 1 && time.getTime() < Date.now() + 3600 * 1000 * 24 * 4) ||
      //     ((date == 2 || date == 3 || date == 4 || date == 5) &&
      //       time.getTime() < Date.now() + 3600 * 1000 * 24 * 6);

      //   return isSimilarStore ? similarForb : diffForb;
    },
    /**
     * 选择出库仓库
     */
    selectStorehouse(val) {
      // 获取该仓库的详细信息
      const storehouse = this.storeOptions.find((item) => {
        return item.id == val;
      });
      this.storehouseName = storehouse.namecn;
      this.country = storehouse.country; // 仓库所在国家 0： 南非za 1 中国cn
      if(this.country == 1) {
        ElMessage.error("请选择ZA国家仓库");
      }
      getPickupStorehuseWithNewAddressApi({
        postcode: storehouse.postcode,
        suburb: storehouse.suburb,
      }).then((res) => {
        this.pickupStoreID = res;
        this.takealotVO.appointmentDate = "";
      });
      this.goodList = [];
      this.initGoodItem();
    },
    /**
     * 选择takelot 仓库
     */
    selectTakelotStorehouse(val) {
      const storehouse = this.takelotStoreOptions.find((item) => {
        return item.id == val;
      });
      this.takealotVO.postcode = storehouse.postcode;
      this.takealotVO.suburb = storehouse.suburb;
      this.takealotVO.takealotAddress = storehouse.address.substring(0, 200);
      this.takealotVO.receiver = storehouse.name;
      this.takealotVO.appointmentDate = "";
      
    },

    /**
     *  获取 信息与费用确认
     * @param {*} options
     */
    getPreviewInfo(options) {
      createPreviewApi(options).then((res) => {
        this.orderPreview = res;
        this.actionDialog = true;
      });
    },
    /**
     * 获取增值服务 内 数据
     * @param {*} param
     */
    addvaluechange(param) {
      this.valueaddorderVo = {
        files: param.filerecordList,
        labelchoose: param.labelchoose ? 1 : 0,
        labelnum: Number(param.labelnum),
        labelremark: param.labelremark,
        reboardchoose: param.reboardchoose ? 1 : 0,
        reboardnum: Number(param.reboardnum),
        reboardremark: param.reboardremark,
        reboxchoose: param.reboxchoose ? 1 : 0,
        reboxednum: Number(param.reboxednum),
        reboxedremark: param.reboxedremark,
      };
    },
    /**
     * 校验  三个表单都通过校验
     */
    async SaveOrder() {
      const storehouseInfForm = this.$refs.storehouseInfo;
      const takelotInfoForm = this.$refs.takelotInfoRef;
      // const form3 = this.$refs.goodItem;
      try {
        let validatestoreinfoflg = await storehouseInfForm.validate();
        let takelotreceiver = await takelotInfoForm.validate();
        let validateaddvalueflg =
          await this.$refs["addvalueform"].validateForm();
        let packInfoFlg = await this.$refs["packInfo"].validate();
        // await form3.validate();

        // 三个表单都通过校验后执行相关代码
        if (
          this.goodList.length > 0 &&
          takelotreceiver &&
          validatestoreinfoflg &&
          validateaddvalueflg &&
          packInfoFlg
        ) {
          const options = this.getAllParams();
          // 出库单【出货仓库】为CN国家(country: 1))
          if (this.country == 1) {
            ElMessage.error("请选择ZA国家仓库");
          } else {
            this.getPreviewInfo(options); // 获取订单 详情
          }
        } else {
          ElMessage.error("请完善下单信息");
        }
      } catch (error) {
        ElMessage.error("请完善下单信息");
      }
    },
    handleOrderType(val) {
      this.orderTypeInfo.largeCargoCategoryId = "";
    },
    /**
     * 重置页面，清空数据
     */
    reset() {
      this.$refs["storehouseInfo"].resetFields();
      this.$refs["addvalueform"].resetForm();
      this.$refs["packInfo"].resetFields();
      this.country = 0;
      this.takealotVO.bookConfirmFileName = "";
      this.$refs["takelotInfoRef"].resetFields();
      this.goodList = [];
      // 初始化 增值服务 数据
      this.addvalueformInitData = {
        labelchoose: 0, //贴标选择
        reboardchoose: 0, //打板选择
        reboxchoose: 0, //打包选择
        insuredchoose: 0, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        month: "", //报价持续月份
      };
      this.$refs.addvalueform.calcprice();
      this.valueaddorderVo = {
        ...this.valueaddorderVo,
        ...this.addvalueformInitData,
      };
      // 初始化 增值服务 数据 结束
    },

    /**
     * 确认下单
     */
    confirmInfo() {
      outboundcreateApi(this.orderOptions).then((res) => {
        this.actionDialog = false;
        ElMessage.success("下单成功!");
        this.reset();
      });
    },
    /**
     * 暂存
     */
    draftInfo() {
      const options = this.getAllParams();
      outboundDraftApi(this.orderOptions).then((res) => {
        if (res.orderid) {
          this.outboundOrderId = res.orderid;
        }
        if (res.outboundno) {
          ElMessageBox.alert(
            `草稿订单的订单号为${res.outboundno}，您可以在我的订单-草稿中找到该订单并继续编辑。`,
            "Hint",
          );
        }
      });
    },
    /**
     * 获取本页面 所有参数
     */
    getAllParams() {
      let options = {};
      options.skulist = this.goodList;
      options.outboundstorehouseid = JSON.parse(
        JSON.stringify(this.storehouseInfo.storehouseId),
      );
      options.valueaddorderVo = this.valueaddorderVo;
      options.takealotVO = this.takealotVO;
      options.deliveryType = 3; // 出库单配送类型 0 派送, 1 自提, 2 跨国移库 , 3 takealot

      options.outboundOrderId = this.outboundOrderId;

      options.remarkno = this.packForm.remarkno; // 参考号
      //   options.remarkno = options.remarkno ? options.remarkno.trim() : null;

      this.orderOptions = options;

      return options;
    },
    /**
     * 初始化 产品 填写信息
     */
    initGoodItem() {
      this.goodItem = {
        sku: "",
        chinesename: "",
        englishname: "",
        id: "",
        productbarcode: "",
        citySuburb: "",
        productimgurl: "",
        quantity: "",
        fillweight: "",
        unit: "",
        itemId: "",
      };
      this.$refs["goodItem"].resetFields();
    },
    /**
     * 添加一条商品信息
     */
    addGoods() {
      this.$refs["goodItem"].validate((valid) => {
        if (valid) {
          let isExit = this.goodList.findIndex(
            (item) => item.sku == this.goodItem.sku,
          );
          if (this.goodItem.quantity > this.goodItem.availablestock)
            return ElMessage.error("库存数量不够,请重新选择");
          if (isExit != -1) {
            this.goodList[isExit].quantity =
              Number(this.goodList[isExit].quantity) +
              Number(this.goodItem.quantity);
          } else {
            this.flag++;
            this.goodList.push({
              sku: this.goodItem.sku,
              chinesename: this.goodItem.chinesename,
              englishname: this.goodItem.englishname,
              id: this.goodItem.id,
              productbarcode: this.goodItem.productbarcode,
              productimgurl: this.goodItem.productimgurl,
              quantity: this.goodItem.quantity,
              fillweight: this.goodItem.fillweight,
              unit: this.goodItem.unit,
              itemId: this.flag,
              snstatus: this.goodItem.snstatus,
            });
            console.log(this.goodList);
          }
          this.initGoodItem();
        } else {
          ElMessage.error("请检查商品信息");
          return false;
        }
      });
    },
    /**
     * 删除一条商品信息
     * @param {*} row
     */
    deleteItem(row) {
      let index = this.goodList.findIndex((item) => item.itemId == row.itemId);
      this.goodList.splice(index, 1);
    },

    /**
     * sku 相关 开始
     */
    selectSku(val) {
      this.goodItem.sku = val.sku;
      outboundgetBySkuApi({
        sku: val.sku,
        storehouseid: this.storehouseInfo.storehouseId,
      }).then((res) => {
        this.goodItem = res;
      });
    },
    getSku(query) {
      if (!this.storehouseInfo.storehouseId) {
        return ElMessage.error("请先选择出货仓库!");
      } else {
        this.skuList = [];
        setTimeout(() => {
          getSkulistApi({
            sku: query,
            pageNum: 1,
          }).then((res) => {
            this.skuList = res.list || [];
          });
        }, 200);
      }
    },

    /**
     * 获取仓库列表
     */
    getstoreselect() {
      // null/-1查全部；0-海外仓；1-不良品仓；
      // null/-1 全部； 0 禁用 1 启用
      getstoreselectApi({
        status: 1,
      }).then((res) => {
        this.storeOptions = res.list;
      });
    },
    /**
     * 获取takelot仓库列表
     */
    getTakealotDCList() {
      getTakealotDCListApi().then((res) => {
        this.takelotStoreOptions = res.map((item) => {
          if (item.code.indexOf("CPT") != -1) {
            item.pickupStoreID = "2293983185166128";
          }
          if (item.code.indexOf("JNB") != -1) {
            item.pickupStoreID = "823519816802652";
          }
          return item;
        });
      });
    },
  },
  computed: {
    sncodeqty() {
      const result = this.goodList.filter((item) => {
        return item.snstatus === 1;
      });
      const total = result.reduce((sum, item) => sum + +item.quantity, 0);
      return total;
    },
    //仓库信息
    storehouseRules() {
      return {
        storehouseId: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    //添加商品校验
    goodItemRules() {
      return {
        sku: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
        quantity: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^\+?[1-9]\d*$/,
            message: "请输入大于0的正整数",
            trigger: "blur",
          },
        ],
      };
    },
    
    takelotRules() {
      // 收件人信息校验规则
    var validateFileUrl = (rule, value, callback) => {
        console.log('validateFileUrl====', value, this.fileList.length)
        if (!this.takealotVO.bookConfirmFileName) {
          callback(new Error('请上传预定证明'))
        } else {
          callback()
        }
      } 
      return {
        takealotStorehouseId: [
          {
            required: true,
            message: "请选择Takealot仓库",
            trigger: ["blur", "change"],
          },
        ],
        postcode: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
        suburb: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
        takealotAddress: [
          {
            required: true,
            message: "请输入地址",
            trigger: ["blur", "change"],
          },
        ],
        appointmentDate: [
          {
            required: true,
            message: "请选择预订日期",
            trigger: ["blur", "change"],
          },
        ],
        appointmentTime: [
          {
            required: true,
            message: "请选择预订时间段",
            trigger: ["blur", "change"],
          },
        ],
        thirdNumber: [
          {
            required: true,
            message: "请输入预订参考号",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "仅限数字和英文",
            trigger: "change",
          },
        ],
        bookConfirmFile: [
          {
            required: true,
            validator: validateFileUrl,
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.outbound-create {
  .form-section {
    :deep(.el-form-item__label) {
      font-weight: 400;
      font-size: 14px;
      color: #1f1f20;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    color: #1f1f20;
    line-height: 28px;
  }

  #addvaluecard {
    :deep(.el-collapse),
    :deep(.el-collapse-item__header),
    :deep(.el-collapse-item__wrap) {
      border: 0 none;
    }
    :deep(.el-collapse-item__header) {
      height: auto;
      line-height: normal;
    }
    :deep(.el-collapse-item__content) {
      padding: 0;
    }
  }
  .radio-section {
    :deep(.el-radio.is-bordered.is-checked) {
      background: #f8f3ed;
    }
  }
}
</style>

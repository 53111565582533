// 入库
export const inboundOrderTable = reactive([
    // 基础信息
    {
        tabName: "基础信息",
        label: "basic",
        activeName: "0",
        descriptions: true,
        form: false,
        table: false,
        total: false,
        inboundOrder: [{
            label: 'Warehouse:',
            prop: 'warehouse',
        }, {
            label: 'Customer:',
            prop: 'customer',
        }, {
            label: 'ASN:',
            prop: 'inboundorderno',
        }, {
            label: 'Customer Code:',
            prop: 'customercode',
        }, {
            label: 'Inbound type:',
            prop: 'deliverytype',
        }, {
            label: 'ETA:',
            prop: 'estinboundtime',
        }, {
            label: 'Classification:',
            prop: 'type',
        }, {
            label: 'Tracking NO.:',
            prop: 'referenceno',
        }, {
            label: 'Reserved Boxes:',
            prop: 'boxqty',
        }],
        inboundOrderDetail: {}
    },
    // 包裹信息
    {

        tabName: "包裹信息",
        label: "detaillist",
        activeName: "1",
        height: 300,
        descriptions: false,
        form: false,
        table: true,
        total: true,
        totalQty:0,
        tableColumn: [
            {
                label: "箱号",
                prop: "boxno",
            },
            {
                label: "SKU",
                prop: "sku",
                align: 'left'
            },
            {
                label: "产品条码",
                prop: "barcode",
            },
            {
                label: "单位",
                prop: "unit",
            },
            {
                label: "数量",
                prop: "qty",
            },
        ],
        tableData: [],
    },
    // 收件信息
    {
        tabName: "收件信息",
        label: "receivedlist",
        activeName: "2",
        height: 300,
        descriptions: false,
        form: false,
        table: true,
        total: false,
        tableColumn: [
            {
                label: "日期",
                prop: "createTime",
                width: "180",
                align: 'left'
            },
            {
                label: "箱子",
                prop: "boxes",
                align: 'left'
            },
            {
                label: "重量",
                prop: "weight",
            },
            {
                label: "体积",
                prop: "vol",
            },
            {
                label: "卸货单位",
                prop: "unloadingunit",
            },
            {
                label: "卸货数量",
                prop: "unloadingqty",
            },
            {
                label: "备注",
                prop: "notes",
                minWidth: "150",
            },
        ],
        tableData: [],
    },
    // 质检信息
    {
        tabName: "质检信息",
        label: "inspectionlist",
        activeName: "3",
        height: 300,
        descriptions: false,
        form: false,
        table: true,
        total: false,
        tableColumn: [
            {
                label: "产品条码",
                prop: "barcode",
                align: 'left'
            },
            {
                label: "名称",
                prop: "name",
            },
            {
                label: "SKU",
                prop: "sku",
                align: 'left'
            },
            {
                label: "单位",
                prop: "unit",
            },
            {
                label: "收件数量",
                prop: "qty",
            },
            {
                label: "合格数量",
                prop: "qualified",
            },
            {
                label: "不合格数量",
                prop: "unqualified",
            },
            {
                label: "质检重量（kg）",
                prop: "inspectweight",
                minWidth: "120",
            },
            {
                label: "质检长度（cm）",
                prop: "inspectlenth",
                minWidth: "120",
            },
            {
                label: "质检宽度（cm）",
                prop: "inspectwidth",
                minWidth: "120",
            },
            {
                label: "质检高度（cm）",
                prop: "inspectheight",
                minWidth: "120",
            },
        ],
        tableData: [],
    },
    // 货架信息
    {
        tabName: "货架信息",
        label: "onshelflist",
        activeName: "4",
        height: 300,
        descriptions: false,
        form: false,
        table: true,
        total: true,
        totalQty:0,
        tableColumn: [
            {
                label: "产品条码",
                prop: "barcode",
                align: 'left'
            },
            {
                label: "名称",
                prop: "name",
            },
            {
                label: "SKU",
                prop: "sku",
            },
            {
                label: "是否上架",
                prop: "shelfStatusStr",
            },
            {
                label: "仓库",
                prop: "warehouse",
                minWidth: "150",
            },
            {
                label: "位置",
                prop: "location",
            },
            {
                label: "数量",
                prop: "qty",
            },
            {
                label: "操作人员",
                prop: "operator",
            },
            {
                label: "上架日期",
                prop: "on_shelfTime",
                minWidth: "150",
                align: 'left'
            },
        ],
        tableData: [],
    },
    // 物流信息
    {
        tabName: "物流信息",
        label: "logistics",
        activeName: "5",
        descriptions: false,
        form: true,
        table: false,
        total: false,
        logisticsInfoFormData: [{
            label: '承运商：',
            prop: 'carriers',
            type: 'input'
        }, {
            label: '收件物流单号：',
            prop: 'waybillno',
            type: 'input'
        }, {
            label: '国内揽件仓库：',
            prop: 'pickWarehouse',
            type: 'input'
        }, {
            label: '物流产品：',
            prop: 'logisticsProduct',
            type: 'input'
        }, {
            label: '发件人英文名：',
            prop: 'senderName',
            type: 'input'
        }, {
            label: '发件人公司英文名：',
            prop: 'senderCompanyName',
            type: 'input'
        }, {
            label: '城市：',
            prop: 'city',
            type: 'input'
        }, {
            label: '发件公司：',
            prop: 'sendCompany',
            type: 'input'
        }, {
            label: '发件人电话：',
            prop: 'senderPhone',
            type: 'input'
        }, {
            label: '社会信用代码：',
            prop: 'creditCode',
            type: 'input'
        }, {
            label: '发件地址（英文）：',
            prop: 'sendAddressEn',
            type: 'textarea',
            class: 'address'
        }, {
            label: '发件地址（中文）：',
            prop: 'sendAddressCn',
            type: 'textarea',
            class: 'address'
        }],
        logistics: {}
    }
])
// 出库
export const outboundOrderTable = reactive([{
    tabName: "出库商品信息",
    label: "skuInfo",
    activeName: "0",
    height: 300,
    descriptions: false,
    form: false,
    table: true,
    tableColumn: [
        {
            label: "图片",
            prop: "picture",
            minWidth: "150",
        },
        {
            label: "SKU",
            prop: "sku",
            align: 'left'
        },
        {
            label: "产品条码",
            prop: "barcode",
            align: 'left'
        },
        {
            label: "名称",
            prop: "chinesename",
            align: 'left'
        },
        {
            label: "重量",
            prop: "fillweight",
        },
        {
            label: "数量",
            prop: "quantity",
        },
        {
            label: "SNcode",
            prop: "sncode",
        },
    ],
    tableData: [],
}, {
    tabName: "收发件人信息",
    label: "orderInfo",
    activeName: "1",
    descriptions: false,
    form: true,
    table: false,
    formData: [{
        label: '收件人英文名：',
        prop: 'receiver',
        type: 'input'
    }, {
        label: '电子邮箱：',
        prop: 'receiveemail',
        type: 'input'
    }, {
        label: '城市：',
        prop: 'receivecity',
        type: 'input'
    }, {
        label: '收件人公司：',
        prop: 'receivecompany',
        type: 'input'
    }, {
        label: '邮编：',
        prop: 'receivepostcode',
        type: 'input'
    }, {
        label: '收件地址：',
        prop: 'receiveaddress',
        type: 'input'
    }, {
        label: '收件人电话：',
        prop: 'receivetelephone',
        type: 'input'
    }, {
        label: 'Suburb：',
        prop: 'receivesuburb',
        type: 'input'
    }],
    takealotFormData: [{
        label: '收件人英文名：',
        prop: 'receiver',
        type: 'input'
    }, {
        label: '电子邮箱：',
        prop: 'receiveemail',
        type: 'input'
    }, {
        label: '城市：',
        prop: 'receivecity',
        type: 'input'
    }, {
        label: '收件人公司：',
        prop: 'receivecompany',
        type: 'input'
    }, {
        label: '邮编：',
        prop: 'receivepostcode',
        type: 'input'
    }, {
        label: '收件地址：',
        prop: 'receiveaddress',
        type: 'input'
    }, {
        label: '收件人电话：',
        prop: 'receivetelephone',
        type: 'input'
    }, {
        label: 'Suburb：',
        prop: 'receivesuburb',
        type: 'input'
    },  {
        label: '预订日期：',
        prop: 'appointmentdate',
        type: 'input'
    }, {
        label: '预订时间段：',
        prop: 'appointmenttime',
        type: 'input'
    }, {
        label: '预订参考号：',
        prop: 'thirdnumber',
        type: 'input'
    }, {
        label: '预订证明：',
        prop: 'bookconfirmfile',
        name: 'bookConfirmFileName',
        type: 'file'
    }],
    orderInfo: {}
}])

// 出库 + 导出

export const outboundExportOrderTable = reactive([
    // 订单基础信息
    {
        tabName: "订单基础信息",
        label: "basic",
        activeName: "0",
        descriptions: true,
        form: false,
        table: false,
        outboundOrder: [{
            label: '出库订单号：',
            prop: 'orderno',
        }, {
            label: '出库商品总数：',
            prop: 'quantity',
        }, {
            label: '出库总费用：',
            prop: 'cost',
            price: true
        }, {
            label: '客户名称：',
            prop: 'customer',
        }, {
            label: '基础价格：',
            prop: 'basiccost',
            price: true
        }, {
            label: '客户代码：',
            prop: 'customercode',
        }, {
            label: '加收费用：',
            prop: 'extracost',
            price: true
        }, {
            label: '出库时间：',
            prop: 'outboundtime',
        }, {
            label: '加收原因：',
            prop: 'extracostremark',
        }, {
            label: '出库仓库：',
            prop: 'storehouseen',
        }],
        inboundOrderDetail: {}
    },
    // 出库商品明细
    {
        tabName: "出库商品信息",
        label: "skuInfo",
        activeName: "1",
        height: 300,
        descriptions: false,
        form: false,
        table: true,
        tableColumn: [
            {
                label: "SKU",
                prop: "sku",
                align: 'left'
            },
            {
                label: "商品名称",
                prop: "name",
                align: 'left'
            },
            {
                label: "上架时间",
                prop: "onshelfdate",
            },
            {
                label: "出库数量",
                prop: "lockquantity",
            },
        ],
        tableData: [],
    }

])


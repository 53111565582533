import {GET, POST} from "@/utils/request.js";
import { removeToken } from "@/utils/auth.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { nextTick } from "vue";
export const EXPORT_FILE_GET = (option) => GET({ ...option, responseType: 'blob' })
export const EXPORT_FILE_POST = (option) => POST({ ...option, responseType: 'blob' })
export const DOWNLOAD_FILE = (res) => {
  // 获取文件类型
  const contentType = res.headers['content-type']
  if (contentType.indexOf('application/json') > -1) {
    res.data.text().then(data => {
      const res = JSON.parse(data)
      if (res.code === 3000012) {
        ElMessage.error({ message: res.message, type: 'error', duration: 3 * 1000 })
        nextTick(() => {
          removeToken();
          router.replace("/login");
        })
      } else {
        ElMessageBox.confirm(res.message, 'Error', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          type: 'error'
        })
          .then(() => {})
          .catch(() => {})
      }
    })
    return false
  }
  // 获取文件名
  const fileNameEncode = res.headers['content-disposition']?.split('filename=')[1]?.split(';')[0] || res.headers['content-disposition']?.split('fileName=')[1]?.split(';')[0] 
 
  const fileName = decodeURI(fileNameEncode).replaceAll('"', '')

  // 创建一个 Blob 对象
  const blob = new Blob([res.data], { type: contentType })

  // 创建一个下载链接
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.style.display = 'none'
  // 将链接添加到 DOM 并触发点击
  document.body.appendChild(link)
  link.click();

  // 移除链接
  document.body.removeChild(link)
  window.URL.revokeObjectURL(link.href)
}
/*
 * @Author: vere
 * @Date: 2024-02-02 15:30:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 15:45:32
 * @Description: 
 */

// import { createApp } from 'vue'
// import App from '../App.vue'
// const app = createApp(App)
// app.directive('el-select-loadmore', {
//   beforeMount(el, binding) {
//     // 获取element-ui定义好的scroll盒子
//     const SELECTWRAP_DOM = el.querySelector(
//       '.el-select-dropdown .el-select-dropdown__wrap'
//     )
//     SELECTWRAP_DOM.addEventListener('scroll', () => {
//       /**
//        * scrollHeight 获取元素内容高度(只读)
//        * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
//        * clientHeight 读取元素的可见高度(只读)
//        * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
//        * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
//        */
//       const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight
//       if (condition) {
//         binding.value()
//       }
//     })
//   }
// })


const selectLoadmore = {
  mounted(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      '.el-select-dropdown .el-select-dropdown__wrap'
    )
    SELECTWRAP_DOM.addEventListener('scroll', () => {
      /**
       * scrollHeight 获取元素内容高度(只读)
       * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
       * clientHeight 读取元素的可见高度(只读)
       * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
       * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
       */
      const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight
      if (condition) {
        binding.value()
      }
    })
  }
}
export default selectLoadmore


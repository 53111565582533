/*
 * @Author: vere
 * @Date: 2024-02-05 15:17:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-05 15:29:49
 * @Description: 
 */
import { reactive, toRefs } from 'vue';

export const useUserInfo = defineStore('userInfo', () => {

    // 相当于 state
    const state = reactive({
        userInfo: {},
        permissions: [],
        buttonPermissions: [],
        routesPermissions: []

    })
    // 相当于 ACTIONS
    const setUserInfo = (val) => {
        state.userInfo = val;
    }
    // 取路由权限
    const routesIdentity = (data) => {
        const identities = reactive([]);

        data.forEach((obj) => {
            if (obj.hasOwnProperty("childs") && Array.isArray(obj.childs)) {
                obj.childs.forEach((child) => {
                    // if (child.hasOwnProperty("childs") && Array.isArray(child.childs)) {
                    //   child.childs.forEach((grandChild) => {
                    if (child.hasOwnProperty("identity")) {
                        identities.push(child.identity);
                    }
                    //   });
                    // }
                });
            } else {
                return;
            }
        });

        return identities;
    }
    // 取按钮权限
    const extractIdentity = (data) => {
        const identities = reactive([]);

        data.forEach((obj) => {
            if (obj.hasOwnProperty("childs") && Array.isArray(obj.childs)) {
                obj.childs.forEach((child) => {
                    if (child.hasOwnProperty("childs") && Array.isArray(child.childs)) {
                        child.childs.forEach((grandChild) => {
                            if (grandChild.hasOwnProperty("identity")) {
                                identities.push(grandChild.identity);
                            }
                        });
                    }
                });
            } else {
                return;
            }
        });

        return identities;
    }
    // 存储 路由权限 和 按钮权限
    const setPermissions = (val) => {
        val.map(item => {
            if (item.identity == '/inbound') {
                item.childs.push({
                    identity: "/inbound/create", name: "新建入库单", hidden: true, childs: []
                })
            }
            if (item.identity == "/outbound") {
                item.childs.push({
                    identity: "/outbound/detail", path: "/outbound/detail/:id", name: "运单详情", hidden: true, childs: []
                })
            }
            if (item.identity == '/storage-center') {
                item.childs.push({
                    identity: "/storage-center/inventory-detail",path: "/storage-center/inventory-detail/:id", name: "查看盘点单", hidden: true, childs: []
                })
            }
            if(item.identity == '/reports') {
                item.childs.map(child => {
                    if(child.identity == '/reports/storehouse-detail') {
                        child.path = '/reports/storehouse-detail/:date?/:storehouseid?'
                    }
                    return child
                })

            }
            return item
        })
        const menuData = val;
        menuData.forEach((item) => {
            item.childs.forEach((val) => {
                if (val.childs) return;
                val.childs = [];
            });
        });

        let deepMenuData = JSON.parse(JSON.stringify(menuData));
        deepMenuData = deepMenuData.filter(item => item.identity !== '/home')
        state.buttonPermissions = extractIdentity(menuData)
        state.routesPermissions = routesIdentity(menuData)
        state.permissions = val;
        localStorage.setItem("routes", JSON.stringify(deepMenuData));
        localStorage.setItem(
            "buttonPermission",
            JSON.stringify(extractIdentity(menuData))
        );
        localStorage.setItem(
            "routesPermission",
            JSON.stringify(routesIdentity(menuData))
        );

    }
    return {
        ...toRefs(state),
        setUserInfo,
        setPermissions
    }
}, {
    persist: true
})

export  const apiExportList = [
    '/inbound/export', 
    '/inbound/exportAll',
    '/inbound/printlist',
    '/inbound/printboxlabel',
    '/inbound/printboxlabel',
    '/inbound/printlist',
    '/inbound/receiveFeeReport/exportById',
    '/inbound/receiveFeeReport/export_list',
    '/inbound/downloadtemplate',
    '/outbound/exportList',
    '/outbound/exportDataAll',
    '/outbound/exportDetailList',
    '/outbound/printWaybill',
    '/outbound/downloadTemplate',
    '/outboundreport/exportdetail',
    '/outboundreport/export',
    '/ClientInventory/exportInventory',
    '/ClientInventory/exportInventoryDetail',
    '/ClientInventory/export',
    '/customerrecharge/export',
    '/valueaddorder/export',
    '/valueaddfeereport/export',
    '/storagefeedetail/export',
    '/storagefee/export',
    '/balancechange/export',
    '/product/index/printbarcode',
    '/product/index/export_data', // 以上导出， 以下上传
    // '/outbound/uploadFile/takeALot'
]

/**出库计费报表 */
export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true, // 操作按钮是否单独占一行
  searchList: [
    // 检索列表
    {
      label: "出库时间:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // pickerOptions: pickerOptions()
    },
    /* {
      label: "出库订单号:",
      type: "input",
      value: "",
      prop: "orderno",
      maxlength: 30,
      placeholder: '请输入出库订单号'
    }, */
    {
      label: "",
      type: "select",
      value: "orderno",
      prop: "orderSelect",
      optionData:  [
        {label: '出库订单号', value: 'orderno'},
        {label: '运单号', value: 'waybillno'},
        {label: '参考号', value: 'remarkno'},
      ],
      
    },
    {
      type: "input",
      value: "",
      prop: "orderSelectVal",
      maxlength: 30,
    },
    {
      label: "出库仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: '全部', value: '-1' }],
      clearable: false,
    },
  ]
})
export const tableOption = reactive({
  height: 550,
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "出库时间",
      prop: "outboundtime",
      minWidth: "130",
    },
    {
      label: "出库订单号",
      prop: "orderno",
      minWidth: "150",
    },
    {
      label: "运单号",
      prop: "trackingno",
      minWidth: "150",
    },
    {
      label: "参考号",
      prop: "remarkno",
      minWidth: "150",
    },
    {
      label: "出库仓库",
      prop: "storehouseen",
      minWidth: "150",
    },
    {
      label: "出库商品总数",
      prop: "quantity",
      minWidth: "100",
    },
    {
      label: "出库费用（人民币）",
      prop: "cost",
      minWidth: "150",
    },
    {
      label: "备注",
      prop: "remark",
      minWidth: "100",
    },
  ],
  // 表格数据，必填
  tableData: [],
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  operationBtn: [
    {
      title: "导出",
      prop: "export",
    },
  ],
})
export const detailTableOption = reactive({
  height: 240,
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "SKU",
      prop: "sku",
    },
    {
      label: "商品名称",
      prop: "name",
    },
    {
      label: "上架时间",
      prop: "onshelfdate",
    },
    {
      label: "出库数量",
      prop: "lockquantity",
    },
  ],
  // 表格数据，必填
  tableData: [],

})





<template>
  <section>
    <!-- 产品管理 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
        </com-search>
      </div>

      <el-row class="mt-lg mb-lg">
        <el-col :span="16">
          <el-button
            type="primary"
            plain
            icon="Upload"
            @click="operateClick('export')"
            >导出</el-button
          >
          <el-button
            type="primary"
            plain
            icon="Printer"
            @click="operateClick('print')"
            >打印条码</el-button
          >
        </el-col>
        <el-col :span="8" class="justify-end">
          <el-button
            v-buttonPermission="`${routePath}/Add`"
            type="primary"
            icon="DocumentAdd"
            @click="operateClick('dialog', 'create', { id: 0 })"
            >创建商品</el-button
          >
        </el-col>
      </el-row>
      <el-table
        stripe
        height="450"
        style="width: 100%; margin: 10px 0 20px"
        :header-cell-style="{ background: '#FAFAFA' }"
        :border="true"
        :data="tableOption.tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :width="column.width"
          :align="column.align || 'center'"
          :fixed="column.fixed"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'inboundorderno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row)"
              >
                <span class="link">{{ row.inboundorderno }}</span>
              </el-link>
            </div>
            <div v-else-if="column.prop == 'productimgurl'">
              <img
                v-if="row.productimgurl"
                :src="row.productimgurl"
                style="margin: 0 auto"
                width="40"
                height="40"
                alt="img"
              />
            </div>
            <div v-else-if="column.prop == 'declaredvalue'">
              {{ (row.declaredvalue / 100).toFixed(2) }}
            </div>
            <div v-else-if="column.prop == 'productstatus'">
              <el-switch
                v-model="row.productstatus"
                :active-value="0"
                :inactive-value="1"
                @change="operateStatus(row.id, row.productstatus)"
              />
            </div>
            <div v-else-if="column.prop == 'snstatus'">
              <!--   -->
              <el-switch
                v-buttonPermission="`${routePath}/SNreg`"
                v-model="row.snstatus"
                :active-value="1"
                :inactive-value="0"
                @change="operateSN(row.id, row.snstatus)"
              />
            </div>
            <div v-else-if="column.prop == 'operation'">
              <!--   -->
              <el-button
                v-buttonPermission="`${routePath}/Edit`"
                type="primary"
                link
                @click="operateClick('dialog', 'edit', row)"
              >
                编辑</el-button
              >
              <el-button
                type="primary"
                link
                @click="operateClick('dialog', 'view', row)"
                >查看</el-button
              >
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pageNum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <products-detail
      :detailType="detailType"
      :id="detailId"
      :dialogVisible="dialogVisible"
      @openDialog="openDialog"
      @freshData="freshData"
    >
    </products-detail>
  </section>
</template>
<script setup>
import { tableOption } from "./column";
import productsDetail from "@/components/product-detail/index.vue";
import {
  productlistApi,
  disableproductApi,
  enableproductApi,
  snOffApi,
  snOnApi,
  exportApi,
  printbarcodeApi,
} from "@/api/product.js";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

defineOptions({
  name: "BasicSettingProducts",
});

const route = useRoute();
const curpath = route.path; // 获取当前url
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  labelWidth: "80px",
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "productName",
      maxlength: 50,
      placeholder: "请输入产品中文名/英文名",
      selectOption: {
        value: "0",
        prop: "nameType",
        optionData: [
          { label: "产品中文名", value: "0" },
          { label: "产品英文名", value: "1" },
        ],
        clearable: false,
      },
    },
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "productcatalogName",
      maxlength: 50,
      placeholder: "请输入产品类型",
      initSelectValue: true,
      selectOption: {
        value: "1",
        prop: "productcatalogLevel",
        optionData: [
          { label: "产品类型(一级)", value: "1" },
          { label: "产品类型(二级)", value: "2" },
          { label: "产品类型(三级)", value: "3" },
        ],
        clearable: false,
      },
    },
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "skuCode",
      maxlength: 50,
      placeholder: "请输入产品条码/SKU",
      selectOption: {
        value: "0",
        prop: "skuOrCode",
        optionData: [
          { label: "产品条码", value: "0" },
          { label: "SKU", value: "1" },
        ],
        clearable: false,
      },
    },
    {
      label: "审核结果:",
      type: "select",
      value: "",
      prop: "examinestatus",
      optionData: [
        { label: "已审核", value: "1" },
        { label: "未审核", value: "0" },
      ],
      clearable: true,
      placeholder: "请选择审核结果",
    },
    {
      label: "产品状态:",
      type: "select",
      value: "",
      prop: "productstatus",
      optionData: [
        { label: "启用", value: "0" },
        { label: "未启用", value: "1" },
      ],
      clearable: true,
      placeholder: "请选择产品状态",
    },
    {
      label: "带磁带电:",
      type: "select",
      value: "",
      prop: "withmagnetictapeflg",
      optionData: [
        { label: "否", value: "0" },
        { label: "是", value: "1" },
      ],
      clearable: true,
      placeholder: "请选择是否带磁带电",
    },
  ],
});
// 搜索入参
const params = ref({
  pageNum: 1,
  pageSize: 10,
  productcatalogLevel: "1", // 产品类型
});
const selectionList = ref([]); // 复选框选中list
/**
 * 获取数据列表
 */
const getList = () => {
  // console.log({ ...params });
  productlistApi(params.value).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj 参数
 */
const search = (obj) => {
  console.log({ ...obj });
  if (obj.nameType == "0") {
    obj.productNameCN = obj.productName || "";
  } else {
    obj.productNameEN = obj.productName || "";
  }
  if (obj.skuOrCode == "0") {
    obj.productbarcode = obj.skuCode || "";
  } else {
    obj.sku = obj.skuCode || "";
  }
  delete obj.nameType;
  delete obj.productName;
  delete obj.skuOrCode;
  delete obj.skuCode;
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = 10;
  params.value = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "productName" || item.prop == "skuCode") {
      item.selectOption.value = "0";
    }
    if (item.prop == "productcatalogName") {
      item.selectOption.value = "1";
    }
  });
  params.value = {
    pageNum: 1,
    pageSize: params.value.pageSize,
    productcatalogLevel: "1",
  };
  handleCurrentChange(1);
};
/**
 * 页面操作按钮
 */
const detailType = ref("create"); // 弹框 弹出类型 create: 创建 edit: 编辑 view: 查看
const dialogVisible = ref(false); // 弹框 状态
const detailId = ref(0); // 产品id
const operateClick = (prop, operationStatus, row) => {
  // console.log(prop, operationStatus, row)
  switch (prop) {
    case "export":
      exportApi(params.value).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "print":
      if (selectionList.value.length <= 0)
        return ElMessage.error("请选择需要打印条码的商品!");
      const ids = selectionList.value.join(",");
      printbarcodeApi({
        idstr: ids,
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "dialog":
      detailType.value = operationStatus;
      detailId.value = row.id;
      dialogVisible.value = true;
      break;
  }
};

const openDialog = (val) => {
  dialogVisible.value = val;
};

const freshData = (val) => {
  getList();
};
/**
 * 复选框 选中列表
 */
const handleSelectionChange = (val) => {
  selectionList.value = val.map((item) => item.id);
};
/**
 * 操作产品状态
 */
const operateStatus = (id, status) => {
  let api;
  status == 0 ? (api = enableproductApi) : (api = disableproductApi);
  api(id).then((res) => {
    console.log(res);
  });
};
/**
 * 操作sn状态
 */
const operateSN = (id, status) => {
  let api;
  status == 0 ? (api = snOffApi) : (api = snOnApi);
  api(id).then((res) => {});
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pageNum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "产品管理",
  });
};
const routePath = computed(() => {
  return route.path;
});
onMounted(() => {
  getList();
});

onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>
<style scope lang="scss"></style>

/**增值服务报表 */
 export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "80px",
  searchList: [
    // 检索列表
    {
      label: "提交时间:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions:pickerOptions()
    },
    {
      label: "充值方式:",
      type: "select",
      value: "-1",
      prop: "rechargetype",
      optionData: [{label: '全部', value: '-1'}],
    },
    {
      label: "流水号:",
      type: "input",
      value: "",
      prop: "flowno",
      maxlength: 30,
      placeholder: '请输入流水号'
    },
    {
      label: "操作账户:",
      type: "input",
      value: "",
      prop: "operate",
      maxlength: 30,
      placeholder: '请输入操作账户'
    },
    {
      label: "审核状态:",
      type: "select",
      value: -1,
      prop: "auditstatus",
      optionData: [{label: '全部', value: -1}],
    },
  ],
})

export const tableOption = reactive({
  height: 550,
  selection: true,
  tableColumn: [
   
    {
      label: "提交时间",
      prop: "submittime",
      minWidth: "180",
      align:'left'
    },
    {
      label: "审核状态",
      prop: "auditstatus",
      minWidth: "150",
    },
    {
      label: "流水号",
      prop: "balancechangeflowno",
      minWidth: "200",
      align:'left'
    },
    {
      label: "充值金额",
      prop: "rechargeamount",
      minWidth: "100",
      align:'right'
    },
    {
      label: "实际到账",
      prop: "actualamount",
      minWidth: "100",
      align:'right'
    },
    {
      label: "币种",
      prop: "currencytype",
      minWidth: "100",
    },
    {
      label: "操作账户",
      prop: "operator",
      minWidth: "180",
      align:'left'
    },
    {
      label: "充值方式",
      prop: "type",
      minWidth: "150",
    },
    {
      label: "备注",
      prop: "remark",
      minWidth: "200",
      align:'left'
    },
    {
      label: "操作",
      prop: "filelink",
      fixed: 'right',
      minWidth: "130",
      align:'left'
    },
  ],

  // 表格数据，必填
  tableData: [],
  
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  operationBtn: [
    {
      title: "作废",
      prop: "cancel",
    },
    {
      title: "导出",
      prop: "export",
    },
  ],
})









<template>
  <section class="layout-distance">
    <el-row type="flex" :gutter="20" class="home">
      <el-col :span="18" class="column_between">
        <el-card
          class="card-section"
          shadow="never"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="justify_between align_center">
            <p class="title">{{ item.title }}</p>
            <el-button
              v-if="item.btn"
              v-routePermission="`/storage-center/stockquery`"
              type="primary"
              size="small"
              plain
              @click="topage('stock')"
              >查看库存详情</el-button
            >
          </div>
          <ul class="list">
            <li class="item" v-for="(dataItem, i) in item.list" :key="i">
              <p class="name">{{ dataItem.label }}</p>
              <p class="count">{{ item.data[`${dataItem.prop}`] || 0 }}</p>
            </li>
          </ul>
        </el-card>
      </el-col>
      <el-col :span="6" class="column_between">
        <el-card class="card-section account-section" shadow="never">
          <div class="justify_between align_center">
            <p class="title">账户信息</p>
            <el-link
              v-buttonPermission="`/home/UpdateShowAccountFlg`"
              :underline="false"
              type="primary"
              class="btn"
              @click="changShowAccountFlg"
              >{{ account_showflg ? "隐藏账户余额" : "显示账户余额" }}</el-link
            >
          </div>
          <div class="detail-account">
            <p class="num">
              {{ account_showflg ? account_balance : "********" }}
            </p>
            <p class="name account-name">账户余额</p>
            <p class="num">
              {{ account_showflg ? account_credit : "********" }}
            </p>
            <p class="name account-name">信用额度</p>
          </div>
          <div class="btn-section justify_center">
            <el-button
              v-routePermission="`/management/recharge`"
              type="primary"
              @click="topage('recharge')"
              >账户充值</el-button
            >
            <el-button
              v-routePermission="`/management/account-statement`"
              type="primary"
              plain
              @click="topage('rechargesearch')"
              >计费流水查询</el-button
            >
          </div>
        </el-card>
        <el-card class="card-section time-section" shadow="never">
          <p class="name">南非时间（UTC+2）</p>
          <p class="time">{{ southAfricaTime }}</p>
        </el-card>
        <el-card class="card-section time-section" shadow="never">
          <p class="name">北京时间（UTC+8）</p>
          <p class="time">{{ beijingTime }}</p>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>
  <script setup>
import { useRouter } from "vue-router";
import { onBeforeUnmount } from "vue";
import { dataList } from "./data.js";
import { homeDataApi, changShowAccountFlgApi } from "@/api/home";
import { useKeepAliveStore } from "@/stores/keepAliveStore.js";
import dayjs from "dayjs";
defineOptions({
  name: "Home",
});


const keepAliveStore = useKeepAliveStore(); // 缓存

const account_balance = ref(""); // 账户余额
const account_credit = ref(""); // 信用额度
const account_showflg = ref(1); // 0-隐藏；1-显示；


const timer = ref(null);
const southAfricaTime = ref(""); //南非时间
const beijingTime = ref(""); // 北京时间

onMounted(() => {
  getData(); // 初始化数据
  getTimes(); // 初始化更新时间
  timer.value = setInterval(getTimes, 1000); // 每秒更新一次时间
});
onBeforeUnmount(() => {
  if (timer.value) {
    clearInterval(timer.value);
  }
});

/**
 * 初始化数据
 */
const getData = () => {
  homeDataApi().then((res) => {
    account_balance.value = res.account_balance;
    account_credit.value = res.account_credit;
    account_showflg.value = res.account_showflg;
    dataList.map((item) => {
      switch (item.label) {
        case "countInbound":
          item.data = res.countInbound;
          break;
        case "countOutbound":
          item.data = res.countOutbound;
          break;
        case "countStorage":
          item.data = res.countStorage;
          break;
      }
    });
  });
};
/**
 * 页面跳转
 */
const topage = (page) => {
  switch (page) {
    case "stock":
      // 库存详情
      keepAliveStore.refresCurrentRoute("/storage-center/stockquery");
      break;
    case "recharge":
      // 账户充值
      keepAliveStore.refresCurrentRoute("/management/recharge");
      break;
    case "rechargesearch":
      // 计费流水查询 即 账户流水
      keepAliveStore.refresCurrentRoute("/management/account-statement");
      break;
  }
};
/**
 * 是否显示账户余额
 */
const changShowAccountFlg = () => {
  changShowAccountFlgApi({
    showaccountflg: account_showflg.value == 1 ? 0 : 1,
  }).then((res) => {
    getData();
  });
};
/**
 * 获取 时间
 */
const getTimes = () => {
  const beijing = dayjs().utcOffset("+08:00");
  const southAfrica= dayjs().utcOffset("+02:00");
  // 南非时间
  southAfricaTime.value = `${southAfrica.format(
    "HH"
  )}:${southAfrica.format("mm")}:${southAfrica.format(
    "ss"
  )} ${southAfrica.format("ddd")}`;

  // 北京时间
  beijingTime.value = `${beijing.format("HH")}:${beijing.format(
    "mm"
  )}:${beijing.format("ss")} ${beijing.format("ddd")}`;
};


</script>
<style lang="scss" scoped>
  .home {
    align-items: stretch;
  }
  .card-section {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &.account-section {
      min-height: 302px;
      background: linear-gradient(180deg, #f8f3ed, #fff);
      :deep(.el-card__body) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &.time-section {
      height: 116px;
    }
    p {
      margin: 0;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .list {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      margin: 16px 0 0;
      .item {
        width: 18.72%;
        padding: 14px 12px;
        border-radius: 3px;
        background: #fcf9f5;
        &:not(:last-child) {
          margin-right: 1.6%;
        }
      }
    }
    .name {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      color: #636363;
      margin-bottom: 12px;
      &.account-name {
        color: #8f8f8f;
      }
    }
    .count {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #191919;
    }
    .btn {
      // color: #BC8D4D;
      padding: 0;
      font-size: 12px;
    }
    .btn-section {
      .el-button {
        font-size: 14px;
        width: 126px;
        text-align: center;
        margin-top: 7px;
      }
    }
    .detail-account {
      text-align: center;
      .num {
        font-size: 28px;
        font-weight: bold;
        line-height: 36px;
        color: #bc8d4d;
        margin: 18px 0 16px;
        min-height: 36px;
      }
    }
    .time {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      color: #1f1f20;
    }
  }
</style>
  
  
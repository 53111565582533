<template>
  <section>
    <!-- 仓储计费报表 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnExport"
          >导出</el-button
        >
      </el-row>
      <el-table
        :data="tableOption.tableData"
        :border="true"
        :header-cell-style="{ background: '#FAFAFA' }"
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="450"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100" align="left">
          <template #default="scope">
            <el-button type="primary" link @click="viewDetail(scope.row)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </section>
</template>

<script setup>
import { tableOption } from "./column";
import { storagefeeListApi, storagefeeExportApi } from "@/api/report";
import { getstoreselectApi } from "@/api/common";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
// import { useKeepAliveStore } from '@/stores/keepAliveStore.js'
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { DOWNLOAD_FILE } from "@/utils/export-file.js";

defineOptions({
  name: "ReportsStorehouse",
});
const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // labelWidth: "90px",
  searchList: [
    // 检索列表
    {
      label: "日期:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // pickerOptions: pickerOptions()
    },
    {
      label: "当前仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: "全部", value: "-1" }],
      clearable: false,
    },
  ],
});
const params = ref({
  pagenum: 1, // 请求 某页 数据
  pageSize: 10,
});

const router = useRouter();
// const keepAliveStore = useKeepAliveStore()

/**
 * 时间筛选 限制在1年内
 */
const dateRange = ref([]); // 选择的时间范围
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
/**
 * 获取数据列表
 */
const getList = () => {
  storagefeeListApi(params.value).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */

const search = (obj) => {
  if (dateRange.value && dateRange.value.length) {
    obj.starttime = dateRange.value[0];
    obj.endtime = dateRange.value[1];
  }
  if (obj.storehouseid === "-1") {
    delete obj.storehouseid;
  }
  params.value = {
    ...obj,
    pagenum: 1,
    pageSize: 10,
  };
  getList();
};

/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "storehouseid") {
      item.value = "-1";
    }
  });
  dateRange.value = "";
  params.value = {
    pagenum: 1,
    pageSize: params.value.pageSize,
  };
  handleCurrentChange(1);
};

/**
 * 查看明细
 * @param {*} row  行信息
 */
const viewDetail = (row) => {
  router.push({
    path: `/reports/storehouse-detail/${row.date}/${row.storehouseid}`,
  });
  // keepAliveStore.refresCurrentRoute({
  //   path: "/reports/storehouse-detail",
  //   query: {
  //     date: row.date,
  //     storehouseid: row.storehouseid,
  //   },
  // })
};
/**
 * 导出
 */
const operationBtnExport = () => {
  storagefeeExportApi(params.value).then((res) => {
    DOWNLOAD_FILE(res);
  });
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pagenum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pagenum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 获取仓库列表
 */
const getWarehouseList = () => {
  // type: null/-1查全部；0-海外仓；1-不良品仓；
  getstoreselectApi({ type: -1 }).then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = [{ label: "全部", value: "-1" }, ...res.list];
      }
    });
  });
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "仓储计费报表",
  });
};
onMounted(() => {
  getWarehouseList(); // 获取仓库列表
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>
<style lang="scss" scoped></style>

<template>
    <div :class="['_crud',{'no-searchList':!searchOption.searchList}]">
     
  
      <el-card class="table-area" shadow="never">
        <div v-if="searchOption.searchList">
          <div class="table-filter">
            <com-search
              :search-option="searchOption"
              @search="
                (Obj) => {
                  $emit('search', Obj);
                }
              "
              @reset="()=>{$emit('reset')}"
              @otherBtnsClick="
                (prop) => {
                  $emit('otherBtnsClick', prop);
                }
              "
              @remoteMethod="
                (cb) => {
                  $emit('remoteMethod', cb);
                }
              "
              @remoteValue="
                (prop) => {
                  $emit('remoteValue', prop);
                }
              "
              @inputRemoteValue="
                (prop) => {
                  $emit('inputRemoteValue', prop);
                }
              "
              @autoCompleteFocus="(prop) => {
                $emit('autoCompleteFocus', prop);
              }"
            >
              <template #specialBtns>
                <slot name="btns"></slot>
              </template>
              <template #date>
                <slot name="date-layout"></slot>
              </template>
            </com-search>
          </div>
        </div>
        <!-- table 上面信息 包括详情 按钮等-->
        <div class="upper-detail">
            <slot name="other-detail" ></slot>
            <slot name="upper-btn-operation" ></slot>
        </div>
        <com-table
          ref="CommonTableRef"
          :table-option="tableOption"
          :object-span-method="objectSpanMethod"
          @operationBtnClick="
            (prop) => {
              $emit('operationBtnClick', prop);
            }
          "
          @columnClick="
            (prop, row) => {
              $emit('columnClick', prop, row);
            }
          "
          @size-change="
            (val) => {
              $emit('size-change', val);
            }
          "
          @current-change="
            (val) => {
              $emit('current-change', val);
            }
          "
          @selection-change="(val) => {
            $emit('selection-change', val);
          }"
          @handle-current-single="(row) => {
            $emit('handle-current-single', row);
          }"
          @object-span-method="(rowIndex, columnIndex)=>{
            $emit('object-span-method', rowIndex, columnIndex)
          }"
          @handle-row-dbclick="(row) => {
            $emit('handle-row-dbclick', row);
          }"
        >
          
  
          <template
            v-for="(item,index) in tableOption.tableColumn"
            #[item.prop]="{ row }"
          >
            <slot :name="item.prop" :row="row">
              <template v-if="row.updateFlg && item.updateFlg">
                <el-input
                  :key="index"
                  v-model="row[item.prop]"
                  v-inputNumber.numberFloat2="8"
                  :disabled="tableOption.disabled"
                  @input="getInputRowData(row)"
                  :max="item.max"
                  :min="item.min"
                />
              </template>
              <template v-else>
                <span :key="index">{{ row[item.prop] }}</span>
              </template>
            </slot>
          </template>
        </com-table>
      </el-card>
    </div>
  </template>
  
  <script>
  import comSearch from '../com-search/index.vue'
  import comTable from '../com-table/index.vue'
  export default {
    name: 'tableLayout',
    components: { comSearch, comTable },
    props: {
      objectSpanMethod: {
        type: Function,
        default: () => {}
      },
      searchOption: {
        type: Object,
        default: () => {
          return {}
        }
      },
      tableOption: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      // 重置
      handldReset() {
        this.searchOption.searchList.forEach((item) => {
          const value = item.value
          if (value instanceof Array) {
            this.$set(item, 'value', [])
          } else if (value instanceof Object) {
            this.$set(item, 'value', {})
          } else {
            this.$set(item, 'value', '')
          }
        })
        this.$emit('reset')
      },
      getInputRowData(rowdata) {
        this.$emit('getInputRowData', rowdata)
      }
    }
  }
  </script>
    <style lang="scss" scoped>
  .upper-detail{
    margin: 25px 0
  }
  
  :deep(.el-pagination span:not([class*="suffix"])),
  .no-searchList{
    padding:0px;
    .table-area{
      margin:0;
      border:0 none;
      // padding: 10px;
      :deep(.el-card__body) {
        padding: 20px;
      }
    }
  }
  </style>
  
  
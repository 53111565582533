/**仓储计费明细报表 */
export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "140px",
  searchList: [
    // 检索列表
    {
      label: "日期:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions: pickerOptions()
    },
    {
      label: "sku:",
      type: "input",
      value: "",
      prop: "sku",
      maxlength: 30,
      placeholder: '请输入sku'
    },
    {
      label: "商品名称:",
      type: "input",
      value: "",
      prop: "product",
      maxlength: 30,
      placeholder: '请输入商品名称'
    },
    {
      label: "当前仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: "全部", value: "-1" }],
    },
    {
      label: "入库订单号/批次号:",
      type: "input",
      value: "",
      prop: "orderno",
      maxlength: 30,
      placeholder: '请输入入库订单号/批次号'
    },
  ]
})
export const tableOption = reactive({
  height: 550,
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "日期",
      prop: "date",
      minWidth: "130",
      align:'left'
    },
    {
      label: "sku",
      prop: "sku",
      minWidth: "130",
      align:'left'
    },
    {
      label: "商品名称",
      prop: "producten",
      minWidth: "150",
      align:'left'
    },
    {
      label: "数量",
      prop: "qty",
      minWidth: "100",
    },
    {
      label: "总计费体积m³",
      prop: "totalvolume",
      minWidth: "130",
    },
    {
      label: "当前库龄（天）",
      prop: "storehouseage",
      minWidth: "130",
    },
    {
      label: "库龄规则",
      prop: "storehouseagerule",
      minWidth: "270",
      align:'left'
    },
    {
      label: "费用（人民币）",
      prop: "storagefee",
      minWidth: "130",
      align:'right'
    },
    {
      label: "长（cm/PCS）",
      prop: "lenth",
      minWidth: "130",
    },
    {
      label: "宽（cm/PCS）",
      prop: "width",
      minWidth: "130",
    },
    {
      label: "高（cm/PCS）",
      prop: "height",
      minWidth: "130",
    },
    {
      label: "单毛重",
      prop: "weight",
      minWidth: "100",
    },
    {
      label: "单体积m³",
      prop: "volume",
      minWidth: "100",
    },
    {
      label: "入库订单号/批次号",
      prop: "inboundorderno",
      minWidth: "200",
      align:'left'
    },
    {
      label: "当前仓库",
      prop: "storehousename",
      minWidth: "150",
    },
    {
      label: "入库类型",
      prop: "inboundordertype",
      minWidth: "130",
    },
    {
      label: "上架时间",
      prop: "onshelfdate",
      minWidth: "130",
    },
  ],
  // 表格数据，必填
  tableData: [],
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  operationBtn: [
    {
      title: "导出",
      prop: "export",
    },
  ],
})







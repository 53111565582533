export const searchOption = reactive({
    labelWidth: "80px",
    // singleRow: true,
    searchList: [
        // 检索列表
        {
            label: "",
            type: "select-input",
            value: "",
            prop: "productName",
            maxlength: 50,
            placeholder: '请输入产品中文名/英文名',
            selectOption: {
                value: "0",
                prop: "nameType",
                optionData: [{ label: "产品中文名", value: "0"},
                { label: "产品英文名", value: "1" }],
                clearable: false,
            },
        },
        {
            label: "",
            type: "select-input",
            value: "",
            prop: "productcatalogName",
            maxlength: 50,
            placeholder: '请输入产品类型',
            initSelectValue: true,
            selectOption: {
                value: "1",
                prop: "productcatalogLevel",
                optionData: [{ label: "产品类型(一级)", value: "1"},
                { label: "产品类型(二级)", value: "2" }, { label: "产品类型(三级)", value: "3" }],
                clearable: false,
            },
        },
        {
            label: "",
            type: "select-input",
            value: "",
            prop: "skuCode",
            maxlength: 50,
            placeholder: '请输入产品条码/SKU',
            selectOption: {
                value: "0",
                prop: "skuOrCode",
                optionData: [{ label: "产品条码", value: "0"},
                { label: "SKU", value: "1" }],
                clearable: false,
            },
        },
        {
            label: "审核结果:",
            type: "select",
            value: "",
            prop: "examinestatus",
            optionData: [{ label: "已审核", value: "1"},
            { label: "未审核", value: "0" }],
            clearable: true,
            placeholder: '请选择审核结果'
        },
        {
            label: "产品状态:",
            type: "select",
            value: "",
            prop: "productstatus",
            optionData: [{ label: "启用", value: "0"},
            { label: "未启用", value: "1" }],
            clearable: true,
            placeholder: '请选择产品状态'
        },
        {
            label: "带磁带电:",
            type: "select",
            value: "",
            prop: "withmagnetictapeflg",
            optionData: [{ label: "否", value: "0"},
            { label: "是", value: "1" }],
            clearable: true,
            placeholder: '请选择是否带磁带电'
        },
    ],
})

export const tableOption = reactive({
    height: 550,
    index: true, // 是否展示序号 非必填
    tableColumn: [
      {
        label: "图片",
        prop: "productimgurl",
        minWidth: "80",
      },
      {
        label: "产品类型(一级)",
        prop: "onelevelname",
        minWidth: "200",
        align:"left"
      },
      {
        label: "产品类型(二级)",
        prop: "twolevelname",
        minWidth: "200",
        align:"left"
      },
      {
        label: "产品类型(三级)",
        prop: "threelevelname",
        minWidth: "200",
        align:"left"
      },
      {
        label: "产品条码",
        prop: "productbarcode",
        minWidth: "140",
        align:"left"
      },
      {
        label: "SKU",
        prop: "sku",
        minWidth: "140",
        align:"left"
      },
      {
        label: "中文名",
        prop: "chinesename",
        minWidth: "120",
      },
      {
        label: "英文名",
        prop: "englishname",
        minWidth: "140",
        align:"left"
      },
      {
        label: "带磁带电",
        prop: "withmagnetictapeflgStr",
        minWidth: "120",
      },
      {
        label: "申报价值(USD)",
        prop: "declaredvalue",
        minWidth: "120",
      },
      {
        label: "审核结果",
        prop: "examinestatusStr",
        minWidth: "120",
      },
      {
        label: "状态",
        prop: "productstatus",
        minWidth: "120",
      },
      {
        label: "SN登记出库",
        prop: "snstatus",
        minWidth: "120",
      },
      {
        label: "操作",
        prop: "operation",
        width: "130",
        fixed:'right',
        align:'left'
      },
    ],
  
    // 表格数据，必填
    tableData: [],
    operationColumn: {
      label: '费用详情',
      fixed: "right",  // 是否fixed
      minWidth: "60", // 最小宽度
      btns: [
        {
          title: "查看",
          prop: "detail",
          type: "primary",
          size: "small",
        },
      ],
    },
    page: {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    },
  })


<template>
  <section class="role">
    <el-card class="table-area-role" shadow="never">
      <el-row>
        <el-col :span="8" class="left-section col-section column-between">
          <div>
            <el-form>
              <el-form-item>
                <el-input
                  v-trim
                  v-model="form.role"
                  maxlength="20"
                  prefix-icon="Search"
                  clearable
                  placeholder="搜索角色"
                  style="width: 100%"
                />
              </el-form-item>
            </el-form>
            <el-table
              ref="singleTable"
              v-loading="isLoading"
              class="table"
              highlight-current-row
              :header-cell-style="{
                height: '0',
                padding: '0',
                display: 'none',
              }"
              :data="tableData"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick"
            >
              <el-table-column prop="rolename" width="auto" align="left">
                <template #default="{ row }">
                  <div class="justify-between align-center">
                    <span class="flex align-center">
                      <el-icon style="margin-right: 5px"><User /></el-icon>
                      {{ row.rolename }}</span
                    >
                    <el-dropdown
                      trigger="hover"
                      @command="handleClickDelete(row)"
                    >
                      <span class="el-dropdown-link">
                        <el-icon><MoreFilled /></el-icon>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="del"
                            >删除</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
              <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>


            </el-table>
          </div>

          <el-button
            v-buttonPermission="`${routePath}/NewRole`"
            type="primary"
            @click="dialogVisible = true"
            style="width: 100%"
            >创建新角色</el-button
          >
        </el-col>
        <el-col :span="16" class="right-section col-section column-between">
          <div class="tree-section">
            <div style="margin-bottom: 16px">
              <el-checkbox v-model="expendAll" @change="handelExpendAll"
                >展开/关闭</el-checkbox
              >
              <el-checkbox v-model="selectedAll" @change="handelSelectedAll"
                >全选/取消全选</el-checkbox
              >
            </div>
            <!-- v-loading="loading"  -->
            <el-tree
              ref="tree"
              node-key="id"
              class="tree"
              :props="props"
              :data="menuList"
              show-checkbox
              default-expand-all
              :default-checked-keys="defaultCheckedKeys"
            />
          </div>

          <div class="">
            <el-button
              v-buttonPermission="`${routePath}/Save`"
              type="primary"
              @click="getCheckedKeys"
              >保存</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 添加角色 -->
    <el-dialog
      :close-on-click-modal="false"
      class="address"
      title="添加角色"
      v-model="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-row>
        <el-col :span="24">
          <el-form
            ref="addForm"
            label-width="90px"
            label-position="right"
            :model="addForm"
            :rules="rules"
            :inline="false"
          >
            <el-form-item prop="name" label="角色名:">
              <el-input
                v-trim
                v-model="addForm.name"
                maxlength="20"
                style="width: 90%"
                placeholder=""
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <template #footer>
        <el-button class="btn" type="primary" @click="handleClose">
          取消
        </el-button>
        <el-button class="btn" type="primary" @click="confirmInfo">
          确认
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>
  <script>
import {
  addRoleApi,
  deleteRoleApi,
  saveroletreelistApi,
  rolesearchApi,
  roletreelistApi,
} from "@/api/management/role";

export default {
  name: "ManagementRole",
  data() {
    return {
      form: {
        role: "",
      },
      selectList: [],
      deleteRoleName: "",
      tableData: [], // 角色列表
      expendAll: true, // 是否展开
      selectedAll: false, // 是否全选
      selectAllId: [], // 选中的id集合
      props: {
        label: "name",
        children: "child",
      },
      menuList: [], // 菜单列表
      loading: true,
      defaultCheckedKeys: [],
      isLoading: false,
      dialogVisible: false, // 添加/编辑
      addForm: {},
      firstRoleid: 0,
      curRoleid: 0, // 当前的角色id
    };
  },
  computed: {
    rules() {
      // 校验新建
      return {
        name: [{ required: true, message: " 请填写此选项!", trigger: "blur" }],
      };
    },
    routePath() {
      return this.$route.path;
    },
  },
  watch: {
    "form.role"() {
      rolesearchApi(this.form).then((res) => {
        this.tableData = res;
        if (this.tableData.length) {
          this.firstRoleid = this.tableData[0].roleid;
          this.$refs.singleTable.setCurrentRow(this.tableData[0]);
        } else {
          this.firstRoleid = 0;
        }
        this.handleRowClick(this.firstRoleid);
        this.recordTotal = res.recordTotal;
      });
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(val) {
      if (val) {
        this.form.pageNum = val;
      }
      rolesearchApi(this.form).then((res) => {
        this.tableData = res;
        if (this.tableData.length) {
          this.firstRoleid = this.tableData[0].roleid;
          this.$refs.singleTable.setCurrentRow(this.tableData[0]);
        } else {
          this.firstRoleid = 0;
        }
        this.handleRowClick(this.firstRoleid);
        this.recordTotal = res.recordTotal;
      });
    },
    confirmInfo() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          addRoleApi(this.addForm).then((res) => {
            this.$message.success("添加角色成功!");
            this.handleClose();
            this.getList();
          });
        } else {
          this.$message.error("请完善用户信息");
          return false;
        }
      });
    },
    getNewTree(obj) {
      obj.map((item) => {
        if (item.selected) {
          this.defaultCheckedKeys.push(item.id);
        }
        if (item.child && item.child.length > 0) {
          this.getNewTree(item.child);
        }
      });
      return obj;
    },
    // 点击角色
    handleRowClick(row) {
      this.loading = true;
      this.defaultCheckedKeys = [];
      let roleid = row.roleid || this.firstRoleid;
      this.curRoleid = roleid;
      roletreelistApi({
        roleid,
      }).then((res) => {
        this.getNewTree(res);
        this.menuList = res;
        this.loading = false;
      });
    },
    centerResourceArr() {
      const resourceArr = this.$refs.tree.getCheckedNodes().filter((item) => {
        return !item.child;
      });
      return resourceArr; //为最后一级节点node（无子节点）
    },
    getCheckedKeys() {
      let arr = [];
      this.centerResourceArr().map((item) => {
        arr.push(item.id);
      });
      if (arr.length <= 0) {
        return this.$message.error("请至少选中一个进行保存");
      }
      const privilegeids = arr.join(",");
      saveroletreelistApi({
        privilegeids,
        roleid: this.curRoleid,
      })
        .then((res) => {
          this.$message.success("保存成功!");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    handleClickDelete(row) {
      this.$confirm(`是否确认删除角色"${row.rolename}"？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        deleteRoleApi({ roleid: row.roleid }).then((res) => {
          this.$message.success("删除角色成功!");
          this.getList();
        });
      });
    },
    delRole() {
      if (this.selectList.length !== 1)
        return this.$message.error("Please select a piece of data!");
      this.deleteRoleName = this.selectList[0].rolename;
      this.$confirm(`是否确认删除角色"${this.deleteRoleName}"？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        deleteRoleApi({ roleid: this.selectList[0].roleid }).then((res) => {
          this.$message.success("删除角色成功!");
          this.getList();
        });
      });
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    handleClose() {
      this.dialogVisible = false;
      this.DeleteDialog = false;
      this.$refs["addForm"].resetFields();
    },
    // 是否展开
    handelExpendAll(e) {
      const nodeMap = this.$refs.tree.store.nodesMap;
      for (const key in nodeMap) {
        const item = nodeMap[key];
        item.expanded = e;
      }
    },

    handelSelectedAll(e) {
      const tree = this.$refs.tree;
      if (e) {
        this.getAllId(this.menuList);
        tree.setCheckedKeys(this.selectAllId);
      } else {
        tree.setCheckedKeys([]);
        this.selectAllId = [];
      }
    },
    getAllId(arr) {
      for (var i = 0; i < arr.length; i++) {
        const item = arr[i];
        this.selectAllId.push(item.id);
        if (item.children && item.children.length > 0) {
          this.getAllId(item.children);
        }
      }
    },
  },
};
</script>
  <style scoped lang="scss">
.role {
  :deep(.table-area-role) {
    padding: 0;
    .el-card__body {
      padding: 0;
    }
    .el-table__inner-wrapper::before {
      height: 0;
    }
    .el-scrollbar__wrap,
    .el-table--enable-row-transition .el-table__body td.el-table__cell {
      border: 0 none;
    }
    .el-table tr.hover-row,
    .el-table__body tr.hover-row > td.el-table__cell,
    .el-table tr:hover,
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #f5f7fa !important;
    }
    .el-tooltip__trigger:focus-visible {
      outline: unset;
    }
  }
  .col-section {
    padding: 20px;
  }
  .left-section {
    border-right: 1px solid #dcdcdc;
  }
  .del-dialog-desc {
    text-align: center;
    font-size: 16px;
  }
  .operation-btn .el-form-item__content {
    display: flex;
    justify-content: space-around;
  }
  :deep(.el-table) {
    width: 100%;
    height: calc(100vh - 356px);
    min-height: 510px;
    margin-top: 0;
    .el-table__body-wrapper {
      height: calc(100% - 45px);
      min-height: 400px;
      overflow-y: auto;
    }
    .el-scrollbar__view{
      height:100%;
    }
  }
  :deep(.el-tree) {
    width: 300px;
    height: calc(100vh - 356px);
    min-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
}

</style>
  
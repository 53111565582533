/* 
* 限制select搜索输入最大长度
*/
const selectMaxlength = {
    beforeMount(el, binding) {
        let flag = true;
        const input = el.querySelector("input");
        input.addEventListener("compositionstart", (e) => {
            flag = false;
          });
          input.addEventListener("compositionend", (e) => {
            flag = true;
          });
        input.addEventListener("input", (e) => {
          if (!flag) return
          if(e.target.value && e.target.value.length > binding.value) {
            e.target.value = e.target.value.slice(0, binding.value)
            console.log(3,  e.target.value);
            input.dispatchEvent(new Event('input'))
          }
        });
        
    }
}
export default selectMaxlength
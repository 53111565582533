/*
 * @Author: vere
 * @Date: 2024-02-02 14:57:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 19:30:33
 * @Description: 
 */
import { ElLoading } from 'element-plus'
import { nextTick } from 'vue'
let needLoadingRequestCount = 0
let loading

const startLoading = () => {
  loading = ElLoading.service({
    lock: true,
    text: 'Loading...', //可以自定义文字
    // spinner: 'Loading', //自定义加载图标类名
    background: 'rgba(0, 0, 0, 0.7)'//遮罩层背景色
  })
}
function endLoading() {
  nextTick(function() {
    // DOM 更新了
    loading.close()
  })
}
export const showLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export const hideLoading = () => {
  setTimeout(tryCloseLoading, 300)
}

function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}


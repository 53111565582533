<template>
  <div>
    <el-form
      ref="addvalueform"
      id="addvalueform"
      label-position="top"
      :class="['addvalue-form', { 'addvalue-detail-form': !isEdit }]"
      :model="addvalueform"
      :rules="addvalueformrules"
    >
      <el-row :gutter="30" v-if="showOrder" :class="{ detail: showOrder }">
        <el-col :span="3" class="b-label font-size-medium flex align-center">
          {{ boundtype == 1 ? "关联入库单号" : "关联出库单号" }}
        </el-col>
        <el-col :span="18">
          <el-link
            :underline="false"
            type="primary"
            @click="$emit('showDetail')"
          >
            <span class="link font-size-medium">{{
              addvalueform.orderno
            }}</span>
          </el-link>
        </el-col>
      </el-row>
      <!-- 贴标服务 -->
      <el-row :class="['margin-distance', { detail: !isEdit }]">
        <!-- 贴标服务 -->
        <el-row
          :gutter="30"
          :class="[
            'row-desc',
            'align-center',
            'margin-bottom',
            { detail: !isEdit },
          ]"
          type="flex"
        >
          <el-col :span="3">
            <el-checkbox
              v-if="isEdit"
              style="margin-right: 15px"
              v-model="addvalueform.labelchoose"
              @input="calcprice($event, 'checkbox', 'labelchoose')"
            ></el-checkbox>
            <span class="b-label">贴标服务 </span>
          </el-col>
          <el-col :span="9">
            <span
              >支持外箱贴标、托盘贴标、SKU贴标，价格{{ labelfee }}元/标
            </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <el-form-item prop="labelnum" label-width="0px">
              <span class="b-label">贴标数：</span>
              <el-input
                v-trim
                maxlength="5"
                prop="addvalueform.labelnum"
                v-inputLimit="0"
                :disabled="!addvalueform.labelchoose"
                min="1"
                @input="calcprice"
                clearable
                style="width: 100px"
                v-model="addvalueform.labelnum"
              />
            </el-form-item>
            <span v-if="showPrice" class="b-label margin-left"
              >价格：
              {{ isEdit ? labelprice : addvalueform.labelprice }}元</span
            >
          </el-col>
        </el-row>
        <el-row :class="['margin-bottom', { detail: !isEdit }]">
          <el-col :span="3" :class="{ tip: isEdit }">备注：</el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="!addvalueform.labelchoose"
              @input="noticeParent"
              maxlength="200"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求。"
              v-model="addvalueform.labelremark"
            >
            </el-input>
          </el-col>
        </el-row>

        <el-row type="flex" align="middle" :class="{ detail: !isEdit }">
          <el-col :span="3" :class="{ tip: isEdit }">附件：</el-col>
          <el-col :span="20" class="flex align-center flex-wrap">
            <el-upload
              v-if="isEdit"
              ref="uploadRef"
              class="pic-card"
              accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :show-file-list="false"
              :disabled="!isEdit || !addvalueform.labelchoose || isLoading"
              :action="uploadAction"
              :headers="uploadHeaders"
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
              :on-preview="handlePreview"
            >
              <el-button
                class="aliPay-btn"
                type="primary"
                icon="Upload"
                :disabled="!isEdit || !addvalueform.labelchoose"
                :loading="isLoading"
                >上传附件</el-button
              >
            </el-upload>
            <span v-for="item of addvalueform.filerecordList" :key="item.id">
              <span style="margin: 5px">
                <a
                  target="_blank"
                  class="font-size-mini link"
                  :href="item.url"
                  >{{ item.filename }}</a
                >
                <span
                  v-if="isEdit"
                  style="margin-top: 2px; color: #8f8f8f"
                  @click="delFile(item)"
                  ><el-icon><CircleCloseFilled></CircleCloseFilled></el-icon
                ></span>
              </span>
            </span>
          </el-col>
        </el-row>
      </el-row>

      <!-- 重新装箱打包服务 -->
      <el-row :class="['margin-distance', { detail: !isEdit }]">
        <el-row
          :gutter="30"
          type="flex"
          :class="['align-center', 'margin-bottom', { detail: !isEdit }]"
        >
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              v-if="isEdit"
              style="margin-right: 15px"
              v-model="addvalueform.reboxchoose"
              @input="calcprice($event, 'checkbox', 'reboxchoose')"
            ></el-checkbox>
            <span class="b-label">重新装箱打包服务</span>
          </el-col>
          <el-col :span="9">
            <span>支持重新装箱和库内打包服务，价格{{ packfee }}元/箱 </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <el-form-item prop="reboxednum" label-width="0px">
              <span class="b-label">装箱数：</span>
              <el-input
                v-trim
                maxlength="5"
                :disabled="!addvalueform.reboxchoose"
                v-inputLimit="0"
                @input="calcprice"
                clearable
                style="width: 100px"
                v-model="addvalueform.reboxednum"
              />
            </el-form-item>
            <span v-if="showPrice" class="b-label margin-left"
              >价格：{{ isEdit ? reboxprice : addvalueform.reboxprice }}元</span
            >
          </el-col>
        </el-row>
        <el-row :class="{ detail: !isEdit }">
          <el-col :span="3" :class="{ tip: isEdit }">备注： </el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="!addvalueform.reboxchoose"
              type="textarea"
              @input="noticeParent"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求。"
              v-model="addvalueform.reboxedremark"
            >
            </el-input>
          </el-col>
        </el-row>
      </el-row>

      <!-- 重新打板服务 -->
      <el-row :class="['margin-distance', { detail: !isEdit }]">
        <el-row
          type="flex"
          :class="['align-center', 'margin-bottom', { detail: !isEdit }]"
        >
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              v-if="isEdit"
              v-model="addvalueform.reboardchoose"
              @input="calcprice($event, 'checkbox', 'reboardchoose')"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">重新打板服务</span>
          </el-col>
          <el-col :span="9">
            <span>包含拆板、重新打板、缠膜费用，价格{{ blankfee }}元/板 </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <el-form-item prop="reboardnum" label-width="0px">
              <span class="b-label">打板数：</span>
              <el-input
                v-trim
                maxlength="5"
                :disabled="!addvalueform.reboardchoose"
                min="1"
                @input="calcprice"
                v-inputLimit="0"
                clearable
                style="width: 100px"
                v-model="addvalueform.reboardnum"
              />
            </el-form-item>
            <span v-if="showPrice" class="b-label margin-left"
              >价格：{{
                isEdit ? reboardprice : addvalueform.reboardprice
              }}元</span
            >
          </el-col>
        </el-row>
        <el-row :class="{ detail: !isEdit }">
          <el-col :span="3" :class="{ tip: isEdit }">备注：</el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="!addvalueform.reboardchoose"
              type="textarea"
              @input="noticeParent"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求。"
              v-model="addvalueform.reboardremark"
            >
            </el-input>
          </el-col>
        </el-row>
      </el-row>
      <!-- 保价 -->
      <el-row
        v-if="insuredshow"
        :class="['margin-distance', { detail: !isEdit }]"
      >
        <el-row
          type="flex"
          :class="['b-label', 'align-center', { detail: !isEdit }]"
        >
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              v-if="isEdit"
              v-model="addvalueform.insuredchoose"
              @input="calcprice($event, 'checkbox', 'insuredchoose')"
              style="margin-right: 10px"
            ></el-checkbox>
            <span class="b-label">保价服务 </span>
          </el-col>
          <el-col :span="21" class="flex align-center">
            <span class="flex-shrink0">订单货物总价</span>
            <el-input
              v-trim
              disabled
              style="width: 100px; margin: 0 10px; height: 32px"
              :model-value="skutotalprice"
              maxlength="8"
              v-inputLimit="2"
            /><span class="flex-shrink0">元，保额</span>
            <el-form-item prop="insuranceamount" label-width="0px">
              <el-input
                v-trim
                :disabled="!addvalueform.insuredchoose"
                clearable
                style="width: 100px; margin: 0 10px"
                @input="calcprice"
                maxlength="8"
                v-inputLimit="2"
                v-model="addvalueform.insuranceamount"
              />
            </el-form-item>
            <span class="flex-shrink0">元，保价持续</span>
            <el-form-item prop="month" label-width="0px">
              <el-input
                v-trim
                @keyup="handleInput"
                :disabled="!addvalueform.insuredchoose"
                maxlength="2"
                @input="calcprice()"
                v-inputLimit="0"
                max="12"
                min="1"
                clearable
                style="width: 100px; margin: 0 10px"
                v-model="addvalueform.month"
              />
              <span class="flex-shrink0"> 个月，保费</span
              ><el-input
                v-trim
                disabled
                style="width: 100px; margin: 0 10px"
                v-model="premium"
                v-inputLimit="2"
              /><span class="flex-shrink0">元/月，价格：</span
              ><span class="color-primary">{{ premiumtotalprice }} 元</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <!-- 扫描SN Code 服务 -->
      <el-row
        v-if="
          create
            ? labelSnCode
            : initData.valueaddorderRequiredBo?.showSNCodeService == 1
        "
        :class="['margin-distance', { detail: !isEdit }]"
      >
        <el-row
          type="flex"
          :class="['align-center', 'margin-bottom', { detail: !isEdit }]"
        >
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              v-if="isEdit"
              v-model="labelSnCode"
              disabled
              :true-value="1"
              :false-value="0"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">扫描SN Code服务</span>
          </el-col>
          <el-col :span="9">
            <span
              >库内扫描SN Code，价格{{
                create
                  ? sncodefee
                  : initData.valueaddorderRequiredBo?.sncodefee
              }}元/件
            </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <el-form-item prop="sncodeqty" label-width="0px">
              <span class="b-label">扫描数：</span>
              <span style="display: inline-block; width: 100px">{{
                sncodeqty
              }}</span>
            </el-form-item>
            <span v-if="showPrice" class="b-label margin-left"
              >价格：{{ sncodeTotalPrice }}元</span
            >
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <!-- 总价 -->
    <el-row
      v-if="showPrice"
      type="flex"
      :class="[
        'total',
        'align-center',

        { 'total-detail': !isEdit, detail: !isEdit },
      ]"
    >
      <el-col :span="3" :class="['price-title', { tip: isEdit }]">
        总价：
      </el-col>
      <el-col :span="18" class="price b-lable color-primary">
        ￥{{ isEdit ? valuetotalprice : addvalueform.valuetotalprice }}
      </el-col>
    </el-row>
    <div class="btn-close">
      <slot name="closeBtn"></slot>
    </div>
  </div>
</template>

<script>
import { valueaddpriceinfo } from "@/api/common";
import { math } from "@/utils/math";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
import { ElMessage } from "element-plus";
export default {
  name: "AddValue",
  props: {
    label: {
      type: Boolean,
      default: false,
    }, //贴标选择

    reboard: {
      type: Boolean,
      default: false,
    }, //打板选择

    rebox: {
      type: Boolean,
      default: false,
    }, //打包选择

    insuredshow: {
      type: Boolean,
      default: false,
    }, //报价是否显示
    skutotalprice: [String, Number], // 货物总价

    insured: {
      type: Boolean,
      default: false,
    }, //保价选择

    addvalueformInitData: Object, //初始数据
    isEdit: {
      // 是否可编辑
      type: Boolean,
      default: true,
    },
    boundtype: {
      type: [Number, String],
      default: 1,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showOrder: {
      type: Boolean,
      default: false,
    },
    sncodeqty: {
      type: [Number, String],
      default: 0,
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var checkQty = (rule, value, callback) => {
      if (rule.fullField == "labelnum" && !this.addvalueform.labelchoose) {
        callback();
      }
      if (rule.fullField == "reboxednum" && !this.addvalueform.reboxchoose) {
        callback();
      }
      if (rule.fullField == "reboardnum" && !this.addvalueform.reboardchoose) {
        callback();
      }
      if (rule.fullField == "month" && !this.addvalueform.insuredchoose) {
        callback();
      }

      if (!value) {
        return callback(new Error("不能为空"));
      }

      if (Number(value).toFixed(0) <= 0) {
        callback(new Error("必须大于0"));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      fileList: [],
      sncodePrice: 0.0,
      blankfee: "0.00", //重新打板价格
      premium: "0.00", //保费
      packfee: "0.00", //装箱价格
      labelfee: "0.00", //标签的价格
      sncodefee: "0.00", // sn  code 的单价

      labelSnCode: 0, // 扫描SN Code 服务是否 选中

      labelprice: "0.00", //贴标的总价
      reboxprice: "0.00", //重新装箱总价
      reboardprice: "0.00", //重新打板总价
      premiumtotalprice: "0.00", //保价总价

      valuetotalprice: "0.00", //总金额
      premiumpercent: "0.00",
      insurancedeductibleexcess: "0.00",

      addvalueform: this.addvalueformInitData || {
        labelchoose: this.label, //贴标选择
        reboardchoose: this.reboard, //打板选择
        reboxchoose: this.rebox, //打包选择
        insuredchoose: this.insured, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        premium: "", //保费
        month: "", //报价持续月份，
        premiumtotal: 0.0,
        sncodeqty: 0, // sn code 服务 扫描数
      },
      timer: null,
      addvalueformrules: {
        labelnum: [{ validator: checkQty, trigger: "blur" }],
        reboardnum: [{ validator: checkQty, trigger: "blur" }],
        reboxednum: [{ validator: checkQty, trigger: "blur" }],
        insuranceamount: [
          {
            validator: (rule, value, callback) => {
              if (!this.addvalueform.insuredchoose) {
                return callback();
              }
              if (!value) {
                return callback(new Error("不能为空"));
              }
              if (
                Number(value) <= Number(this.insurancedeductibleexcess) ||
                Number(value) > Number(this.skutotalprice)
              ) {
                callback(
                  new Error(
                    "金额需要大于不计免赔金额:" +
                      this.insurancedeductibleexcess +
                      ",且小于货物总价:" +
                      this.skutotalprice,
                  ),
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        month: [{ validator: checkQty, trigger: "blur" }],
      },
    };
  },
  computed: {
    uploadAction() {
      return import.meta.env.VITE_BASE_URL + "/valueaddorder/uploadFile/label";
    },
    uploadHeaders() {
      const headers = {
        "Buffalo-ticket": getToken(),
      };
      return headers;
    },
    sncodeTotalPrice() {
      const sncodefee = this.sncodefee;
      const sncodeqty = Number(this.sncodeqty);
      const total = math.multiplyTwo(sncodefee, sncodeqty).toFixed(2);
      this.sncodePrice = total;
      return total;
    },
    initData() {
      this.premium = this.addvalueformInitData.premium || 0;
      this.premiumtotalprice = this.addvalueformInitData.premiumtotal || 0.0;
      return this.addvalueformInitData;
    },
  },
  watch: {
    sncodeqty(newVal, oldVal) {
      if (this.labelSnCode == 1) {
        const total = math.multiplyTwo(this.sncodefee, this.sncodeqty);
        this.valuetotalprice = (
          Number(this.labelprice) +
          Number(this.reboxprice) +
          Number(this.reboardprice) +
          Number(this.premiumtotalprice) +
          total
        ).toFixed(2);
        console.log(newVal, oldVal);
      }
    },
    "addvalueform.labelchoose"(newVal, OldVal) {
      if (!newVal) {
        this.$refs.addvalueform.validateField("labelnum");
      }
    },
    "addvalueform.reboardchoose"(newVal, OldVal) {
      if (!newVal) {
        this.$refs.addvalueform.validateField("reboardnum");
      }
    },
    "addvalueform.reboxedchoose"(newVal, OldVal) {
      if (!newVal) {
        this.$refs.addvalueform.validateField("reboxednum");
      }
    },
    "addvalueform.insuredchoose"(newVal, OldVal) {
      if (!newVal) {
        this.$refs.addvalueform.validateField("insuranceamount");
        this.$refs.addvalueform.validateField("month");
      }
    },
    addvalueformInitData(newVal) {
      // 从父组件传过来的对象里面有任何值发生变化，就重新渲染数据
      this.addvalueform = this.addvalueformInitData;
    },
  },
  mounted() {
    this.getAddValueInfo();
  },
  methods: {
    handlePreview(file) {
      file.url && window.open(file.url)
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        ElMessage.error("文件大小不超过5M");
        return false
      }
      if (this.addvalueform.filerecordList.length === 5){
        ElMessage.error("上传附件不能超过5个!");
        return false
      }
       
      this.isLoading = true;
      this.timer = setTimeout(() => {
        this.isLoading = false;
        this.$refs.uploadRef.clearFiles();
        this.$refs.uploadRef.abort();
        ElMessage.error("上传超时");
      }, 30000);
    },
    handleChange(file) {
      file.url = URL.createObjectURL(file.raw);
    },
    handleSuccess(response, file) {
      this.isLoading = false;
      clearTimeout(this.timer);
      if (response.status !== "00000") {
        if (response.code === 3000012) {
          removeToken();
          router.replace("/login");
        }
        return ElMessage.error(response.message);
      } else {
        this.addvalueform.filerecordList.push({
          filelink: response.data.url,
          filename: file.name,
          url: file.url,
        });
      }
    },
    handleError() {
      this.isLoading = false;
      clearTimeout(this.timer);
      return this.$message.error("上传失败");
    },
    delFile(val) {
      let index = this.addvalueform.filerecordList.findIndex(
        (item) => item.filename === val.filename,
      );
      this.addvalueform.filerecordList.splice(index, 1);
    },
    getAddValueInfo() {
      valueaddpriceinfo({}).then((res) => {
        console.log(res);
        this.labelfee = Number(res.labelfee).toFixed(2);
        this.packfee = Number(res.packfee).toFixed(2);
        this.blankfee = Number(res.blankfee).toFixed(2);
        this.sncodefee = res.valueaddorderRequiredBo.sncodefee;
        this.labelSnCode = res.valueaddorderRequiredBo.showSNCodeService;
        // this.premium = res.premium;

        this.premiumpercent = res.premiumpercent;
        this.insurancedeductibleexcess = Number(
          res.insurancedeductibleexcess,
        ).toFixed(2);
      });
    },

    //增值服务计算
    calcprice(ev, type, labelType) {
      const params = {};
      if (type === "checkbox") {
        if (labelType === "labelchoose") {
          let isChecked = ev.target.checked;
          params.labelchoose = isChecked;
          if (!isChecked) {
            this.addvalueform.labelnum = "";
            this.addvalueform.labelremark = "";
            this.labelprice = "0.00";
            this.addvalueform.filerecordList = [];
          }
        } else if (labelType === "reboxchoose") {
          let isChecked = ev.target.checked;
          params.reboxchoose = isChecked;
          if (!isChecked) {
            this.reboxprice = "0.00";
            this.addvalueform.reboxednum = "";
            this.addvalueform.reboxedremark = "";
          }
        } else if (labelType === "reboardchoose") {
          let isChecked = ev.target.checked;
          params.reboardchoose = isChecked;
          if (!isChecked) {
            this.addvalueform.reboardnum = "";
            this.reboardprice = "0.00";
            this.addvalueform.reboardremark = "";
          }
        } else if (labelType === "insuredchoose") {
          let isChecked = ev.target.checked;
          params.insuredchoose = isChecked;
          if (!isChecked) {
            this.addvalueform.insuranceamount = "";
            this.addvalueform.month = "";
            this.premium = "";
            this.premiumtotalprice = "0.00";
          }
        }
      } else {
        //贴标价格
        if (this.addvalueform.labelchoose) {
          this.labelprice = Number(
            this.addvalueform.labelnum * this.labelfee,
          ).toFixed(2);
        } else {
          this.labelprice = "0.00";
          this.addvalueform.labelnum = "";
        }

        //装箱价格
        if (this.addvalueform.reboxchoose) {
          this.reboxprice = Number(
            this.addvalueform.reboxednum * this.packfee,
          ).toFixed(2);
        } else {
          this.reboxprice = "0.00";
          this.addvalueform.reboxednum = "";
        }
        //打包价格
        if (this.addvalueform.reboardchoose) {
          this.reboardprice = Number(
            this.addvalueform.reboardnum * this.blankfee,
          ).toFixed(2);
        } else {
          this.addvalueform.reboardnum = "";
          this.reboardprice = "0.00";
        }

        //保价价格
        if (this.addvalueform.insuredchoose) {
          this.premium = Number(
            this.addvalueform.insuranceamount * this.premiumpercent,
          ).toFixed(2);
          this.premiumtotalprice = Number(
            this.premium * this.addvalueform.month,
          ).toFixed(2); //baojia价
        } else {
          this.addvalueform.insuranceamount = "";
          this.addvalueform.month = "";
          this.premium = "";
          this.premiumtotalprice = "0.00";
        }
      }

      this.valuetotalprice = (
        Number(this.labelprice) +
        Number(this.reboxprice) +
        Number(this.reboardprice) +
        Number(this.premiumtotalprice) +
        +this.sncodePrice
      ).toFixed(2); //表单总价

      //更新父组件信息
      this.noticeParent(params);
    },
    noticeParent(params) {
      const obj = { ...this.addvalueform, ...params };
      this.$emit("input", obj);
      this.$emit("getAddValueForm", obj);
    },
    handleInput() {
      // 使用正则表达式限制只能输入1-12的正整数
      this.addvalueform.month = Number(this.addvalueform.month); // 只保留数字
      const num = this.addvalueform.month; // 转换为整数

      if (num < 1) {
        this.addvalueform.month = "1"; // 小于1时设置为1
      } else if (num > 12) {
        this.addvalueform.month = "12"; // 大于12时设置为12
      } else {
        this.addvalueform.month = num.toString(); // 在范围内时保持原值
      }
    },
    async validateForm() {
      try {
        return await this.$refs["addvalueform"].validate();
      } catch (error) {
        return false;
      }
    },
    resetForm() {
      this.addvalueform.labelremark = "";
      this.addvalueform.reboardremark = "";
      this.addvalueform.reboxedremark = "";
      this.$refs["addvalueform"].resetFields();
      this.noticeParent();
    },
  },
};
</script>

<style scoped lang="scss">
.upload-input {
  display: none;
}
.flex-shrink0 {
  flex-shrink: 0;
}
.orderno {
  margin-left: 30px;
}

.el-row {
  width: 100%;
  &.detail {
    line-height: normal;
  }
}
.b-label {
  font-weight: 600;
}
.price-title {
  font-size: 14px;
  color: #1f1f20;
}
.margin-distance {
  margin: 25px 0;
}
.margin-bottom {
  margin-bottom: 20px;
}
.margin-left {
  margin-left: 15px;
}
.addvalue-form {
  // padding-bottom: 25px;
  font-size: 14px;
  color: #1f1f20;
  &.addvalue-detail-form {
    padding: 0;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
.price {
  font-size: 24px;
  font-weight: 900;
}
.btn-close {
  text-align: right;
}
.tip {
  padding-left: 29px;
}
</style>

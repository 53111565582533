
export const apiData = [
  //group 账户信息查询
  // {
  //   id:'accountQuery',
  //   titleZh: '账户信息查询',
  //   titleEn: 'Query User information',
  //   api: '/api-json/v2/GetCustomer?userName=59781287XX@qq.com&cusPsw=123456',
  //   request: 'GET',
  //   requestExampleZh: '请求示例',
  //   requestExampleEn: 'Request example',
  //   requestExample:'/api-json/v2/GetCustomer?userName=597812587@qq.com&cusPsw=123456',
  //
  //   requestParameterZh: '请求参数',
  //   requestParameterEn: 'Request Parameter',
  //   requestParameter: [{
  //     titleZh: '参数名',
  //     titleEn: 'Parameter name',
  //     label: 'Parameter name',
  //     key: 'Parameter name',
  //     resizable: false,
  //     width: 150,
  //   },{
  //     titleZh: '类型',
  //     titleEn: 'Type',
  //     label: 'Type',
  //     key: 'Type',
  //     resizable: false,
  //     width: 100,
  //   }, {
  //     titleZh: '必需',
  //     titleEn: 'Required',
  //     label: 'Required',
  //     key: 'Required',
  //     resizable: false,
  //     width: 100,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   }, {
  //     titleZh: '示例',
  //     titleEn: 'e.g.',
  //     label: 'e.g.',
  //     key: 'eg',
  //     resizable: false,
  //   }],
  //   requestParameterTableSource:[{
  //     'Parameter name': 'userName',
  //     'Type': 'string',
  //     'Required': 'R',
  //     'Chinese description': '	用户名',
  //     'English description': 'username',
  //     'eg': '597812587@qq.com'
  //   }, {
  //     'Parameter name': 'cusPsw',
  //     'Type': 'string',
  //     'Required': 'R',
  //     'Chinese description': '密码',
  //     'English description': 'password',
  //     'eg': '	123456'
  //   }],
  //
  //   returnExampleZh: '响应示例',
  //   returnExampleEn: 'Return example',
  //   returnExample: `
  //   {
  //     "success": "success",
  //     "code": "100",
  //     "message": null,
  //     "customerCode": "1000",
  //     "accountMoney": "5990.53",
  //     "key": "e0a9cde1-a87d-bd26-33f3-614612683447"
  //   }
  //   `,
  //   responseParameterZh: '响应参数',
  //   responseParameterEn: 'Response Parameter',
  //   responseParameter: [{
  //     titleZh: '参数名',
  //     titleEn: 'Parameter name',
  //     label: 'Parameter name',
  //     key: 'Parameter name',
  //     resizable: false,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   },{
  //     titleZh: '类型',
  //     titleEn: 'Type',
  //     label: 'Type',
  //     key: 'Type',
  //     resizable: false,
  //   }],
  //   responseParameterTableSource:[{
  //     'Parameter name': 'success',
  //     'Chinese description': '是否成功',
  //     'English description': 'whether succeed',
  //     'Type': '(success/failure)'
  //   }, {
  //     'Parameter name': 'code',
  //     'Chinese description': '代码',
  //     'English description': 'code',
  //     'Type': '100即成功'
  //   }, {
  //     'Parameter name': 'customerCode',
  //     'Chinese description': '客户Code',
  //     'English description': 'Customer code',
  //     'Type': 'string'
  //   }, {
  //     'Parameter name': 'accountMoney',
  //     'Chinese description': '账号余额',
  //     'English description': 'Account balance ',
  //     'Type': 'string'
  //   }, {
  //     'Parameter name': 'key',
  //     'Chinese description': '客户key',
  //     'English description': 'Customer Key ',
  //     'Type': 'string'
  //   }],
  //
  //   errorCodeZh: '错误代码',
  //   errorCodeEn: 'error code',
  //   errorCode:[{
  //     titleZh: '代码',
  //     titleEn: 'code',
  //     label: 'code',
  //     key: 'code',
  //     resizable: false,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   }],
  //   errorCodeTableSource:[{
  //     'code': '100',
  //     'Chinese description': '成功',
  //     'English description': 'success',
  //   }, {
  //     'code': '101',
  //     'Chinese description': '失败',
  //     'English description': 'fail',
  //   }]
  // },

  //group 产品创建
  {
    id:'productCreate',
    titleZh: '产品创建',
    titleEn: 'Create Product',
    api: '/api-json/v2/CreateGoods?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'POST',

    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
      width: 200
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
      width: 200
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    bodyRequestExampleZh: 'Body 请求示例',
    bodyRequestExampleEn: 'Body Request example',
    bodyRequestExample: `
    {
      "customerCode": "10003",
      "sku": "product33",
      "barCode": "productbarcode33",
      "enName": "product33",
      "cnName": "商品33",
      "remark": "",
      "price": "10.3",
      "weight": "2.3",
      "length": "3",
      "width": "4",
      "height": "5",
      "hsCode": "",
      "brand": "",
      "origin": "10",
      "battery": "1",
      "expectNum": "",
      "oneLevelName": "运动装备",
      "twoLevelName": "足球用品",
      "threeLevelName": "",
      "unit": "10",
      "state": "0"
    }`,
    bodyRequestParameterZh: 'Body 请求参数',
    bodyRequestParameterEn: 'Body Request Parameter',
    bodyRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    },  {
      titleZh: '长度',
      titleEn: 'Length',
      label: 'Length',
      key: 'Length',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
      width: 300
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
      width: 235
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    bodyRequestParameterTableSource:[{
      'Parameter name': 'customerCode',
      'Type': 'string',
      'Required': '	R',
      'Length': '	5',
      'Chinese description': '客户Code',
      'English description': 'Customer Code',
      'eg': '10000'
    }, {
      'Parameter name': 'sku',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': 'sku (不可重复)',
      'English description': 'sku (not repeatable)',
      'eg': 'MZL0110701'
    }, {
      'Parameter name': 'barcode',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': '产品条码（不可重复)',
      'English description': 'Product barcode (not repeatable)',
      'eg': 'MZL0110701'
    }, {
      'Parameter name': 'enName',
      'Type': 'string',
      'Required': 'R',
      'Length': '200',
      'Chinese description': '英文名称',
      'English description': '	English name',
      'eg': 'Pen'
    }, {
      'Parameter name': 'cnName',
      'Type': 'string',
      'Required': 'R',
      'Length': '200',
      'Chinese description': '中文名称',
      'English description': 'Chinese name',
      'eg': '钢笔'
    }, {
      'Parameter name': 'remark',
      'Type': 'string',
      'Required': 'O',
      'Length': '150',
      'Chinese description': '描述',
      'English description': '	describe',
      'eg': ''
    }, {
      'Parameter name': 'price',
      'Type': 'string',
      'Required': 'R',
      'Length': '9',
      'Chinese description': '申报价值(美元)',
      'English description': 'value',
      'eg': '8.5'
    }, {
      'Parameter name': 'weight',
      'Type': 'string',
      'Required': 'R',
      'Length': '12',
      'Chinese description': '重量(kg)',
      'English description': '	weight',
      'eg': '8.5'
    }, {
      'Parameter name': 'length',
      'Type': 'string',
      'Required': 'R',
      'Length': '12',
      'Chinese description': '长度(cm)',
      'English description': 'length',
      'eg': '8'
    }, {
      'Parameter name': 'width',
      'Type': 'string',
      'Required': 'R',
      'Length': '12',
      'Chinese description': '	宽度(cm)',
      'English description': '	width',
      'eg': '8'
    }, {
      'Parameter name': 'height',
      'Type': 'string',
      'Required': 'R',
      'Length': '12',
      'Chinese description': '	高度(cm)',
      'English description': '	heigth',
      'eg': '8'
    },  {
      'Parameter name': 'hsCode',
      'Type': 'string',
      'Required': 'O',
      'Length': '10',
      'Chinese description': '客户海关编码',
      'English description': 'Customer Customs Code',
      'eg': '	581000'
    },  {
      'Parameter name': 'brand',
      'Type': 'string',
      'Required': 'O',
      'Length': '10',
      'Chinese description': '品牌',
      'English description': '	brand',
      'eg': ''
    },  {
      'Parameter name': 'origin',
      'Type': 'string',
      'Required': 'R',
      'Length': '10',
      'Chinese description': '原产地（10-CN; 20-USA; 30-SA;）',
      'English description': '	country of origin',
      'eg': '	10'
    },  {
      'Parameter name': 'battery',
      'Type': 'string',
      'Required': 'R',
      'Length': '1',
      'Chinese description': '是否含电池，0不含，1含电池',
      'English description': 'Whether it contains battery, 0 does not contain, 1 contains battery',
      'eg': '0'
    },  {
      'Parameter name': 'expectNum',
      'Type': 'string',
      'Required': 'O',
      'Length': '10',
      'Chinese description': '预警数量',
      'English description': '	Number of warnings',
      'eg': '10'
    },
    //   {
    //   'Parameter name': 'imgUrl',
    //   'Type': 'string',
    //   'Required': 'O',
    //   'Length': '100',
    //   'Chinese description': '产品图片url',
    //   'English description': 'product image URL',
    //   'eg': ''
    // },
      {
      'Parameter name': 'oneLevelName',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': '一级目录',
      'English description': 'primary directory',
      'eg': '运动装备'
    }, {
      'Parameter name': 'twoLevelName',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': '二级目录',
      'English description': 'secondary directory',
      'eg': '足球用品'
    }, {
      'Parameter name': 'threeLevelName',
      'Type': 'string',
      'Required': 'O',
      'Length': '50',
      'Chinese description': '三级目录',
      'English description': 'tertiary directory',
      'eg': ''
    }, {
      'Parameter name': 'unit',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': '单位: 10-件; 20-双; 30-箱; 40-包; 50-套;',
      'English description': 'unit',
      'eg': '10'
    }, {
      'Parameter name': 'state',
      'Type': 'string',
      'Required': 'R',
      'Length': '50',
      'Chinese description': '产品状态: 0-启用; 1-未启用;',
      'English description': 'state',
      'eg': '0'
    },
    ],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": "成功"
    }`,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code:100'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示描述',
      'English description': 'Prompt description'
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 产品查询
  {
    id:'productQuery',
    titleZh: '产品查询',
    titleEn: 'Query product',
    api: '/api-json/v2/GetGoodsList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',
    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/GetGoodsList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3&page=1&sku=&barcode=',

    requestParameterZh: '请求参数',
    requestParameterEn: 'Request Parameter',
    requestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    requestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'key',
      'English description': 'key',
      'eg': '271bd87a-60bd-4ef8-b3bc-5192e2e72de3'

    }, {
      'Parameter name': 'sku',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': 'sku',
      'English description': 'sku',
      'eg': ''
    }, {
      'Parameter name': 'barCode',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': 'barCode',
      'English description': 'barCode',
      'eg': ''
    }, {
      'Parameter name': 'page',
      'Type': 'int',
      'Required': 'O',
      'Chinese description': '页数（每页查询50条数据）,不填写默认查询第一页',
      'English description': 'Number of pages (each page query 50 data), do not fill in the default query first page',
      'eg': '1'
    }],


    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": null,
      "count": 21,
      "data": [
        {
          "count": 1,
          "sku": "product33",
          "barCode": "productbarcode33",
          "enName": "product33",
          "cnName": "商品33",
          "remark": "",
          "price": "10.30",
          "weight": "2.3",
          "length": "3.0",
          "width": "4.0",
          "height": "5.0",
          "hsCode": "",
          "origin": "CN",
          "brand": "",
          "battery": "是",
          "expectNum": "0",
          "checkWeight": "2.3",
          "checkLength": "0.0",
          "checkWidth": "4.0",
          "checkHeight": "0.0"
        }
      ]
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    },{
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'code',
      'Chinese description': '代码 100即成功',
      'English description': 'code',
      'Type': 'string',
      'eg': '100'
    }, {
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': 'string',
      'eg': '(success/failure)'
    }, {
      'Parameter name': 'count',
      'Chinese description': '总数量',
      'English description': 'The total amount',
      'Type': 'int',
      'eg': '1'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'count',
        'Chinese description': '查询总个数',
        'English description': 'Total number of queries',
        'Type': 'int',
        'eg': '1'
      },{
        'Parameter name': 'sku',
        'Type': 'string',
        'eg': '	MZL0110701',
        'Chinese description': 'sku',
        'English description': 'sku',
      },{
        'Parameter name': 'barCode',
        'Type': 'string',
        'eg': '	MZL0110701',
        'Chinese description': '产品条码',
        'English description': 'Product barcode',
      },{
        'Parameter name': 'enName',
        'Type': 'string',
        'eg': '	Pen',
        'Chinese description': '英文名称',
        'English description': 'English name',
      },{
        'Parameter name': 'cnName',
        'Type': 'string',
        'eg': '	钢笔',
        'Chinese description': '中文名称',
        'English description': 'Chinese name',
      },{
        'Parameter name': 'remark',
        'Type': 'string',
        'eg': '	',
        'Chinese description': '描述',
        'English description': 'describe',
      },{
        'Parameter name': 'price(USD)',
        'Type': 'string',
        'eg': '	8.5',
        'Chinese description': '价值',
        'English description': 'Price',
      },{
        'Parameter name': 'weight',
        'Type': 'string',
        'eg': '8.5',
        'Chinese description': '重量(kg)',
        'English description': 'weight',
      },{
        'Parameter name': 'length',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '长度(cm)',
        'English description': 'length',
      },{
        'Parameter name': 'width',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '宽度(cm)',
        'English description': 'width',
      },{
        'Parameter name': 'height',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '高度(cm)',
        'English description': 'height',
      },{
        'Parameter name': 'hsCode',
        'Type': 'string',
        'eg': '	581000',
        'Chinese description': '客户海关编码',
        'English description': 'HSCode',
      },{
        'Parameter name': 'origin',
        'Type': 'string',
        'eg': '	CN',
        'Chinese description': '原产地',
        'English description': 'country of origin',
      },{
        'Parameter name': 'brand',
        'Type': 'string',
        'eg': '	',
        'Chinese description': '品牌',
        'English description': 'Brand',
      },{
        'Parameter name': 'battery',
        'Type': 'string',
        'eg': '	0',
        'Chinese description': '是否含电池，0不含，1含电池',
        'English description': 'Whether it contains battery, 0 does not contain, 1 contains battery',
      },{
        'Parameter name': 'expectNum',
        'Type': 'string',
        'eg': '	10',
        'Chinese description': '预警数量',
        'English description': 'Number of warnings',
      },{
        'Parameter name': 'checkWeight',
        'Type': 'string',
        'eg': '	8.5',
        'Chinese description': '质检重量',
        'English description': 'Quality inspection weight',
      },{
        'Parameter name': 'checkLength',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '质检长度',
        'English description': 'Quality inspection length',
      },{
        'Parameter name': 'checkWidth',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '质检宽度',
        'English description': 'Quality inspection width',
      },{
        'Parameter name': 'checkHeight',
        'Type': 'string',
        'eg': '	8',
        'Chinese description': '质检高度',
        'English description': 'Quality inspection height',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 创建入库单
  {
    id:'inboundCreate',
    titleZh: '创建入库单',
    titleEn: 'Create Inbound',
    api: '/api-json/v2/CreateASN?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'POST',

    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    bodyRequestExampleZh: 'Body 请求示例',
    bodyRequestExampleEn: 'Body Request example',
    bodyRequestExample: `
    {
      "customerCode": "10003",
      "reachStartTime": "2023-11-12",
      "style": "CGRK",
      "type": "0",
      "warehouseCode": "JHB-DC",
      "waybillNumber": "",
      "deliveryStyle": "3",
      "remark": "",
      "remarkno": "",
      "asnDetail": [
        {
          "boxno": "1",
          "quantity": "2",
          "sku": "product1"
        }
      ],
      "inboundtrackingorderInfo": {
        "creditcode": "yuio",
        "email": "aa@qq.com",
        "logisticsServices": "0",
        "postcode": "1619",
        "suburb": "Spartan",
        "sender": "hjk",
        "senderaddressen": "address qwe",
        "senderaddresscn": "",
        "sendercity": "",
        "sendercompany": "",
        "sendercompanyen": "companyname",
        "sendertelephone": "189189189",
        "terms": "2",
        "trackingno": "cninboundorderno"
      },
      "valueaddorderInfo": {
        "labelChoose": "1",
        "reboardChoose": "1",
        "reboxChoose": "1",
        "insuredChoose": "1",
        "insuranceAmount": "1000",
        "labelNum": "1",
        "labelRemark": "fghj",
        "month": "2",
        "reboardNum": "3",
        "reboardRemark": "ghj",
        "reboxedNum": "6",
        "reboxedRemark": "ghjk"
      }
    }`,
    bodyRequestParameterZh: 'Body 请求参数',
    bodyRequestParameterEn: 'Body Request Parameter',
    bodyRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    bodyRequestParameterTableSource:[
    //   {
    //   'Parameter name': 'asnStatus',
    //   'Type': 'string',
    //   'Required': '	R',
    //   'Chinese description': '入库状态 0=草稿;1=未收货;',
    //   'English description': 'Inbound status 0=draft; 1=not received;',
    //   'eg': '0'
    // },
      {
      'Parameter name': 'type',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '入库分类 0=一件代发;',
      'English description': 'Incoming classification 0 = drop shipping;',
      'eg': '0'
    },{
      'Parameter name': 'style',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '入库类型 CGRK=采购入库;THRK=退货入库;',
      'English description': 'Warehousing type CGRK=Purchase warehousing; THRK=Returned goods warehousing;',
      'eg': 'CGRK'
    },{
      'Parameter name': 'customerCode',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '	客户代码',
      'English description': '	Customer Code',
      'eg': '10000'
    },{
      'Parameter name': 'warehouseCode',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '仓储代码',
      'English description': '	Warehouse code',
      'eg': 'USW'
    },{
      'Parameter name': 'deliveryStyle',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '运输方式（ 2 自行送货 3 Buffalo物流 ）',
      'English description': 'Means of transportation ( 2 Self delivery 3 Buffalo express)',
      'eg': '2'
    },{
      'Parameter name': 'waybillNumber',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '交货物流单号',
      'English description': 'tracking number',
      'eg': 'YT20201154140'
    },{
      'Parameter name': 'reachStartTime',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '	预计到仓时间',
      'English description': '	Estimated arrival time',
      'eg': '	2020-07-01'
    },{
        'Parameter name': 'remark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '备注',
        'English description': 'remark',
        'eg': ''
    },{
        'Parameter name': 'remarkno',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '参考号',
        'English description': 'Reference No',
        'eg': ''
    },
    //   {
    //   'Parameter name': 'ReferenceNo',
    //   'Type': 'string',
    //   'Required': '	O',
    //   'Chinese description': '参考号',
    //   'English description': 'Reference No',
    //   'eg': '	CSS2020301010'
    // },{
    //   'Parameter name': 'OrderNo',
    //   'Type': 'string',
    //   'Required': '	O',
    //   'Chinese description': '退货单号（类型为THRK必填）',
    //   'English description': 'Return order number (required if the type is THRK)',
    //   'eg': '	M100000000123658'
    // },
      {
      'Parameter name': 'asnDetail',
      'Type': 'JSON',
      'Required': '	R',
      'Chinese description': '产品信息',
      'English description': 'product information',
      'eg': '	参数如下 (The Parameters are as follows)',
     },{
        'Parameter name': 'inboundtrackingorderInfo',
        'Type': 'JSON',
        'Required': '	O',
        'Chinese description': 'buffalo物流单信息（选buffalo物流时必填）',
        'English description': 'buffalo logistics information',
        'eg': '	参数如下 (The Parameters are as follows)',
      },{
        'Parameter name': 'valueaddorderInfo',
        'Type': 'JSON',
        'Required': '	O',
        'Chinese description': '增值服务信息',
        'English description': 'value-added service information',
        'eg': '	参数如下 (The Parameters are as follows)',
      },
     ],
    bodyRequestChildNode: [{
      requestParameter: [{
        titleZh: 'asnDetail参数名',
        titleEn: 'asnDetail Parameter name',
        label: 'asnDetail Parameter name',
        key: 'asnDetail Parameter name',
        resizable: false,
        width: 200,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '必需',
        titleEn: 'Required',
        label: 'Required',
        key: 'Required',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }, {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'asnDetail Parameter name': 'sku',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	物品sku',
        'English description': '	sku',
        'eg': '	SKU1'
      }, {
        'asnDetail Parameter name': 'quantity',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '		物品数量',
        'English description': '	quantity',
        'eg': '		10'
      }, {
        'asnDetail Parameter name': 'boxno',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	箱号(必须为纯数字)',
        'English description': '	case number',
        'eg': '		1'
      },
      ]
    }, {
      requestParameter: [{
        titleZh: 'inboundtrackingorderInfo参数名',
        titleEn: 'inboundtrackingorderInfo Parameter name',
        label: 'inboundtrackingorderInfo Parameter name',
        key: 'inboundtrackingorderInfo Parameter name',
        resizable: false,
        width: 200,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '必需',
        titleEn: 'Required',
        label: 'Required',
        key: 'Required',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }, {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'inboundtrackingorderInfo Parameter name': 'trackingno',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	物流单号(国内转运仓收件)（buffalo采购入库必填）',
        'English description': '	Logistics Tracking Number (CN Transit Warehouse Receipt) (required for Buffalo Purchasing and Inventory)',
        'eg': '	SKU1'
      }, {
        'inboundtrackingorderInfo Parameter name': 'logisticsServices',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '物流产品，基础数据接口中获取（buffalo采购入库必填）',
        'English description': 'logistics services',
        'eg': '0'
      }, {
        'inboundtrackingorderInfo Parameter name': 'terms',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	Terms，基础数据接口中获取（物流产品为大货时必填）',
        'English description': '	terms',
        'eg': '2'
      }, {
        'inboundtrackingorderInfo Parameter name': 'sender',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	发件人',
        'English description': '	sender',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'sendertelephone',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	发件人电话，退货入库仅支持27开头',
        'English description': '	sender telephone',
        'eg': '189189189'
      }, {
        'inboundtrackingorderInfo Parameter name': 'sendercompany',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人公司',
        'English description': '	sender company',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'sendercompanyen',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	发件人公司英文',
        'English description': '	english sender company ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'sendercity',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人城市',
        'English description': '	sender city ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'senderaddressen',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	发件人地址(英文)',
        'English description': '	"Sender\'s address" in English ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'senderaddresscn',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人地址(中文)',
        'English description': '	Sender\'s address ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'creditcode',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	社会信用代码',
        'English description': '	Credit Code ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'email',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人Email',
        'English description': '	Sender\'s email ',
        'eg': ''
      }, {
        'inboundtrackingorderInfo Parameter name': 'postcode',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人Postcode（buffalo退货入库必填）',
        'English description': '	Sender\'s Postcode ',
        'eg': '1619'
      }, {
        'inboundtrackingorderInfo Parameter name': 'suburb',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '	发件人suburb（buffalo退货入库必填）',
        'English description': '	Sender\'s suburb ',
        'eg': 'Spartan'
      },
      ]
    },{
      requestParameter: [{
        titleZh: 'valueaddorderInfo参数名',
        titleEn: 'valueaddorderInfo Parameter name',
        label: 'valueaddorderInfo Parameter name',
        key: 'valueaddorderInfo Parameter name',
        resizable: false,
        width: 200,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '必需',
        titleEn: 'Required',
        label: 'Required',
        key: 'Required',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }, {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'valueaddorderInfo Parameter name': 'labelChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	贴标选择 1选择 0默认不选',
        'English description': '	Labeling Selection Identifier',
        'eg': '	0'
      }, {
        'valueaddorderInfo Parameter name': 'reboxChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '打包选择 1选择 0不选',
        'English description': 'Packaging Selection Identifier',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboardChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '打板选择 1选择 0不选',
        'English description': 'Board Selection Indicator',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'insuredChoose',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保价选择(出库传0) 1选择 0不选',
        'English description': 'Insurance Selection Identifier',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'labelNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '贴标数',
        'English description': 'Number of Labels Applied',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'labelRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '贴标备注',
        'English description': 'Labeling Remarks',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'reboxedNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打包数',
        'English description': 'Number of items repackaged',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboxedRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打包备注',
        'English description': 'Remarks for Repackaging',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'reboardNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打板数',
        'English description': 'Number of Circuit Boards for Reprintin',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboardRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打板备注',
        'English description': 'Remarks for Reprinting the Circuit Board',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'insuranceAmount',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保额(出库传0)',
        'English description': 'insured amount',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'month',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保价持续月数(出库传0)',
        'English description': 'Duration of Insurance Coverage',
        'eg': '		0'
      },
      //  {
      //   'valueaddorderInfo Parameter name': 'files',
      //   'Type': 'JSON',
      //   'Required': '	O',
      //   'Chinese description': '文件',
      //   'English description': 'files',
      //   'eg': ''
      // },
      ]
    },
    //   {
    //   requestParameter: [{
    //     titleZh: 'files参数名',
    //     titleEn: 'files Parameter name',
    //     label: 'files Parameter name',
    //     key: 'files Parameter name',
    //     resizable: false,
    //     width: 200,
    //   }, {
    //     titleZh: '类型',
    //     titleEn: 'Type',
    //     label: 'Type',
    //     key: 'Type',
    //     resizable: false,
    //     width: 100,
    //   }, {
    //     titleZh: '必需',
    //     titleEn: 'Required',
    //     label: 'Required',
    //     key: 'Required',
    //     resizable: false,
    //     width: 100,
    //   }, {
    //     titleZh: '中文描述',
    //     titleEn: 'Chinese description',
    //     label: 'Chinese description',
    //     key: 'Chinese description',
    //     resizable: false,
    //   }, {
    //     titleZh: '英文描述',
    //     titleEn: 'English description',
    //     label: 'English description',
    //     key: 'English description',
    //     resizable: false,
    //   }, {
    //     titleZh: '示例',
    //     titleEn: 'e.g.',
    //     label: 'e.g.',
    //     key: 'eg',
    //     resizable: false,
    //   }],
    //   requestParameterTableSource:[{
    //     'files Parameter name': 'filename',
    //     'Type': 'string',
    //     'Required': '	O',
    //     'Chinese description': '	文件名',
    //     'English description': '	file name',
    //     'eg': '	aaa'
    //   }, {
    //     'files Parameter name': 'filelink',
    //     'Type': 'string',
    //     'Required': '	O',
    //     'Chinese description': '文件链接',
    //     'English description': 'file url',
    //     'eg': '	http://aaa.jpg'
    //   }
    //   ]
    // }

    ],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "failure",
      "code": "101",
      "message": "余额不足"
    }`,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code:100'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示描述',
      'English description': 'Prompt description'
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 入库单查询
  {
    id:'inboundQuery',
    titleZh: '入库单查询',
    titleEn: 'Query ASN',
    api: '/api-json/v2/getGRNlist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',
    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/getGRNlist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3&asnNumber=A100001903210001',

    requestParameterZh: '请求参数',
    requestParameterEn: 'Request Parameter',
    requestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    requestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'key',
      'English description': 'key',
      'eg': '271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }, {
      'Parameter name': 'asnNumber',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': '入库单号',
      'English description': 'Inbound order number',
      'eg': 'A100001903210001'
    }],


    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": null,
      "asnNumber": "ASN20231117100300003",
      "asnStatus": "已上架",
      "warehouseName": "海外仓",
      "referenceNo": "BUFZA5110000317YQ",
      "createTime": "2023-11-17 15:54:32",
      "sumSKU": 1,
      "data": [
        {
          "sku": "799",
          "barcode": "799",
          "qty": 33,
          "qualified": 33,
          "unqualified": 0,
          "shelfQty": 8
        }
      ]
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
    },{
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'Type': '100即成功',
      'eg': '100'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示信息',
      'English description': 'error message',
      'Type': '',
      'eg': ''
    }, {
      'Parameter name': 'asnNumber',
      'Chinese description': '入库单号',
      'English description': 'Inbound order number',
      'Type': 'string',
      'eg': 'ASN20231117100300003'
    }, {
      'Parameter name': 'asnStatus',
      'Chinese description': '入库单状态',
      'English description': 'Inbound order status',
      'Type': 'string',
      'eg': '已上架'
    }, {
      'Parameter name': 'warehouseName',
      'Chinese description': '仓储名称',
      'English description': 'Warehouse name',
      'Type': 'string',
      'eg': '海外仓'
    }, {
      'Parameter name': 'referenceNo',
      'Chinese description': '参考号',
      'English description': 'Reference No',
      'Type': 'string',
      'eg': ''
    }, {
      'Parameter name': 'createTime',
      'Chinese description': '创建时间',
      'English description': 'Creation time',
      'Type': 'string',
      'eg': '2023-11-17 15:54:32'
    }, {
      'Parameter name': 'sumSKU',
      'Chinese description': 'SKU种类数',
      'English description': 'SKU Number of species',
      'Type': 'int',
      'eg': '10'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'sku',
        'Type': 'string',
        'eg': '	',
        'Chinese description': 'sku',
        'English description': 'sku',
      },{
        'Parameter name': 'barcode',
        'Type': 'string',
        'eg': '	',
        'Chinese description': '产品条码',
        'English description': 'Product barcode',
      },{
        'Parameter name': 'qty',
        'Type': 'int',
        'eg': '	7',
        'Chinese description': '预约总数量',
        'English description': 'Total number of appointments',
      },{
        'Parameter name': 'qualified',
        'Type': 'int',
        'eg': '	7',
        'Chinese description': '合格数量',
        'English description': 'Qualified quantity',
      },{
        'Parameter name': 'unqualified',
        'Type': 'int',
        'eg': '	0',
        'Chinese description': '不合格数量',
        'English description': 'Unqualified quantity',
      },{
        'Parameter name': 'shelfQty',
        'Type': 'int',
        'eg': '	7',
        'Chinese description': '上架数量',
        'English description': 'Quantity on shelves',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 入库单查询
  // {
  //     id:'inboundQuery',
  //   titleZh: '入库单查询',
  //   titleEn: 'ASN Query',
  //   api: '/api-json/v2/getGRNlist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
  //   request: 'GET',
  //   requestExampleZh: '请求示例',
  //   requestExampleEn: 'Request example',
  //   requestExample:'/api-json/v2/getGRNlist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3&ASNNumber=A100001903210001',
  //   requestParameterZh: '请求参数',
  //   requestParameterEn: 'Request Parameter',
  //   requestParameter: [{
  //     titleZh: '参数名',
  //     titleEn: 'Parameter name',
  //     label: 'Parameter name',
  //     key: 'Parameter name',
  //     resizable: false,
  //     width: 150,
  //   },{
  //     titleZh: '类型',
  //     titleEn: 'Type',
  //     label: 'Type',
  //     key: 'Type',
  //     resizable: false,
  //     width: 100,
  //   }, {
  //     titleZh: '必需',
  //     titleEn: 'Required',
  //     label: 'Required',
  //     key: 'Required',
  //     resizable: false,
  //     width: 100,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   }, {
  //     titleZh: '示例',
  //     titleEn: 'e.g.',
  //     label: 'e.g.',
  //     key: 'eg',
  //     resizable: false,
  //   }],
  //   requestParameterTableSource:[{
  //     'Parameter name': 'Key',
  //     'Type': 'string',
  //     'Required': '',
  //     'Chinese description': '	Key',
  //     'English description': 'Key',
  //     'eg': 'Key'
  //
  //   }, {
  //     'Parameter name': 'ASNNumbe',
  //     'Type': 'string',
  //     'Required': '',
  //     'Chinese description': '入库单号',
  //     'English description': '	Inbound order number',
  //     'eg': '	A100001903210001'
  //
  //   }],
  //   returnExampleZh: '响应示例',
  //   returnExampleEn: 'Return example',
  //   returnExample: `
  //   {
  //     "success":"success",
  //     "message":"",
  //     "ASNNumber":"A100001811290004",
  //     "ASNStatus":"已上架",
  //     "FullName":"**仓",
  //     "ReferenceNo":"123456789",
  //     "CreateTime":"2018-11-29 15:56:57",
  //     "sumSKU":"5",
  //     "data":[
  //         {
  //             "SKU":"AB",
  //             "GoodsCode":"AB",
  //             "ShouldQuantity":"20",
  //             "OKQuantity":"20",
  //             "FactQuantity":"0",
  //             "Quantity":"20"
  //         },
  //         {
  //             "SKU":"Cc",
  //             "GoodsCode":"Cc",
  //             "ShouldQuantity":"20",
  //             "OKQuantity":"20",
  //             "FactQuantity":"0",
  //             "Quantity":"20"
  //         },
  //         {
  //             "SKU":"DF",
  //             "GoodsCode":"DF",
  //             "ShouldQuantity":"20",
  //             "OKQuantity":"20",
  //             "FactQuantity":"0",
  //             "Quantity":"20"
  //         },
  //         {
  //             "SKU":"CD",
  //             "GoodsCode":"CD",
  //             "ShouldQuantity":"20",
  //             "OKQuantity":"20",
  //             "FactQuantity":"0",
  //             "Quantity":"20"
  //         },
  //         {
  //             "SKU":"toi",
  //             "GoodsCode":"toi",
  //             "ShouldQuantity":"20",
  //             "OKQuantity":"20",
  //             "FactQuantity":"0",
  //             "Quantity":"20"
  //         }
  //     ]
  // }`,
  //   responseParameterZh: '响应参数',
  //   responseParameterEn: 'Response Parameter',
  //   responseParameter: [{
  //     titleZh: '参数名',
  //     titleEn: 'Parameter name',
  //     label: 'Parameter name',
  //     key: 'Parameter name',
  //     resizable: false,
  //     width:150
  //   },{
  //     titleZh: '类型',
  //     titleEn: 'Type',
  //     label: 'Type',
  //     key: 'Type',
  //     resizable: false,
  //     width: 100,
  //   },{
  //     titleZh: '示例',
  //     titleEn: 'e.g.',
  //     label: 'e.g.',
  //     key: 'eg',
  //     resizable: false,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   }],
  //   responseParameterTableSource:[{
  //     'Parameter name': 'ASNNumber',
  //     'Type': 'string',
  //     'eg': '	A100001810170003',
  //     'Chinese description': '	入库单号',
  //     'English description': 'Inbound order number'
  //   }, {
  //     'Parameter name': 'ASNStatus',
  //     'Type': 'string',
  //     'eg': '		已完成',
  //     'Chinese description': '入库单状态(1 未收货,2 已收货,3 已质检,4 已上架,(10 头程物流入库,11 头程物流出库 已作废),8 已完成)',
  //     'English description': 'Inbound order status (1 unreceived, 2 received, 3 quality inspected, 4 put on the shelf, 10 head logistics inbound, 11 head logistics out of storage, 8 completed)'
  //   }, {
  //     'Parameter name': 'FullName',
  //     'Type': 'string',
  //     'eg': '	**仓',
  //     'Chinese description': '仓储名称',
  //     'English description': 'Warehouse name'
  //   }, {
  //     'Parameter name': 'ReferenceNo',
  //     'Type': 'string',
  //     'eg': '17215154036266959',
  //     'Chinese description': '参考号',
  //     'English description': 'Reference No'
  //   }, {
  //     'Parameter name': 'CreateTime',
  //     'Type': 'string',
  //     'eg': '2018-10-24 10:10:55',
  //     'Chinese description': '创建时间',
  //     'English description': 'Creation time'
  //   }, {
  //     'Parameter name': 'sumSKU',
  //     'Type': 'string',
  //     'eg': '10',
  //     'Chinese description': 'SKU种类数',
  //     'English description': 'SKU Number of species'
  //   }],
  //   responseChildNode: [{
  //     requestParameter: [{
  //       titleZh: '子表名称',
  //       titleEn: 'Detail name',
  //       label: 'Detail name',
  //       key: 'Detail name',
  //       resizable: false,
  //       width: 150,
  //     }, {
  //       titleZh: '类型',
  //       titleEn: 'Type',
  //       label: 'Type',
  //       key: 'Type',
  //       resizable: false,
  //       width: 100,
  //     },  {
  //       titleZh: '示例',
  //       titleEn: 'e.g.',
  //       label: 'e.g.',
  //       key: 'eg',
  //       resizable: false,
  //     }, {
  //       titleZh: '中文描述',
  //       titleEn: 'Chinese description',
  //       label: 'Chinese description',
  //       key: 'Chinese description',
  //       resizable: false,
  //     }, {
  //       titleZh: '英文描述',
  //       titleEn: 'English description',
  //       label: 'English description',
  //       key: 'English description',
  //       resizable: false,
  //     }],
  //     requestParameterTableSource:[{
  //       'Detail name': 'SKU',
  //       'Type': 'string',
  //       'eg': '	100',
  //       'Chinese description': '	SKU',
  //       'English description': '	SKU',
  //     }, {
  //       'Detail name': 'GoodsCode',
  //       'Type': 'string',
  //       'eg': '	SKU001',
  //       'Chinese description': '条码	',
  //       'English description': 'Bar code',
  //     }, {
  //       'Detail name': 'ShouldQuantity',
  //       'Type': 'string',
  //       'eg': '	100',
  //       'Chinese description': '	预约总数量',
  //       'English description': '	Total number of appointments',
  //     }, {
  //       'Detail name': 'OKQuantity',
  //       'Type': 'string',
  //       'eg': '	90',
  //       'Chinese description': '	合格数量',
  //       'English description': '	Qualified quantity',
  //     }, {
  //       'Detail name': 'FactQuantity',
  //       'Type': 'string',
  //       'eg': '	10',
  //       'Chinese description': '	不合格数量',
  //       'English description': '	Unqualified quantity',
  //     }, {
  //       'Detail name': 'Quantity',
  //       'Type': 'string',
  //       'eg': '	10',
  //       'Chinese description': '	上架数量',
  //       'English description': '	Quantity on shelves',
  //     }]
  //   }],
  //   errorCodeZh: '错误代码',
  //   errorCodeEn: 'error code',
  //   errorCode:[{
  //     titleZh: '代码',
  //     titleEn: 'code',
  //     label: 'code',
  //     key: 'code',
  //     resizable: false,
  //   }, {
  //     titleZh: '中文描述',
  //     titleEn: 'Chinese description',
  //     label: 'Chinese description',
  //     key: 'Chinese description',
  //     resizable: false,
  //   }, {
  //     titleZh: '英文描述',
  //     titleEn: 'English description',
  //     label: 'English description',
  //     key: 'English description',
  //     resizable: false,
  //   }],
  //   errorCodeTableSource:[{
  //     'code': '100',
  //     'Chinese description': '成功',
  //     'English description': 'success',
  //   }, {
  //     'code': '101',
  //     'Chinese description': '失败',
  //     'English description': 'fail',
  //   }]
  // },

  //group 创建出库单
  {
    id:'outboundCreate',
    titleZh: '创建出库单',
    titleEn: 'Create Order',
    api: '/api-json/v2/CreateOrder?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'POST',

    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '长度',
      titleEn: 'Length',
      label: 'Length',
      key: 'Length',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': '必需',
      'Length': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    bodyRequestExampleZh: 'Body 请求示例',
    bodyRequestExampleEn: 'Body Request example',
    bodyRequestExample: `
    {
      "customerCode": "10003",
      "deliveryType": "0",
      "shipToName": "sendername3",
      "shipToMobileNumber": "189189189",
      "shipToArea": "area 3",
      "shipToAdress1": "address 3",
      "shipToAdress2": "",
      "shipToCity": "Kempton Park",
      "shipToSuburb": "Spartan",
      "shipToZipCode": "1619",
      "shipToCompanyName": "",
      "shipToEmail": "aa@qq.com",
      "warehouseCode": "JHB-DC",
      "remark": "",
      "remarkno": "",
      "prioritylevel": "0",
      "logisticsServices": "0",
      "terms": "2",
      "arrivedWarehouseCode": "",
      "products": [
        {
          "quantity": "1",
          "sku": "product1"
        }
      ],
      "valueaddorder": {
        "labelChoose": "1",
        "reboxChoose": "1",
        "reboardChoose": "1",
        "labelNum": "6",
        "labelRemark": "",
        "reboxedNum": "7",
        "reboxedRemark": "",
        "reboardNum": "8",
        "reboardRemark": ""
      }
    }`,
    bodyRequestParameterZh: 'Body 请求参数',
    bodyRequestParameterEn: 'Body Request Parameter',
    bodyRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 200,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    },
    //   {
    //   titleZh: '长度',
    //   titleEn: 'Length',
    //   label: 'Length',
    //   key: 'Length',
    //   resizable: false,
    //   width: 100,
    // },
      {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    bodyRequestParameterTableSource:[
      {
        'Parameter name': 'customerCode',
        'Type': 'string',
        'Required': '	R',
        'Length': '	5',
        'Chinese description': '客户代码',
        'English description': 'Customer Code',
        'eg': ''
      },{
        'Parameter name': 'deliveryType',
        'Type': 'string',
        'Required': '	R',
        'Length': '	1',
        'Chinese description': '出库单配送类型（0-派送; 1-自提;）',
        'English description': 'delivery type',
        'eg': '0'
      },{
        'Parameter name': 'shipToName',
        'Type': 'string',
        'Required': '	R',
        'Length': '	100',
        'Chinese description': '收件人名称',
        'English description': 'receiver',
        'eg': ''
      },{
        'Parameter name': 'shipToMobileNumber',
        'Type': 'string',
        'Required': '	R',
        'Length': '	20',
        'Chinese description': '收件人手机号',
        'English description': 'receiver telephone',
        'eg': ''
      },{
        'Parameter name': 'shipToArea',
        'Type': 'string',
        'Required': '	R',
        'Length': '	50',
        'Chinese description': '收件人区域',
        'English description': 'receiver area',
        'eg': ''
      },{
        'Parameter name': 'shipToAdress1',
        'Type': 'string',
        'Required': '	R',
        'Length': '	200',
        'Chinese description': '收件人地址行1',
        'English description': 'receiver address1',
        'eg': ''
      },{
        'Parameter name': 'shipToAdress2',
        'Type': 'string',
        'Required': '	O',
        'Length': '	200',
        'Chinese description': '收件人地址行2',
        'English description': 'receiver address2',
        'eg': ''
      },{
        'Parameter name': 'shipToCity',
        'Type': 'string',
        'Required': '	O',
        'Length': '	50',
        'Chinese description': '收件人城市',
        'English description': 'receiver city',
        'eg': ''
      },{
        'Parameter name': 'shipToSuburb',
        'Type': 'string',
        'Required': '	O',
        'Length': '	50',
        'Chinese description': '收件人乡镇',
        'English description': 'receiver suburb',
        'eg': ''
      },{
        'Parameter name': 'shipToZipCode',
        'Type': 'string',
        'Required': '	R',
        'Length': '	50',
        'Chinese description': '收件人postcode',
        'English description': 'receiver postcode',
        'eg': ''
      },{
        'Parameter name': 'shipToCompanyName',
        'Type': 'string',
        'Required': '	O',
        'Length': '	100',
        'Chinese description': '收件人公司',
        'English description': 'receiver company',
        'eg': ''
      },{
        'Parameter name': 'shipToEmail',
        'Type': 'string',
        'Required': '	O',
        'Length': '	50',
        'Chinese description': '收件人邮箱',
        'English description': 'receiver email',
        'eg': ''
      },{
        'Parameter name': 'warehouseCode',
        'Type': 'string',
        'Required': '	R',
        'Length': '	50',
        'Chinese description': '仓储代码',
        'English description': '	Warehouse code',
        'eg': 'JHB'
      },{
        'Parameter name': 'remark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '备注',
        'English description': 'remark',
        'eg': ''
      },{
        'Parameter name': 'remarkno',
        'Type': 'string',
        'Required': '',
        'Chinese description': '参考号',
        'English description': 'Reference No',
        'eg': ''
      },{
        'Parameter name': 'prioritylevel',
        'Type': 'string',
        'Required': '',
        'Chinese description': '优先出库标识：0-普通；1-优先；',
        'English description': 'priority shipping label',
        'eg': '1'
      },{
        'Parameter name': 'logisticsServices',
        'Type': 'string',
        'Required': 'O',
        'Chinese description': '物流产品，基础数据接口中获取（CN仓库派送或跨国移库时必填）',
        'English description': 'logistics services',
        'eg': '0'
      },{
        'Parameter name': 'terms',
        'Type': 'string',
        'Required': 'O',
        'Chinese description': 'Terms，基础数据接口中获取（物流产品为大货时必填）',
        'English description': 'terms',
        'eg': '2'
      },{
        'Parameter name': 'arrivedWarehouseCode',
        'Type': 'string',
        'Required': 'O',
        'Length': '50',
        'Chinese description': '目的仓库代码（跨国移库必传）',
        'English description': 'Destination Warehouse Code (Required for Cross-border Transfers)',
        'eg': 'JHB'
      },{
        'Parameter name': 'products',
        'Type': 'JSON',
        'Required': '	R',
        'Length': '	',
        'Chinese description': '产品信息',
        'English description': 'product info',
        'eg': ''
      },{
        'Parameter name': 'valueaddorder',
        'Type': 'JSON',
        'Required': '	O',
        'Length': '	',
        'Chinese description': '增值服务信息',
        'English description': 'value-added service information',
        'eg': ''
      }
    ],
    bodyRequestChildNode: [{
      requestParameter: [{
        titleZh: 'products参数名',
        titleEn: 'products Parameter name',
        label: 'products Parameter name',
        key: 'products Parameter name',
        resizable: false,
        width: 200,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '必需',
        titleEn: 'Required',
        label: 'Required',
        key: 'Required',
        resizable: false,
        width: 100,
      },
      //   {
      //   titleZh: '长度',
      //   titleEn: 'Length',
      //   label: 'Length',
      //   key: 'Length',
      //   resizable: false,
      //   width: 100,
      // },
        {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }, {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'products Parameter name': 'SKU',
        'Type': 'string',
        'Required': '	R',
        'Length': '	50',
        'Chinese description': '	物品SKU',
        'English description': '	SKU',
        'eg': '	SKU1'
      }, {
        'products Parameter name': 'quantity',
        'Type': 'string',
        'Required': '	R',
        'Length': '	12',
        'Chinese description': '	产品数量',
        'English description': '	quantity',
        'eg': '	12'
      }]
    },{
      requestParameter: [{
        titleZh: 'valueaddorderInfo参数名',
        titleEn: 'valueaddorderInfo Parameter name',
        label: 'valueaddorderInfo Parameter name',
        key: 'valueaddorderInfo Parameter name',
        resizable: false,
        width: 200,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '必需',
        titleEn: 'Required',
        label: 'Required',
        key: 'Required',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }, {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'valueaddorderInfo Parameter name': 'labelChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '	贴标选择 1选择 0默认不选',
        'English description': '	Labeling Selection Identifier',
        'eg': '	0'
      }, {
        'valueaddorderInfo Parameter name': 'reboxChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '打包选择 1选择 0不选',
        'English description': 'Packaging Selection Identifier',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboardChoose',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '打板选择 1选择 0不选',
        'English description': 'Board Selection Indicator',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'insuredChoose',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保价选择(出库传0) 1选择 0不选',
        'English description': 'Insurance Selection Identifier',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'labelNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '贴标数',
        'English description': 'Number of Labels Applied',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'labelRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '贴标备注',
        'English description': 'Labeling Remarks',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'reboxedNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打包数',
        'English description': 'Number of items repackaged',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboxedRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打包备注',
        'English description': 'Remarks for Repackaging',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'reboardNum',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打板数',
        'English description': 'Number of Circuit Boards for Reprintin',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'reboardRemark',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '重新打板备注',
        'English description': 'Remarks for Reprinting the Circuit Board',
        'eg': '		'
      }, {
        'valueaddorderInfo Parameter name': 'insuranceAmount',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保额(出库传0)',
        'English description': 'insured amount',
        'eg': '		0'
      }, {
        'valueaddorderInfo Parameter name': 'month',
        'Type': 'string',
        'Required': '	O',
        'Chinese description': '保价持续月数(出库传0)',
        'English description': 'Duration of Insurance Coverage',
        'eg': '		0'
      },
        //  {
        //   'valueaddorderInfo Parameter name': 'files',
        //   'Type': 'JSON',
        //   'Required': '	O',
        //   'Chinese description': '文件',
        //   'English description': 'files',
        //   'eg': ''
        // },
      ]
    }],


    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": "订单保存并提交成功(Order saved and submitted successfully)!",
      "csRefNo": "123123",
      "orderNo": "M100010000957812",
      "trackingNo": "",
      "valueaddorderNo": "",
      "transferNo": "",
      "enmessage": ""
    }

    {
      "success": "failure",
      "code": "101",
      "message": "product1 商品库存不足"
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'Type': '100即成功'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示描述',
      'English description': 'Prompt description',
      'Type': 'string'
    }],
    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 出库单查询
  {
    id:'outboundQuery',
    titleZh: '出库单查询',
    titleEn: 'Query Order',
    api: '/api-json/v2/GetOrderList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',
    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/GetOrderList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3&page=1&orderNo=test001',

    requestParameterZh: '请求参数',
    requestParameterEn: 'Request Parameter',
    requestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    requestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'key',
      'English description': 'key',
      'eg': '271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }, {
      'Parameter name': 'orderStatus',
      'Type': 'int',
      'Required': 'O',
      'Chinese description': '状态(0-待处理,10-待拣货,20-待打包,30-待发货,40-已发货,50-完成,55-已截单)',
      'English description': 'Status(0-Pending,10-Picking,20-Packing,30-Waiting for outbound,40-Outbound,50-Complete,55-Terminated)',
      'eg': '0'
    }, {
      'Parameter name': 'orderNo',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '系统单号',
      'English description': 'System tracking number',
      'eg': ''
    }, {
      'Parameter name': 'trackingNo',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '运单号',
      'English description': 'Tracking No',
      'eg': ''
    }, {
      'Parameter name': 'startTime',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '开始创建时间(yyyy-MM-dd HH:mm:ss)',
      'English description': 'Start creation time',
      'eg': '2023-12-01 10:10:02'
    }, {
      'Parameter name': 'endTime',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '结束创建时间(yyyy-MM-dd HH:mm:ss)',
      'English description': 'End creation time',
      'eg': '2023-12-01 10:10:02'
    }, {
      'Parameter name': 'page',
      'Type': 'int',
      'Required': 'O',
      'Chinese description': '页数（每页查询20条数据）,不填写默认查询第一页',
      'English description': 'Number of pages (each page query 20 data), do not fill in the default query first page',
      'eg': '1'
    }],


    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": null,
      "data": [
        {
          "orderNo": "M100392024010100002",
          "trackingNo": "SC202401010003",
          "orderStatus": " 待拣货",
          "shippingCode": "",
          "totalAmount": "230.00",
          "createTime": "2024-01-01 08:40:13",
          "createUser": "zyy0119",
          "remarkNo": "",
          "valueaddNo": "VA202303140001、VA202303140002",
          "serve": "派送",
          "type": "一件代发",
          "storehouse": "BUFFALO",
          "shippingTime": "",
          "logisticsStatus": "Order Confirmed-下单成功",
          "remark": "",
          "priority": "优先",
          "receiverInfo": {
            "nameen": "Jack",
            "company": "",
            "phone": "189189189",
            "email": "",
            "postcode": "1619",
            "suburb": "Esselen Park",
            "city": "Kempton Park",
            "address": ""
          },
          "skuInfoList": [
            {
              "sku": "book",
              "chineseName": "书",
              "englishName": "book",
              "barcode": "book",
              "qty": "1",
              "weight": "1.0",
              "snCode": ""
            }
          ]
        }
      ],
      "count": 1
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'code',
      'Chinese description': '代码 100即成功',
      'English description': 'code',
      'Type': 'string',
      'eg':'100'
    },{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': 'string',
      'eg':'(success/failure)'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示信息',
      'English description': 'error message',
      'Type': 'string',
      'eg':''
    }, {
      'Parameter name': 'count',
      'Chinese description': '总数量',
      'English description': 'The total amount',
      'Type': 'int',
      'eg':'1'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[
      //   {
      //   'Parameter name': 'odrOrderMainId',
      //   'Type': 'string',
      //   'eg': '	2813029663153066',
      //   'Chinese description': '订单ID',
      //   'English description': 'Order ID',
      // },
        {
        'Parameter name': 'orderNo',
        'Type': 'string',
        'eg': '	M100392024010100002',
        'Chinese description': '系统单号',
        'English description': 'System tracking number',
      },{
        'Parameter name': 'trackingNo',
        'Type': 'string',
        'eg': '	SC202401010003',
        'Chinese description': '运单号',
        'English description': 'Tracking No',
      },{
        'Parameter name': 'orderStatus',
        'Type': 'string',
        'eg': '	已截单',
        'Chinese description': '订单状态',
        'English description': 'Order Status',
      },{
        'Parameter name': 'shippingCode',
        'Type': 'string',
        'eg': '	',
        'Chinese description': '运输服务',
        'English description': 'Shipping Code',
      }
      // ,{
      //   'Parameter name': 'country',
      //   'Type': 'string',
      //   'eg': '	',
      //   'Chinese description': '国家',
      //   'English description': 'Country',
      // }
      ,{
        'Parameter name': 'totalAmount',
        'Type': 'string',
        'eg': '	230.00',
        'Chinese description': '费用',
        'English description': 'TotalAmount',
      },{
        'Parameter name': 'createTime',
        'Type': 'string',
        'eg': '	2023-12-01 08:40:13',
        'Chinese description': '创建时间',
        'English description': 'CreateTime',
      },{
          'Parameter name': 'createUser',
          'Type': 'string',
          'eg': '',
          'Chinese description': '创建人',
          'English description': 'Create User',
      },{
        'Parameter name': 'remarkNo',
        'Type': 'string',
        'eg': '',
        'Chinese description': '参考号',
        'English description': 'Reference No',
      },{
        'Parameter name': 'valueaddNo',
        'Type': 'string',
        'eg': 'VA202303140001、VA202303140002',
        'Chinese description': '增值服务单号',
        'English description': 'Value-add',
      },{
        'Parameter name': 'serve',
        'Type': 'string',
        'eg': '派送',
        'Chinese description': '服务',
        'English description': 'Delivery Type',
      },{
        'Parameter name': 'type',
        'Type': 'string',
        'eg': '一件代发',
        'Chinese description': '类型',
        'English description': 'Reference No',
      },{
        'Parameter name': 'storehouse',
        'Type': 'string',
        'eg': '',
        'Chinese description': '仓库',
        'English description': 'Warehouse',
      },{
        'Parameter name': 'shippingTime',
        'Type': 'string',
        'eg': '2023-12-01 08:40:13',
        'Chinese description': '发货时间',
        'English description': 'Outbound Time',
      },{
        'Parameter name': 'logisticsStatus',
        'Type': 'string',
        'eg': 'Order Confirmed-下单成功',
        'Chinese description': '物流状态',
        'English description': 'Logistics',
      },{
        'Parameter name': 'remark',
        'Type': 'string',
        'eg': '',
        'Chinese description': '备注',
        'English description': 'Notes',
      },{
          'Parameter name': 'priority',
          'Type': 'string',
          'eg': '优先',
          'Chinese description': '优先级',
          'English description': 'priority',
      },{
        'Parameter name': 'receiverInfo',
        'Type': 'JSON',
        'eg': '',
        'Chinese description': '收件人信息',
        'English description': 'Receiver Info',
      },{
        'Parameter name': 'skuInfoList',
        'Type': 'JSON',
        'eg': '',
        'Chinese description': '出库商品信息',
        'English description': 'SKU Info',
      }]
    },{
      requestParameter: [{
        titleZh: '收件人信息参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[
        {
          'Parameter name': 'nameen',
          'Type': 'string',
          'eg': '',
          'Chinese description': '英文名',
          'English description': 'English name',
        },{
          'Parameter name': 'company',
          'Type': 'string',
          'eg': '',
          'Chinese description': '公司名称',
          'English description': 'Company name',
        },{
          'Parameter name': 'phone',
          'Type': 'string',
          'eg': '',
          'Chinese description': '电话',
          'English description': 'phone',
        },{
          'Parameter name': 'email',
          'Type': 'string',
          'eg': '',
          'Chinese description': '邮箱',
          'English description': 'email',
        },{
          'Parameter name': 'postcode',
          'Type': 'string',
          'eg': '',
          'Chinese description': '邮编',
          'English description': 'postcode',
        },{
          'Parameter name': 'suburb',
          'Type': 'string',
          'eg': '',
          'Chinese description': '乡镇',
          'English description': 'suburb',
        },{
          'Parameter name': 'city',
          'Type': 'string',
          'eg': '',
          'Chinese description': '城市',
          'English description': 'city',
        },{
          'Parameter name': 'address',
          'Type': 'string',
          'eg': '',
          'Chinese description': '地址',
          'English description': 'address',
        }]
    },{
      requestParameter: [{
        titleZh: '出库商品信息参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 160,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[
        {
          'Parameter name': 'sku',
          'Type': 'string',
          'eg': '',
          'Chinese description': 'SKU',
          'English description': 'SKU',
        },{
          'Parameter name': 'barcode',
          'Type': 'string',
          'eg': '',
          'Chinese description': '商品条码',
          'English description': 'barcode',
        },{
          'Parameter name': 'chineseName',
          'Type': 'string',
          'eg': '',
          'Chinese description': 'SKU中文名',
          'English description': 'SKU Chinese name',
        },{
          'Parameter name': 'englishName',
          'Type': 'string',
          'eg': '',
          'Chinese description': 'SKU英文名',
          'English description': 'SKU English name',
        },{
          'Parameter name': 'qty',
          'Type': 'string',
          'eg': '1',
          'Chinese description': '数量',
          'English description': 'number',
        },{
          'Parameter name': 'weight',
          'Type': 'string',
          'eg': '1.23',
          'Chinese description': '重量',
          'English description': 'weight',
        },{
          'Parameter name': 'snCode',
          'Type': 'string',
          'eg': '',
          'Chinese description': 'SN Code',
          'English description': 'SN Code',
        }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 出库运费计算
  {
    id:'outboundEstCost',
    titleZh: '出库单运费计算',
    titleEn: 'Query Freight',
    api: '/api-json/v2/GetPrice?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'POST',

    // requestExampleZh: '请求示例',
    // requestExampleEn: 'Request example',
    // requestExample:'/api-json/v2/GetPrice?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '长度',
      titleEn: 'Length',
      label: 'Length',
      key: 'Length',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
      width: 180
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
      width: 180
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': '必需',
      'Length': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    bodyRequestExampleZh: 'Body 请求示例',
    bodyRequestExampleEn: 'Body Request example',
    bodyRequestExample: `
    {
      "warehouseCode": "JHB-DC",
      "country": "ZA",
      "customerCode": "10003",
      "city": "Kempton Park",
      "suburb": "Spartan",
      "postcode": "1619",
      "weight": "1.2"
    }`,
    bodyRequestParameterZh: 'Body 请求参数',
    bodyRequestParameterEn: 'Body Request Parameter',
    bodyRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    bodyRequestParameterTableSource:[
    //   {
    //   'Parameter name': 'key',
    //   'Type': 'string',
    //   'Required': '	R',
    //   'Chinese description': 'key',
    //   'English description': 'key',
    //   'eg': '271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    // },{
    //   'Parameter name': 'type',
    //   'Type': 'string',
    //   'Required': '	R',
    //   'Chinese description': '服务类型',
    //   'English description': 'Service type',
    //   'eg': ''
    // },
      {
      'Parameter name': 'warehouseCode',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '仓储代码',
      'English description': 'Warehouse code',
      'eg': 'SZCK'
    },{
      'Parameter name': 'customerCode',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '客户Code',
      'English description': 'Customer Code',
      'eg': '10000'
    },{
      'Parameter name': 'city',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '城市',
      'English description': 'city',
      'eg': 'Kempton Park'
    },{
      'Parameter name': 'suburb',
      'Type': 'string',
      'Required': '	R',
      'Chinese description': '乡镇',
      'English description': 'suburb',
      'eg': 'Spartan'
    },{
      'Parameter name': 'postcode',
      'Type': 'string',
      'Required': ' R',
      'Chinese description': '邮编',
      'English description': 'postcode',
      'eg': '1619'
    },{
      'Parameter name': 'weight',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': '重量',
      'English description': 'weight',
      'eg': '1.5'
    },
    //   {
    //   'Parameter name': 'volume',
    //   'Type': 'string',
    //   'Required': '	R',
    //   'Chinese description': '体积',
    //   'English description': 'volume',
    //   'eg': '1'
    // },{
    //   'Parameter name': 'minimum',
    //   'Type': 'string',
    //   'Required': '	选填',
    //   'Chinese description': '是否只返回最少报价',
    //   'English description': 'Whether to return only the least quotes',
    //   'eg': 'true'
    // }
    ],
    // bodyRequestChildNode: [{
    //   requestParameter: [{
    //     titleZh: 'asnDetail参数名',
    //     titleEn: 'asnDetail Parameter name',
    //     label: 'asnDetail Parameter name',
    //     key: 'asnDetail Parameter name',
    //     resizable: false,
    //     width: 200,
    //   }],
    //   requestParameterTableSource:[{
    //     'asnDetail Parameter name': 'SKU',
    //     'Type': 'string',
    //     'Required': '	R',
    //     'Chinese description': '	物品SKU',
    //     'English description': '	SKU',
    //     'eg': '	SKU1'
    //   }]
    // }],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": null,
      "data": [
        {
          "code": null,
          "name": null,
          "weight": "1.58",
          "prices": "12.10",
          "refTime": null
        }
      ]
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'Type': '100即成功'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'weight',
        'Type': 'string',
        'eg': '	1.5',
        'Chinese description': '重量',
        'English description': 'weight',
      },{
        'Parameter name': 'prices',
        'Type': 'string',
        'eg': '	12.10',
        'Chinese description': '价格(ZAR)',
        'English description': 'price',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 出库订单取消
  {
    id:'outboundOrderCancel',
    titleZh: '出库订单取消',
    titleEn: 'Order Cancel',
    api: '/api-json/v2/InterceptOrder?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'POST',

    // requestExampleZh: '请求示例',
    // requestExampleEn: 'Request example',
    // requestExample:'/api-json/v2/InterceptOrder?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '长度',
      titleEn: 'Length',
      label: 'Length',
      key: 'Length',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
      width: 180
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
      width: 180
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': '必需',
      'Length': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    bodyRequestExampleZh: 'Body 请求示例',
    bodyRequestExampleEn: 'Body Request example',
    bodyRequestExample: `
    {
      "orderNo": "M100000000180722"
    }`,
    bodyRequestParameterZh: 'Body 请求参数',
    bodyRequestParameterEn: 'Body Request Parameter',
    bodyRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    bodyRequestParameterTableSource:[
      {
        'Parameter name': 'orderNo',
        'Type': 'string',
        'Required': '	R',
        'Chinese description': '系统单号/运单号',
        'English description': 'System tracking number / waybill number',
        'eg': 'M100000000180722'
      }
    ],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": "截单成功"
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'Type': '100即成功'
    }, {
      'Parameter name': 'message',
      'Chinese description': '提示信息',
      'English description': 'error message',
      'Type': ''
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 库存查询
  {
    id:'InventoryQuery',
    titleZh: '库存查询',
    titleEn: 'Query Inventory',
    api: '/api-json/v2/getinventorylist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',
    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/getinventorylist?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3&page=1&warehouseCode=JHB',

    requestParameterZh: '请求参数',
    requestParameterEn: 'Request Parameter',
    requestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    requestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'key',
      'English description': 'key',
      'eg': '271bd87a-60bd-4ef8-b3bc-5192e2e72de3'

    }, {
      'Parameter name': 'warehouseCode',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': '仓库代码',
      'English description': 'Warehouse code',
      'eg': 'JHB-DC'
    }, {
      'Parameter name': 'sku',
      'Type': 'string',
      'Required': 'O',
      'Chinese description': 'SKU',
      'English description': 'SKU',
      'eg': ''
    }, {
      'Parameter name': 'page',
      'Type': 'int',
      'Required': 'O',
      'Chinese description': '页数（每页查询20条数据）,不填写默认查询第一页',
      'English description': 'Number of pages (each page query 20 data), do not fill in the default query first page',
      'eg': '1'
    }],


    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
    {
      "success": "success",
      "code": "100",
      "message": null,
      "count": "1",
      "data": [{
        "count": "1",
        "goodsCode": "tes99883",
        "sku": "tes99883",
        "totalNumber": "2844360",
        "tLength": "0.0",
        "tWidth": "0.0",
        "tHigh": "0.0",
        "tWeight": "10.0",
        "number": "1",
        "warehouseCode": "BUFFALO"
      }]
    }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    },{
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    },{
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'Type': 'string',
      'eg': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码 100即成功',
      'English description': 'code',
      'Type': 'int',
      'eg': '100'
    },{
      'Parameter name': 'count',
      'Type': 'string',
      'eg': '	7',
      'Chinese description': '总数量',
      'English description': 'The total amount',
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'count',
        'Type': 'string',
        'eg': '	7',
        'Chinese description': '总数量',
        'English description': 'The total amount',
      },{
        'Parameter name': 'goodsCode',
        'Type': 'string',
        'eg': '	',
        'Chinese description': '产品条码',
        'English description': 'Product barcode',
      },{
        'Parameter name': 'sku',
        'Type': 'string',
        'eg': '	',
        'Chinese description': 'sku',
        'English description': 'sku',
      },{
        'Parameter name': 'totalNumber',
        'Type': 'string',
        'eg': '	0',
        'Chinese description': '可用库存',
        'English description': 'Available inventory',
      },{
        'Parameter name': 'number',
        'Type': 'string',
        'eg': '	0',
        'Chinese description': '架上库存',
        'English description': 'Shelf inventory',
      },{
        'Parameter name': 'tLength',
        'Type': 'string',
        'eg': '	0.0',
        'Chinese description': '质检长',
        'English description': 'QC Length',
      },{
        'Parameter name': 'tWidth',
        'Type': 'string',
        'eg': '	0.0',
        'Chinese description': '质检宽',
        'English description': 'QC width',
      },{
        'Parameter name': 'tHigh',
        'Type': 'string',
        'eg': '	0.0',
        'Chinese description': '质检高',
        'English description': 'QC High',
      },{
        'Parameter name': 'tWeight',
        'Type': 'string',
        'eg': '	0.0',
        'Chinese description': '质检重',
        'English description': 'QC Weight',
      },{
        'Parameter name': 'warehouseCode',
        'Type': 'string',
        'eg': '	BUFFALO',
        'Chinese description': '仓储代码',
        'English description': 'Warehouse Code',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

];
/**
* 基础数据
*/
export const basicApiData = [
  // {
  //  id:'basicCountry',
  //  titleZh: '查询国家',
  //  titleEn: 'Country Lsit',
  //  api: '/api-json/v2/GetCountryList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
  //  request: 'GET',
  //  requestExampleZh: '请求示例',
  //  requestExampleEn: 'Request example',
  //  requestExample:'/api-json/v2/GetCountryList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
  //
  //  requestParameterZh: '请求参数',
  //  requestParameterEn: 'Request Parameter',
  //  requestParameter: [{
  //    titleZh: '参数名',
  //    titleEn: 'Parameter name',
  //    label: 'Parameter name',
  //    key: 'Parameter name',
  //    resizable: false,
  //    width: 150,
  //  },{
  //    titleZh: '类型',
  //    titleEn: 'Type',
  //    label: 'Type',
  //    key: 'Type',
  //    resizable: false,
  //    width: 100,
  //  }, {
  //    titleZh: '必需',
  //    titleEn: 'Required',
  //    label: 'Required',
  //    key: 'Required',
  //    resizable: false,
  //    width: 100,
  //  }, {
  //    titleZh: '中文描述',
  //    titleEn: 'Chinese description',
  //    label: 'Chinese description',
  //    key: 'Chinese description',
  //    resizable: false,
  //  }, {
  //    titleZh: '英文描述',
  //    titleEn: 'English description',
  //    label: 'English description',
  //    key: 'English description',
  //    resizable: false,
  //  }, {
  //    titleZh: '示例',
  //    titleEn: 'e.g.',
  //    label: 'e.g.',
  //    key: 'eg',
  //    resizable: false,
  //  }],
  //  requestParameterTableSource:[{
  //    'Parameter name': 'Key',
  //    'Type': 'string',
  //    'Required': '',
  //    'Chinese description': 'key',
  //    'English description': 'key',
  //    'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
  //  }],
  //
  //
  //  returnExampleZh: '响应示例',
  //  returnExampleEn: 'Return example',
  //  returnExample: `
  //  {
  //    "success": "success",
  //    "code": "100",
  //    "data": [{
  //      "CountryID": "233",
  //      "ShortName": "CN",
  //      "CnName": "中国",
  //      "EnName": "China"
  //    }]
  //  }
  //  `,
  //  responseParameterZh: '响应参数',
  //  responseParameterEn: 'Response Parameter',
  //  responseParameter: [{
  //    titleZh: '参数名',
  //    titleEn: 'Parameter name',
  //    label: 'Parameter name',
  //    key: 'Parameter name',
  //    resizable: false,
  //  }, {
  //    titleZh: '中文描述',
  //    titleEn: 'Chinese description',
  //    label: 'Chinese description',
  //    key: 'Chinese description',
  //    resizable: false,
  //  }, {
  //    titleZh: '英文描述',
  //    titleEn: 'English description',
  //    label: 'English description',
  //    key: 'English description',
  //    resizable: false,
  //  }, {
  //    titleZh: '示例',
  //    titleEn: 'e.g.',
  //    label: 'e.g.',
  //    key: 'eg',
  //    resizable: false,
  //  }],
  //  responseParameterTableSource:[{
  //    'Parameter name': 'success',
  //    'Chinese description': '是否成功',
  //    'English description': 'whether succeed',
  //    'eg': '	(success/failure)'
  //  }, {
  //    'Parameter name': 'code',
  //    'Chinese description': '	错误代码',
  //    'English description': '	error code',
  //    'eg': '	100即成功'
  //  }, {
  //    'Parameter name': 'CountryID',
  //    'Chinese description': '	国家ID',
  //    'English description': '	Country ID',
  //    'eg': 'string'
  //  }, {
  //    'Parameter name': 'ShortName',
  //    'Chinese description': '国家简称',
  //    'English description': 'Country abbreviation',
  //    'eg': 'string'
  //  }, {
  //    'Parameter name': 'CnName',
  //    'Chinese description': '国家中文名',
  //    'English description': 'Country Chinese name',
  //    'eg': 'string'
  //  }, {
  //    'Parameter name': 'EnName',
  //    'Chinese description': '国家英文名',
  //    'English description': 'Country English name',
  //    'eg': 'string'
  //  }],
  //  errorCodeZh: '错误代码',
  //  errorCodeEn: 'error code',
  //  errorCode:[{
  //    titleZh: '代码',
  //    titleEn: 'code',
  //    label: 'code',
  //    key: 'code',
  //    resizable: false,
  //  }, {
  //    titleZh: '中文描述',
  //    titleEn: 'Chinese description',
  //    label: 'Chinese description',
  //    key: 'Chinese description',
  //    resizable: false,
  //  }, {
  //    titleZh: '英文描述',
  //    titleEn: 'English description',
  //    label: 'English description',
  //    key: 'English description',
  //    resizable: false,
  //  }],
  //  errorCodeTableSource:[{
  //    'code': '100',
  //    'Chinese description': '成功',
  //    'English description': 'success',
  //  }, {
  //    'code': '101',
  //    'Chinese description': '失败',
  //    'English description': 'fail',
  //  }]
  // },

  //group 查询仓储信息
  {
    id:'basicWarehouse',
    titleZh: '仓储信息',
    titleEn: 'Warehousing List',
    api: '/api-json/v2/GetStorageList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',

    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/GetStorageList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'API 密钥',
      'English description': '	API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
     {
      "success": "success",
      "code": "100",
      "data": [{
        "warehouseCode": "JHB",
        "warehouseName": "南非仓"
      }]
     }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'eg': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'eg': '100即成功'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
        width: 100
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'warehouseCode',
        'Type': 'string',
        'eg': 'JHB-DC',
        'Chinese description': '仓储代码',
        'English description': 'Warehouse code',
      },{
        'Parameter name': 'warehouseName',
        'Type': 'string',
        'eg': '南非仓',
        'Chinese description': '仓储名',
        'English description': 'Warehouse name',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

  //group 查询物流服务
  {
    id:'basicLogisticsService',
    titleZh: '物流服务',
    titleEn: 'Logistics Services',
    api: '/api-json/v2/GetShippingList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',

    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/GetShippingList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'API 密钥',
      'English description': 'API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
     {
      "success": "success",
      "code": "100",
      "data": [{
        "name": "经济快件",
        "nameEn": "China to receiver",
        "type": 0
      }]
     }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'eg': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'eg': '100即成功'
    }],
    responseChildNode: [{
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
        width: 100
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'name',
        'Type': 'string',
        'eg': '经济快件',
        'Chinese description': '物流服务中文名',
        'English description': 'logistics service chinese name',
      },{
        'Parameter name': 'nameEn',
        'Type': 'string',
        'eg': 'China to receiver',
        'Chinese description': '物流服务英文名',
        'English description': 'logistics service english name',
      },{
        'Parameter name': 'type',
        'Type': 'string',
        'eg': '0',
        'Chinese description': '物流服务ID',
        'English description': 'logistics service id',
      }]
    }],

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },


  //group 查询Terms
  {
    id:'basicTerms',
    titleZh: 'Terms',
    titleEn: 'Terms',
    api: '/api-json/v2/GetTermsList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    request: 'GET',

    requestExampleZh: '请求示例',
    requestExampleEn: 'Request example',
    requestExample:'/api-json/v2/GetTermsList?key=271bd87a-60bd-4ef8-b3bc-5192e2e72de3',
    queryRequestParameterZh: 'Query 请求参数',
    queryRequestParameterEn: 'Query example',
    queryRequestParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
      width: 150,
    }, {
      titleZh: '类型',
      titleEn: 'Type',
      label: 'Type',
      key: 'Type',
      resizable: false,
      width: 100,
    }, {
      titleZh: '必需',
      titleEn: 'Required',
      label: 'Required',
      key: 'Required',
      resizable: false,
      width: 100,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    queryRequestParameterTableSource:[{
      'Parameter name': 'key',
      'Type': 'string',
      'Required': 'R',
      'Chinese description': 'API 密钥',
      'English description': 'API KEY',
      'eg': '	271bd87a-60bd-4ef8-b3bc-5192e2e72de3'
    }],

    returnExampleZh: '响应示例',
    returnExampleEn: 'Return example',
    returnExample: `
     {
      "success": "success",
      "code": "100",
      "data": [{
        "termsid": 1,
        "termsname": "DDU-普货"
      }]
     }
    `,
    responseParameterZh: '响应参数',
    responseParameterEn: 'Response Parameter',
    responseParameter: [{
      titleZh: '参数名',
      titleEn: 'Parameter name',
      label: 'Parameter name',
      key: 'Parameter name',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }, {
      titleZh: '示例',
      titleEn: 'e.g.',
      label: 'e.g.',
      key: 'eg',
      resizable: false,
    }],
    responseParameterTableSource:[{
      'Parameter name': 'success',
      'Chinese description': '是否成功',
      'English description': 'whether succeed',
      'eg': '(success/failure)'
    }, {
      'Parameter name': 'code',
      'Chinese description': '代码',
      'English description': 'code',
      'eg': '100即成功'
    }],
    responseChildNode: {
      requestParameter: [{
        titleZh: '参数名',
        titleEn: 'Parameter name',
        label: 'Parameter name',
        key: 'Parameter name',
        resizable: false,
        width: 150,
      }, {
        titleZh: '类型',
        titleEn: 'Type',
        label: 'Type',
        key: 'Type',
        resizable: false,
        width: 100,
      },  {
        titleZh: '示例',
        titleEn: 'e.g.',
        label: 'e.g.',
        key: 'eg',
        resizable: false,
        width: 100
      }, {
        titleZh: '中文描述',
        titleEn: 'Chinese description',
        label: 'Chinese description',
        key: 'Chinese description',
        resizable: false,
      }, {
        titleZh: '英文描述',
        titleEn: 'English description',
        label: 'English description',
        key: 'English description',
        resizable: false,
      }],
      requestParameterTableSource:[{
        'Parameter name': 'termsid',
        'Type': 'long',
        'eg': '1',
        'Chinese description': 'terms id',
        'English description': 'terms id',
      },{
        'Parameter name': 'termsname',
        'Type': 'string',
        'eg': 'DDU-普货',
        'Chinese description': 'terms name',
        'English description': 'terms name',
      }]
    },

    errorCodeZh: '错误代码',
    errorCodeEn: 'error code',
    errorCode:[{
      titleZh: '代码',
      titleEn: 'code',
      label: 'code',
      key: 'code',
      resizable: false,
    }, {
      titleZh: '中文描述',
      titleEn: 'Chinese description',
      label: 'Chinese description',
      key: 'Chinese description',
      resizable: false,
    }, {
      titleZh: '英文描述',
      titleEn: 'English description',
      label: 'English description',
      key: 'English description',
      resizable: false,
    }],
    errorCodeTableSource:[{
      'code': '100',
      'Chinese description': '成功',
      'English description': 'success',
    }, {
      'code': '101',
      'Chinese description': '失败',
      'English description': 'fail',
    }]
  },

 ]




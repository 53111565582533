/*
 * @Author: vere
 * @Date: 2024-02-02 14:26:51
 * @LastEditors: 
 * @LastEditTime: 2024-02-04 17:08:00
 * @Descriptiolog
 */
const TOKEN_KEY = 'token_key'

export const getTicket = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const setTicket = (token) => {
  return new Promise((resolve) => {
    console.log('token', token)
    localStorage.setItem(TOKEN_KEY, token)
    resolve(token)
  })
}

export const removeTicket = () => {
  return new Promise((resolve) => {
    localStorage.removeItem(TOKEN_KEY)
    resolve()
  })
}

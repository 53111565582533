<template>
  <section>
    <!-- 盘点管理 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter mb-lg">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
        </com-search>
      </div>
      <!-- <el-row class="mt-lg mb-lg justify-end">
        <el-button
          v-buttonPermission="`${routePath}/NewCreate`"
          type="primary"
          icon="DocumentAdd"
          @click="createQueryIntroHandle"
          >新建盘点单</el-button
        >
      </el-row> -->
      <el-table
        :data="tableOption.tableData"
        :height="tableOption.height"
        :header-cell-style="{ background: '#FAFAFA' }"
        border
        stripe
        style="width: 100%"
      >
        <el-table-column
          type="index"
          width="60"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(column, index) in tableOption.tableColumn"
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :align="column.align || 'center'"
          :min-width="column.minWidth"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'invno'">
              <span v-if="row.state == '过期'">{{ row.invno }}</span>
              <el-link
                v-else
                type="primary"
                :underline="false"
                @click="showDetail(row.id)"
              >
                <span class="link">{{ row.invno }}</span>
              </el-link>
            </div>
            <div v-else-if="column.prop == 'createtime'">
              {{ row.createtime }}
            </div>
            <div v-else-if="column.prop == 'finishtime'">
              {{ row.finishtime }}
            </div>
            <!--  | dateFormatFilter -->
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>

        <!-- 空数据 展示 -->
        <template #empty>
          <div class="img-wrap flex align-center flex-direction">
            <img
              style="width: 168px; height: 168px"
              src="~@/assets/images/nodata.png"
              alt="no data"
            />
            <span style="color: #636363">暂无数据</span>
          </div>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        style="text-align: right"
        :current-page="params.pageNum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-card>

    <!--新建盘点单提示弹出框-->
    <el-dialog
      title="新建盘点单"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-model="isVisible"
      width="50%"
    >
      <el-row>
        <el-col :span="24">
          <div class="create-query-order-dialog">
            <el-row class="main-background remind basic-color">
              <el-col :span="24">
                <div class="flex">
                  <el-icon class="icon link"><WarningFilled /></el-icon>
                  <div>
                    <p class="note">注意</p>
                    <p>1.如果盘点涉及多个仓库，将会生成多张盘点单。</p>
                    <p>
                      2.在您创建盘点单后，相关的仓库会在空闲时段处理您的盘点要求，如期望加快盘点速度，可联系客服人员催单。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-form class="mt-lg">
              <el-form-item label="盘点单类型：">
                <el-radio-group v-model="queryOrderType">
                  <el-radio
                    v-for="(item, index) in queryTypeOptions"
                    :key="index"
                    :label="item.type"
                    :value="item.type"
                    >{{ item.nameEn }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <el-button @click="queryIntroHandle('cancel')">取消</el-button>
        <el-button type="primary" @click="queryIntroHandle('confirm')"
          >提交</el-button
        >
      </template>
    </el-dialog>
    <!--    盘点弹出框-->
    <el-dialog
      :title="queryTitle"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      v-model="queryVisible"
      :width="queryOrderType == 1 ? '60%' : '32%'"
    >
      <!--按客户进行盘点-->
      <el-form
        v-if="queryOrderType == 2"
        ref="orderQueryFormRef"
        class="mt-lg"
        label-width="100px"
        :model="orderQueryForm"
        :rules="orderQueryFormRules"
      >
        <!--  默认为最早创建的仓库-->
        <el-form-item label="仓库" prop="store">
          <el-select
            v-model="orderQueryForm.store"
            clearable
            placeholder="请选择仓库"
            class="w_100"
          >
            <el-option
              v-for="item in warehouseOptions"
              :key="item.id"
              :label="item.namecn"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- 禁用框，默认为登陆客户-->
        <el-form-item label="客户名称" prop="customer">
          <el-input
            v-trim
            v-model="orderQueryForm.customer"
            placeholder="客户名称"
            disabled
            class="w_100"
          />
        </el-form-item>
      </el-form>
      <!--按指定sku盘点-->
      <div v-else-if="queryOrderType == 1">
        <el-row class="table-filter">
          <el-col :span="24">
            <el-form
              ref="skuQueryFormRef"
              label-width="90px"
              class="sku-form"
              :model="skuQueryForm"
              :rules="skuQueryFormRules"
              inline
            >
              <el-form-item label="SKU:" prop="sku">
                <!--   @focus="checkoutSkuOptionsHandle" -->
                <el-select
                  v-model="skuQueryForm.skuName"
                  class="select_w"
                  filterable
                  value-key="id"
                  clearable
                  remote
                  remote-show-suffix
                  placeholder="请选择SKU"
                  :teleported="false"
                  :remote-method="handleSkuRemoteMethod"
                  @change="handleSkuChange"
                >
                  <el-option
                    v-for="item in skuOptions"
                    :key="item.id"
                    :label="item.sku"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="商品名称:" prop="product">
                <el-input
                  v-trim
                  v-model="skuQueryForm.product"
                  class="select_w"
                  placeholder="商品名称"
                  disabled
                />
              </el-form-item>
              <!-- 仓库有默认选项 ,默认为最早创建的仓库-->
              <el-form-item label="仓库:" prop="store">
                <el-select
                  v-model="skuQueryForm.store"
                  placeholder="请选择仓库"
                  class="select_w"
                >
                  <el-option
                    v-for="item in warehouseOptions"
                    :key="item.id"
                    :label="item.namecn"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                ><el-button type="primary" @click="insertHandle"
                  >插入盘点清单</el-button
                ></el-form-item
              >
            </el-form>
          </el-col>
        </el-row>

        <el-row class="justify_between align-center" style="margin: 16px 0">
          <span style="color: #191919; font-weight: 500">盘点清单</span>
          <el-button
            style="float: right"
            type="danger"
            icon="Delete"
            @click="deleteHandle"
            >删除</el-button
          >
        </el-row>
        <el-table
          ref="multipleTable"
          border
          min-height="100"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          :data="skuQueryList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60" align="center" />
          <el-table-column prop="skuname" label="SKU" />
          <el-table-column prop="whname" label="仓库" />
          <el-table-column prop="zonename" label="库区" />
          <el-table-column prop="shelfname" label="货架" align="center" />
          <el-table-column prop="locationname" label="货位" align="center" />
          <!-- 空数据 展示 -->
          <template #empty>
            <com-no-data :smallImg="true"></com-no-data>
          </template>
        </el-table>
      </div>
      <template #footer>
        <el-button @click="queryOperateHandle('cancel')">取消</el-button>
        <el-button type="primary" @click="queryOperateHandle('confirm')"
          >提交</el-button
        >
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {
  inventoryStateOptionsApi,
  inventoryAdjustOptionsApi,
  inventoryAuditOptionsApi,
  inventoryCreateTypeApi,
  createInventoryInsertCheckApi,
  createInventoryOrderApi,
  inventoryListApi,
} from "@/api/storage-center/inventory";
import { commonWarehouseOptionsApi, getSkulistApi } from "@/api/common";
import { searchOption, tableOption } from "./column";
// import { useKeepAliveStore } from "@/stores/keepAliveStore.js";

import { useTrackingInfo } from "@/stores/tracking.js";

import { useRoute, useRouter } from "vue-router";
export default {
  name: "StorageCenterInventory",
  setup() {
    // const keepAliveStore = useKeepAliveStore();
    const trackingInfo = useTrackingInfo();
    const router = useRouter();
    const route = useRoute();
    const curpath = route.path;
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间
    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "盘点管理",
      });
    };
    /**
     * 查看盘点单
     */
    const showDetail = (id) => {
      router.push({
        path: `/storage-center/inventory-detail/${id}`,
      });
      // this.keepAliveStore.refresCurrentRoute({
      //   path: "",
      //   query: { id },
      // });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });
    return {
      // keepAliveStore,
      trackingInfo,
      tracking,
      showDetail,
    };
  },
  data() {
    return {
      searchOption: {
        // 顶部表单检索内容,非必填
        labelWidth: "80px",
        searchList: [
          // 检索列表
          {
            label: "盘点单号:",
            type: "input",
            value: "",
            prop: "invno",
            maxlength: 20,
            placeholder: "请输入盘点单号",
          },
          // {
          //   label: "状态:",
          //   type: "select",
          //   value: "",
          //   prop: "statetype",
          //   optionData: [],
          //   clearable: true,
          //   placeholder: "请选择状态",
          // },
          {
            label: "调整库存:",
            type: "select",
            value: "",
            prop: "adjust",
            optionData: [],
            clearable: true,
            placeholder: "请选择调整库存",
          },

          {
            label: "盘点仓库:",
            type: "select",
            value: "",
            prop: "warehouseid",
            optionData: [],
            clearable: true,
            placeholder: "请选择盘点仓库",
          },
          // {
          //   label: "审核结果:",
          //   type: "select",
          //   value: "",
          //   prop: "audittype",
          //   optionData: [],
          //   clearable: true,
          //   placeholder: "请选择审核结果",
          // },
        ],
      },
      tableOption, // table 主 列表数据
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      isVisible: false,
      queryOrderType: 1, // 盘点单类型
      queryTypeOptions: [],
      queryVisible: false, // 盘点弹出框
      orderQueryForm: {
        store: "", // 按客户盘点--仓库id
        customer: "", // 按客户盘点_客户名称
        userid: "", //客户id
      },
      orderQueryFormRules: {
        store: [{ required: true, message: "请选择仓库", trigger: "change" }],
        customer: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
        ],
      },
      // 按sku盘点
      skuOptions: [], // sku列表
      skuQueryForm: {
        sku: "",
        product: "",
        store: "",
        skuName: "",
      },
      skuQueryFormRules: {
        sku: [{ required: true, message: "请选择SKU", trigger: "change" }],
        product: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        store: [{ required: true, message: "请选择仓库", trigger: "change" }],
      },
      skuQueryList: [],

      stateOptions: [],
      typeOptions: [],
      adjustOptions: [],
      warehouseOptions: [],
      auditOptions: [],
      skuPage: 1,
    };
  },

  created() {
    this.checkoutBasicInfoHandle();
    this.getList();
  },
  methods: {
    /**
     * 条件检索
     * @param {*}
     */
    search(obj) {
      // const obj = {};
      // this.searchOption.searchList.forEach((item) => {
      //   if (item.value !== "") {
      //     obj[item.prop] = item.value;
      //   }
      // });
      this.params = { ...obj, pageNum: 1, pageSize: 10 };
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = 10;
      this.getList();
    },
    /**
     * 重置， 清空
     */
    reset() {
      this.searchOption.searchList.map((item) => {
        item.value = "";
      });

      this.params = { pageNum: 1, pageSize: this.params.pageSize };
      this.getList();
    },

    /**
     * 初始化数据,获取盘点单列表
     */
    getList() {
      inventoryListApi(this.params)
        .then((res) => {
          this.tableOption.tableData = res.list;
          this.tableOption.page.total = res.recordTotal;
        })
        .catch((err) => {});
    },

    /** 新建盘点单*/
    createQueryIntroHandle() {
      this.isVisible = true;
      this.queryOrderType = 1;
      //获取盘点类型枚举
      this.checkoutInventoryTypeEnumHandle();
    },
    /** 选择sku盘点清单*/
    handleSelectionChange(event) {
      this.skuQuerySelectList = event || [];
    },
    /** 删除操作*/
    deleteHandle() {
      if (this.skuQuerySelectList.length === 0) {
        return this.$message.error("请选择一条数据!");
      }
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      }).then(() => {
        this.skuQuerySelectList.forEach((item) => {
          const index = this.skuQueryList.findIndex(
            (sku) => sku.inventorydetailid == item.inventorydetailid,
          );
          this.skuQueryList.splice(index, 1);
        });
      });
    },
    /** 插入盘点清单*/
    insertHandle() {
      /**
       * 1.sku为必选项
       * 2，仓库有默认值
       * 若仓库中没有改SKU商品，则点击插入盘点单是弹框提示，仓库中没有该商品
       * */
      this.$refs["skuQueryFormRef"].validate((valid) => {
        if (!valid) return;
        const { sku, store } = this.skuQueryForm;
        createInventoryInsertCheckApi({ skuid: sku, whid: store })
          .then((res) => {
            const totalSkuQueryList = [...res, ...this.skuQueryList];
            const map = new Map();
            const skuQueryList = totalSkuQueryList.filter(
              (item) =>
                !map.has(item.inventorydetailid) &&
                map.set(item.inventorydetailid, item),
            );
            this.skuQueryList = skuQueryList;
            // this.skuQueryList = [...res, ...this.skuQueryList]
          })
          .catch(() => {});
      });
    },
    /** 新建盘点单弹出框操作*/
    queryIntroHandle(type) {
      switch (type) {
        case "cancel":
          this.isVisible = false;
          break;
        case "confirm":
          if (!this.queryOrderType)
            return this.$message.error("请选择盘点类型!");
          this.queryVisible = true;
          if (this.queryOrderType == 1) {
            this.$refs["skuQueryFormRef"]?.resetFields();
            this.skuQueryForm.sku = "";
            this.skuQueryForm.product = "";
            this.skuQueryForm.store = "";
            this.skuQueryForm.skuName = "";
            //默认为第一个仓库
            this.skuQueryForm.store = this.warehouseOptions[0].id;
            this.skuQueryList = [];
            this.skuQuerySelectList = [];
          } else if (this.queryOrderType == 2) {
            this.$refs["orderQueryFormRef"]?.resetFields();
            //默认为新建里的仓库
            this.orderQueryForm.store = this.warehouseOptions[0].id;
            let user = JSON.parse(localStorage.getItem("user"));
            this.orderQueryForm.customer = user.name;
            this.orderQueryForm.userid = user.id;
          }
          break;
      }
    },
    /** 判断操作*/
    queryOperateHandle(type) {
      switch (type) {
        case "cancel":
          this.queryVisible = false;
          break;
        case "confirm":
          /**
           * 1.按客户进行盘点 若该客户在该仓库没有任何产品，则弹窗提示：无需要盘点的商品！
           * */
          let basic = {};
          let newList = [];
          if (this.queryOrderType == 1) {
            // 按指定SKU盘点
            if (this.skuQuerySelectList.length == 0)
              return this.$swal.fire(
                {
                  icon: "error",
                  title: "Error",
                  text: "请至少选择一个",
                  allowOutsideClick: false,
                },
                // 'Error', '请至少选择一个！', 'error'
              );
            // 按指定SKU盘点
            this.skuQuerySelectList.forEach((item) => {
              newList.push({
                wh: item.whid,
                inventorydetailid: item.inventorydetailid,
              });
            });
            basic = {
              customer: this.skuQueryForm.customer,
              type: this.queryOrderType,
              warehouse: this.skuQueryForm.store,
              skulist: newList,
            };
            this.$refs["skuQueryFormRef"].validate((valid) => {
              if (!valid) return;
              this.createConfirmHandle(basic);
            });
          } else if (this.queryOrderType == 2) {
            // 按客户盘点
            // 按客户盘点
            basic = {
              type: this.queryOrderType,
              warehouse: this.orderQueryForm.store,
              customer: this.orderQueryForm.userid,
            };
            this.$refs["orderQueryFormRef"].validate((valid) => {
              if (!valid) return;
              this.createConfirmHandle(basic);
            });
          }
          break;
      }
    },
    /** 确定新建盘点单*/
    createConfirmHandle(option) {
      createInventoryOrderApi(option)
        .then(() => {
          this.$message.success("Successfully!");
          this.queryVisible = false;
          this.isVisible = false;
          this.params.pageNum = 1;
          this.tableOption.page.currentPage = 1;
          this.getList();
        })
        .catch(() => {});
    },
    /**
     * 选择sku
     * @param {*} val
     */
    handleSkuChange(val) {
      this.skuQueryForm.skuName = val?.sku;
      this.skuQueryForm.sku = val?.id;
      this.skuQueryForm.product = val?.chinesename;
    },
    /**
     * 远程 模糊 搜索sku
     * @param {*} query
     */
    handleSkuRemoteMethod(query) {
      this.skuOptions = [];
      setTimeout(() => {
        this.checkoutSkuOptionsHandle(query);
      }, 200);
    },
    /** 获取sku列表*/
    checkoutSkuOptionsHandle(query) {
      getSkulistApi({
        page: 1,
        sku: query,
      }).then((res) => {
        const { list } = res;
        this.skuOptions = list || [];
      });
    },

    /** 获取盘点type 枚举*/
    checkoutInventoryTypeEnumHandle() {
      inventoryCreateTypeApi().then((res) => {
        this.queryTypeOptions = res || [];
      });
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.tableOption.page.currentPage = currentPage;
      this.params.pageNum = currentPage;
      this.getList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.params.pageNum = 1;
      this.params.pageSize = pageSize;
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = pageSize;
      this.getList();
    },
    /**
     * 获取搜索列表数据
     */
    checkoutBasicInfoHandle() {
      // 1.获取state列表
      inventoryStateOptionsApi().then((res) => {
        this.stateOptions = res || [];
        res.map((item) => {
          item["label"] = item.name;
          item["value"] = item.type;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "statetype") {
            item.optionData = res;
          }
        });
      });
      // 2.获取adjust inventory列表
      inventoryAdjustOptionsApi().then((res) => {
        this.adjustOptions = res || [];
        res.map((item) => {
          item["label"] = item.name;
          item["value"] = item.type;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "adjust") {
            item.optionData = res;
          }
        });
      });
      // 3.获取audit列表
      inventoryAuditOptionsApi().then((res) => {
        this.auditOptions = res || [];
        res.map((item) => {
          item["label"] = item.name;
          item["value"] = item.type;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "audittype") {
            item.optionData = res;
          }
        });
      });
      // 5.获取warehouse列表
      commonWarehouseOptionsApi().then((res) => {
        const { list } = res || {};
        this.warehouseOptions = list || [];
        res.list.map((item) => {
          item["label"] = item.namecn;
          item["value"] = item.id;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "warehouseid") {
            item.optionData = res.list;
          }
        });
      });
    },
  },
  computed: {
    queryTitle() {
      return this.queryOrderType == 1 ? "指定SKU盘点" : "按客户盘点";
    },
    routePath() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.create-query-order-dialog {
  .remind {
    padding: 16px 20px;
    border-radius: 4px;
    font-size: 14px;
    p {
      display: block;
      margin-bottom: 8px;
    }
    .note {
      color: #1f1f20;
      font-weight: 400;
    }
    .icon {
      font-size: 20px;
      margin-right: 9px;
    }
  }
}
.sku-form {
  .el-form-item {
    margin-right: 15px;
  }
}
</style>

/*
 * @Author: vere
 * @Date: 2024-02-02 15:25:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 15:42:07
 * @Description: 
 */
/**
 * 去除两边空格
 * 使用 <el-input v-model="xxx" v-trim></el-input>
 */
function getInput(el) {
  let inputEle;
  const { tagName } = el;
  if (tagName === "INPUT" || tagName === "TEXTAREA") {
    inputEle = el;
  } else {
    inputEle = el.querySelector("input");
    if (!inputEle) {
      inputEle = el.querySelector("textarea");
    }
  }
  return inputEle;
}
function dispatchEvent(el, type) {
  let evt = document.createEvent('HTMLEvents')
  evt.initEvent(type, true, true)
  el.dispatchEvent(evt)
}
const trim = {
  mounted: el => {
    let inputEle = getInput(el)
    const handler = function (event) {
      const newVal = event.target.value.trim()
      if (event.target.value != newVal) {
        event.target.value = newVal
        dispatchEvent(inputEle, 'input')
      }
    }
    el.inputEle = inputEle
    el._blurHandler = handler
    inputEle.addEventListener('blur', handler)
  },
  unmounted(el) {
    const { inputEle } = el
    inputEle.removeEventListener('blur', el._blurHandler)
  }
}
trim.install = function (Vue) {
  Vue.directive('trim', trim)
}
export default trim


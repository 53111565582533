
<template>
  <div class="login-wrapper">
    <div class="login-form">
      <div class="login-form-content">
        <h1>Sign IN</h1>
        <el-form
          ref="loginFormRef"
          class="mx-lg"
          :model="loginForm"
          :rules="loginRules"
          label-position="left"
        >
          <el-form-item prop="username">
            <el-input
              v-trim
              ref="username"
              v-model="loginForm.username"
              placeholder="Please enter User Name"
              name="username"
              type="text"
              class="ipt"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-trim
              v-model="loginForm.password"
              type="password"
              placeholder="8 ~ 20 digits including numbers and letters"
              class="ipt"
            />
          </el-form-item>
        </el-form>
        <div class="form-btn">
          <el-button type="primary" round @click="handleLogin(loginFormRef)"
            >Login</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { loginApi, rsaPublicKeyApi } from "@/api/user/login";
import { setToken, removeToken } from "@/utils/auth";
import { setTicket } from "@/utils/storage"
import JSEncrypt from "jsencrypt";
import { useRouter } from "vue-router";
import { useUserInfo } from "@/stores/userInfo";
import { onMounted, ref, reactive} from "vue";

const loginFormRef = ref();
/**
 * 登录 参数
 */
const loginForm = reactive({
  username: "",
  password: "",
});
/**
 * 登录校验规则
 */
const loginRules = reactive({
  username: [
    { required: true, trigger: "blur", message: "Please enter username" },
  ],
  password: [
    { required: true, trigger: "blur", message: "Please enter password" },
    {
      pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,20})$/,
      message: "Please enter 8 ~ 20 digits including numbers and letters",
      trigger: "blur",
    },
  ],
});

/**
 * 后端接口获取公钥
 */
const rsa = ref("");
const getRsaPublicKey = () => {
  rsaPublicKeyApi().then((res) => {
    rsa.value = res;
  });
};

/**
 * @description: 加密处理
 * @param {*} rsaPublicKey 公钥
 * @param {*} val 需要加密内容
 * @return {String}
 */
const handlEencry = (rsaPublicKey, val) => {
  let encryptor = new JSEncrypt();
  //设置公钥
  encryptor.setPublicKey(rsaPublicKey);

  // 对内容进行加密
  return encryptor.encrypt(val);
};
/**
 * 登录按钮
 */
const storeUserInfo = useUserInfo();  // pinia 存储用户信息
const encryptPwd = ref(""); // 加密后的密码
const router = useRouter();
const handleLogin = async (loginFormRef) => {
  if (!loginFormRef) return;
  await loginFormRef.validate((valid, fields) => {
    if (valid) {
      // 密码加密，并 解码
      encryptPwd.value = encodeURIComponent(
        handlEencry(rsa.value, loginForm.password)
      );

      // 登录
      loginApi({
        username: loginForm.username,
        pwd: encryptPwd.value,
      })
        .then((res) => {
          // cookie存储 ticket
          setToken(res.ticket);
          setTicket(res.ticket)
          // 本地存储 返回的用户信息
          localStorage.setItem("user", JSON.stringify(res.user));
          storeUserInfo.setUserInfo(res.user)
          storeUserInfo.setPermissions(res.permissions)
          setTimeout(()=>{
            router.push({ path: "/" })
          }, 500)
        })
        .catch((error) => {
          ElMessage.error(error?.message);
        });
    } else {
      return false
    }
  });
};
onMounted(()=>{
  localStorage.clear();
  setToken("");
  removeToken()
  getRsaPublicKey() // 获取公钥
})
</script>

<style scoped lang="scss">
.login-wrapper {
  background: url("../../assets/images/index_bg.png") no-repeat;
  // font-family: Verdana;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: #fff;
  height: 100vh;
  text-align: center;
  position: relative;

  .login-form {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: 100;
    width: 500px;
  }
  .login-form-content {
    background: #ffffff;
    padding: 8px 40px 40px 40px;
    border-radius: 20px;
    h1 {
      font-size: 40px;
      font-weight: 300;
      color: #66421f;
      letter-spacing: 7px;
      margin-bottom: 40px;
    }
    .form-btn {
      :deep(.el-button) {
        border-radius: 25px;
        height: 50px;
        width: 50%;
        margin: 40px auto 0;
        span {
          font-size: 18px;
          // font-family: Verdana;
        }
      }
    }
    :deep(.el-input__wrapper) {
      border-radius: 20px;
      height: 45px;
      line-height: 45px;
    }
  }
}
.login-wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #62472b;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <ul class="api-item-list">
    <li><slot></slot></li>
    <li v-for="item in apiData" :key="item.id" :id="item.id" >
      <el-row>
        <el-col :span="24" class="title-item">
          <span class="titleZh gray">{{ item.titleZh }}</span>
          <span class="titleEn">{{ item.titleEn }}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24">
          <div class="api-request">
            <span :class="{ orange: item.request == 'POST' }">{{
              item.request
            }}</span>
            <span> {{ item.api }}</span>
          </div>
        </el-col>
      </el-row>

      <!-- 请求示例 -->
      <el-row v-show="item.requestExampleZh">
        <el-col :span="24">
          <div class="request-example">
            <span class="gray">{{ item.requestExampleZh }}</span>
            <span class="black1">{{ item.requestExampleEn }}</span>
          </div>
          <div class="request-example-detail">
            {{ item.requestExample }}
          </div>
        </el-col>
      </el-row>
      <!-- 请求参数 -->
      <n-table
        :titleZh="item.requestParameterZh"
        :titleEn="item.requestParameterEn"
        :tableSource="item.requestParameterTableSource"
        :columns="item.requestParameter"
        v-show="item.requestParameterZh"
      ></n-table>
       <!-- 请求参数 子表 -->
       <n-table
        :tableSource="item.requestChildNode.requestParameterTableSource"
        :columns="item.requestChildNode.requestParameter"
        v-if="item.requestChildNode"
      ></n-table>
      <!-- Query 请求参数 -->
      <n-table
        :titleZh="item.queryRequestParameterZh"
        :titleEn="item.queryRequestParameterEn"
        :tableSource="item.queryRequestParameterTableSource"
        :columns="item.queryRequestParameter"
        v-show="item.queryRequestParameterZh"
      ></n-table>
      <!-- Query 请求参数 子表 -->
      <n-table
        :tableSource="item.queryRequestChildNode.requestParameterTableSource"
        :columns="item.queryRequestChildNode.requestParameter"
        v-if="item.queryRequestChildNode"
      ></n-table>
      <!-- Body 请求示例 -->
      <el-row v-show="item.bodyRequestExampleZh">
        <el-col :span="24">
          <div class="request-example">
            <span class="gray">{{ item.bodyRequestExampleZh }}</span>
            <span class="black1">{{ item.bodyRequestExampleEn }}</span>
          </div>
          <div class="code-editor" v-if="item.bodyRequestExample">
            <pre>{{ item.bodyRequestExample }}</pre>
          </div>
        </el-col>
      </el-row>
      <!-- Body 请求参数 -->
      <n-table
        :titleZh="item.bodyRequestParameterZh"
        :titleEn="item.bodyRequestParameterEn"
        :tableSource="item.bodyRequestParameterTableSource"
        :columns="item.bodyRequestParameter"
        v-show="item.bodyRequestParameterZh"
      ></n-table>
      <!-- Body 请求参数 有child node -->
<!--      <n-table-->
<!--        :tableSource="item.bodyRequestChildNode.requestParameterTableSource"-->
<!--        :columns="item.bodyRequestChildNode.requestParameter"-->
<!--        v-if="item.bodyRequestChildNode"-->
<!--      ></n-table>-->
      <n-table
        v-for="(node,i) in item.bodyRequestChildNode" :key="i"
        :tableSource="node.requestParameterTableSource"
        :columns="node.requestParameter"
        v-show="item.bodyRequestChildNode.length"
      ></n-table>
      <!-- 响应示例 -->
      <el-row>
        <el-col :span="24">
          <div class="request-example">
            <span class="gray">{{ item.returnExampleZh }}</span>
            <span class="black1">{{ item.returnExampleEn }}</span>
          </div>
          <div class="code-editor" v-if="item.returnExample">
            <pre>{{ item.returnExample }}</pre>
          </div>
        </el-col>
      </el-row>
      <!-- 响应参数 -->
      <n-table
        :titleZh="item.responseParameterZh"
        :titleEn="item.responseParameterEn"
        :tableSource="item.responseParameterTableSource"
        :columns="item.responseParameter"
        v-show="item.responseParameterZh"
      ></n-table>
      <!-- 响应参数子表 -->
<!--      <n-table-->
<!--        :tableSource="item.responseChildNode.requestParameterTableSource"-->
<!--        :columns="item.responseChildNode.requestParameter"-->
<!--        v-if="item.responseChildNode"-->
<!--      ></n-table>-->
      <n-table
        v-for="(node,i) in item.responseChildNode" :key="i"
        :tableSource="node.requestParameterTableSource"
        :columns="node.requestParameter"
        v-show="item.responseChildNode.length"
      ></n-table>
      <!-- 错误代码 -->
      <n-table
        :titleZh="item.errorCodeZh"
        :titleEn="item.errorCodeEn"
        :tableSource="item.errorCodeTableSource"
        :columns="item.errorCode"
        v-show="item.errorCodeZh"
      ></n-table>
      <el-divider class="line"></el-divider>
    </li>
  </ul>
</template>

<script>
import nTable from "./table.vue";
export default {
  components: {
    nTable,
  },
  props: {
    apiData:{
        type: Array,
        default: []
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../anchor.scss";
</style>

import axios from "axios";
import { stringify } from "qs";
import { getToken, removeToken } from "@/utils/auth.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { showLoading, hideLoading } from "@/utils/loading";
import router from "@/router";
import { baseURL } from "./baseURL";
import  { apiExportList } from './exportapi'
import { nextTick } from "vue";
// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL(),
  timeout: 50000,
  // headers: { 'Content-Type': 'application/json;charset=utf-8' }
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.config.showLoading === 1) {
      showLoading();
    }
    if (
      config.method == "post" &&
      config.headers["Content-type"] == "application/x-www-form-urlencoded"
    ) {
      /** 是post请求，并且请求头是application/x-www-form-urlencoded*/
      config.data = stringify(config.data);
    } else {
    }
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  },
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    hideLoading();
    if (response.status == 200) {
      const { status, message, data } = response.data || {};
      if (apiExportList.includes(response.config.url)) {
        return response
      } else if (
        response.config.url === "/getRsaPublicKey" ||
        response.config.url === "https://pic.buffaloex.com/buffaloex-upload/upload"
      ) {
        return response.data;
      }  else if (Number(status) === 0) {
        return data;
      } else {
        if (response.data.code === 3000012) {
          ElMessage({
            message: message,
            type: 'error',
            grouping: true,
            duration: 3 * 1000,
          });
          nextTick(() => {
            removeToken();
            router.replace("/login");
          })
         
        } else  {
          ElMessageBox.alert(message, 'Error', {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            type: 'error'
          })
            .then(() => {})
            .catch(() => {})
        }
        return Promise.reject();
      }
    } else {
      ElMessage({
        message: "System Error!",
        type: 'error',
        grouping: true,
        duration: 5 * 1000,
      });
    }
  },
  (error) => {
    hideLoading();
    ElMessage({
      message: error.message,
      type: "error",
      grouping: true,
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);
const request = (option) => {
  const {
    url,
    method,
    params,
    data,
    headersType,
    responseType = "",
    config,
    closeloading,
  } = option;
  const requestOption = {
    url,
    method,
    params,
    data,
    headers: {
      "Content-type": headersType || "application/x-www-form-urlencoded",
      'Buffalo-ticket': getToken() || '',
    },
    config: config || {
      showLoading: closeloading ? 0 : 1,
    },
  };
  if (responseType) {
    requestOption["responseType"] = responseType;
  }
  return service(requestOption);
};

// 导出 axios 实例
// export default service
export const POST = (option) => request({ method: "post", ...option });
export const GET = (option) => request({ method: "get", ...option });


<template>
  <section>
    <!-- 常用收件人管理 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter justify-between">
        <com-search :searchOption="searchOption" @search="search"> </com-search>
        <div>
          <!--  -->
          <el-button
            v-buttonPermission="`${routePath}/Delete`"
            type="danger"
            icon="Delete"
            plain
            @click="delAddress"
            >删除</el-button
          >
          <!--  -->
          <el-button
            v-buttonPermission="`${routePath}/Add`"
            type="primary"
            icon="Plus"
            plain
            @click="ShowDialog('create')"
            >新增</el-button
          >
        </div>
      </div>
      <com-table
        :table-option="tableOption"
        @selection-change="handleSelectionChange"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
        <template #operation="{ row }">
          <!--  -->
          <el-button
            v-buttonPermission="`${routePath}/Edit`"
            type="primary"
            link
            @click="ShowDialog('edit', row)"
          >
            编辑
          </el-button>
        </template>
      </com-table>
    </el-card>
    <!-- 收件人弹框 -->
    <el-dialog
      :title="title"
      v-model="actionDialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="50%"
    >
      <el-form
        ref="receiverInfo"
        :model="receiverInfo"
        label-position="top"
        class="receiver-info"
      >
        <el-form-item
          v-for="(formItem, index) in receiverInfoRules"
          :key="index"
          :label="formItem.label"
          :prop="formItem.prop"
          :rules="formItem.rules"
          :class="formItem.class"
        >
          <el-input
            v-if="formItem.type == 'input'"
            v-model="receiverInfo[`${formItem.prop}`]"
            :disabled="formItem.disabled"
            :placeholder="formItem.placeholder"
          ></el-input>

          <!-- <el-select
            v-if="formItem.prop == 'code'"
            style="width: 100%"
            ref="select"
            v-model="receiverInfo.code"
            v-loadMore="loadmore"
            value-key="text"
            filterable
            remote
            reserve-keyword
            @focus="onPostcode"
            placeholder=""
            :remote-method="getPostCode"
            :loading="loading"
            @change="selectPostcode"
          >
            <el-option
              v-for="(item, index) in postcodeOptions"
              :key="index"
              :label="item.text"
              :value="item"
            />
          </el-select> -->

          <el-select
            v-if="formItem.prop == 'suburb'"
            style="width: 100%"
            v-model="receiverInfo.citySuburb"
            v-loadMore="loadmoreCitySuburb"
            filterable
            remote
            reserve-keyword
            :teleported="false"
            placeholder="城市/Suburb"
            @focus="onSuburb"
            :remote-method="queryCitySuburb"
            :loading="loading"
            @change="selectCitySuburb"
          >
            <el-option
              v-for="item in SuburbOptions"
              :key="item.id"
              :label="getCitySuburbOptVal(item)"
              :value="getCitySuburbOptVal(item)"
            />
          </el-select>
          <el-select
            v-if="formItem.prop == 'code'"
            style="width: 100%"
            ref="select"
            v-model="receiverInfo.code"
            value-key="text"
            filterable
            reserve-keyword
            placeholder="请选择邮编"
          >
            <el-option
              v-for="item in postcodeOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-input
            v-if="formItem.type == 'textarea'"
            type="textarea"
            v-model="receiverInfo[`${formItem.prop}`]"
            :placeholder="formItem.placeholder"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button class="btn" @click="handleClose"> 取消 </el-button>
        <el-button class="btn" type="primary" @click="confirmInfo">
          确认
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {
  searchPostcodeApi,
  searchCitySuburbApi,
  searchPostCodeByCitySuburb,
} from "@/api/common.js";
import {
  addreceiveApi,
  delreceiveApi,
  getListApi,
  modifyreceiveApi,
} from "@/api/receive.js";
// import tableLayout from "@/components/TableLayout/index.vue";
import { searchOption, tableOption } from "./column";
import { receiverInfoRules } from "./rule";
export default {
  name: "OutboundReceiver",
  // components: { tableLayout },
  data() {
    return {
      searchOption: {
        // 顶部表单检索内容,非必填
        // labelWidth: "140px",
        closeReset: true,
        searchList: [
          // 检索列表
          {
            type: "input",
            value: "",
            prop: "phoneorname",
            maxlength: 100,
            prefixIcon: "Search",
            placeholder: "请输入姓名/电话",
            icon: "Search",
          },
        ],
      },
      tableOption,
      receiverInfoRules,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      selectList: [], //复选框选择的数据
      actionDialog: false, // 以下 为弹框内容
      title: "",
      receiverInfo: {
        nameen: "",
        company: "",
        phone: "",
        code: "",
        city: "",
        email: "",
        suburb: "",
        address: "",
        id: "",
      }, // 收件人信息
      parameter: {},
      pagenum: 1, //邮编和城市页数
      SuburbOptions: [],
      postcodeOptions: [],
      loading: false,
      searchCitySuburbParameter: {
        search: "",
        page: 1,
      },
      citySuburbPageInfo: {
        currentPagenum: 1,
        totalPageCount: 0,
      },
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 获取表格数据
     */
    getList() {
      getListApi(this.params).then((res) => {
        this.tableOption.tableData = res.list;
        this.tableOption.page.total = res.recordTotal;
      });
    },
    /**
     * 条件检索
     * @param {*} obj
     */
    search(obj) {
      this.params = { ...obj, pageNum: 1, pageSize: 10 };
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = 10;
      this.getList();
    },
    ShowDialog(prop, row) {
      if (prop == "create") {
        this.actionDialog = true;
        this.title = "新增常用收件人";
      } else {
        this.title = "编辑常用收件人";
        const {
          receiver,
          receivecompany,
          receivetelephone,
          receivepostcode,
          receivecity,
          receiveemail,
          receivesuburb,
          receiveaddress,
          id,
        } = row;
        this.receiverInfo.nameen = receiver;
        this.receiverInfo.company = receivecompany;
        this.receiverInfo.phone = receivetelephone;
        this.receiverInfo.code = receivepostcode;
        this.receiverInfo.citySuburb = this.getCitySuburbOptVal({
          city: receivecity,
          suburb: receivesuburb,
        });
        this.receiverInfo.city = receivecity;
        this.receiverInfo.email = receiveemail;
        this.receiverInfo.suburb = receivesuburb;
        this.receiverInfo.address = receiveaddress;
        this.receiverInfo.id = id;
        this.actionDialog = true;
      }
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.tableOption.page.currentPage = currentPage;
      this.params.pageNum = currentPage;
      this.getList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.params.pageNum = 1;
      this.params.pageSize = pageSize;
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = pageSize;
      this.getList();
    },
    /**
     * 复选框 选择
     */
    handleSelectionChange(val) {
      this.selectList = val;
    },
    /**
     * 删除该条地址
     */
    delAddress() {
      if (this.selectList.length != 1)
        return ElMessage.warning("请选择一条数据");
      ElMessageBox.confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          delreceiveApi(this.selectList[0].id).then((res) => {
            this.getList();
            ElMessage.success("删除成功!");
          });
        })
        .catch(() => {
          console.log("quxiao");
        });
    },
    /**
     * 新增 或 编辑 提交
     */
    confirmInfo() {
      let api;
      if (this.receiverInfo.id) {
        api = modifyreceiveApi;
      } else {
        api = addreceiveApi;
      }
      this.$refs["receiverInfo"].validate((valid) => {
        if (valid) {
          api(this.receiverInfo).then((res) => {
            if (this.receiverInfo.id) {
              ElMessage.success("地址修改成功!");
            } else {
              ElMessage.success("地址添加成功!");
            }
            this.handleClose();
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    /**
     *  查询邮编
     */
    selectPostcode(val) {
      this.receiverInfo.code = val;
    },
    selectCitySuburb(val) {
      const item = this.SuburbOptions.find(
        (item) => this.getCitySuburbOptVal(item) === val
      );
      // this.receiverInfo.code = "";
      this.receiverInfo.suburb = item.suburb;
      this.receiverInfo.city = item.city;

      this.searchPostCodeByCitySuburb(item.city, item.suburb);
    },
    searchPostCodeByCitySuburb(city, suburb) {
      searchPostCodeByCitySuburb({ city, suburb }).then((postcodeList) => {
        this.postcodeOptions = postcodeList || [];
        this.receiverInfo.code = postcodeList[0];
      });
    },
    getCitySuburbOptVal(item) {
      return `${item.city},${item.suburb}`;
    },
    /**
     * suburb 获取焦点
     */
    onSuburb() {
      this.searchCitySuburbParameter.page = 1;
      this.citySuburbPageInfo.pagenum = 1;
      this.getSuburb();
    },
    queryCitySuburb(query) {
      if (query !== "") {
        this.searchCitySuburbParameter.search = query;
        this.searchCitySuburbParameter.page = 1;
        this.getSuburb();
      }
    },
    /**
     * postcode, 获取焦点
     */
    onPostcode() {
      this.parameter.page = 1;
      this.pagenum = 1;
      this.parameter.type = "postcode";
      this.getPostCode();
    },
    /**
     * 获取city/Suburb
     */
    getSuburb() {
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = res.list;
        this.citySuburbPageInfo.totalPageCount = res.pageCount;
      });
    },
    /**
     * 获取PostCode
     */
    getPostCode(query) {
      if (query !== "") {
        this.parameter.search = query;
        this.parameter.type = "postcode";
        setTimeout(() => {
          searchPostcodeApi(this.parameter).then((res) => {
            this.postcodeOptions = res.list;
          });
        }, 200);
      }
    },
    /**
     *  postcode 加载更多
     */
    loadmore() {
      this.pagenum++;
      this.parameter.page = this.pagenum;
      searchPostcodeApi(this.parameter).then((res) => {
        if (this.parameter.type == "postcode") {
          this.postcodeOptions = [...this.postcodeOptions, ...res.list];
        } else {
          this.SuburbOptions = [...this.SuburbOptions, ...res.list];
        }
      });
    },
    // city/suburb 触底加载加载更多数据
    loadmoreCitySuburb() {
      if (
        this.citySuburbPageInfo.totalPageCount <
        this.citySuburbPageInfo.currentPagenum
      ) {
        return;
      }
      this.citySuburbPageInfo.currentPagenum++;
      this.searchCitySuburbParameter.page =
        this.citySuburbPageInfo.currentPagenum;
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = [...this.SuburbOptions, ...res.list];
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.receiverInfo = {
        nameen: "",
        company: "",
        phone: "",
        code: "",
        city: "",
        email: "",
        suburb: "",
        address: "",
        id: "",
      };
      this.$refs["receiverInfo"].clearValidate();
      this.$refs["receiverInfo"].resetFields();
      this.actionDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.table-filter {
  background: #fff;
  padding: 0;
}
.receiver-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  :deep(.el-form-item__label) {
    line-height: 1.2;
  }

  .el-form-item {
    min-width: 45%;
    margin-left: 1%;
    margin-right: 1%;

    &.address {
      width: 100%;
    }
  }
}
</style>


<template>
  <section class="outbound-create">
    <!-- 仓库信息 + 包裹信息 -->
    <el-row :gutter="16" class="mb-[16px]">
      <el-col :span="12">
        <el-card shadow="never" id="storehouseInfo" class="bg-white">
          <h2 class="margin-16">仓库信息</h2>
          <el-form
            ref="storehouseInfo"
            label-position="top"
            class="form-section"
            :rules="storehouseRules"
            :model="storehouseInfo"
          >
            <el-form-item prop="storehouseId" label="出货仓库">
              <el-select
                value-key="namecn"
                style="width: 100%"
                placeholder="请选择出货仓库"
                v-model="storehouseInfo.storehouseId"
                @change="selectStorehouse"
              >
                <el-option
                  v-for="(item, index) in storeOptions"
                  :key="index"
                  :label="item.namecn"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never" class="bg-white">
          <h2 class="margin-16">包裹信息</h2>
          <el-form
            ref="packInfo"
            label-position="top"
            class="form-section"
            :model="packForm"
          >
            <el-form-item prop="remarkno" label="参考号">
              <el-input
                v-model.trim="packForm.remarkno"
                :maxlength="50"
                placeholder="请输入参考号"
              />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 收件人信息 -->
    <el-row class="mb-[16px]">
      <el-col :span="24">
        <el-card shadow="never" id="receiverInfo">
          <el-row type="flex" class="justify-between align-center mb-[16px]">
            <h2>取件方式</h2>
          </el-row>
          <el-row class="radio-section">
            <el-radio-group
              v-model="receiverInfo.deliveryType"
              @change="handleRadio"
            >
              <el-radio :value="0" size="large" border>快递派送</el-radio>
              <el-radio
                :value="1"
                size="large"
                border
                :disabled="!storehouseInfo.storehouseId"
                >仓库自提</el-radio
              >
              <!-- 240516 -->
              <template v-if="country == 1">
                <el-radio
                  :value="2"
                  size="large"
                  border
                  style="margin-right: 8px"
                  >跨国移库</el-radio
                >
                <template v-if="receiverInfo.deliveryType == 2">
                  <el-icon :size="40" color="#E7E7E7">
                    <el-icon-minus />
                  </el-icon>
                  <el-select
                    class="za-store-list"
                    value-key="namecn"
                    placeholder="请选择需要转移到的仓库"
                    v-model="arrivedwarehouseid"
                    @change="selectArrivedWarehouse"
                  >
                    <el-option
                      v-for="(item, index) in ZAStoreList"
                      :key="index"
                      :label="item.namecn"
                      :value="item.id"
                    />
                  </el-select>
                  <h2 style="font-size:14px;color:#f56c6c">（必选）</h2>
                </template>
              </template>
            </el-radio-group>
          </el-row>
          <el-divider border-style="dashed"></el-divider>
          <el-row type="flex" class="justify-between align-center mb-[16px]">
            <h2>收件人信息</h2>
            <el-button
              v-if="!(country == 1 && receiverInfo.deliveryType == 2)"
              class="button"
              type="primary"
              plain
              icon="User"
              @click="showAddress()"
              >常用收件人</el-button
            >
          </el-row>
          <el-form
            ref="receiverInfo"
            :rules="receiverInfoRules"
            :model="receiverInfo"
            :validate-on-rule-change="false"
            label-position="top"
            class="form-section form-information"
          >
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  prop="receiver"
                  :label="`收件人${country == 1 && receiverInfo.deliveryType == 1 ? '' : '英文名'}`"
                >
                  <el-input
                    v-trim
                    v-model="receiverInfo.receiver"
                    maxlength="100"
                    :placeholder="`请输入收件人${country == 1 && receiverInfo.deliveryType == 1 ? '' : '英文名'}`"
                    :disabled="country == 1 && receiverInfo.deliveryType == 2"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="receivecompany" label="收件人公司">
                  <el-input
                    v-trim
                    v-model="receiverInfo.receivecompany"
                    maxlength="100"
                    placeholder="请输入收件人公司"
                    :disabled="receiverInfo.deliveryType == 2"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="receivetelephone" label="收件人电话">
                  <el-input
                    v-trim
                    v-model="receiverInfo.receivetelephone"
                    maxlength="50"
                    placeholder="请输入收件人电话"
                    :disabled="country == 1 && receiverInfo.deliveryType == 2"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="电子邮件" prop="receiveemail">
                  <el-input
                    v-trim
                    v-model="receiverInfo.receiveemail"
                    maxlength="100"
                    placeholder="请输入电子邮件"
                    :disabled="receiverInfo.deliveryType == 2"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <!-- 仓库 为 CN 且 勾选 仓库自提 邮编 城市 隐藏 -->
              <el-col
                :span="6"
                v-if="!(country == 1 && receiverInfo.deliveryType == 1)"
              >
                <el-form-item
                  id="receivesuburb"
                  prop="citySuburb"
                  label="城市/Suburb"
                >
                  <el-select
                    style="width: 100%"
                    v-model="receiverInfo.citySuburb"
                    v-loadMore="loadmoreCitySuburb"
                    filterable
                    remote
                    reserve-keyword
                    :teleported="false"
                    placeholder="请选择城市/Suburb"
                    :remote-method="queryCitySuburb"
                    :loading="loading"
                    :disabled="
                      receiverInfo.deliveryType == 1 ||
                      (country == 1 && receiverInfo.deliveryType == 2)
                    "
                    @change="selectCitySuburb"
                    @focus="onSuburb"
                  >
                    <el-option
                      v-for="item in SuburbOptions"
                      :key="item.id"
                      :label="getCitySuburbOptVal(item)"
                      :value="getCitySuburbOptVal(item)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="6"
                v-if="!(country == 1 && receiverInfo.deliveryType == 1)"
              >
                <!-- --{{ receiverInfo }} -->
                <el-form-item
                  id="receivepostcode"
                  prop="receivepostcode"
                  label="邮编"
                >
                  <el-select
                    :disabled="
                      receiverInfo.deliveryType == 1 ||
                      receiverInfo.deliveryType == 2
                    "
                    style="width: 100%"
                    ref="select"
                    v-model="receiverInfo.receivepostcode"
                    filterable
                    reserve-keyword
                    placeholder="请选择邮编"
                  >
                    <el-option
                      v-for="item in postcodeOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="country == 1 && receiverInfo.deliveryType == 1 ? 24 : 12"
              >
                <el-form-item
                  id="receiveaddress"
                  prop="receiveaddress"
                  :label="`收件地址${country == 1 && receiverInfo.deliveryType == 1 ? '' : '(英文)'}`"
                  style="width: 100%"
                >
                  <el-input
                    v-trim
                    v-model="receiverInfo.receiveaddress"
                    :placeholder="`请输入收件地址${country == 1 && receiverInfo.deliveryType == 1 ? '' : '(英文)'}`"
                    maxlength="256"
                    :disabled="
                      receiverInfo.deliveryType == 1 ||
                      (country == 1 && receiverInfo.deliveryType == 2)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row class="w-[100%]">
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-trim
                    maxlength="30"
                    v-model="receiverInfo.remark"
                    placeholder="请输入备注"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 产品信息 -->
    <el-row class="mb-[16px]">
      <el-col :span="24">
        <el-card shadow="never" id="goodInfo">
          <h2 class="margin-16">产品信息</h2>
          <el-form
            ref="goodItem"
            :rules="goodItemRules"
            :model="goodItem"
            label-position="top"
          >
            <el-row :gutter="16">
              <el-col :span="5">
                <el-form-item prop="sku" label="SKU">
                  <el-select
                    style="width: 100%"
                    ref="select"
                    v-model="goodItem.sku"
                    value-key="sku"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请选择SKU"
                    :teleported="false"
                    :remote-method="getSku"
                    @change="selectSku"
                  >
                    <el-option
                      v-for="item in skuList"
                      :key="item.sku"
                      :label="item.sku"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item prop="quantity" label="数量">
                  <el-input
                    v-trim
                    v-model="goodItem.quantity"
                    maxlength="50"
                    placeholder="请输入数量"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="单位">
                  <el-input disabled v-model="goodItem.unit"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="可用库存">
                  <el-input disabled v-model="goodItem.availablestock" />
                </el-form-item>
              </el-col>
              <el-col :span="4" class="mt-[30px]">
                <el-form-item>
                  <el-button type="primary" icon="Plus" @click="addGoods"
                    >添加</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            border
            :data="goodList"
            :header-cell-style="{ background: '#FAFAFA' }"
            style="margin: 10px 0"
            :height="tableOption.height"
          >
            <el-table-column
              v-for="(item, index) in tableOption.tableColumn"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.minWidth"
              :width="item.width"
              :align="item.align"
            >
              <template #default="scope">
                <el-button
                  v-if="item.prop == 'operation'"
                  link
                  @click="deleteItem(scope.row)"
                  ><el-icon><Delete /></el-icon
                ></el-button>
                <span v-else> {{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <!-- 空数据 展示 -->
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!-- 优先出库 -->
    <el-row class="mb-[16px]">
      <el-col :span="24">
        <el-card shadow="never">
          <h2 class="margin-16">优先级</h2>
          <el-checkbox :true-value="1" :false-value="0" v-model="prioritylevel"
            >优先出库：价格为当前订单的出库操作费</el-checkbox
          >
        </el-card>
      </el-col>
    </el-row>
    <!-- 增值服务下拉 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card id="addvaluecard" shadow="never">
          <el-collapse model-value="1" ref="addvaluecard">
            <el-collapse-item id="addvaluecarditem" name="1">
              <template #title>
                <h2>增值服务</h2>
              </template>
              <add-value
                @getAddValueForm="addvaluechange"
                ref="addvalueform"
                :addvalueformInitData="addvalueformInitData"
                :sncodeqty="sncodeqty"
                :create="true"
              ></add-value>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card shadow="never" class="justify_end mt-md">
          <el-button type="primary" plain @click="draftInfo">暂存</el-button>
          <el-button type="primary" @click="SaveOrder">下单</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- 联系人弹框 -->
    <el-dialog
      class="address dialog-min-width"
      title="常用收件人"
      v-model="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
    >
      <el-row>
        <com-search
          :searchOption="searchOption"
          @search="searchAddress"
        ></com-search>
      </el-row>
      <el-table
        style="width: 100%"
        :height="addressTableOption.height"
        :border="true"
        :data="addressList"
        :header-cell-style="{ background: '#FAFAFA' }"
        @row-dblclick="dblclickAddress"
      >
        <el-table-column
          v-for="(item, index) in addressTableOption.tableColumn"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
        />
        <!-- 空数据 展示 -->
        <template #empty>
            <com-no-data :smallImg="true"></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="addressTotal > 0"
        style="margin-top: 10px"
        class="justify-end"
        :current-page="addressParams.pageNum"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="addressParams.pageSize"
        background
        :total="addressTotal"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-dialog>
    <!-- 下单确认弹框 -->
    <el-dialog
      class="action-dialog"
      title="信息及费用确认"
      v-model="actionDialog"
      width="70%"
      :close-on-click-modal="false"
    >
      <expense-confirm
        :storehouseName="storehouseName"
        :arrivedWarehouseName="arrivedWarehouseName"
        :receiverInfo="receiverInfo"
        :orderPreview="orderPreview"
      ></expense-confirm>
      <template #footer>
        <el-button class="btn" @click="actionDialog = false"> 取消 </el-button>
        <el-button class="btn" type="primary" @click="confirmInfo">
          保存
        </el-button>
      </template>
    </el-dialog>
    <!-- 下单 物流产品类型 -->
    <el-dialog
      title="选择物流信息"
      v-model="orderTypeDialog"
      width="400px"
      :close-on-click-modal="false"
      :show-close="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="orderTypeRef"
        label-width="100px"
        label-position="left"
        :model="orderTypeInfo"
        :rules="orderTypeRules"
      >
        <el-form-item label="物流产品" prop="orderType">
          <el-select
            v-model="orderTypeInfo.orderType"
            placeholder="请选择物流产品类型"
            @change="handleOrderType"
          >
            <el-option
              v-for="item in orderType"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="orderTypeInfo.orderType == 90"
          label="Terms"
          prop="largeCargoCategoryId"
        >
          <el-select
            style="width: 100%"
            v-model="orderTypeInfo.largeCargoCategoryId"
            placeholder="请选择Terms"
          >
            <el-option
              v-for="item in Terms"
              :key="item.categoryid"
              :label="item.name"
              :value="item.categoryid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="税费承担人" prop="taxpeople">
          <el-select
            v-model="orderTypeInfo.taxpeople"
            placeholder="请选择税费承担人"
          >
            <el-option
              v-for="item in taxpeopleType"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button class="btn" @click="orderTypeDialog = false">
          取消
        </el-button>
        <el-button class="btn" type="primary" @click="orderTypeConfirm">
          保存
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {
  getstoreselectApi,
  searchPostcodeApi,
  searchCitySuburbApi,
  getSkulistApi,
  searchPostCodeByCitySuburb,
  getStoreByCountryAndCurrentUserApi,
  getOrderTypeEnumApi,
} from "@/api/common.js";
import {
  outboundcreateApi,
  outboundgetBySkuApi,
  createPreviewApi,
  outboundDraftApi,
  outboundDraftDataApi,
} from "@/api/outbound.js";
import { getListApi } from "@/api/receive.js";
import addValue from "@/components/add-value/index.vue";
import expenseConfirm from "./components/expense-confirm.vue";
import comNoData from '@/components/com-no-data/index.vue'
import { ElMessage, ElMessageBox } from "element-plus";
import { tableOption, addressTableOption } from "./column";

import { useRoute } from "vue-router";

import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { smaller } from "mathjs";

export default {
  name: "OutboundCreate",
  components: { addValue, expenseConfirm, comNoData },
  setup() {
    const trackingInfo = useTrackingInfo();
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间
    const route = useRoute();
    const curpath = route.path;
    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "我要下单",
      });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });
    return {
      trackingInfo,
      tracking,
    };
  },
  data() {
    return {
      // accessTime: Date.now(),
      storeOptions: [], // 仓库列表
      storehouseName: "", // 仓库名字
      arrivedWarehouseName: "", // 跨国移库的仓库
      country: 0, // 仓库所在国家 0： 南非 1 中国
      storehouseInfo: {
        storehouseId: "", // 仓库id
      },

      packForm: {
        remarkno: "", // 参考号
      },
      searchOption: {
        // 常用收件人
        closeReset: true,
        searchList: [
          {
            label: "",
            type: "input",
            value: "",
            prop: "phoneorname",
            maxlength: 50,
            prefixIcon: "Search",
            placeholder: "请输入姓名/电话",
          },
        ],
      },
      addressParams: {
        pageNum: 1,
        pageSize: 10,
      },
      addressTableOption, // 常用收件人column

      ZAStoreList: [], // 跨国移库 仓库列表

      addressTotal: 0, // 总数
      addressList: [], // 常用收件人地址列表  结束

      goodList: [], // 产品列表
      outboundOrderId: 0, //出库单id，暂存后会有
      tableOption, // 表格 column
      goodItem: {
        sku: "",
        chinesename: "",
        englishname: "",
        id: "",
        productbarcode: "",
        productimgurl: "",
        quantity: "",
        fillweight: "",
        unit: "",
        itemId: "",
      }, //添加的一列商品

      prioritylevel: 0, // 优先出库 0/null:普通， 1：优先
      flag: 0,
      dialogVisible: false, //收件人弹框
      arrivedwarehouseid: "", //派送类型为跨国移库时传选择的仓库id
      receiverInfo: {
        // 收件人信息
        deliveryType: 0, //0: 快递派送， 1: 自提  2： 跨国移库

        receiver: "",
        receivecompany: "",
        receivetelephone: "",
        receivecity: "",
        receiveemail: "",
        citySuburb: "",
        receivepostcode: "",
        receivesuburb: "",
        receiveaddress: "",
        receiveaddress2: "",
        remark: "",
      },
      pagenum: 1, //邮编和城市页数
      parameter: {
        search: "",
        page: 1,
      },
      searchCitySuburbParameter: {
        search: "",
        page: 1,
      },
      citySuburbPageInfo: {
        currentPagenum: 1,
        totalPageCount: 0,
      },
      SuburbOptions: [],
      postcodeOptions: [],
      skuList: [],
      skuTotalPageNum: 0, // 总的分页
      loading: false,
      actionDialog: false, //订单确认弹框
      orderPreview: {
        deliveryfee: "0.00",
        outboundfee: "0.00",
        balance: "0.00",
        creditline: "0.00",

        deliveryfee_unpaid: "0.00",
        orderqty_undelivery: "0",

        orderqty_unoutbound: "0",
        outboundfee_unpaid: "0.00",

        rechargecost: "0.00",

        valueaddprice: "0.00",
        valueadd_price_unpaid: "0.00",
        valueadd_qty: "0",
      },
      valueaddorderVo: {
        files: [],
        labelchoose: 0,
        labelnum: 0,
        labelremark: "",
        reboardchoose: 0,
        reboardnum: 0,
        reboardremark: "",
        reboxchoose: 0,
        reboxednum: 0,
        reboxedremark: "",
      },
      //增值服务初始数据
      addvalueformInitData: {
        labelchoose: 0, //贴标选择
        reboardchoose: 0, //打板选择
        reboxchoose: 0, //打包选择
        insuredchoose: 0, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        month: "", //报价持续月份
        sncodeqty: '', // sn code 服务数量
      },
      orderOptions: {}, // 点击订单 所需要的参数
      
      orderTypeDialog: false, // 物流产品类型 弹框 状态
      orderType: [], // 物流产品类型
      taxpeopleType: [{
        type: 1, 
        name: '收件人'
      }, {
        type: 2, 
        name: '发件人'
      }], // 税费承担人
      Terms: [],
      orderTypeInfo: {
        orderType: "", // 物流产品类型
        largeCargoCategoryId: "", // Terms
        taxpeople: '' // 税费承担人
      },
    };
  },
 
  mounted() {
    this.getstoreselect();
    this.getZAStoreByCurrentUser();
    this.getDraftDetail();
  },
  methods: {
    /**
     * 获取草稿 详情
     */
    getDraftDetail() {
      //编辑草稿-initData
      const orderid = this.$route.query ? this.$route.query.id : 0;
      if (orderid && orderid > 0) {
        outboundDraftDataApi({ outboundId: orderid }).then((res) => {
          if (res && res.outboundOrderId && res.outboundOrderId > 0) {
            this.packForm.remarkno = res.remarkno;
            if (res.address) {
              this.receiverInfo = {
                ...res.address,
                citySuburb: `${res.address.receivecity},${res.address.receivesuburb}`,
              };
              if (res.address.receivecity && res.address.receivesuburb) {
                this.searchPostCodeByCitySuburb(
                  res.address.receivecity,
                  res.address.receivesuburb,
                );
              }
            }
          
            if (res.skulist) {
              this.goodList = res.skulist;
            }
            this.receiverInfo.deliveryType = res.deliveryType;
            this.prioritylevel = +res.prioritylevel;
            if (res.outboundstorehouseid > 0) {
              this.storehouseInfo.storehouseId = res.outboundstorehouseid + "";
              // 待写
              // 获取对应仓库是否属于 cn 若为cn 仓库 , 则展示出 跨国移库 按钮
              const warehouseInfo = this.storeOptions.find((item) => {
                return item.id == res.outboundstorehouseid;
              });
              this.country = warehouseInfo?.country; // 仓库所在国家 0： 南非za 1 中国cn
              this.arrivedwarehouseid = res.arrivedwarehouseid || ""; // 跨国移库 选择的入库仓库
            }
            this.outboundOrderId = res.outboundOrderId; // 出库订单id

            if (res.valueaddorderVo) {
              const filerecordList = res.valueaddorderVo?.files.map((item) => {
                return { ...item, url: item.filelink };
              });
              this.addvalueformInitData.filerecordList = filerecordList;
              this.addvalueformInitData.labelchoose =
                res.valueaddorderVo.labelchoose &&
                res.valueaddorderVo.labelchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.reboardchoose =
                res.valueaddorderVo.reboardchoose &&
                res.valueaddorderVo.reboardchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.reboxchoose =
                res.valueaddorderVo.reboxchoose &&
                res.valueaddorderVo.reboxchoose == 1
                  ? true
                  : false;
              this.addvalueformInitData.insuredchoose =
                res.valueaddorderVo.insuredchoose &&
                res.valueaddorderVo.insuredchoose == 1
                  ? true
                  : false;

              this.addvalueformInitData.labelnum =
                res.valueaddorderVo.labelnum || "";
              this.addvalueformInitData.labelremark =
                res.valueaddorderVo.labelremark || "";

              this.addvalueformInitData.reboardnum =
                res.valueaddorderVo.reboardnum || "";
              this.addvalueformInitData.reboardremark =
                res.valueaddorderVo.reboardremark || "";

              this.addvalueformInitData.reboxednum =
                res.valueaddorderVo.reboxednum || "";
              this.addvalueformInitData.reboxedremark =
                res.valueaddorderVo.reboxedremark || "";

              this.addvalueformInitData.insuranceamount =
                res.valueaddorderVo.insuranceamount || "";
              this.addvalueformInitData.month = res.valueaddorderVo.month || "";
              this.addvalueformInitData.sncodeqty = res.valueaddorderRequiredBo?.sncodeqty || 0
              this.$refs.addvalueform.calcprice();
            }
          }
        });
      }
    },
    /**
     * 选择出库仓库
     */
    selectStorehouse(val) {
      // 获取该仓库的详细信息
      const storehouse = this.storeOptions.find((item) => {
        return item.id == val;
      });
      this.storehouseName = storehouse.namecn;
      this.country = storehouse.country; // 仓库所在国家 0： 南非za 1 中国cn
      if (this.country == 0 && this.receiverInfo.deliveryType == 2) {
        this.receiverInfo.deliveryType = 0;
        this.arrivedwarehouseid = "";
      }
      this.getReceiverInfoByWarehose();
      this.goodList = [];
      this.$refs["receiverInfo"].clearValidate();
      this.initGoodItem();
    },

    /**
     * 取件方式 切换
     * @param {*} val
     */
    handleRadio(val) {
      // 0:派送  1：自提
      this.$refs["receiverInfo"].resetFields();
      if (val == 1) {
        this.getReceiverInfoByWarehose();
      }
      if (val != 2) {
        this.arrivedwarehouseid = "";
        this.arrivedWarehouseName = "";
      }
    },
    /**
     * 勾选仓库 自提 根据仓库获取收件人信息-- 且不允许修改以下字段
     * @param {*} country  0：南非 1：中国
     */
    getReceiverInfoByWarehose() {
      if (this.receiverInfo.deliveryType !== 1) return false; // 不是自提 不执行
      const warehouseInfo =
        this.storeOptions.find(
          (warehouse) => warehouse.id === this.storehouseInfo.storehouseId,
        ) || {};
      if (this.country == 1) {
        // 中国
        this.receiverInfo.citySuburb = "";
        this.receiverInfo.receivecity = "";
        this.receiverInfo.receivesuburb = "";
        this.receiverInfo.receivepostcode = "";
        this.receiverInfo.receiveaddress = warehouseInfo.addresscn;
      } else {
        // 南非
        this.receiverInfo.citySuburb = this.getCitySuburbOptVal(warehouseInfo);
        this.receiverInfo.receivecity = warehouseInfo.city;
        this.receiverInfo.receivesuburb = warehouseInfo.suburb;
        this.receiverInfo.receivepostcode = warehouseInfo.postcode;
        this.receiverInfo.receiveaddress = warehouseInfo.address;
      }
    },
    /**
     * 跨国移库 仓库列表数据
     */
    getZAStoreByCurrentUser() {
      getStoreByCountryAndCurrentUserApi({
        countryType: this.country,
      }).then((res) => {
        this.ZAStoreList = res;
      });
    },
    /**
     * 跨国移库， 选择仓库
     * @param {*} val
     */
    selectArrivedWarehouse(val) {
      const warehouseInfo = this.ZAStoreList.find((item) => {
        return item.id == val;
      });
      this.arrivedWarehouseName = warehouseInfo.namecn;
      this.receiverInfo.citySuburb = this.getCitySuburbOptVal(warehouseInfo);
      this.receiverInfo.receivecity = warehouseInfo.city;
      this.receiverInfo.receivesuburb = warehouseInfo.suburb;
      this.receiverInfo.receivepostcode = warehouseInfo.postcode;
      this.receiverInfo.receiveaddress = warehouseInfo.address;
      this.receiverInfo.receiver = warehouseInfo.contacts;
      this.receiverInfo.receivetelephone = warehouseInfo.phone;
      this.$refs["receiverInfo"].clearValidate();
    },
    /**
     * 获取物流产品的枚举
     */
    getOrderType() {
      getOrderTypeEnumApi().then((res) => {
        this.orderType = res.ordertypeList;
        this.Terms = res.termsList;
        this.orderTypeInfo = {
          orderType: "",
          largeCargoCategoryId: "",
          taxpeople: ''
        };
        this.orderTypeDialog = true;
        this.$nextTick(() => {
          this.$refs["orderTypeRef"].resetFields();
        });
      });
    },
    /**
     * 物流产品 保存
     */
    orderTypeConfirm() {
      this.$refs["orderTypeRef"].validate((valid) => {
        if (valid) {
          this.orderOptions = { ...this.orderOptions, ...this.orderTypeInfo };
          this.getPreviewInfo(this.orderOptions);
        }
      });
    },
    /**
     *  获取 信息与费用确认
     * @param {*} options
     */
    getPreviewInfo(options) {
      createPreviewApi(options).then((res) => {
        this.orderPreview = res;
        this.actionDialog = true;
      });
    },
    /**
     * 获取增值服务 内 数据
     * @param {*} param
     */
    addvaluechange(param) {
      this.valueaddorderVo = {
        files: param.filerecordList,
        labelchoose: param.labelchoose ? 1 : 0,
        labelnum: Number(param.labelnum),
        labelremark: param.labelremark,
        reboardchoose: param.reboardchoose ? 1 : 0,
        reboardnum: Number(param.reboardnum),
        reboardremark: param.reboardremark,
        reboxchoose: param.reboxchoose ? 1 : 0,
        reboxednum: Number(param.reboxednum),
        reboxedremark: param.reboxedremark,
      };
    },
    /**
     * 校验  三个表单都通过校验
     */
    async SaveOrder() {
      const storehouseInfForm = this.$refs.storehouseInfo;
      const receiverInfoForm = this.$refs.receiverInfo;
      // const form3 = this.$refs.goodItem;
      try {
        let validatestoreinfoflg = await storehouseInfForm.validate();
        let validatereceiver = await receiverInfoForm.validate();
        let validateaddvalueflg =
          await this.$refs["addvalueform"].validateForm();
        let packInfoFlg = await this.$refs["packInfo"].validate();
        if (this.receiverInfo.deliveryType == 1) {
          validatereceiver = true;
        }
        // await form3.validate();

        // 三个表单都通过校验后执行相关代码
        if (
          this.goodList.length > 0 &&
          validatereceiver &&
          validatestoreinfoflg &&
          validateaddvalueflg &&
          packInfoFlg
        ) {
          //   let options = {};

          //   options.address = this.getReceiveInfo();
          //   options.skulist = this.goodList;
          //   options.outboundstorehouseid = JSON.parse(
          //     JSON.stringify(this.storehouseInfo.storehouseId),
          //   );
          //   options.valueaddorderVo = this.valueaddorderVo;
          //   options.deliveryType = this.receiverInfo.deliveryType;
          //   options.outboundOrderId = this.outboundOrderId;
          //   options.remarkno = this.packForm.remarkno; // 参考号
          //   options.remarkno = options.remarkno ? options.remarkno.trim() : null;

          //   options.prioritylevel = this.prioritylevel; // 优先级
          //   this.orderOptions = options;
          const options = this.getAllParams();
          // 出库单【出货仓库】为CN国家(country: 1)且选择了跨国移库和快递派送(deliveryType:0/2, 即不为1)
          if (this.country == 1) {
            if (options.address.deliveryType == 2) {
              this.orderOptions.arrivedwarehouseid = this.arrivedwarehouseid;
              this.getOrderType();
            } else if (options.address.deliveryType == 0) {
              this.getOrderType();
            } else {
              this.getPreviewInfo(options); // 获取订单 详情
            }
          } else {
            this.getPreviewInfo(options);
          }
        } else {
          ElMessage.error("请完善下单信息");
        }
      } catch (error) {
        // if (
        //   this.receiverInfo.deliveryType == 2 &&
        //   !this.receiverInfo.arrivedwarehouseid
        // ) {
        //   return ElMessage.error("跨国移库方式未选择仓库，请重新选择取件方式");
        // }
        ElMessage.error("请完善下单信息");
      }
    },
    handleOrderType(val) {
      this.orderTypeInfo.largeCargoCategoryId = "";
    },
    /**
     * 重置页面，清空数据
     */
    reset() {
      this.$refs["storehouseInfo"].resetFields();
      this.$refs["receiverInfo"].resetFields();
      this.$refs["receiverInfo"].clearValidate();
      this.$refs["addvalueform"].resetForm();
      this.$refs["packInfo"].resetFields();
      this.receiverInfo.deliveryType = 0;
      this.country = 0;

      this.goodList = [];
      // 初始化 增值服务 数据
      this.addvalueformInitData = {
        labelchoose: 0, //贴标选择
        reboardchoose: 0, //打板选择
        reboxchoose: 0, //打包选择
        insuredchoose: 0, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        month: "", //报价持续月份
      };
      this.$refs.addvalueform.calcprice();
      this.valueaddorderVo = {
        ...this.valueaddorderVo,
        ...this.addvalueformInitData,
      };
      // 初始化 增值服务 数据 结束
    },

    /**
     * 确认下单
     */
    confirmInfo() {
      //   let options = {};
      //   options.deliveryfee = this.orderPreview.deliveryfee;
      //   options.outboundfee = this.orderPreview.outboundfee;

      //   options.address = this.getReceiveInfo();
      //   options.skulist = this.goodList;
      //   options.outboundstorehouseid = JSON.parse(
      //     JSON.stringify(this.storehouseInfo.storehouseId),
      //   );
      //   options.valueaddorderVo = this.valueaddorderVo;
      //   options.deliveryType = this.receiverInfo.deliveryType;
      //   options.outboundOrderId = this.outboundOrderId;
      //   options.remarkno = this.packForm.remarkno; // 参考号
      //   options.remarkno = options.remarkno ? options.remarkno.trim() : null;

      //   options.prioritylevel = this.prioritylevel; // 优先级
      //   const options = this.getAllParams();
      if (this.country == 1 && this.orderOptions.address.deliveryType == 2) {
        this.orderOptions.arrivedwarehouseid = this.arrivedwarehouseid;
      } else {
        this.orderOptions.arrivedwarehouseid = "";
      }

      outboundcreateApi(this.orderOptions).then((res) => {
        this.orderTypeDialog = false;
        this.actionDialog = false;
        ElMessage.success("下单成功!");
        this.reset();
      });
    },
    /**
     * 暂存
     */
    draftInfo() {
      //   let options = {};
      //   options.deliveryfee = this.orderPreview.deliveryfee;
      //   options.outboundfee = this.orderPreview.outboundfee;

      //   options.address = this.getReceiveInfo();
      //   options.skulist = this.goodList;
      //   options.outboundstorehouseid = this.storehouseInfo.storehouseId
      //     ? JSON.parse(JSON.stringify(this.storehouseInfo.storehouseId))
      //     : 0;
      //   options.valueaddorderVo = this.valueaddorderVo;
      //   options.deliveryType = this.receiverInfo.deliveryType;
      //   options.outboundOrderId = this.outboundOrderId;
      //   options.remarkno = this.packForm.remarkno; // 参考号
      //   options.remarkno = options.remarkno ? options.remarkno.trim() : null;

      //   options.prioritylevel = this.prioritylevel; // 优先出库
      const options = this.getAllParams();
      if (this.country == 1 && options.address.deliveryType == 2) {
        this.orderOptions.arrivedwarehouseid = this.arrivedwarehouseid;
      } else {
        this.orderOptions.arrivedwarehouseid = "";
      }
      outboundDraftApi(this.orderOptions).then((res) => {
        if (res.orderid) {
          this.outboundOrderId = res.orderid;
        }
        if (res.outboundno) {
          ElMessageBox.alert(
            `草稿订单的订单号为${res.outboundno}，您可以在我的订单-草稿中找到该订单并继续编辑。`,
            "Hint",
          );
        }
      });
    },
    /**
     * 获取本页面 所有参数
     */
    getAllParams() {
      let options = {};

      options.address = this.getReceiveInfo();
      options.skulist = this.goodList;
      options.outboundstorehouseid = JSON.parse(
        JSON.stringify(this.storehouseInfo.storehouseId),
      );
      options.valueaddorderVo = this.valueaddorderVo;
      options.deliveryType = this.receiverInfo.deliveryType;
      options.outboundOrderId = this.outboundOrderId;
      options.remarkno = this.packForm.remarkno; // 参考号
      options.remarkno = options.remarkno ? options.remarkno.trim() : null;

      options.prioritylevel = this.prioritylevel; // 优先级

      this.orderOptions = options;

      return options;
    },
    getReceiveInfo() {
      const receiverInfo = { ...this.receiverInfo };
      receiverInfo.citySuburb = undefined;
      return receiverInfo;
    },
    /**
     * 初始化 产品 填写信息
     */
    initGoodItem() {
      this.goodItem = {
        sku: "",
        chinesename: "",
        englishname: "",
        id: "",
        productbarcode: "",
        citySuburb: "",
        productimgurl: "",
        quantity: "",
        fillweight: "",
        unit: "",
        itemId: "",
      };
      this.$refs["goodItem"].resetFields();
    },
    /**
     * 添加一条商品信息
     */
    addGoods() {
      this.$refs["goodItem"].validate((valid) => {
        if (valid) {
          let isExit = this.goodList.findIndex(
            (item) => item.sku == this.goodItem.sku,
          );
          if (this.goodItem.quantity > this.goodItem.availablestock)
            return ElMessage.error("库存数量不够,请重新选择");
          if (isExit != -1) {
            this.goodList[isExit].quantity =
              Number(this.goodList[isExit].quantity) +
              Number(this.goodItem.quantity);
          } else {
            this.flag++;
            this.goodList.push({
              sku: this.goodItem.sku,
              chinesename: this.goodItem.chinesename,
              englishname: this.goodItem.englishname,
              id: this.goodItem.id,
              productbarcode: this.goodItem.productbarcode,
              productimgurl: this.goodItem.productimgurl,
              quantity: this.goodItem.quantity,
              fillweight: this.goodItem.fillweight,
              unit: this.goodItem.unit,
              itemId: this.flag,
              snstatus: this.goodItem.snstatus
            });
            console.log(this.goodList);
          }
          this.initGoodItem();
        } else {
          ElMessage.error("请检查商品信息");
          return false;
        }
      });
    },
    /**
     * 删除一条商品信息
     * @param {*} row
     */
    deleteItem(row) {
      let index = this.goodList.findIndex((item) => item.itemId == row.itemId);
      this.goodList.splice(index, 1);
    },
    selectPostcode(val) {
      this.receiverInfo.receivepostcode = val;
    },
    /**
     * 选择城市/suburb
     * @param {*} val
     */
    selectCitySuburb(val) {
      const item = this.SuburbOptions.find(
        (item) => this.getCitySuburbOptVal(item) === val,
      );
      this.receiverInfo.receivesuburb = item.suburb;
      this.receiverInfo.receivecity = item.city;
      this.searchPostCodeByCitySuburb(item.city, item.suburb);
    },
    searchPostCodeByCitySuburb(city, suburb) {
      searchPostCodeByCitySuburb({ city, suburb }).then((postcodeList) => {
        this.postcodeOptions = postcodeList || [];
        // 默认选择第一个
        this.receiverInfo.receivepostcode = this.postcodeOptions[0] || "";
      });
    },
    getPostCode(query) {
      if (query !== "") {
        this.parameter.search = query;
        this.parameter.type = "postcode";
        searchPostcodeApi(this.parameter).then((res) => {
          this.postcodeOptions = res.list;
          this.skuTotalPageNum = res.pageCount;
        });
      }
    },
    getCitySuburbOptVal(item = {}) {
      return `${item.city},${item.suburb}`;
    },
    // 查询city/suburb
    queryCitySuburb(query) {
      if (query !== "") {
        this.searchCitySuburbParameter.search = query;
        this.getSuburb();
      }
    },
    getSuburb() {
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = res.list;
        this.citySuburbPageInfo.totalPageCount = res.pageCount;
      });
    },
    // 默认查询city/suburb
    onSuburb() {
      this.searchCitySuburbParameter.page = 1;
      this.citySuburbPageInfo.currentPagenum = 1;
      this.getSuburb();
    },
    // city/suburb 触底加载加载更多数据
    loadmoreCitySuburb() {
      if (
        this.citySuburbPageInfo.totalPageCount <
        this.citySuburbPageInfo.currentPagenum
      ) {
        return;
      }
      this.citySuburbPageInfo.currentPagenum++;
      this.searchCitySuburbParameter.page =
        this.citySuburbPageInfo.currentPagenum;
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = [...this.SuburbOptions, ...res.list];
      });
    },
    onPostcode() {
      this.parameter.page = 1;
      this.pagenum = 1;
      this.parameter.type = "postcode";
      this.getPostCode();
    },
    /**
     * sku 相关 开始
     */
    selectSku(val) {
      this.goodItem.sku = val.sku;
      outboundgetBySkuApi({
        sku: val.sku,
        storehouseid: this.storehouseInfo.storehouseId,
      }).then((res) => {
        this.goodItem = res;
      });
    },
    getSku(query) {
      if (!this.storehouseInfo.storehouseId) {
        return ElMessage.error("请先选择仓库!");
      } else {
        this.skuList = [];
        setTimeout(() => {
          getSkulistApi({
            sku: query,
            pageNum: 1,
          }).then((res) => {
            this.skuList = res.list || [];
          });
        }, 200);
      }
    },
    /**
     * 获取 常用收件人 地址 信息  开始
     */
    showAddress() {
      this.searchOption.searchList[0].value = "";
      this.addressParams = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getAddressList();
    },
    /**
     * 搜索地址列表数据
     */
    searchAddress(obj) {
      this.addressParams = { ...obj, pageNum: 1, pageSize: 10 };
      this.getAddressList();
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.addressParams.pageNum = currentPage;
      this.getAddressList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.addressParams.pageNum = 1;
      this.addressParams.pageSize = pageSize;
      this.getAddressList();
    },
    /**
     * 双击选择 常用收件人地址
     * @param {*} val
     */

    dblclickAddress(val) {
      if (this.receiverInfo.deliveryType == 0) {
        // 快递派送
        this.receiverInfo.citySuburb = this.getCitySuburbOptVal({
          city: val.receivecity,
          suburb: val.receivesuburb,
        });
        this.receiverInfo.receivecity = val.receivecity;
        this.receiverInfo.receivepostcode = val.receivepostcode;
        this.receiverInfo.receivesuburb = val.receivesuburb;
        this.receiverInfo.receiveaddress = val.receiveaddress;
        this.receiverInfo.receiveaddress2 = val.receiveaddress2;
      }

      this.receiverInfo.receiver = val.receiver;
      this.receiverInfo.receivecompany = val.receivecompany;
      this.receiverInfo.receivetelephone = val.receivetelephone;
      this.receiverInfo.receiveemail = val.receiveemail;
      this.$refs["receiverInfo"].clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 获取 常用收件人列表 结束
     */
    getAddressList() {
      getListApi(this.addressParams).then((res) => {
        this.addressList = res.list;
        this.addressTotal = res.recordTotal;
        if (!this.dialogVisible) {
          this.dialogVisible = true;
        }
      });
    },

    /**
     * 获取仓库列表
     */
    getstoreselect() {
      // null/-1查全部；0-海外仓；1-不良品仓；
      // null/-1 全部； 0 禁用 1 启用
      getstoreselectApi({
        status: 1,
      }).then((res) => {
        this.storeOptions = res.list;
      });
    },
  },
  computed: {
    sncodeqty() {
      console.log('---', this.goodList.length);
      const result = this.goodList.filter(item => {
        return item.snstatus === 1
      })
      console.log(result);
      const total = result.reduce((sum, item) => sum + (+item.quantity), 0);
      console.log('total-', total);
      return total
    },
    //仓库信息
    storehouseRules() {
      return {
        storehouseId: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    // 物流产品类型
    orderTypeRules() {
      return {
        orderType: [
          {
            required: true,
            message: "请选择物流产品",
            trigger: "change",
          },
        ],
        largeCargoCategoryId: [
          {
            required: true,
            message: "请选择Terms",
            trigger: "change",
          },
        ],
        taxpeople: [ {
            required: true,
            message: "请选择税费承担人",
            trigger: "change",
          },]
      };
    },
    //添加商品校验
    goodItemRules() {
      return {
        sku: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
        quantity: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^\+?[1-9]\d*$/,
            message: "请输入大于0的正整数",
            trigger: "blur",
          },
        ],
      };
    },
    // 收件人信息校验规则
    receiverInfoRules() {
      return {
        receiver: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur"],
          },
        ],
        receivetelephone: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur"],
          },
          {
            pattern:
              this.country == 1 && this.receiverInfo.deliveryType == 1
                ? /^1[3-9][0-9]{9}$/
                : /^(27|0|)[0-9]{9}$/,
            message:
              this.country == 1 && this.receiverInfo.deliveryType == 1
                ? "请输入11位有效的中国手机号"
                : "请输入有效的南非手机号",
            trigger: "blur",
          },
        ],
        receivepostcode: [
          {
            required: this.receiverInfo.deliveryType == 1 ? false : true,
            message: "此处不能空白",
            trigger: ["blur"],
          },
        ],
        citySuburb: [
          {
            required: this.receiverInfo.deliveryType == 1 ? false : true,
            message: "此处不能空白",
            trigger: ["blur"],
          },
        ],
        receiveaddress: [
          {
            required: this.receiverInfo.deliveryType == 1 ? false : true,
            message: "此处不能空白",
            trigger: ["blur"],
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.outbound-create {
  color: #1f1f20;
  .form-section {
    :deep(.el-form-item__label) {
      font-weight: 400;
      font-size: 14px;
      color: #1f1f20;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    color: #1f1f20;
    line-height: 28px;
  }
  .width100 {
    width: 100%;
  }

  .black-bg {
    background: #fff;
  }
  .margin-16 {
    margin-bottom: 16px;
  }

  #addvaluecard {
    :deep(.el-collapse),
    :deep(.el-collapse-item__header),
    :deep(.el-collapse-item__wrap) {
      border: 0 none;
    }
    :deep(.el-collapse-item__header) {
      height: auto;
      line-height: normal;
    }
    :deep(.el-collapse-item__content) {
      padding: 0;
    }
  }
  .radio-section {
    :deep(.el-radio.is-bordered.is-checked) {
      background: #f8f3ed;
    }
  }
}
.za-store-list {
  width: 208px;
  margin-left: 8px;
}
</style>

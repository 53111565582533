import { createRouter, createWebHistory } from 'vue-router'

const Layout = () => import('@/layout/index.vue')

export const constantRoutes = [
 
  {
      path: '/',
      component: Layout,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import('@/views/home/index.vue'),
          meta: { 
            title: '首页',  
            icon:'icon-shouye-weixuanzhong',
            affix: true,
            breadcrumb: true,
            keepAlive: true
          }
        }
      ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true
  },
  {
    path: '/open-api',
    component: () => import('@/views/open-api/index.vue'),
    hidden: true
  },
  {
    path: '/open-apiToSupplier',
    component: () => import('@/views/open-api/to-supplier.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)*', redirect: '/404', hidden: true }
]


const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior: () => ({ left: 0, top: 0 })
})

let pathname = window.location.pathname
let searchQuery = window.location.search;
pathname = searchQuery ? pathname + searchQuery : pathname;

let onerun = true;

router.beforeEach(async (to, from, next) => {
  if (onerun) {
    addMenus()
    onerun = false
    next(pathname)
    // next({ path: to.path })
  } else {
    if (from.path == '/login') {
      addMenus()
    }
    next()
  }
})


const temprray = constantRoutes
function addMenus() {
  let returnData = []
  const routes = JSON.parse(localStorage.getItem('routes')) || []
  if (routes.length <= 0) {
    returnData = [...constantRoutes]
  } else {
    returnData = generateRoutesFromMenu(routes)
    temprray.forEach(item => {
      returnData.push(item)
    })
    const homeObjIndex = returnData.findIndex(item => item.path === '/')
    const removedObj = returnData.splice(homeObjIndex, 1)[0]
    returnData.unshift(removedObj)

  }
  router.options.routes = returnData
  returnData.map(item => router.addRoute(item))
}

const routeMap = new Map([
  ["入库管理",'Inbound'],
  ["入库单管理",'InboundList'],
  ["新建入库单",'InboundCreate'],
  ["出库管理",'Outbound'],
  ["运单详情",'OutboundDetail'],
  ["我的订单",'OutboundList'],
  ["我要下单",'OutboundCreate'],
  ["导入下单",'OutboundImport'],
  ["常用收件人管理",'OutboundReceiver'],
  ["Take a lot",'OutboundTakealot'],
  ["仓储管理",'StorageCenter'],
  ["库存查询",'StorageCenterStockquery'],
  ["流水查询",'StorageCenterFlowquery'],
  ["盘点管理",'StorageCenterInventory'],
  ["查看盘点单",'StorageCenterInventoryDetail'],
  ["基础信息设置",'BasicSetting'],
  ["产品管理",'BasicSettingProducts'],
  ["账户信息管理",'Management'],
  ["子账户管理",'ManagementSubaccount'],
  ["账户信息管理",'ManagementAccount'],
  ["角色权限管理",'ManagementRole'],
  ["账户流水",'ManagementAccountStatement'],
  ["账户充值",'ManagementRecharge'],
  ["充值记录查询",'ManagementRechargeRecord'],
  ["增值服务",'Services'],
  ["增值服务管理",'ServicesOrder'],
  ["报表明细",'Reports'],
  ["入库计费明细",'ReportsInbound'],
  ["出库计费报表",'ReportsOutbound'],
  ["仓储计费报表",'ReportsStorehouse'],
  ["仓储计费明细表",'ReportsStorehouseDetail'],
  ["增值服务计费报表",'ReportsValueadd'],
])

const modules = import.meta.glob('../views/**/index.vue', { eager: true })

// 递归生成路由
function generateRoutesFromMenu(menuData) {
  const routes = []
  // 遍历菜单数据
  menuData.forEach(item => {

    const path = `../views${item.identity}/index.vue`
    // 在 import 表达式内部嵌入 /* @vite-ignore */ 注释可以忽略 vite 内部弹出的警告
    // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
    // const component = item.redirect ? Layout : ()=>import(/* @vite-ignore */path)
    const component = item.redirect ? Layout : modules[path]?.default 

    const route = {
      path: item.path || item.identity,
      redirect: item.redirect || '',
      alwaysShow: !!item.redirect,
      component,
      name: routeMap.get(item.name) || 'no-name',
      meta: {
        title: item.name,
        icon: item.icon ? item.icon : '',
        keepAlive: false
      },
      hidden: item.hidden || false

    }
    if (item.childs && item.childs.length > 0 && item.childs[0].childs) {
      route.children = generateRoutesFromMenu(item.childs)
    }
    const existingObjIndex = temprray.findIndex(conRoute => conRoute.path === route.path)
    if (existingObjIndex != -1) {
      if (temprray[existingObjIndex].children && temprray[existingObjIndex].children.length > 0) {
        temprray[existingObjIndex].children.forEach(tempitem => {
          if (!(route.children && route.children.length > 0)) {
            route.children = []
          }
          route.children.push(tempitem)
        })
      }
      temprray.splice(existingObjIndex, 1)
    }
    routes.push(route)
  })
  return routes
}

// 重置路由
export function resetRouter() {
  router.replace({ path: '/login' })
}

export default router

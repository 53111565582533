<template>
  <section>
    <!-- 出库计费报表 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnExport"
          >导出</el-button
        >
      </el-row>
      <el-table
        stripe
        height="450"
        style="width: 100%; margin: 10px 0 20px"
        :header-cell-style="{ background: '#FAFAFA' }"
        :data="tableOption.tableData"
        :border="true"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'orderno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row)"
              >
                <span class="link">{{ row.orderno }}</span>
              </el-link>
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="tableOption.page.currentPage"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableOption.page.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 查看出库单详情 有导出按钮 出库 orderType = 3: 出库 + 导出-->
    <order-detail-dialog
      :orderType="3"
      :id="outboundorderid"
      :dialogVisible="detailDialog"
      @openDialog="openDialog"
    >
    </order-detail-dialog>
  </section>
</template>

<script setup>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { tableOption } from "./column";
import { outboundreportListApi, outboundreportExportApi } from "@/api/report";
import { getstoreselectApi } from "@/api/common";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

defineOptions({
  name: "ReportsOutbound",
});

const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true, // 操作按钮是否单独占一行
  searchList: [
    // 检索列表
    {
      label: "出库时间:",
      type: "date",
      value: "",
      prop: "daterange",
    },
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "orderSelectVal",
      maxlength: 30,
      placeholder: "请输入内容",
      selectOption: {
        value: "orderno",
        prop: "orderSelect",
        optionData: [
          { label: "出库订单号", value: "orderno" },
          { label: "运单号", value: "waybillno" },
          { label: "参考号", value: "remarkno" },
        ],
      },
    },
    {
      label: "出库仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: "全部", value: "-1" }],
      clearable: false,
    },
  ],
});
let params = reactive({
  pageNum: 1, // 请求 某页 数据
  pageSize: 10,
});

const detailDialog = ref(false); // 弹框 状态

const outboundorderid = ref(""); // 当前出库单id

const dateRange = ref("");
/**
 * 时间筛选 限制在1年内
 */
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};

/**
 * 获取数据列表
 */
const getList = () => {
  console.log("params", params);
  outboundreportListApi(params).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};

/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  console.log(obj, "----");
  if (dateRange.value && dateRange.value.length) {
    obj.startdate = dateRange.value[0];
    obj.enddate = dateRange.value[1];
  }
  if (obj.storehouseid === "-1") {
    delete obj.storehouseid;
  }
  if (obj.orderSelectVal) {
    obj[obj.orderSelect] = obj.orderSelectVal;
  }
  delete obj.orderSelectVal;
  delete obj.orderSelect;
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = 10;
  params = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};

/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "storehouseid") {
      item.value = "-1";
    } else if (item.prop == "orderSelect") {
      item.value = "orderno";
    }
  });
  dateRange.value = "";
  params = {
    pageNum: 1,
    pageSize: params.pageSize,
  };
  handleCurrentChange(1);
};

/**
 * 表格上方的操作按钮(这里只有导出按钮)
 */
const operationBtnExport = () => {
  outboundreportExportApi(params).then((res) => {
    DOWNLOAD_FILE(res);
  });
};

/**
 * 点击出库订单号， 显示计费明细
 */
const showDetail = (row) => {
  outboundorderid.value = row.id;
  detailDialog.value = true;
};
const openDialog = (val) => {
  detailDialog.value = val;
};

/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  tableOption.page.currentPage = currentPage;
  params.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = pageSize;
  params.pageNum = 1;
  params.pageSize = pageSize;
  getList();
};

/**
 * 获取仓库列表
 */
const getWarehouseList = () => {
  // type: null/-1查全部；0-海外仓；1-不良品仓；
  getstoreselectApi({ type: -1 }).then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = [{ label: "全部", value: "-1" }, ...res.list];
      }
    });
  });
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "出库计费报表",
  });
};
onMounted(() => {
  getWarehouseList(); // 获取仓库列表
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>
<style lang="scss" scoped></style>

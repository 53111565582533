import * as $math from 'mathjs';

export const math = {
  // t1 + t2
  addTwo (t1, t2) {
    return parseFloat($math.format($math.chain(t1 || 0).add($math.bignumber(t2 || 0)).done()));
  },
  // t1 - t2
  subtractTwo (t1, t2) {
    return parseFloat($math.format($math.chain(t1 || 0).subtract($math.bignumber(t2 || 0)).done()));
  },
  // t1 * t2
  multiplyTwo (t1, t2) {
    return parseFloat($math.format($math.chain(t1 || 0).multiply($math.bignumber(t2 || 0)).done()));
  },
  // t1 / t2
  divideTwo (t1, t2) {
    return parseFloat($math.format($math.chain(t1 || 0).divide($math.bignumber(t2 || 0)).done()));
  }
};


/**
 * 使用时
 */

// import { math } from '@/utils/math';

// 例如 调用加法 a + b
// math.addTwo(a, b);
// // 例如 调用减法 a - b
// math.subtractTwo(a, b);
// // 例如 调用乘法 a X b
// math.multiplyTwo(a, b);
// // 例如 调用除法 a / b
// math.divideTwo(a, b);


/**仓储计费报表 */
export const searchOption = reactive({
    // 顶部表单检索内容,非必填
    labelWidth: "80px",
    searchList: [
        // 检索列表
        {
            label: "盘点单号:",
            type: "input",
            value: "",
            prop: "invno",
            maxlength: 20,
            placeholder: '请输入盘点单号'
        },
        {
            label: "状态:",
            type: "select",
            value: "",
            prop: "statetype",
            optionData: [],
            clearable: true,
            placeholder: '请选择状态'
        },
        {
            label: "调整库存:",
            type: "select",
            value: "",
            prop: "adjust",
            optionData: [],
            clearable: true,
            placeholder: '请选择调整库存'
        },

        {
            label: "盘点仓库:",
            type: "select",
            value: "",
            prop: "warehouseid",
            optionData: [],
            clearable: true,
            placeholder: '请选择盘点仓库'
        },
        {
            label: "审核结果:",
            type: "select",
            value: "",
            prop: "audittype",
            optionData: [],
            clearable: true,
            placeholder: '请选择审核结果'
        },

    ],
})
export const tableOption = reactive({
    height: 450,
    tableColumn: [
        {
            label: "盘点单号",
            prop: "invno",
            minWidth: "180",
            align: 'left'
        },
        {
            label: "盘点仓库",
            prop: "storenamecn",
            minWidth: "140",
        },
        {
            label: "状态",
            prop: "state",
            minWidth: "100",
        },
        {
            label: "审核结果",
            prop: "audit",
            minWidth: "100",
        },
        {
            label: "调整库存",
            prop: "adjust",
            minWidth: "100",
        },
        // {
        //     label: "盘点单类型",
        //     prop: "type",
        //     minWidth: "100",
        // },
        {
            label: "创建人",
            prop: "createuser",
            minWidth: "100",
        },
        {
            label: "创建时间",
            prop: "createtime",
            minWidth: "180",
            align: 'left'
        },
        {
            label: "盘点结束时间",
            prop: "finishtime",
            minWidth: "180",
            align: 'left'
        },
        {
            label: "备注",
            prop: "notes",
            minWidth: "180",
        },
    ],
    // 表格数据，必填
    tableData: [],
    page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
})






import { GET, POST } from "@/utils/request";
import { EXPORT_FILE_GET } from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

// 禁用产品
export const disableproductApi = (id) =>
  GET({ url: `/product/index/${id}/disable`, params: { id } });

// 启用产品
export const enableproductApi = (id) =>
  GET({ url: `/product/index/${id}/enable`, params: { id } });

// 取消登记出库
export const snOffApi = (id) => GET({ url: `/product/index/${id}/snOff` });

// sn登记产品
export const snOnApi = (id) => GET({ url: `/product/index/${id}/snOn` });

// 一级目录
export const Level1Api = () =>
  GET({ url: "/product/index/listLevel1select", params: "" });

// 二级目录
export const Level2Api = (id) =>
  GET({ url: "/product/index/listLevel2select", params: { id } });

// 三级目录
export const Level3Api = (id) =>
  GET({ url: "/product/index/listLevel3select", params: { id } });

// 产品类型 下拉查询
export const listLevelselectApi = (params) =>
  GET({ url: "/product/index/listLevelselect", params, closeloading: true });

// 新增产品
export const addproductApi = (data) =>
  POST({ url: "/product/index/addNew", data });

// 更新产品
export const updateproductApi = (data) =>
  POST({ url: "/product/index/update", data });

// 产品列表查询
export const productlistApi = (data) =>
  POST({ url: "/product/index/list", data });

// 获取产品详情
export const getByIdApi = (id) =>
  GET({ url: "/product/index/getById", params: { id } });

export const exportApi = (params) =>
  EXPORT_FILE_GET({ url: "/product/index/export_data", params });

// 箱标
export const printbarcodeApi = (params) =>
  EXPORT_FILE_GET({ url: "/product/index/printbarcode", params });

// 上传图片
// /product/index/uploadFile

export const uploadFileApi = (data) =>
   POST({ url: "/product/index/uploadFile", data, config: {upload: true},  headersType: 'multipart/form-data' });
  
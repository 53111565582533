<template>
  <div class="crete-setp">
    <div v-for="(item, index) of steps" :key="index" class="box">
      <div>
        <span :class="['step-num', isActive(index) ? 'active' : '', isFinished(index) ? 'finished-step-num' : '']">
          <el-icon :size="25" class="finished-icon" v-if="isFinished(index)"><CircleCheck /></el-icon>
          <template v-else> {{index + 1}}</template>
        </span>
        <span :class="['step-title', isActive(index) ? 'active-title' : '', isFinished(index) ? 'finished-title' : '']">{{
          item
        }}</span>
      </div>
      <span :class="['line', isFinished(index) ? 'active-line' : '']" v-if="hideLastLine(index)"></span>
    </div>
    <!-- <el-button type="primary" @click="back">back</el-button>
    <el-button type="primary" @click="next">next</el-button> -->
  </div>
</template>
<script>
/* import { computed, ref } from "vue";
const emit = defineEmits(["next", "back"]);

const active = ref(0);
const steps = ref([
  "入库信息",
  "Buffalo物流单",
  "增值服务",
  "订单确认",
  "打印",
]);

const hideLastLine = (index) => index !== steps.value.length - 1;

const isActive = (index) => index === active.value;

const back = () => {
  if (active.value === 0) {
    return;
  }
  active.value = active.value - 1;
};

const next = () => {
  if (active.value === steps.value.length - 1) {
    return;
  }
  active.value = active.value + 1;
}; */
export default {
  props: {
    tabIndex: {
      default: 0,
      type: [Number, String]
    },
    switchTab: {
      type: Function,
    }
  },
  data() {
    return {
      steps: [
        "入库信息",
        "物流单",
        "增值服务",
        "订单确认",
        "打印",
      ],
    }
  },
  // watch: {
  //   tabIndex(newVal) {
  //     console.log('newVal', newVal)
  //     this.tabIndex = Number(newVal)
  //   }
  // },
  methods: {
    isFinished(index) {
      return index < this.tabIndex
    },
    hideLastLine(index){
      return index !== this.steps.length - 1
    },
    isActive(index){
      return index === this.tabIndex
    },
    back() {
      if (this.tabIndex === 0) {
        return;
      }
      this.$emit('switch-tab', Number(this.tabIndex) - 1)
    },
    next() {
      if (this.tabIndex === this.steps.length - 1) {
        return;
      }
       this.$emit('switch-tab', Number(this.tabIndex) + 1)
    },
  }
}
</script>

<style lang="scss" scoped>
.crete-setp {
  display: flex;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:nth-last-child(1)) {
      flex-grow: 1;
    }
  }

  .step-num {
    $height: 25px;
    display: inline-block;
    width: $height;
    height: $height;
    line-height: $height;
    border-radius: 50%;
    text-align: center;
    border: 1px solid var(--gray-900);
    color: var(--gray-900);

    &:nth-of-type(n + 2) {
      margin-left: 10px;
    }
  }

  .finished-step-num {
    border: none;
    vertical-align: top;
  }

  .active {
    background-color: var(--main-color);
    color: var(--main-color-100);
    border: none;
  }

  .finished-icon {
    color: var(--main-color);
    margin-top: -1px;
  }

  .step-title {
    padding: 10px;
    color: var(--gray-900);
    font-weight: bold;
  }
  .active-title {
    color: var(--main-color);
  }
  .finished-title {
    color: var( --mian-text-color);
  }

  .line {
    background: #dcdcdc;
    margin: 10px;
    height: 2px;
    flex-grow: 1;
  }
  .active-line {
    background: var(--main-color);
  }

  .finished {
    font-size: 25px;
    width: 25px;
    height: 25px;
    display: inline-block;
  }
}
</style>

<template>
  <section>
    <!-- 仓储计费明细表 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnExport"
          >导出</el-button
        >
      </el-row>
      <el-table
        :data="tableOption.tableData"
        :border="true"
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="450"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </section>
</template>

<script setup>
import { tableOption } from "./column";
import {
  storagefeedetailListApi,
  storagefeedetailExportApi,
} from "@/api/report";
import { getstoreselectApi } from "@/api/common";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

defineOptions({
  name: "ReportsStorehouseDetail",
});

const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "140px",
  searchList: [
    // 检索列表
    {
      label: "日期:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions: pickerOptions()
    },
    {
      label: "sku:",
      type: "input",
      value: "",
      prop: "sku",
      maxlength: 30,
      placeholder: "请输入sku",
    },
    {
      label: "商品名称:",
      type: "input",
      value: "",
      prop: "product",
      maxlength: 30,
      placeholder: "请输入商品名称",
    },
    {
      label: "当前仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: "全部", value: "-1" }],
    },
    {
      label: "入库订单号/批次号:",
      type: "input",
      value: "",
      prop: "orderno",
      maxlength: 30,
      placeholder: "请输入入库订单号/批次号",
    },
  ],
});

const params = ref({
  pagenum: 1, // 请求 某页 数据
  pageSize: 10,
});
const lastMonth = ref(""); // 上月
const nowMonth = ref(""); // 下月
/**
 * 时间筛选 限制在1月内
 */
const dateRange = ref([]); // 选择的时间范围
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 1,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 1,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
/**
 * 获取数据列表
 */
const getList = () => {
  storagefeedetailListApi(params.value).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  obj.starttime = dateRange.value[0];
  obj.endtime = dateRange.value[1];
  if (obj.storehouseid === "-1") {
    delete obj.storehouseid;
  }
  params.value = {
    ...obj,
    pagenum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  const storehouseid = route.params?.storehouseid;

  searchOption.searchList.map((item) => {
    item["value"] = "";
    if (item.prop == "storehouseid") {
      item.value = storehouseid ? storehouseid : "-1";
    }
  });
  const starttime = route.params?.date
    ? route.params?.date
    : dayjs().add(-1, "month").format("YYYY-MM-DD");
  const endtime = route.params?.date
    ? route.params?.date
    : dayjs().format("YYYY-MM-DD");
  dateRange.value = route.params?.date
    ? [route.params?.date, route.params?.date]
    : [starttime, endtime];

  params.value = {
    pagenum: 1,
    pageSize: params.value.pageSize,
    starttime,
    endtime,
    storehouseid,
  };
  handleCurrentChange(1);
};
/**
 * 表格上方的操作按钮(这里只有导出按钮)
 */
const operationBtnExport = () => {
  storagefeedetailExportApi(params.value).then((res) => {
    DOWNLOAD_FILE(res);
  });
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pagenum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pagenum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 获取仓库列表
 */
const getWarehouseList = () => {
  const storehouseid = route.params?.storehouseid
    ? route.params.storehouseid + ""
    : "";
  if (storehouseid) params.value.storehouseid = storehouseid;
  // type: null/-1查全部；0-海外仓；1-不良品仓；
  getstoreselectApi({ type: -1 }).then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = [{ label: "全部", value: "-1" }, ...res.list];
        item.value = storehouseid ? storehouseid : "-1";
      }
    });
  });
};
/**
 * 获取默认时间段
 */
const getDate = () => {
  const date = route.params?.date;
  if (date) {
    lastMonth.value = date;
    nowMonth.value = date;
  } else {
    lastMonth.value = dayjs().add(-1, "month").format("YYYY-MM-DD"); // 获取上月
    nowMonth.value = dayjs().format("YYYY-MM-DD"); // 当天
  }
  params.value.starttime = lastMonth.value;
  params.value.endtime = nowMonth.value;
  dateRange.value = [lastMonth.value, nowMonth.value];
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "仓储计费明细表",
  });
};
onMounted(() => {
  getDate();
  getWarehouseList(); // 获取仓库列表
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>
<style lang="scss" scoped></style>

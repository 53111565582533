import { defineStore } from "pinia";
import { reactive,toRefs } from "vue";
import axios from 'axios'
import {usageURL} from '@/utils/baseURL'
export const useTrackingInfo  = defineStore('trackingInfo', () => {

    let state = reactive({
        pathInfo: {}, // tag 关闭时 当前的路由信息

    })

    // 设置请求的头部
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const userid = JSON.parse(localStorage.getItem("user"))?.id
    /**
     * 为埋点存储数据, 删除顶部标签时 触发
     * params{*} val : 关闭的当前路由的 信息
     */
    const setPathInfo = (val) => {
        state.pathInfo = val
    }
    
    /**
     * 埋点， 
     */
    const setTracking = (data) => {
        const params = ref({
            userid: userid,
            source: 14, // 14: 客户端， 15: 后台
            version: 1
        })
    
        params.value = {...params.value, ...data}
    
        axios.post(`${usageURL() || ''}/usage/save`, params.value, config)
    }


    return {
        ...toRefs(state),
        setPathInfo,
        setTracking
    }

}, {
    persist: true
})
import { reactive } from 'vue'

export const dataList = reactive([{
    title: '入库',
    label: 'countInbound',
    list: [
        {
            label: '未收货',
            prop: 'unreceived',
        }, 
        {
            label: '已收货',
            prop: 'received',
        }, 
        {
            label: '已质检',
            prop: 'inspected',
        }, 
        {
            label: '已上架',
            prop: 'onshelf',
        }
    ],
    data:[]
}, 
{
    title: '出库',
    label: 'countOutbound',
    list: [
        {
            label: '待处理',
            prop: 'pending',
        }, 
        {
            label: '待拣货',
            prop: 'picking',
        }, 
        {
            label: '待打包',
            prop: 'packing',
        }, 
        {
            label: '待出库',
            prop: 'waitoutbound',
        }, 
        {
            label: '已发货',
            prop: 'outbound',
        }
    ],
    data:[]
}, 
{
    title: '仓储',
    label: 'countStorage',
    btn: '查看库存详情',
    list: [
        {
            label: 'SKU数量',
            prop: 'skuQty',
        }, 
        {
            label: '前日仓储总体积',
            prop: 'vol',
        }, 
        {
            label: '产品库存预警',
            prop: 'warningSkuQty',
        }, 
    ],
    data:[]
}])
/**仓储计费报表 */
export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // labelWidth: "90px",
  searchList: [
    // 检索列表
    {
      label: "日期:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // pickerOptions: pickerOptions()
    },
    {
      label: "当前仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: '全部', value: '-1' }],
      clearable: false
    },
  ]
})
export const tableOption = reactive({
  height: 550,
  highlightCurrentRow: true, // 是否高亮
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "日期",
      prop: "date",
      minWidth: "130",
      align:'left'
    },
    {
      label: "库存体积(m³）",
      prop: "totalvolume",
      minWidth: "130",
    },
    {
      label: "仓库",
      prop: "storehousename",
      minWidth: "130",
      align:'left'
    },
    {
      label: "库存费用（人民币）",
      prop: "storagefee",
      minWidth: "130",
      align:'right'
    },
  ],
  // 表格数据，必填
  tableData: [],
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
})






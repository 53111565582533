
import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

//1 新建
export const confirmAddApi = (data) => POST({ url: '/valueaddorder/confirm',headersType: 'application/json', data })

//2 删除附件
export const delfileApi = (data) => POST({ url: '/valueaddorder/delfile', data })


//3 销毁
export const destoryApi = (data) => POST({ url: '/valueaddorder/destory', data })

//4 详情
export const detailApi = (data) => POST({ url: '/valueaddorder/detail', data })

//5 查询列表
export const searchAddApi = (params) => GET({ url: '/valueaddorder/search', params })

//6 下拉
export const getstatuselectApi = (params) => GET({ url: '/valueaddorder/getstatuselect', params })

//7 下一步
export const nextstepApi = (data) => POST({ url: '/valueaddorder/nextstep', data })

// 导出
export const exportApi = (params) => EXPORT_FILE_GET({ url: '/valueaddorder/export', params })
<template>
  <div class="open-api">
    <div class="open-api-title">BUFFALO Open API</div>
    <div class="open-api-content">
      <!--锚点导航-->
      <div class="menu-section">
        <ul class="menu-list">
          <li class="total-title">MENU</li>
          <li >
            <a href="#how" class="menu-item">
              <span class="no how">#</span>
              <div class="title">
                <span class="titleZh">开发指南</span>
                <span class="titleEn">Developer Guide</span>
              </div>
            </a>
          </li>
          <li class="total-title">接口功能-Interface Function</li>
          <li v-for="(item, index) in apiData" :key="index">
            <a :href="'#' + item.id" class="menu-item">
              <span class="no">{{ index + 1 }}</span>
              <div class="title">
                <span class="titleZh">{{ item.titleZh }}</span>
                <span class="titleEn">{{ item.titleEn }}</span>
              </div>
            </a>
          </li>
          <li  class="total-title">基础数据-BASIC DATA</li>

          <li v-for="(item, index) in basicApiData" :key="'basic'+index">
            <a :href="'#' + item.id" class="menu-item">
              <span class="no">{{ index + 1 }}</span>
              <div class="title">
                <span class="titleZh">{{ item.titleZh }}</span>
                <span class="titleEn">{{ item.titleEn }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!--展示锚点-->
      <div class="api-section">
        <ul class="api-section-list">
          <li>
            <el-row>
              <el-col :span="24" class="api-section-title">
                <p><span>BUFFALO</span><span>开放平台</span></p>
                <p>Developer Guide</p>
              </el-col>
            </el-row>
            <el-divider></el-divider>
          </li>
          <li>
            <el-row>
              <el-col :span="24" class="open-api-desc">
                <p>
                  提供给合作伙伴及用户使用，用户可以方便的集成到网站或系统，实现信息无缝对接。
                </p>
                <p>
                  Provided for use by partners and users, users can easily
                  integrate into websites or systems to achieve seamless
                  information connection.
                </p>
              </el-col>
            </el-row>
          </li>

          <li id="how">
            <el-row>
              <el-col :span="24" class="title-item">
                <span class="titleZh gray">如何使用</span>
                <span class="titleEn">How to use</span>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <ul class="usage-method">
                  <li v-for="item in usage" :key="item.index">
                    <span class="index">{{ item.index }}</span>
                    <p>
                      <span v-html="item.contentZh"></span>
                      <span v-html="item.contentEn"></span>
                    </p>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </li>
          <li v-if="apiData.length">
            <api-item :apiData="apiData" ></api-item>
          </li>
          <li v-if="basicApiData.length">
            <api-item :apiData="basicApiData">
              <div class="section2">
                <p class="section2-title">
                  <span>基础数据</span>
                  <span>Basic data</span>
                </p>
                <el-divider></el-divider>
                <p class="section2-desc">API接口需要的基础数据信息查询接口</p>
                <p class="section2-desc">The basic data information query interface required by the API interface.</p>

              </div>

            </api-item>
          </li>

        </ul>
      </div>
    </div>
  </div>

</template>
<script>
import { apiData, basicApiData } from "./apiData.js";
import apiItem from './components/api-item.vue'
export default {
  name: 'OpenApi',
  components: {
    apiItem
  },
  data() {
    return {
      apiData,  // json
      basicApiData,  // json
      usage: [
        {
          index: 1,
          contentZh: "首先您需要拥有海外仓账户，如果没有，请联系客服人员。",
          contentEn:
            "Firstly, you need to have an overseas warehouse account. If not, please contact customer service.",
        },
        {
          index: 2,
          contentZh:
            "进入账户信息及子账户管理→账户信息管理，获取您的接口秘钥，key格式271bd87a-60bd-4ef8-b3bc-5192e2e72de3",
          contentEn:
            "Enter account information and sub account management → account information management, obtain your interface secret key, key format: 271bd87a-60bd-4ef8-b3bc-5192e2e72de3",
        },
        {
          index: 3,
          contentZh: "接口服务请求的地址是 https://overseasindex.buffaloex.com</br>测试环境为 http://preview-overseas-index.buffaloex.com",
          contentEn:
            "The address requested by the interface service is https://overseasindex.buffaloex.com</br>The test environment is http://preview-overseas-index.buffaloex.com",
        },
        {
          index: 4,
          contentZh: "POST接口请求头说明及示例：&nbsp;&nbsp;1).Content-Type:application/json &nbsp;&nbsp;2).key:271bd87a-60bd-4ef8-b3bc-5192e2e72de3 &nbsp;&nbsp;3).sign:使用SHA1withRSA签名算法进行数字签名，长度2048，获取私钥请联系客服人员",
          contentEn: "Explanation and example of POST interface request header: &nbsp;&nbsp;1).Content-Type:application/json &nbsp;&nbsp;2).key:271bd87a-60bd-4ef8-b3bc-5192e2e72de3 &nbsp;&nbsp;3).sign: SHA1withRSA signature algorithm is used for digital signature with a length of 2048. Please contact customer service to obtain the private key.",
        },
      ]
    };
  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
@import "./anchor.scss";
.section2{
  margin-bottom: 25px;
  .section2-title{
    font-size:30px;
    margin-top:24px;
    span:first-child{
      display:block;
      font-weight:100;
    }
  }
  // .section2-desc{
  //   line-height:0.8;
  // }

}

</style>

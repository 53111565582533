export const receiverInfoRules =
    [
        {
            prop: 'nameen',
            label: '收件人英文名',
            placeholder: '限英文/空格',
            type: 'input',
            maxlength: 50,
            rules: [
                { required: true, message: "此处不能空白", trigger: "blur" },
                {
                    pattern: /^[A-Za-z+\s]+$/,
                    message: "只能输入英文/空格",
                    trigger: "blur",
                },
            ]
        },
        {
            prop: 'company',
            label: '收件人公司',
            placeholder: '请输入收件人公司',
            type: 'input',
            maxlength: 50
        },
        {
            prop: 'phone',
            label: '收件人电话',
            placeholder: '请输入收件人电话',
            type: 'input',
            maxlength: 50,
            rules: [
                { required: true, message: "请输入数字", trigger: "blur" },
                {
                    pattern: /^[0-9+\s]+$/,
                    message: "请输入有效的电话号码",
                    trigger: "blur",
                },
            ]
        },
        {
            prop: 'email',
            label: '电子邮件',
            placeholder: '请输入电子邮件',
            type: 'input',
            maxlength: 50
        },
        {
            prop: 'suburb',
            label: '城市/Suburb',
            type: 'select',
            multiple: false,
            placeholder: '',
            rules: [
                {
                    required: true,
                    message: "此处不能空白",
                    trigger: ["blur", "change"],
                },
            ]
        },
        {
            prop: 'code',
            label: '邮编',
            type: 'select',
            multiple: false,
            placeholder: '',
            rules: [
                {
                    required: true,
                    message: "此处不能空白",
                    trigger: ["blur", "change"],
                },
            ]
        },
        // {
        //     prop: 'city',
        //     label: '城市',
        //     placeholder: '',
        //     type: 'input',
        //     maxlength: 50,
        //     disabled: true
        // },
        {
            prop: 'address',
            label: '收件地址（英文）',
            placeholder: '请输入收件地址（英文）',
            type: 'textarea',
            maxlength: 100,
            class:"address",
            rules: [
                { required: true, message: "此处不能空白", trigger: "blur" },
                // { pattern: /^[a-zA-Z0-9]+$/, message: '只能输入英文和数字', trigger: 'blur' }
                {
                    pattern: /^[^\u4e00-\u9fa5]+$/,
                    message: "只能输入英文字符和空格",
                    trigger: "blur",
                },
            ]
        }
    ]


const validateNum = (rule, value, callback) => {
    if (!value.trim()) {
        return callback(new Error('Please enter money'))
    }
    if (!value || value === '0') return callback(new Error('仅支持输入正数'))
    callback()
}


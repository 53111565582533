export const searchOption = reactive({
    labelWidth: "80px",
    // singleRow: true,
    searchList: [
        // 检索列表
        {
            label: "",
            type: "select-input",
            value: "",
            prop: "orderno",
            maxlength: 100,
            placeholder: '请输入对应单号/参考号',
            selectOption: {
                value: "0",
                prop: "querytype",
                optionData: [{ value: "0", label: "订单号" },
                { value: "1", label: "运单号" }, { value: "2", label: "参考号" },],
                clearable: false,
            },
        },
        {
            label: "仓库:",
            type: "select",
            value: "",
            prop: "storehouseid",
            optionData: [],
            clearable: true,
            placeholder: '请选择仓库',
        },
        // {
        //     label: "运单号",
        //     type: "input",
        //     value: "",
        //     prop: "waybillno",
        //     maxlength: 100,
        // },
        {
            label: "创建时间:",
            type: "date",
            // dateType: "daterange",
            value: "",
            prop: "daterange",
            // clearable: false,
            // pickerOptions: pickerOptions()
        },
    ],
})
export const outboundOrderTable = reactive([
    // 草稿
    {
        tabName: "草稿",
        count: 0,
        label: "draft",
        activeName: "-5",
        height: 500,
        selection: true,
        index:true,
        // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
                icon:'Upload'
            },
            {
                title: "导出详情",
                prop: "exportDetail",
                icon:'Upload'
            },
            {
                title: "删除",
                prop: "delete",
                icon:'Delete'
            },
            {
                title: "编辑",
                prop: "edit",
                icon:'Edit'
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth:"180",
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 待处理
    {
        tabName: "待处理",
        count: 0,
        label: "pending",
        activeName: "0",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
            {
                title: "截单",
                prop: "terminated",
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 待拣货
    {
        tabName: "待拣货",
        count: 0,
        label: "picking",
        activeName: "10",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
            {
                title: "截单",
                prop: "terminated",
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
           
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'

            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },

    },
    // 待打包
    {
        tabName: "待打包",
        count: 0,
        label: "packing",
        activeName: "20",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
            {
                title: "截单",
                prop: "terminated",
            },
           
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },

        ],
        tableData: [],
       
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 待出库
    {
        tabName: "待出库",
        count: 0,
        label: "waitoutbound",
        activeName: "30",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
            {
                title: "截单",
                prop: "terminated",
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left',
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left',
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left',
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 已发货
    {
        tabName: "已发货",
        count: 0,
        label: "outbound",
        activeName: "40",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
                icon:'Upload'
            },
            {
                title: "导出详情",
                prop: "exportDetail",
                icon:'Upload'
            },
            {
                title: "下载面单",
                prop: "download",
            },
            {
                title: "查看最新取件码",
                prop: "queryNewCode",
                buttonPermission: 'CheckNewPickCode'
            }
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200"
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left',
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "发货时间",
                prop: "outboundtime",
                minWidth: "180",
                align:'left',
            },
            {
                label: "物流状态",
                prop: "logistics",
                minWidth: "200",
                align:'left',
            },
            
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left',
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 完成
    {
        tabName: "完成",
        count: 0,
        label: "complete",
        activeName: "50",
        height: 550,
        selection: true,
        index:true,
         // table 上面左侧按钮
         uperLeftOperationBtn: [{
            title: "下载面单",
            prop: "download",
        }],
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "发货时间",
                prop: "outboundtime",
                minWidth: "180",
                align:'left'
            },
            {
                label: "物流状态",
                prop: "logistics",
                minWidth: "200",
                align:'left'
            },
            
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },
            {
                label: "自提凭证",
                prop: "selfimages",
                minWidth: '160'
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 已截单
    {
        tabName: "已截单",
        count: 0,
        label: "terminated",
        activeName: "55",
        height: 550,
        selection: true,
        index:true,
         // table 上面右侧按钮
        uperOperationBtn: [
            {
                title: "导出",
                prop: "export",
            },
            {
                title: "导出详情",
                prop: "exportDetail",
            },
            {
                title: "下载面单",
                prop: "download",
            },
        ], 
        tableColumn: [
            {
                label: "订单号",
                prop: "orderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "运单号",
                prop: "trackingno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorderlist",
                minWidth: "200"
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '180',
                align:'left'
            },
            {
                label: "服务",
                prop: "serviceCN",
                minWidth: '120'
            },
            {
                label: "类型",
                prop: "type",
                minWidth: '150',
                align:'left'
            },
            {
                label: "仓库",
                prop: "storehouse",
                minWidth: '150',
                align:'left'
            },
            {
                label: "优先级",
                prop: "priorityCN",
                minWidth: '100',
                align:'center'
            },
            {
                label: "物流状态",
                prop: "logistics",
                minWidth: "200",
                align:'left'
            },
            
            {
                label: "备注",
                prop: "remark",
                minWidth: '150',
                align:'left'
            },

        ],
        tableData: [],
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
])



/**增值服务报表 */
export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "完成时间:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions: pickerOptions()
    },
    {
      label: "增值单号:",
      type: "input",
      value: "",
      prop: "orderno",
      maxlength: 20,
      placeholder: '请输入增值单号'
    },
    {
      label: "关联单号:",
      type: "input",
      value: "",
      prop: "relatedorder",
      maxlength: 30,
      placeholder: '请输入关联单号'
    },
  ]
})

export const tableOption = reactive({
  height: 550,
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "完成时间",
      prop: "completetime",
      minWidth: "150",
      align:'left'
    },
    {
      label: "增值服务订单号",
      prop: "orderno",
      minWidth: "150",
      align:'left'
    },
    {
      label: "关联订单号",
      prop: "relatedorder",
      minWidth: "150",
      align:'left'
    },
    {
      label: "下单时间",
      prop: "createtime",
      minWidth: "150",
      align:'left'
    },
    {
      label: "增值服务费用（人民币）",
      prop: "valueaddfee",
      minWidth: "150",
      align:'right'
    },
  ],
  // 表格数据，必填
  tableData: [],
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  operationBtn: [
    {
      title: "导出",
      prop: "export",
    },
  ],
})






<template>
  <section>
    <!-- 我的订单 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
          @handleRemoteMethods="handleRemoteMethods"
          @handleRemoteSelectFocus="handleRemoteSelectFocus"
          @handleSelectChangeMethods="handleSelectChangeMethods"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row type="flex" class="mt-lg mb-lg">
        <el-button
          type="primary"
          icon="Upload"
          plain
          @click="operateHandle('exportAll')"
          >全部状态导出</el-button
        >
      </el-row>
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane
          v-for="(item, index) in outboundOrderTable"
          :key="index"
          :label="`${item.tabName}（${item.count}）`"
          :name="item.activeName"
        >
          <el-row type="flex" style="margin: 5px 0 20px">
            <el-button
              type="primary"
              plain
              icon="Upload"
              @click="operateHandle('export')"
            >
              导出
            </el-button>
            <el-button
              type="primary"
              plain
              icon="Upload"
              @click="operateHandle('exportDetail')"
            >
              导出详情
            </el-button>
            <template v-if="activeName == -5">
              <el-button
                type="primary"
                plain
                icon="Edit"
                @click="operateHandle('edit')"
              >
                编辑
              </el-button>
              <el-button
                type="danger"
                plain
                icon="Delete"
                @click="operateHandle('delete')"
              >
                删除
              </el-button>
            </template>
            <el-button
              v-if="activeName != -5"
              type="primary"
              plain
              icon="Download"
              @click="operateHandle('download')"
            >
              下载面单
            </el-button>
            <el-button
              v-if="activeName != -5"
              type="primary"
              plain
              icon="DocumentCopy"
              @click="operateHandle('copy')"
            >
              复制
            </el-button>

            <el-button
              v-if="
                activeName == 0 ||
                activeName == 10 ||
                activeName == 20 ||
                activeName == 30
              "
              v-buttonPermission="`${routePath}/Terminated`"
              type="danger"
              plain
              icon="Close"
              @click="operateHandle('terminated')"
            >
              截单
            </el-button>
            <el-button
              v-if="activeName == 40"
              v-buttonPermission="`${routePath}/CheckNewPickCode`"
              type="primary"
              plain
              icon="View"
              @click="operateHandle('queryNewCode')"
            >
              查看最新取件码
            </el-button>
          </el-row>

          <el-table
            :data="item.tableData"
            :header-cell-style="{ background: '#FAFAFA' }"
            :border="true"
            style="width: 100%"
            stripe
            height="400"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              type="index"
              width="60"
              label="序号"
              align="center"
            />
            <el-table-column
              v-for="column in item.tableColumn"
              :key="column.prop"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :align="column.align || 'center'"
            >
              <template #default="{ row }">
                <div
                  v-if="column.prop == 'orderno' || column.prop == 'trackingno'"
                >
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="showDetail(column.prop, row)"
                  >
                    <span class="link">{{
                      column.prop == "orderno" ? row.orderno : row.trackingno
                    }}</span>
                  </el-link>
                </div>

                <div v-else-if="column.prop == 'valueaddorderlist'">
                  <el-link
                    type="primary"
                    v-for="valueaddorderItem in row.valueaddorderlist"
                    :key="valueaddorderItem.id"
                    :underline="false"
                    @click="showDetail('valueadd', valueaddorderItem.id)"
                  >
                    <span class="link">{{ valueaddorderItem.orderno }}</span>
                  </el-link>
                </div>

                <div v-else-if="column.prop == 'selfimages'">
                  <template v-if="row.selfimages">
                    <el-link
                      type="primary"
                      :underline="false"
                      v-for="item in row.selfimages"
                      :key="item.filename"
                      @click="showDetail('file', item.filelink)"
                    >
                      <span class="link">{{ item.filename }}</span>
                    </el-link>
                  </template>
                </div>

                <div v-else>
                  {{ row[column.prop] }}
                </div>
              </template>
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data></com-no-data>
            </template>
          </el-table>
          <el-pagination
            v-if="item.page && item.page.total > 0"
            background
            class="justify-end"
            :current-page="item.page.currentPage"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="item.page.pageSize"
            layout="total,sizes,prev, pager, next, jumper"
            :total="item.page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- orderType="1" 1为入库 2为出库 -->
    <order-detail-dialog
      :orderType="2"
      :id="detailorderid"
      :serviceType="detailorderItem.service"
      :dialogVisible="detailDialog"
      @openDialog="openDialog"
    >
      <template #operationBtn>
        <div class="justify-end" v-if="activeName == '-5'">
          <el-button
            type="primary"
            plain
            icon="Edit"
            @click="operateHandle('edit', detailorderid)"
            >编辑订单</el-button
          >
          <el-button
            type="danger"
            plain
            icon="Delete"
            @click="operateHandle('delete', detailorderid)"
            >删除</el-button
          >
        </div>
      </template>
    </order-detail-dialog>
    <!-- 详情弹框列表 -->
    <el-dialog
      class="dialog-min-width"
      width="70%"
      title="增值服务"
      :close-on-click-modal="false"
      v-model="dialogVisible"
    >
      <add-value
        :addvalueformInitData="serviceForm"
        :sncodeqty="sncodeqty"
        :insuredshow="false"
        :isEdit="false"
        :showOrder="true"
        :boundtype="2"
      >
        <template #closeBtn>
          <el-button @click="dialogVisible = false">关闭</el-button>
        </template>
      </add-value>
    </el-dialog>
  </section>
</template>

<script setup>
import {
  getstoreselectApi,
  getSelectListBySkuOrBarcodeApi,
  getoutboundDeliveryTypeEnumApi,
} from "@/api/common.js";
import {
  outboundlistApi,
  terminatedApi,
  deleteApi,
  outboundGetNewPickCodeApi,
  copyOutboundApi,
  singleExportApi,
  allExportApi,
  singleExportDetailApi,
  downloadApi,
} from "@/api/outbound.js";
import { detailApi } from "@/api/addservers";

import { DOWNLOAD_FILE } from "@/utils/export-file.js";

import { useKeepAliveStore } from "@/stores/keepAliveStore.js";

import { outboundOrderTable } from "./column";

import dayjs from "dayjs";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useTrackingInfo } from "@/stores/tracking.js"; //埋点

defineOptions({
  name: "OutboundList",
});

const searchOption = reactive({
  labelWidth: "80px",
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "orderno",
      maxlength: 100,
      placeholder: "请输入对应单号/参考号",
      selectOption: {
        value: "0",
        prop: "querytype",
        optionData: [
          { value: "0", label: "订单号" },
          { value: "1", label: "运单号" },
          { value: "2", label: "参考号" },
        ],
        clearable: false,
      },
    },
    {
      label: "仓库:",
      type: "select",
      value: "",
      prop: "storehouseid",
      optionData: [],
      clearable: true,
      placeholder: "请选择仓库",
    },
    {
      label: "创建时间:",
      type: "date",
      value: "",
      prop: "daterange",
    },
    {
      label: "",
      type: "select-remote-select",
      value: "",
      prop: "productId",
      maxlength: 50,
      placeholder: "请输入产品条码/SKU",
      clearable: true,
      selectOption: {
        value: 1,
        prop: "productQueryType",
        optionData: [
          { value: 1, label: "产品条码" },
          { value: 0, label: "SKU" },
        ],
      },
    },
    {
      label: "优先级:",
      type: "select",
      value: "",
      prop: "priority",
      placeholder: "请选择优先级",
      clearable: true,
      optionData: [
        { value: "1", label: "优先" },
        { value: "0", label: "普通" },
      ],
    },
    {
      label: "服务:",
      type: "select",
      value: " ",
      prop: "deliveryType",
      placeholder: "请选择服务",
      clearable: false,
      optionData: [{ value: " ", label: "全部" }],
    },
  ],
});
const router = useRouter();
const route = useRoute();
const curpath = route.path; // 获取当前url

const activeName = ref("-5");
const params = ref({
  pageNum: 1,
  pageSize: 10,
  status: "-5",
  starttime: dayjs().add(-2, "month").format("YYYY-MM-DD"),
  endtime: dayjs().format("YYYY-MM-DD"),
});
const detailDialog = ref(false); //  订单详情 弹框状态
const dialogVisible = ref(false); // 增值服务 详情 弹框状态

const serviceForm = ref({}); // 增值服务
const sncodeqty = ref(0); // 增值服务 扫描的个数

const selectionList = ref([]); // 复选框选中的数据列表
const dialogType = ref(1); // 弹框里展示的内容 1增值服务 2订单详情
const detailorderid = ref(""); //当前查看详情的订单id
const detailorderItem = ref({}); //当前查看详情的订单

const dateRange = ref([]);
const lastMonth = ref("");
const nowMonth = ref("");

const keepAliveStore = useKeepAliveStore();

const trackingInfo = useTrackingInfo();
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间
/**
 * 时间筛选 限制在1年内
 */
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
/**
 * barcode/sku 筛选 模糊搜索
 */
const barcodeOrSkuResult = ref([]); // 下拉框产品条码/sku 列表

/**
 * 远程 左侧下拉框 类型type 1: sku, 2:barcode 做选择时，清空右侧框内内容
 * @param {*}
 */
const handleSelectChangeMethods = () => {
  searchOption.searchList.map((item) => {
    if (item.prop === "productId") {
      item.value = "";
    }
  });
};

/**
 * 远程 select 获取焦点 得到类型type
 * @param {*} type  商品搜索类型：0-sku；1-barcode；
 */
const handleRemoteSelectFocus = (type) => {
  getSelectListBySkuOrBarcode(type);
};
/**
 * 获取类型，以及输入的值
 * @param {*} obj => value, type
 */
const handleRemoteMethods = (obj) => {
  setTimeout(() => {
    getSelectListBySkuOrBarcode(obj.type, obj.value);
  }, 200);
};

const getSelectListBySkuOrBarcode = (queryType, barcodeOrSku) => {
  // auditedflg是否审核: 0或null:全查,1:已审核,2,未审核
  getSelectListBySkuOrBarcodeApi({
    auditedflg: 1,
    pageNum: 1,
    queryType, // 商品搜索类型：0-sku；1-barcode；
    barcodeOrSku,
  }).then((res) => {
    barcodeOrSkuResult.value = res.list.map((item) => {
      if (queryType == 1) {
        return { value: item.id, label: item.productbarcode };
      } else {
        return { value: item.id, label: item.sku };
      }
    });
    searchOption.searchList.map((item) => {
      if (item.prop == "productId") {
        item.optionData = barcodeOrSkuResult.value;
      }
    });
  });
};

/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  obj.starttime = dateRange.value[0];
  obj.endtime = dateRange.value[1];
  outboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = 10;
  });
  delete obj.productQueryType;
  params.value = {
    ...obj,
    status: activeName.value,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "orderno") {
      item.selectOption.value = "0";
    }
    if (item.prop == "productId") {
      item.selectOption.value = 1;
    }
  });
  dateRange.value = [lastMonth.value, nowMonth.value];
  params.value = {
    status: activeName.value,
    pageNum: 1,
    pageSize: params.value.pageSize,
    starttime: lastMonth.value,
    endtime: nowMonth.value,
  };
  handleCurrentChange(1);
};

/**
 * 初始化数据
 */
const getList = () => {
  outboundlistApi(params.value).then((res) => {
    outboundOrderTable.map((item) => {
      item.tableData = res.list;
      item.page.total = res.recordTotal;
      switch (item.activeName) {
        case "-5":
          item.count = res.recordtotal_draft;
          break;
        case "0":
          item.count = res.recordtotal_pending;
          break;
        case "10":
          item.count = res.recordtotal_picking;
          break;
        case "20":
          item.count = res.recordtotal_packing;
          break;
        case "30":
          item.count = res.recordtotal_waitoutbound;
          break;
        case "40":
          item.count = res.recordtotal_outbound;
          break;
        case "50":
          item.count = res.recordtotal_complete;
          break;
        case "55":
          item.count = res.recordtotal_terminated;
          break;
      }
    });
  });
};

/**
 * 复选框选择
 */
const handleSelectionChange = (val) => {
  selectionList.value = val;
};

/**
 *  通过 type 获取详情
 */
const showDetail = (type, prop) => {
  switch (type) {
    case "orderno":
      // 通过订单号 选取订单详情
      detailorderid.value = prop.id;
      detailorderItem.value = prop;
      detailDialog.value = true;
      break;
    case "trackingno":
      // 运单号 跳转运单详情页
      router.push({
        path: `/outbound/detail/${prop.id}`,
      });

      //   keepAliveStore.refresCurrentRoute({
      //     path: "/outbound/detail",
      //     query: { id: prop },
      //   });
      break;
    case "valueadd":
      // 增值服务详情
      detailApi({ id: prop }).then((res) => {
        dialogType.value = 1;
        serviceForm.value = res;
        serviceForm.value.filerecordList = res.valueaddorderVo.files?.map((item) => {
          return {
            filename: item.filename,
            filelink: item.filelink,
            url: item.filelink
          };
        });
        sncodeqty.value = res.valueaddorderRequiredBo?.sncodeqty || 0;

        dialogVisible.value = true;
      });
      break;
    case "file":
      // 预览图片
      prop && window.open(prop);
      break;
  }
};
/**
 * 订单详情弹框 状态返回
 * @param {*} val  返回的 状态 即布尔值
 */
const openDialog = (val) => {
  detailDialog.value = val;
};

/**
 * 对table数据进行操作
 *  @param {*} type  操作类型
 *  @param {*} id  操作id
 */
const operateHandle = (type, id) => {
  let _params = JSON.parse(JSON.stringify(params.value));
  const idsArray = selectionList.value.map((item) => item.id);
  let ids = "";
  if (idsArray.length) {
    ids = idsArray.join(",");
  }
  switch (type) {
    case "download":
      if (!ids) {
        return ElMessage.error("请至少选择一条数据!");
      }
      downloadApi({
        ids,
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "export":
      singleExportApi({ ...params.value, ids }).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "exportDetail":
      singleExportDetailApi({ ...params.value, ids }).then((res) => {
        DOWNLOAD_FILE(res);
      });
     
      break;
    case "exportAll":
      delete _params.status;
      allExportApi(_params).then((res) => {
        DOWNLOAD_FILE(res);
      });
      
      break;
    case "terminated":
      if (selectionList.value.length !== 1)
        return ElMessage.error("请选择一条数据!");
      ElMessageBox.confirm("确定要截单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        terminatedApi({ id: selectionList.value[0].id }).then((res) => {
          ElMessage.success("截单成功");
          getList();
        });
      });
      break;
    case "delete":
      if (id) {
        delDraftOrder(id);
      } else if (selectionList.value.length !== 1) {
        return ElMessage.error("请选择一条数据!");
      } else {
        delDraftOrder(selectionList.value[0].id);
      }
      break;
    case "edit":
      if (detailDialog.value) {
        keepAliveStore.refresCurrentRoute({
          path:
            detailorderItem.value.service == "Take a lot"
              ? "/outbound/takealot"
              : "/outbound/create",
          query: {
            id,
          },
        });
      } else {
        if (selectionList.value.length !== 1) {
          return ElMessage.error("请选择一条数据!");
        }
        keepAliveStore.refresCurrentRoute({
          path:
            selectionList.value[0].service == "Take a lot"
              ? "/outbound/takealot"
              : "/outbound/create",
          query: {
            id: selectionList.value[0].id,
          },
        });
      }
      dialogVisible.value = false;
      break;
    case "queryNewCode":
      queryNewCode();
      break;
    case "copy":
      if (selectionList.value.length !== 1) {
        return ElMessage.error("请选择一条数据!");
      } else {
        copyOutboundApi({
          id: selectionList.value[0].id,
        }).then((res) => {
          ElMessage.success("复制成功");
          getList();
        });
      }
      break;
  }
};

/**
 * 查看最新取件码
 */
const queryNewCode = async () => {
  if (selectionList.value.length !== 1) {
    ElMessage.error("请选择一条数据!");
  } else if (selectionList.value[0].service !== "WH Self Collection") {
    ElMessage.error("请选择仓库自提订单");
  } else {
    const data = await outboundGetNewPickCodeApi({
      orderId: selectionList.value[0].id,
    });
    data &&
      ElMessageBox.alert(`${data} (六位取件码)`, {
        confirmButtonText: "确认",
        callback: (action) => {
          if (action === "confirm") {
            console.log("confirm");
          } else {
            console.log("cancel");
          }
        },
      });
  }
};

/**
 * 删除草稿订单
 */
const delDraftOrder = (id) => {
  ElMessageBox.confirm("确认删除出库订单草稿吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  })
    .then(() => {
      deleteApi({ outboundId: id }).then((res) => {
        ElMessage.success("删除成功");
        detailDialog.value = false;
        getList();
      });
    })
    .catch(() => {});
};
/**
 * 主页 tab 切换
 */
const tabClick = (tab) => {
  tracking();
  params.value.pageNum = 1;
  params.value.pageSize = 10;
  params.value.status = tab.paneName;
  activeName.value = tab.paneName;
  accessTime.value = Date.now();
  outboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = 10;
  });
  getList();
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  outboundOrderTable.map((item) => {
    item.page.currentPage = currentPage;
  });
  params.value.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  outboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = pageSize;
  });
  params.value.pageNum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 获取 出库类型-Delivery Type
 */
const getDeliveryType = () => {
  getoutboundDeliveryTypeEnumApi().then((res) => {
    if (res?.length) {
      res.map((item) => {
        item["label"] = item.name;
        item["value"] = item.type;
      });
      const deliveryTypeItem = searchOption.searchList.find(
        (item) => item.prop == "deliveryType",
      );
      deliveryTypeItem.optionData = [{ label: "全部", value: "" }, ...res];
    }
  });
};
/**
 * 获取仓库下拉框
 */
const getstoreselect = () => {
  getstoreselectApi().then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = res.list;
      }
    });
  });
};
/**
 * 获取默认时间段
 */
const getDate = () => {
  lastMonth.value = dayjs().add(-2, "month").format("YYYY-MM-DD"); // 获取2个月前;
  nowMonth.value = dayjs().format("YYYY-MM-DD"); // 当月;
  dateRange.value = [lastMonth.value, nowMonth.value];
};
/**
 * 获取页面路径
 */
const routePath = computed(() => {
  return route.path;
});
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "我的订单",
    tabname: getTabName,
  });
};
const getTabName = computed(() => {
  switch (activeName.value) {
    case "-5":
      return "草稿";
      break;
    case "0":
      return "待处理";
      break;
    case "10":
      return "待拣货";
      break;
    case "20":
      return "待打包";
      break;
    case "30":
      return "待出库";
      break;
    case "40":
      return "已发货";
      break;
    case "50":
      return "完成";
      break;
    case "55":
      return "已截单";
      break;
  }
});
onMounted(() => {
  getDate();
  getstoreselect();
  getDeliveryType();
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>

<style scoped lang="scss"></style>

/** 获取盘点state选项列表*/
import { GET, POST } from '@/utils/request'


/** 1.获取state选项列表*/
export const inventoryStateOptionsApi = () => GET({ url: '/stockCount/getstateselect' })


/** 2.获取adjust inventory列表*/
export const inventoryAdjustOptionsApi = () => GET({ url: '/stockCount/getadjustselect' })


/** 3.获取audit 选择列表*/
export const inventoryAuditOptionsApi = () => GET({ url: '/stockCount/getauditselect' })


/** 获取盘点列表*/
export const inventoryListApi = (data) => POST({ url: '/stockCount/search', data })

/** 获取库存列表*/
export const queryInventoryListApi = (params) => GET({ url: '/Inventory/getInventorylist', params })

/** 库存管理flow列表*/
export const queryFlowListApi = (params) => GET({ url: '/Inventory/getInventoryFlowlist', params })

/** 库存管理 flow type */
export const queryFlowTypeOptionsApi = () => GET({ url: '/Inventory/getInventoryflowTypeEnum' })

/** 新建盘点单--获取盘点单type*/
export const inventoryCreateTypeApi = () => GET({ url: '/Inventorylist/getInventorylistTypeEnum' })

/** 新建盘点单--insert check*/
export const createInventoryInsertCheckApi = (params) => GET({ url: '/Inventorylist/checkbutton', params })

/** 新建盘点单*/
export const createInventoryOrderApi = (data) => POST({ url: '/Inventorylist/create', data, headersType: 'application/json' })

/** 盘点单详情*/
export const inventoryDetailApi = (data) => POST({ url: '/stockCountDetail/detailList', data })

/** 盘点单详情头部信息*/
export const inventoryDetailHeadApi = (id) => POST({ url: '/stockCountDetail/detailHead', params: { id }})


// 4. 盘点详情结果枚举
export const resultEnumApi = () => GET({ url: '/stockCountDetail/getStockCountDetailResultEnum' })
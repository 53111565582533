<template>
  <div class="outbound-detail">
    <!-- 基础信息 -->
    <el-row class="margin-16">
      <el-col :span="24">
        <el-card id="messageInfo" shadow="never">
          <h2 class="margin-16">基本信息</h2>
          <el-row>
            <el-col :span="24">
              <el-descriptions
                :column="4"
                direction="vertical"
                class="descriptions"
              >
                <el-descriptions-item label="订单号">{{
                  baseinfo.ordernumber || "-"
                }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{
                  baseinfo.createtime || "-"
                }}</el-descriptions-item>
                <!-- <el-descriptions-item label="Inner Area">{{
                  baseinfo.innerarea || "-"
                }}</el-descriptions-item> -->
                <el-descriptions-item label="订单状态">
                  {{ baseinfo.status || "-" }}</el-descriptions-item
                >
                <el-descriptions-item label="客户单号"
                  >{{ baseinfo.reference }}
                </el-descriptions-item>
                <el-descriptions-item label="最新更新时间">
                  {{ baseinfo.updatetime }}</el-descriptions-item
                >
                <el-descriptions-item label="服务"
                  >{{ baseinfo.selfcollect }}
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 地址信息 -->
    <el-row :gutter="16" class="margin-16">
      <el-col :span="12">
        <el-card id="receiverInfo" shadow="never">
          <h2 class="margin-16">收件人信息</h2>
          <el-form
            ref="formRef"
            :model="form"
            label-position="left"
            label-width="110px"
            class="form-section"
          >
            <el-form-item label="公司：">
              <div>{{ receiver.receivecompany || "-" }}</div>
            </el-form-item>
            <el-form-item label="收件人：">
              <div>{{ receiver.receiver || "-" }}</div>
            </el-form-item>
            <el-form-item label="电话号：">
              <div>{{ receiver.receivetelephone || "-" }}</div>
            </el-form-item>
            <el-form-item label="地址：">
              <div>{{ receiver.receiveaddress || "-" }}</div>
            </el-form-item>
            <el-form-item label="城市/镇：">
              <div>{{ receiver.receivecity || "-" }}</div>
            </el-form-item>
            <!-- 下拉框 -->
            <el-form-item label="邮编/Suburb：">
              <div>{{ receiver.receivepostcode || "-" }}</div>
            </el-form-item>
            <!-- 下拉框 -->
            <el-form-item label="邮箱：">
              <div>{{ receiver.receiveemail || "-" }}</div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card id="sendInfo" shadow="never">
          <h2 class="margin-16">发件人信息</h2>
          <el-form
            ref="formRef"
            :model="form"
            label-position="left"
            label-width="70px"
            class="form-section"
          >
            <el-form-item label="公司：">
              <div>{{ sender.receivecompany || "-" }}</div>
            </el-form-item>
            <el-form-item label="发件人：">
              <div>{{ sender.receiver || "-" }}</div>
            </el-form-item>
            <el-form-item label="邮编：">
              <div>{{ sender.receivepostcode || "-" }}</div>
            </el-form-item>
            <el-form-item label="Suburb：">
              <div>{{ sender.receivesuburb || "-" }}</div>
            </el-form-item>
            <el-form-item label="电话：">
              <div>{{ sender.receivetelephone || "-" }}</div>
            </el-form-item>
            <el-form-item label="地址：">
              <div>{{ sender.receiveaddress || "-" }}</div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- Detail information -->
    <el-row class="margin-16">
      <el-col :span="24">
        <el-card id="Detail" shadow="never">
          <h2 class="margin-16">详细信息</h2>
          <div>
            <el-table
              :header-cell-style="{
                'text-align': 'center',
                background: '#FAFAFA',
              }"
              :cell-style="{ 'text-align': 'center' }"
              :data="tableData_new"
              border
              style="width: 100%"
            >
              <el-table-column prop="boxnumber" label="箱号" />
              <el-table-column prop="estweight" label="预估重量（KG）" />
              <el-table-column prop="weight" label="实际重量（KG）" />
              <el-table-column prop="boxnumber" label="体积（cm3）">
                <template #default="scope">
                  {{ scope.row.length }}*{{ scope.row.width }}*{{
                    scope.row.height
                  }}
                </template>
              </el-table-column>
              <!-- 空数据 展示 -->
              <template #empty>
                <com-no-data :smallImg="true"></com-no-data>
              </template>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- Tracking information -->
    <el-row class="margin-16 last-section">
      <el-col :span="24">
        <el-card id="Tracking" shadow="never">
          <h2 class="margin-16">轨迹信息</h2>
          <el-steps direction="vertical" class="tracking-steps" :active="1">
            <el-step
              v-for="item in recordMap"
              :key="item.id"
              :description="item.content"
              :title="item.displaytime"
              :icon="item.icon"
            />
          </el-steps>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { expressdetailApi } from "@/api/outbound.js";
import { Van, DocumentChecked, Document } from "@element-plus/icons-vue";
import { shallowRef } from "vue";
export default {
  layout: "cmsTemplate",
  name: "OutboundDetail",
  data() {
    return {
      form: {},
      textarea: "",
      orderId: "",
      baseinfo: {}, ///基础信息
      detailinfo: {},
      sender: {},
      receiver: {}, ///问题信息
      recordMap: [], ///轨迹信息
      tableData_new: [],
    };
  },
  mounted() {
    this.orderId = this.$route.params.id;

    this.getinfo();
  },
  methods: {
    //获取基础信息
    getinfo() {
      expressdetailApi(this.orderId).then((res) => {
        this.baseinfo = res.baseinfo;
        this.receiver = res.receiver;
        this.sender = res.sender;
        this.recordMap = res.tracking.tracking;
        this.tableData_new = res.detailinfo.detail;
        if (this.recordMap?.length > 0) {
          this.recordMap.map((item) => {
            item.icon = shallowRef(Van);
          });
          this.recordMap[this.recordMap.length - 1].icon = shallowRef(Document);
          this.recordMap[0].icon = shallowRef(DocumentChecked);
        }
      });
    },
    //处理详情数据
    transformData(data) {
      const transformedData = [];

      data.forEach((item) => {
        const newItem = { ...item };
        delete newItem.detaillist;
        // console.log('newItem', newItem);

        if (item.detaillist && item.detaillist.length > 0) {
          transformedData.push({
            width: newItem.width,
            height: newItem.height,
            length: newItem.length,
            boxnumber: newItem.boxnumber,
            weight: newItem.weight,
            finalweight: newItem.finalweight,
            volumweight: newItem.volumweight,
            // boxnumber: newItem.boxnumber,
            // code: detail.code,
            categorycn: item.detaillist[0].categorycn,
            categoryen: item.detaillist[0].categoryen,
            hscode: item.detaillist[0].hscode,
            number: item.detaillist[0].number,
            declaredvalue: item.detaillist[0].declaredvalue,
            edit: false,
            id: item.detaillist[0].id,
          });
        }
        if (item.detaillist.length > 1) {
          for (let i = 1; i < item.detaillist.length; i++) {
            transformedData.push({
              categorycn: item.detaillist[i].categorycn,
              categoryen: item.detaillist[i].categoryen,
              hscode: item.detaillist[i].hscode,
              number: item.detaillist[i].number,
              declaredvalue: item.detaillist[i].declaredvalue,
              edit: false,
              id: item.detaillist[i].id,
            });
          }
        }

        // transformedData.push(newItem)
      });
      // console.log('transformedData', transformedData);
      return transformedData;
    },
  },
};
</script>
<style lang="scss" scoped>
.outbound-detail {
  font-weight: normal;
  .card-wrap :deep(.el-card__body) {
    padding-bottom: 8px;
  }
  :deep(.el-descriptions .el-descriptions__cell) {
    width: 25%;
  }
  :deep(.el-descriptions .el-descriptions__label) {
    font-weight: 400;
    color: #636363;
  }
  :deep(.el-descriptions .el-descriptions__content) {
    font-weight: 600;
    color: #1f1f20;
  }
  .form-section {
    :deep(.el-form-item__label) {
      font-weight: 400;
      font-size: 14px;
      color: #1f1f20;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    color: #1f1f20;
    line-height: 28px;
  }
  .width100 {
    width: 100%;
  }

  .margin-16 {
    margin-bottom: 16px;
  }

  #receiverInfo,
  #sendInfo {
    height: 100%;
  }

  .tracking-steps {
    :deep(.el-step.is-vertical .el-step__title) {
      font-weight: bold;
    }
    :deep(.el-step.is-vertical .el-step__main) {
      padding-bottom: 24px;
      padding-left: 16px;
    }
    :deep(.el-step__description) {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      color: #8f8f8f;
    }
    :deep(.el-step__icon) {
      width: 24px;
      height: 24px;
      opacity: 1;
      background: #c5c5c5;
      border-radius: 12px;
    }
    :deep(.el-step__icon-inner[class*="el-icon"]:not(.is-status)) {
      font-size: 14px;
      font-weight: normal;
      color: #fff;
    }
    :deep(.el-step__head.is-finish .el-step__icon) {
      background: #bc8c4d;
    }
  }
}
</style>


import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/


// 库存管理列表
export const getInventorylistApi = (params) => GET({ url: '/ClientInventory/getInventorylist', params })


// 库存管理详情
export const getInventoryDetaillistApi = (params) => GET({ url: '/ClientInventory/getInventoryDetaillist', params })



// 获取详情下拉框
export const getShelforZoneorLocationlistApi = (params) => GET({ url: '/ClientInventory/getShelforZoneorLocationlist', params })

// 导出
export const exportInventoryApi = (params) => EXPORT_FILE_GET({ url: '/ClientInventory/exportInventory', params })

// 导出详情
export const exportInventoryDetailApi = (params) => EXPORT_FILE_GET({ url: '/ClientInventory/exportInventoryDetail', params })

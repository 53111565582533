/**子账户管理 */
import { reactive } from "vue"
export const searchOption = reactive({
    // 顶部表单检索内容,非必填
    // singleRow: true,
    searchList: [
      // 检索列表
      {
        label: "用户名:",
        type: "input",
        value: "",
        prop: "username",
        maxlength: 50,
        placeholder: '请输入用户名'
      },
      {
        label: "角色:",
        type: "autocomplete",
        value: "",
        prop: "role",
        maxlength: 50,
        placeholder: '请选择角色',
        clearable: true
      },
      {
        label: "可用/禁用:",
        type: "select",
        value: "0",
        prop: "enabledisable",
        optionData: [{label: '全部', value:"0"}, {label: '可用', value:"401"}, {label: '禁用', value:"405"}],
        clearable: false,
      },
    ],
  })

  export const tableOption = reactive({
    height: 550,
    selection: true,
    index: true, // 是否展示序号 非必填
    tableColumn: [
      {
        label: "用户名",
        prop: "name",
        minWidth: "140",
      },
      {
        label: "邮箱",
        prop: "email",
        minWidth: "140",
        align:'left'
      },
      {
        label: "角色",
        prop: "rolestring",
        minWidth: "140",
        align:'left'
      },
      {
        label: "状态",
        prop: "productstatus",
        minWidth: "50",
        align:"center"
      },
    ],
  
    // 表格数据，必填
    tableData: [],
    operationColumn: {
      align:'left',
      fixed: "right",  // 是否fixed
      minWidth: "60", // 最小宽度
      btns: [
        {
          title: "重置密码",
          prop: "resetPwd",
          type: "primary",
        },
        {
          title: "编辑",
          prop: "edit",
          type: "primary",
        },
      ],
    },
    page: {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    },
    operationBtn: [
      {
        title: "创建用户",
        prop: "createUser",
      },
    ],
  })
export const rules = reactive([{
    label: "仓库自提",
    type: "input",
    value: "",
    prop: "productbarcode",
    maxlength: 50,
    placeholder: '请输入产品条码',
    rules: [
      { required: true, message: " 请填写此选项!", trigger: "blur" },
      {
        pattern: /^[^\u4e00-\u9fa5（）【】，。？！￥、……——“”：；‘~·]+$/g,
        message: "禁止输入中文及中文符号!",
        trigger: ["blur", "change"],
      }
    ] 
}])

export const tableOption = reactive({
  height: 320,
  tableColumn: [
    {
      label: "SKU",
      prop: "sku",
      minWidth: "200",
      align:'left'
    },
    {
      label: "中文品名",
      prop: "chinesename",
      minWidth: "200",
      align:'left'
    },
    {
      label: "英文品名",
      prop: "englishname",
      minWidth: "200",
      align:'left'
    },
    {
      label: "数量",
      prop: "quantity",
      minWidth: "100",
      align:'center'
    },
    {
      label: "单位",
      prop: "unit",
      minWidth: "100",
      align:'center'
    },
    {
      label: "操作",
      prop: "operation",
      width: "100",
      align:"center"
    },
  ],
  // 表格数据，必填
})

export const addressTableOption = reactive({
  height: 350,
  tableColumn: [
    {
      label: "姓名",
      prop: "receiver",
      minWidth: "160",
    },
    {
      label: "公司名称",
      prop: "receivecompany",
      minWidth: "180",
    },
    {
      label: "电话",
      prop: "receivetelephone",
      minWidth: "120",
    },
    {
      label: "地址",
      prop: "receiveaddress",
      minWidth: "200",
    },
  ],
  // 表格数据，必填
})
/**仓储计费报表 */
export const searchOption = reactive({
    // 顶部表单检索内容,非必填
    labelWidth: "80px",
    // singleRow: true,
    searchList: [
        // 检索列表
        {
            label: "SKU:",
            type: "input",
            value: "",
            prop: "sku",
            maxlength: 50,
            placeholder: '请输入SKU'
        },
        {
            label: "类型:",
            type: "select",
            value: 0,
            prop: "type",
            optionData: [{ label: "All", value: 0 }],
            clearable: false
        },
        {
            label: "日期:",
            type: "date",
            // dateType: "daterange",
            value: "",
            prop: "daterange",
            // clearable: false,
            // pickerOptions: pickerOptions()
        },
        {
            label: "仓库:",
            type: "select",
            value: "0",
            prop: "storehouseid",
            optionData: [{ label: "All", value: '0' }],
            clearable: false
        },
        {
            label: "单据号:",
            type: "input",
            value: "",
            prop: "order",
            maxlength: 20,
            placeholder: '请输入单据号'
        },
        {
            label: "关联单据:",
            type: "input",
            value: "",
            prop: "relatedorder",
            maxlength: 30,
            placeholder: '请输入关联单据'
        },
    ],
})
export const tableOption = reactive({
    height: 500,
    index: true, // 是否展示序号 非必填
    // 表格数据，必填
    tableData: [],
    page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
    operationBtn: [
        {
            title: "导出",
            prop: "export",
        },
    ],
})
export const tableColumn =reactive([
    {
        label: "类型",
        prop: "type",
        minWidth: "100",
    },
    {
        label: "单据号",
        prop: "orderno",
        minWidth: "200",
        align: 'left'
    },
    {
        label: "SKU",
        prop: "sku",
        minWidth: "150",
        align: 'left'
    },
    {
        label: "批次号",
        prop: "batchno",
        minWidth: "200",
        align: 'left'
    },
    {
        label: "数量",
        prop: "qty",
        minWidth: "100",
    },
    {
        label: "仓库",
        prop: "warehouse",
        minWidth: "160",
    },
    {
        label: "库区",
        prop: "zone",
        minWidth: "100",
    },
    {
        label: "货架",
        prop: "shelf",
        minWidth: "100",
    },
    {
        label: "货位",
        prop: "location",
        minWidth: "100",
    },
    {
        label: "操作日期",
        prop: "date",
        minWidth: "200",
        align: 'left'
    },
    {
        label: "货位剩余库存",
        prop: "locationsurplus",
        minWidth: "160",
    },
    {
        label: "仓库剩余库存",
        prop: "whsurplus",
        minWidth: "160",
    },
    {
        label: "关联单据",
        prop: "relatedorder",
        minWidth: "200",
        align: 'left'
    },
    {
        label: "备注",
        prop: "remark",
        minWidth: "250",
    },
]
)






<template>
  <section>
    <!-- 充值记录查询 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row style="margin-top: 20px">
        <el-col :span="24" class="basic-background price-item">
          <p>累计充值</p>
          <p class="label-price">
            <span class="lable">¥ </span>
            <span class="price">{{ totalamount }}</span>
          </p>
        </el-col>
      </el-row>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnClick('export')"
          >导出</el-button
        >
        <el-button
          type="danger"
          plain
          icon="Close"
          @click="operationBtnClick('cancel')"
          >作废</el-button
        >
      </el-row>
      <el-table
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="400"
        :header-cell-style="{ background: '#FAFAFA' }"
        :data="tableOption.tableData"
        :border="true"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
          :fixed="column.fixed"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'filelink'">
              <el-link
                :underline="false"
                type="primary"
                @click="viewFile(row.filelink)"
              >
                <span class="link">查看凭证</span>
              </el-link>
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </section>
</template>
    
<script setup>
import { tableOption } from "./column";
import {
  rechargeListApi,
  destroyApi,
  auditStatusEnumListApi,
  rechargeTypeEnumListApi,
  exportApi
} from "@/api/management/recharge";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { onMounted } from "vue";
import dayjs from "dayjs";
import { ElMessage, ElMessageBox } from "element-plus";
defineOptions({
  name: "ManagementRechargeRecord",
});

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "80px",
  searchList: [
    // 检索列表
    {
      label: "提交时间:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions:pickerOptions()
    },
    {
      label: "充值方式:",
      type: "select",
      value: "-1",
      prop: "rechargetype",
      optionData: [{label: '全部', value: '-1'}],
    },
    {
      label: "流水号:",
      type: "input",
      value: "",
      prop: "flowno",
      maxlength: 30,
      placeholder: '请输入流水号'
    },
    {
      label: "操作账户:",
      type: "input",
      value: "",
      prop: "operate",
      maxlength: 30,
      placeholder: '请输入操作账户'
    },
    {
      label: "审核状态:",
      type: "select",
      value: -1,
      prop: "auditstatus",
      optionData: [{label: '全部', value: -1}],
    },
  ],
})
const params = ref({
  pagenum: 1, // 请求 某页 数据
  pageSize: 10,
  auditstatus: -1, // 审核状态
});
const totalamount = ref(0); // 累计充值金额

let selectionList = reactive([]); // 复选框选中list
const lastMonth = ref(""); // 3月前时间
const nowMonth = ref(""); // 当前时间
/**
 * 时间筛选 限制在1年内
 */
const choiceDate = ref("");
const dateRange = ref([]); // 选择的时间范围
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};

/**
 * 获取数据列表
 */
const getList = () => {
  rechargeListApi(params.value).then((res) => {
    totalamount.value = res.totalamount;
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  console.log(obj);
  if (obj.rechargetype == "-1") {
    delete obj.rechargetype;
  }
  obj.startdate = dateRange.value[0];
  obj.enddate = dateRange.value[1];
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = 10;
  params.value = {
    ...obj,
    pagenum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "auditstatus") {
      item.value = -1;
    }
    if (item.prop == "rechargetype") {
      item.value = "-1";
    }
  });
  dateRange.value = [lastMonth.value, nowMonth.value];
  params.value = {
    pageNum: 1,
    pageSize: params.value.pageSize,
    auditstatus: -1,
    startdate: lastMonth.value,
    enddate: nowMonth.value
  };
  handleCurrentChange(1);
};
/**
 * 表格上方的操作按钮(这里只有导出按钮)
 * @param {*} prop 导出
 */
const operationBtnClick = (prop) => {
  switch (prop) {
    case "export":
    exportApi(params.value).then(res => {
      DOWNLOAD_FILE(res)
    })
      break;
    case "cancel":
      if (selectionList.length <= 0)
        return ElMessage.error("请选择要作废的充值订单!");
      const auditList = selectionList.filter(
        (item) => item.auditstatus !== "未审核"
      );
      if (auditList.length > 0) {
        return ElMessage.error("存在无法作废的订单，请重新选择!");
      }
      const idsArray = selectionList.map((item) => item.id);
      const ids = idsArray.join(",");
      ElMessageBox.confirm("是否作废选中的订单？", {
        showClose: false,
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          console.log("000");
          destroyApi({ ids }).then((res) => {
            getList();
          });
        })
        .catch(() => {
          console.log("cancel");
        });
      break;
  }
};

/**
 * 查看凭证
 */
const viewFile = (filelink) => {
  if (filelink) {
    window.open(filelink, "_blank");
  } else {
    return ElMessage.error("未上传凭证!");
  }
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pagenum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pagenum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 复选框 选中列表
 */
const handleSelectionChange = (val) => {
  selectionList = val;
};

/**
 * 审核状态下拉
 */
const getAuditStatusList = () => {
  auditStatusEnumListApi().then((res) => {
    res.map((item) => {
      item["label"] = item.name;
      item["value"] = item.type;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "auditstatus") {
        item.optionData = [{ label: "全部", value: -1 }, ...res];
      }
    });
  });
};
/**
 * 充值类型下拉
 */
const getRechargeTypeEnumList = () => {
  rechargeTypeEnumListApi().then((res) => {
    res.map((item) => {
      item["label"] = item.name;
      item["value"] = item.type;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "rechargetype") {
        item.optionData = [{ label: "全部", value: "-1" }, ...res];
      }
    });
  });
};
/**
 * 获取默认时间段
 */
const getDate = () => {
  const last = dayjs().add(-3, "month").format("YYYY-MM-DD"); // 获取3月前
  const now = dayjs().format("YYYY-MM-DD"); // 当天
  lastMonth.value = last;
  nowMonth.value = now;
  params.value.startdate = last;
  params.value.enddate = now;
  dateRange.value = [last, now];
};

onMounted(() => {
  getDate(); // 获取默认时间
  getAuditStatusList(); // 审核状态下拉
  getRechargeTypeEnumList(); // 充值类型下拉
  getList();
});
</script>
<style lang="scss" scoped>
.price-item {
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #636363;
  .label-price {
    font-weight: 700;
    color: #1f1f20;
    margin-top: 12px;
  }
  .price {
    font-size: 28px;
  }
  .label {
    font-size: 16px;
  }
}
</style>
    
    
<template>
  <el-dialog
    class="products-detail-dialog"
    :title="
      detailType == 'create'
        ? '新建商品'
        : detailType == 'view'
          ? '查看商品'
          : '编辑商品'
    "
    width="80%"
    v-model="detailDialog"
    :close-on-click-modal="false"
    :before-close="closeDialog"
  >
    <el-form
      ref="detailRef"
      label-width="140px"
      label-position="right"
      class="products-detail"
      :model="detailInfo"
      :disabled="detailType == 'view'"
    >
      <template v-if="detailType == 'create'">
        <el-form-item
          v-for="(item, index) in createrules"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :class="item.class"
          :rules="item.rules"
        >
          <template v-if="item.type == 'input'">
            <el-input
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              :placeholder="item.placeholder"
              :clearable="item.clearable"
              :maxlength="item.maxlength"
              :disabled="item.disabled"
            ></el-input>
          </template>
          <template v-else-if="item.type === 'autocomplete'">
            <el-autocomplete
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              :fetch-suggestions="queryAutoComplete"
              :placeholder="item.placeholder"
              :disabled="item.disabled"
              @select="selectAutoComplete"
              @focus="inputAutoCompleteFocus(item.prop)"
            />
          </template>
          <template v-else-if="item.type === 'select'">
            <el-select
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              fit-input-width
              :placeholder="item.placeholder"
              :disabled="item.disabled"
              :multiple="item.multiple"
            >
              <el-option
                v-for="(element, i) in item.options"
                :label="element.label"
                :value="element.value"
                :key="i"
              />
            </el-select>
          </template>
          <template v-else-if="item.type === 'remote-select'">
            <el-select
              v-model="detailInfo[`${item.prop}`]"
              multiple
              filterable
              remote
              reserve-keyword
              remote-show-suffix
              fit-input-width
              :placeholder="item.placeholder"
              :remote-method="
                (value) => handleRemoteSelectMethods(value, item.prop)
              "
              style="width: 100%"
            >
              <el-option
                v-for="(element, i) in item.options"
                :label="element.label"
                :value="element.value"
                :key="i"
              />
            </el-select>
          </template>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item
          v-for="(item, index) in rules"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :class="item.class"
          :rules="item.rules"
        >
          <template v-if="item.type == 'input'">
            <el-input
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              :placeholder="item.placeholder"
              :clearable="item.clearable"
              :maxlength="item.maxlength"
              :disabled="item.disabled"
            ></el-input>
          </template>

          <template v-else-if="item.type === 'autocomplete'">
            <el-autocomplete
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              :fetch-suggestions="queryAutoComplete"
              :placeholder="item.placeholder"
              :disabled="item.disabled"
              @select="selectAutoComplete"
              @focus="inputAutoCompleteFocus(item.prop)"
            />
          </template>

          <template v-if="item.type === 'select'">
            <el-select
              v-model="detailInfo[`${item.prop}`]"
              style="width: 100%"
              fit-input-width
              :placeholder="item.placeholder"
              :disabled="item.disabled"
              :multiple="item.multiple"
            >
              <el-option
                v-for="(element, i) in item.options"
                :label="element.label"
                :value="`${element.value}`"
                :key="i"
              />
            </el-select>
          </template>
        </el-form-item>
      </template>
      <el-row class="image-content">
        <el-col :span="24">
          <el-form-item
            prop="productimgurl"
            label="产品图片:"
          >
            <el-upload
              ref="uploadRef"
              accept=".jpg,.jpeg,.png"
              list-type="picture-card"
              :class="[
                'upload-file',
                'flex',
                'flex-col',
                { 'upload-none': uploadHidden },
              ]"
              :disabled="detailType == 'view'"
              :action="uploadAction"
              :headers="uploadHeaders"
              :limit="1"
              :file-list="fileList"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-upload="beforeUpload"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
            >
              <el-icon><Plus /></el-icon>
              <template #tip class="el-upload__tip"> 不超过5MB </template>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <template v-if="detailType == 'view'">
        <el-button class="btn" @click="closeDialog">关闭 </el-button>
      </template>
      <template v-else>
        <el-button class="btn" @click="closeDialog"> 取消 </el-button>
        <el-button class="btn" type="primary" @click="confirmInfo(detailRef)">
          提交
        </el-button>
      </template>
    </template>
  </el-dialog>

  <el-dialog
    :close-on-click-modal="false"
    title="提示"
    v-model="remindDialog"
    width="50%"
  >
    <div class="order-list">
      <span class="remind"
        >{{ orderList.substring(0, 256)
        }}{{
          orderList.length > 256 ? "..." : ""
        }}故无法修改。请先处理订单。</span
      >
    </div>
    <template #footer>
      <el-button type="primary" @click="remindDialog = false">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { rules, createrules } from "./rules";
import { originselectApi, unitListApi } from "@/api/common.js";
import {
  addproductApi,
  updateproductApi,
  getByIdApi,
  listLevelselectApi,
} from "@/api/product.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, watch } from "vue";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
defineOptions({
  name: "productsDetail",
});

const props = defineProps({
  detailType: {
    type: String, // 字段详情类型 create: 创建 edit: 编辑 view: 查看
    default: () => {
      return "create";
    },
  },
  id: [String, Number], // 行id
  dialogVisible: Boolean,
});

const emits = defineEmits(["openDialog", "freshData"]);
// const isLoading = ref(false)
const detailRef = ref();
const detailDialog = ref(false); // 弹框 状态

const detailInfo = ref({
  id: "", // 产品id
  productbarcode: "", // 产品条码
  sku: "", // SKU
  chinesename: "", // 中文名
  englishname: "", // 英文名
  description: "", // 具体描述
  onelevelid: "", // 产品类型(一级)id
  twolevelid: "", // 产品类型(二级)id
  threelevelid: "", // 产品类型(三级)id
  productunit: "", // 产品单位
  hscode: "", // HSCode
  countryoforigin: "", // 原产地
  declaredvalue: "", // 申报价值(USD)
  productstatus: "", // 产品状态
  brand: "", // 品牌
  earlywarningnumber: "", // 库存最低预警
  withmagnetictapeflg: "", // 带磁带电
  fillweight: "", // 填报重量[kg]
  filllength: "", // 填报长度[cm]
  fillwidth: "", // 填报宽度[cm]
  fillheight: "", // 填报高度[cm]
  // 编辑 / 查看时 才展示 质检字段
  inspectweight: "", // 质检重量[kg]
  inspectlength: "", // 质检长度[cm]
  inspectwidth: "", // 质检宽度[cm]
  inspectheight: "", // 质检高度[cm]
  productimgurl: "", //
});

const acceptFileType = ref(['image/png', 'image/jpg', 'image/jpeg'])
// 图片相关
const uploadRef = ref();
const fileList = ref([]); // 上传的图片列表
const uploadHidden = ref(false); // 是否隐藏上传 按钮
const timer = ref(null);

const remindDialog = ref(false); // 编辑 提交 后端提示报错信息状态
const orderList = ref(""); // 编辑 提交 后端提示报错信息 内容

watch([() => props.id, () => props.dialogVisible], (newVal, oldVal) => {
  // const idNewVal = newVal[0];
  const dialogVisibleNewVal = newVal[1];
  // const idOldVal = oldVal[0];
  // if (props.detailType == "edit") {
  //   rules.map((item) => {
  //     // item.prop == "productbarcode" ||
  //     if (item.prop == "sku") {
  //       item.disabled = true;
  //     }
  //   });
  // }
  if (props.id == 0 || props.detailType == "create") {
    // 创建商品
    detailInfo.value = {};
    // imageUrl.value = "";
    detailDialog.value = dialogVisibleNewVal;
    return false;
  } else if (!dialogVisibleNewVal) {
    // 若点击 关闭弹框  则只进行关闭 不进行 其他操作
    detailDialog.value = dialogVisibleNewVal;
  } else {
    // 请求数据
    getDetail(props.id);
  }
});

/**
 * 获取 产品详情
 * @param {*} id  产品id
 */
const getDetail = (id) => {
  getByIdApi(id).then((res) => {
    detailInfo.value = res;
    detailInfo.value.productstatus = "" + res.productstatus;
    detailInfo.value.withmagnetictapeflg = res.withmagnetictapeflg + "";
    detailInfo.value.productunit = "" + res.productunit;
    detailInfo.value.countryoforigin = "" + res.countryoforigin;
    detailInfo.value.declaredvalue = res.declaredvalue / 100;
    // imageUrl.value = res.productimgurl;
    if (res.productimgurl) {
      uploadHidden.value = true;
      fileList.value = [
        {
          url: res.productimgurl,
        },
      ];
    }
    rules.map((item) => {
      if (item.prop == "productbarcode") {
        // 大于0代表是供应商的,  productbarcode不可编辑
        item.disabled = res.supplieruserid > 0 ? true : false;
      }
    });
    detailDialog.value = true;
  });
};
/**
 * 关闭弹窗
 */
const closeDialog = () => {
  if (props.id == 0 || props.detailType == "create") {
    detailRef.value?.resetFields(); // 重置该表单项
  }
  handleRemove();
  detailDialog.value = false;
  emits("openDialog", false);
};
/**
 *  产品确认 提交
 */
const confirmInfo = async (detailRef) => {
  if (!detailRef) return;
  let api;
  if (props.id) {
    api = updateproductApi;
  } else {
    api = addproductApi;
  }
  await detailRef.validate((valid) => {
    if (valid) {
      let _detailInfo = { ...detailInfo.value };
      _detailInfo.declaredvalue = (
        detailInfo.value.declaredvalue * 100
      ).toFixed(0);
      // _detailInfo.productimgurl = imageUrl.value;
      api(_detailInfo).then((res) => {
        if (res) {
          orderList.value = res;
          remindDialog.value = true;
          return false;
        }
        props.id
          ? ElMessage.success("编辑商品成功!")
          : ElMessage.success("创建商品成功!");
        emits("freshData", true);
        closeDialog();
      });
    } else {
      return false;
    }
  });
};
/**
 * 以下为  产品类型 模糊查询
 * @param {*} value
 * @param {*} cb
 */
const levelResult = ref([]); // 搜索返回的 产品类型 列表

const level = ref(0); // 当前 操作的是 输入框 ， 1:一级产品类型 2：二级产品类型 3：三级产品类型

const parentid = ref(0); // 产品类型 父级id

const queryAutoComplete = (value, cb) => {
  getLevelList(level.value, parentid.value, value);
  setTimeout((query) => {
    cb(levelResult.value);
  }, 1000);
};

/**
 * 选中下拉框中 数据
 * @param {*} value
 */

const selectAutoComplete = (item) => {
  if (level.value == 1) {
    detailInfo.value.onelevelid = item.id;
    detailInfo.value.twolevelname = "";
    detailInfo.value.threelevelname = "";
    detailInfo.value.twolevelid = "";
    detailInfo.value.threelevelid = "";
  } else if (level.value == 2) {
    detailInfo.value.twolevelid = item.id;
    detailInfo.value.threelevelname = "";
    detailInfo.value.threelevelid = "";
  } else {
    detailInfo.value.threelevelid = item.id;
    detailInfo.value.hscode = item.hsCode;
  }
};
/**
 * 输入框 获取 焦点 时, 获取 操作对象
 * @param {*} prop
 */
const inputAutoCompleteFocus = (prop) => {
  level.value = prop == "onelevelname" ? 1 : prop == "twolevelname" ? 2 : 3;
  parentid.value =
    prop == "onelevelname"
      ? 0
      : prop == "twolevelname"
        ? detailInfo.value.onelevelid
        : detailInfo.value.twolevelid;
};
/**
 * 远程 select下拉框
 * @param {*} value 输入的值
 * @param {*} prop 操作的对象
 */
const handleRemoteSelectMethods = (value, prop) => {
  console.log(value, prop);
};

const getLevelList = (level, parentid, name) => {
  listLevelselectApi({
    level,
    parentid,
    name,
  })
    .then((res) => {
      levelResult.value = res.map((item) => {
        return {
          value: item.chinesename,
          label: item.id,
          id: item.id,
          hsCode: item.hsCode,
        };
      });
    })
    .catch((err) => {
      ElMessage.error(err.message);
    });
};
/**
 * 改版
 * 图片上传
 */
const uploadAction = computed(() => {
  return import.meta.env.VITE_BASE_URL + "/product/index/uploadFile";
});

const uploadHeaders = computed(() => {
  const headers = {
    "Buffalo-ticket": getToken(),
  };
  return headers;
});
const productimgurlRule = computed(() => {
  const validateFileUrl = (rule, value, callback) => {
    console.log("validateFileUrl====");
    if (!detailInfo.value.productimgurl) {
      callback(new Error("请上传该选项!"));
    } else {
      callback();
    }
  };
  return [
    {
      required: true,
      validator: validateFileUrl,
      trigger: ["blur", "change"],
    },
  ];
});

// 上传文件新接口
const beforeUpload = (file) => {
  
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    ElMessage.error("上传图片不能超过5MB");
    return false
  }
  if(!acceptFileType.value.includes(file.type)){
    ElMessage.error("上传图片类型为PNG,JPG,JPEG");
    return false
  }
  uploadHidden.value = true;
  timer.value = setTimeout(() => {
    uploadRef.value.clearFiles();
    uploadRef.value.abort();
    ElMessage.error("上传超时");
    uploadHidden.value = false;
  }, 30000);
};
const handleSuccess = (response, file, fileList) => {
  clearTimeout(timer.value);
  if (response.status !== "00000") {
    uploadRef.value.clearFiles();
    uploadHidden.value = false;
    if (response.code === 3000012) {
      removeToken();
      router.replace("/login");
    }
    return ElMessage.error(response.message);
  } else {
    detailInfo.value.productimgurl = response.data.url;
    detailRef.value.clearValidate(["productimgurl"]);
    // ElMessage.success('Upload Successfully')
  }
};
const handleError = () => {
  uploadHidden.value = false;
  clearTimeout(timer.value);
  return ElMessage.error("上传失败");
};
const handleRemove = () => {
  fileList.value = [];
  detailInfo.value.productimgurl = "";
  uploadHidden.value = false;
  clearTimeout(timer.value);
  uploadRef.value.abort();
};
const handlePreview = (file) => {
  file.url && window.open(file.url)
};
/**图片上传结束 */
/**
 * 产品单位
 */
const getUnit = () => {
  unitListApi().then((res) => {
    // this.unitList = res;
    res = res.map((item) => {
      return { label: item.name, value: item.type };
    });
    rules.map((item) => {
      if (item.prop == "productunit") {
        item.options = res;
      }
    });
    createrules.map((item) => {
      if (item.prop == "productunit") {
        item.options = res;
      }
    });
  });
};
/**
 * 原产地
 */
const getOrigin = () => {
  originselectApi().then((res) => {
    // this.originList = res;
    res = res.map((item) => {
      return { label: item.name, value: item.type };
    });
    rules.map((item) => {
      if (item.prop == "countryoforigin") {
        item.options = res;
      }
    });
    createrules.map((item) => {
      if (item.prop == "countryoforigin") {
        item.options = res;
      }
    });
  });
};
onMounted(() => {
  getUnit();
  getOrigin();
});
// onBeforeUnmount(() => {
//   clearTimeout(timer.value)
// })
</script>

<style lang="scss" scoped>
.image-content {
  width: 100%;
  :deep(.el-form-item__content) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.upload-input {
  display: none;
}
.image-upload {
  min-width: 120px;
  min-height: 120px;
  padding: 10px;
  &.el-button:hover,
  &.el-button:focus {
    background: #ffffff;
    border-color: #dcdfe6;
    color: #606266;
  }
}
// remote-select 结束
.avatar {
  max-height: 100px;
  display: block;
}
.products-detail {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 33%;
    min-width: 245px;
  }
}
.remind {
  font-weight: 500;
  line-height: 27px;
  word-break: break-all;
}
.upload-none :deep(.el-upload--picture-card) {
  display: none;
}
</style>

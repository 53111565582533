
import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/



// 库存管理flow列表
export const getInventoryFlowlistApi = (params) => GET({ url: '/ClientInventory/getInventoryFlowlist', params })

// 库存管理flow下拉框
export const getInventoryflowTypeEnumApi = (params) => GET({ url: '/ClientInventory/getInventoryflowTypeEnum', params })


// 库存管理列表
export const getInventorylistApi = (params) => GET({ url: '/ClientInventory/getInventorylist', params })


// 库存管理详情
export const getInventoryDetaillistApi = (params) => GET({ url: '/ClientInventory/getInventoryDetaillist', params })



// 获取详情下拉框
export const getShelforZoneorLocationlistApi = (params) => GET({ url: '/ClientInventory/getShelforZoneorLocationlist', params })

// 导出
export const exportApi = (params) => EXPORT_FILE_GET({ url: '/ClientInventory/export', params })

<template>
  <section class="recharge font-size-small padding-lg">
    <el-row class="reacharge-notice mb-lg">
      <el-col :span="24" class="notice flex align-center">
        <el-icon class="warning-filled link font-size-larger"
          ><WarningFilled
        /></el-icon>
        银行卡转账充值需要人工审核，超过3天未审核的充值记录将自动作废，已提交的充值申请可在充值记录查询功能中查找。
      </el-col>
    </el-row>
    <el-row :gutter="16" class="container" type="flex">
      <el-col :span="9">
        <el-card class="aliPay pay-section" shadow="never">
          <img
            src="~@/assets/images/alipay.png"
            alt="alipay"
            class="alipay-logo mb-lg"
          />
          <el-row class="button width100">
            <el-col :span="24">
              <el-form :model="aliForm" :rules="aliRules" ref="aliForm">
                <el-form-item label="" prop="aliPayAmount">
                  <el-input
                    v-model="aliForm.aliPayAmount"
                    v-inputNumber.numberFloat2="7"
                    v-trim
                    size="large"
                    placeholder="CNY"
                    class="pay-amount"
                  />
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row class="width100">
            <el-col :span="24">
              <el-button
                class="aliPay-btn width100"
                type="primary"
                @click="onSubmitAlipay"
                >充值</el-button
              >
            </el-col>
          </el-row>
          <el-row class="tip" type="flex" justify="center">
            手续费: 0.6%
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="9">
        <el-card class="unionPay pay-section" shadow="never">
          <img
            src="~@/assets/images/unionPay.png"
            class="unionPay-logo mb-lg"
            alt="unionPay"
          />

          <el-row class="width100 margin-16" type="flex">
            <el-col :span="24" class="justify-between align-center">
              <span class="title">公司名称：</span>
              <span class="desc-content">上海旨福供应链管理有限公司</span>
            </el-col>
          </el-row>

          <el-row class="width100 margin-16" type="flex">
            <el-col :span="24" class="justify-between align-center">
              <span class="title">开户行：</span>
              <span class="desc-content">招商银行上海分行田林支行</span>
            </el-col>
          </el-row>
          <el-row class="width100 margin-16" type="flex">
            <el-col :span="24" class="justify-between align-center">
              <span class="title">账号：</span>
              <span class="desc-content">121924439510601</span>
            </el-col>
          </el-row>
          <el-row class="width100">
            <el-col :span="24">
              <el-form :model="bankForm" :rules="bankRules" ref="bankForm">
                <el-form-item label="" prop="unionPayAmount">
                  <el-input
                    v-model="bankForm.unionPayAmount"
                    v-inputNumber.numberFloat2="7"
                    v-trim
                    placeholder="CNY"
                    size="large"
                  />
                </el-form-item> </el-form
            ></el-col>
          </el-row>

          <el-row
            class="describe opRow width100"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <el-col :span="17" class="justify-between align-center">
              <span class="name">请上传支付凭证：</span>
            </el-col>
            <el-col :span="7" class="justify-end">
              <!-- :limit="5" -->
              <el-upload
                ref="uploadRef"
                class="pic-card"
                :show-file-list="false"
                :disabled="isLoading"
                :action="uploadAction"
                :headers="uploadHeaders"
                :multiple="false"
                :accept="acceptFileType"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-error="handleError"
                :on-change="handleChange"
              >
                <el-button
                  class="aliPay-btn"
                  type="primary"
                  icon="Upload"
                  :loading="isLoading"
                  >上传凭证</el-button
                >
              </el-upload>
            </el-col>
          </el-row>
          <el-row class="width100 margin-16">
            <el-col :span="24" class="width100">
              <a
                target="_blank"
                class="font-size-mini link mx-[5px] clamp1"
                :href="uploadImg.url"
                >{{ uploadImg.filename }}</a
              >
             
              <span v-if="uploadImg.filename" style="margin-top: 2px; color: #8f8f8f" @click="removeFile"
                ><el-icon><CircleCloseFilled></CircleCloseFilled></el-icon
              ></span>
            </el-col>
          </el-row>

          <el-row class="width100">
            <el-col :span="24">
              <el-button
                class="aliPay-btn unionSubmit width100"
                type="primary"
                @click="onSubmit"
                >提交</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="container pt-lg">
      <el-col :span="18" class="flex">
        <span class="remark">备注：</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="限制100字符长度"
          v-model="remark"
          maxlength="100"
          show-word-limit
          class="content"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import {
  createPayOrderApi,
  submitPayBankOrder,
} from "@/api/management/recharge";
import { baseImgURL } from "@/utils/baseURL";
import { ElMessage, ElMessageBox } from "element-plus";

import { useTrackingInfo } from "@/stores/tracking.js";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
import { useRoute } from "vue-router";
// const baseUpoadImgUrl = baseImgURL();

export default {
  name: "ManagementRecharge",
  setup() {
    const trackingInfo = useTrackingInfo();
    const route = useRoute();
    const curpath = route.path;
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "账户充值",
      });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });

    const uploadAction = computed(() => {
      return import.meta.env.VITE_BASE_URL + "/recharge/uploadFile";
    });
    const uploadHeaders = computed(() => {
      const headers = {
        "Buffalo-ticket": getToken(),
      };
      return headers;
    });
    return {
      trackingInfo,
      tracking,
      uploadHeaders,
      uploadAction,
    };
  },
  data() {
    const aliValidae = (rule, value, callback) => {
      if (Number.isNaN(Number.parseFloat(value))) {
        callback("请输入要充值的金额");
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      remark: null,
      fileList: [],
      acceptFileType: `.jpg,.png,.jpeg,`,
      payType: {
        alipay: "alipay",
        banCard: "banCard",
      },
      aliForm: {
        aliPayAmount: null,
      },
      bankForm: {
        unionPayAmount: null,
      },
      uploadImg: {
        url: ''
      },
      aliRules: {
        aliPayAmount: [
          {
            validator: aliValidae,
            message: "请输入要充值的金额",
            trigger: "blur",
          },
        ],
      },
      bankRules: {
        unionPayAmount: [
          {
            validator: aliValidae,
            message: "请输入要充值的金额",
            trigger: "blur",
          },
        ],
      },
      maxFileSize: 1024 * 1024 * 10,
      timer: null,
    };
  },

  methods: {
    isSupportFileType(file) {
      const index = file.name.lastIndexOf(".");
      const ext = file.name.slice(index);
      return this.acceptFileType.split(",").includes(ext);
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        ElMessage.error("文件大小不超过10M");
        return false
      }
      if(!this.isSupportFileType(file)) {
        ElMessage.error("不支持该文件类型");
        return false
      }
      this.isLoading = true;
      this.timer = setTimeout(() => {
        this.isLoading = false;
        this.$refs.uploadRef.clearFiles();
        this.$refs.uploadRef.abort();
        ElMessage.error("上传超时");
      }, 30000);
    },
    handleChange(file) {
      file.url = URL.createObjectURL(file.raw);
    },
    handleSuccess(response, file, fileList) {
      this.isLoading = false;
      clearTimeout(this.timer);
      if (response.status !== "00000") {
        this.$refs.uploadRef.clearFiles();
        if (response.code === 3000012) {
          removeToken();
          router.replace("/login");
        }
        return ElMessage.error(response.message);
      } else {
        // 只允许上传一张， 多了直接覆盖
        this.uploadImg = {
          filelink: response.data.url,
          uid: file.uid,
          filename: file.name,
          url: file.url,
        };
        // this.fileList = [this.uploadImg];
      }
    },
    handleError() {
      this.isLoading = false;
      clearTimeout(this.timer);
      return this.$message.error("上传失败");
    },
    // 删除上传的附件
    removeFile() {
      // this.fileList = this.fileList.filter((file) => file.uid !== fileUid);
      this.uploadImg = {
        url: ''
      };
    },
   
    handlePictureCardPreview(file) {
      const blobUrl = URL.createObjectURL(file.raw);
      window.open(blobUrl);
      URL.revokeObjectURL(blobUrl);
    },
    // 支付宝充值
    onSubmitAlipay() {
      this.$refs.aliForm.validate((valid) => {
        if (valid) {
          const data = {
            remark: this.remark,
            money: this.aliForm.aliPayAmount,
            returnurl: window.location.href,
          };
          createPayOrderApi(data).then((params) => {
            if (params) {
              var temp_form = document.createElement("form");
              temp_form.action = params.payUrl;
              temp_form.method = "POST";

              for (var key in params) {
                var opt = document.createElement("input");
                opt.name = key;
                opt.type = "hidden";
                opt.value = params[key];
                temp_form.appendChild(opt);
              }

              temp_form.style.display = "none";
              document.body.appendChild(temp_form);
              // 提交数据
              temp_form.submit();
            } else {
              ElMessage.error("充值请求失败！");
            }
          });
        }
      });
    },
    onSubmit() {
      // if (!this.uploadImg) {
      //   return ElMessage.warning('请上传凭证!')
      // }
      this.$refs.bankForm.validate((bol) => {
        if (!bol) return;
        const data = {
          remark: this.remark,
          money: this.bankForm.unionPayAmount,
          filelink: this.uploadImg?.filelink,
          filename: this.uploadImg?.filename,
        };
        submitPayBankOrder(data)
          .then((res) => {
            ElMessageBox.confirm(
              "充值请求已收到，将在1天内进行确认，加入您的余额。",
              "Success",
              {
                showCancelButton: false,
                showClose: false,
                type: "success",
                confirmButtonText: "OK",
              },
            ).then(() => {
              this.clearBankInput();
              this.clearApipayInput();
            });
          })
          .catch(() => {
            ElMessage.error("充值失败");
          });
      });
    },
    clearApipayInput() {
      this.aliForm.aliPayAmount = null;
      this.remark = null;
    },
    clearBankInput() {
      this.bankForm.unionPayAmount = null;
      this.uploadImg = {
        url:''
      };
      this.fileList = [];
      this.remark = null;
    },
  },
};
</script>
<style scoped lang="scss">
.recharge {
  background: #fff;
  border-radius: 3px;
  font-weight: 400;
  // // header: 60  tag: 46  Breadcrumb: 50, footer: 62
  height: calc(100vh - 218px);
  min-height: 535px;
  .notice {
    color: #4c4c4d;
    padding: 16px 25px;
    background: #f8f3ed;
    border-radius: 6px;
    .warning-filled {
      margin-right: 8px;
    }
  }
  .width100 {
    width: 100%;
  }
  .pay-section {
    background: #f5f5f5;
    border-radius: 3px;
    border: 0 none;
    min-height: 353px;
    height: 100%;
    :deep(.el-card__body) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .alipay-logo {
      width: 40px;
    }
    .tip {
      margin-top: 16px;
      color: #636363;
    }
    .unionPay-logo {
      width: 66px;
      height: 40px;
    }
    .title {
      color: #636363;
    }
    .desc-content {
      font-weight: 600;
      color: #1f1f20;
    }
    .margin-16 {
      margin-bottom: 16px;
    }
  }
  .remark {
    white-space: nowrap;
    color: #1f1f20;
    margin-right: 16px;
  }
  .name {
    white-space: nowrap;
    display: inline-block;
    color: #1f1f20;
    font-weight: 400;
  }
}
.pic-card {
  :deep(.el-upload) {
    width: 100%;
  }
}
.file-container {
  list-style-type: none;
  .file-item {
    .link {
      word-break: break-all;
    }
  }
}
</style>

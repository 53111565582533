
export const tableColumn = [
    {
      label: 'SKU',
      prop: 'sku',
      minWidth: '200'
    },
    {
      label: '产品条码',
      prop: 'barcode',
      minWidth: '200'
    },
    {
      label: '系统数量',
      prop: 'systemQtyAboutSku',
      minWidth: '150'
    },
    {
      label: '盘点数量',
      prop: 'countQtyAboutSku',
      minWidth: '150'
    },
    {
      label: '系统与盘点差异',
      prop: 'differenceQtyAboutSku',
      minWidth: '150'
    },
    {
      label: '盘点结果',
      prop: 'result',
      minWidth: '150'
    },
    {
      label: '库区-货架-货位',
      prop: 'location',
      minWidth: '150'
    },
    {
      label: '记录数量',
      prop: 'recordQty',
      minWidth: '150'
    },
    {
      label: '实际数量',
      prop: 'actualQty',
      minWidth: '150'
    },
    {
      label: '记录与实际差异',
      prop: 'differenceQty',
      minWidth: '150'
    }
  ]
  
import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
// 成本常量设置
export const costbasicsetApi = (params) => GET({ url: '/costbasicset/list', params})// 获取成本设置列表
// /costbasicset/update
// 修改设置
export const costbasicupdateApi = (data) => POST({ url: '/costbasicset/update', data, headersType: 'application/json'})//修改设置


// 销售收入汇总表
// list
export const salessummaryListApi = (data) => POST({ url: '/salessummaryreport/search', data})
// export
export const salessummaryreportApi = () => GET({ url: '/salessummaryreport/export'})

// 收入类型
export const typeselectApi = () => GET({ url: '/salessummaryreport/typeselect'})

// 成本分析报表菜单
// list
export const costanalysisListApi = (params) => GET({ url: '/costanalysisreport/list', params}) // // 获取年度报表列表
// detail
export const costanalysisDetailApi = (params) => GET({ url: '/costanalysisreport/getById', params}) 
// export
export const costanalysisreportApi = () => GET({ url: '/costanalysisreport/export_list'})

// 销售利润率报表
// list
export const salesmarginstatementListApi = (data) => POST({ url: '/Salesmarginstatement/list', data}) 
// export
export const salesmarginstatementreportApi = () => GET({ url: '/Salesmarginstatement/export_list'})




// 增值服务计费报表
export const valueaddfeeListApi = (params) => GET({ url: '/valueaddfeereport/search', params}) // list
// 导出
export const valueaddfeereportApi = (params) => EXPORT_FILE_GET({ url: '/valueaddfeereport/export', params })


// 仓储计费详情报表
export const storagefeedetailListApi = (params) => GET({ url: '/storagefeedetail/search', params}) // list
// 导出
export const storagefeedetailExportApi = (params) => EXPORT_FILE_GET({ url: '/storagefeedetail/export', params })



// 仓储计费报表
export const storagefeeListApi = (params) => GET({ url: '/storagefee/search', params}) // list
// 导出
export const storagefeeExportApi = (params) => EXPORT_FILE_GET({ url: '/storagefee/export', params })


// 出库计费列表
export const outboundreportListApi = (params) => GET({ url: '/outboundreport/list', params}) // list
export const outboundreportDetailApi = (params) => GET({ url: '/outboundreport/listreport', params}) // 出库计费明细
// 导出
export const outboundreportExportApi = (params) => EXPORT_FILE_GET({ url: '/outboundreport/export', params })

// 

// 入库计费报表
export const inboundreportListApi = (data) => POST({ url: '/inbound/receiveFeeReport/list', data}) // list
export const inboundreportDetailApi = (params) => GET({ url: '/inbound/receiveFeeReport/getById', params}) // 出库计费明细
// 导出
export const inboundreportExportApi = (params) => EXPORT_FILE_GET({ url: '/inbound/receiveFeeReport/export_list', params })


// 导出详情
export const inboundreportdetailExportApi = (params) => EXPORT_FILE_GET({ url: '/inbound/receiveFeeReport/exportById', params })








/**  公共接口 */
import { GET, POST } from '@/utils/request'
/**  get请求用params , POST请求用data */

//1 获取 物流商 枚举
export const getExpresscompanyEnumApi = (params) => GET({ url: '/common/getExpresscompanyEnum', params })

//2 获取入库 类型 枚举
export const getInboundDeliveryTypeEnumApi = (params) => GET({ url: '/common/getInboundDeliveryTypeEnum', params })

//3 获取入库 分类 枚举
export const getInboundTypeEnumApi = (params) => GET({ url: '/common/getInboundTypeEnum', params })

//4 sku模糊搜索
export const getSkulistApi = (params) => GET({ url: '/common/getSkulist', params, closeloading:true })

/**
 * 下面两条重复 ，后面清除
 * @returns 
 */
//5 获取warehouse下拉列表
export const commonWarehouseOptionsApi = () => GET({ url: '/common/getstoreselect' })

// storehouse下拉框
export const getstoreselectApi = (params) => GET({ url: '/common/getstoreselect', params })


//6 原产地下拉数据
export const originselectApi = (options) => GET({ url: '/common/getCountryoforiginEnum', params: { options }})

//7 单位数据列表
export const unitListApi = (options) => GET({ url: '/common/getProductUnitEnum', params: { options }})


//8 postcode 公用下拉
export const searchPostcodeApi = (params) => GET({ url: '/common/searchPostcode', params, closeloading:true })

//9 获取sku列表
// export const commonSkuOptionsApi = (params) => GET({ url: '/common/getSkulist', params })

//10 流水费用类型下拉
export const getBalanceChangeTypeEnumApi = (params) => GET({ url: '/common/getBalanceChangeTypeEnum', params })

//11 增值服务价格信息
export const valueaddpriceinfo = (params) => GET({ url: '/common/getValueAddedConfig', params })

//12 二期-发货类型和terms下拉框
export const getOrderTypeEnumApi = (params) => GET({ url: '/common/getOrderTypeEnum', params })

//13 suburb 公用下拉
export const searchCitySuburbApi = (params) => GET({ url: '/common/searchCitySuburb', params, closeloading:true })

//14 postcode或suburb 公用下拉
export const searchPostCodeByCitySuburb = (params) => GET({ url: '/common/searchPostcodeList', params, closeloading:true })

//15 客户sku或barcode模糊搜索
export const getSelectListBySkuOrBarcodeApi = (params) => GET({ url: '/common/getSelectListBySkuOrBarcode', params, closeloading:true })

// 16 当前登录人所属ZA仓库下拉  
export const getZAStoreByCurrentUserApi = () => GET({ url: '/common/getZAStoreByCurrentUser', closeloading:true })

// 17 跨国移库时 仓库下拉框 getStoreByCountryAndCurrentUser
export const getStoreByCountryAndCurrentUserApi = (params) => GET({ url: '/common/getStoreByCountryAndCurrentUser',params, closeloading:true })

// // /common/getOrderTypeEnum
// // 18` 获取物流产品的枚举（海外仓客户关联的admin客户支持的物流产品，若为大货，则将必填的terms列表数据一同返回）
// export const getOrderTypeEnumApi = () => GET({ url: '/common/getOrderTypeEnum', closeloading:true })
// 上传图片
export const uploadApi = (params) => GET({
    url: 'https://pic.buffaloex.com/buffaloex-upload/upload', params
})


// 18 获取出库派送类型枚举
export const getoutboundDeliveryTypeEnumApi = () => GET({ url: '/common/getoutboundDeliveryTypeEnum', closeloading:true })

<template>
  <section class="inventory-detail">
    <el-card shadow="never" class="card-wrap">
      <div
        class="detail-content-title flex align-center mb-lg text-[var(--title-color)]"
      >
        <strong class="order-number font-size-larger mr-lg">{{
          headInfo.invno
        }}</strong>
        <span
          :class="[
            'status',
            {
              green: headInfo.status == 'Inventorying',
              gray: headInfo.status == 'Finish',
            },
          ]"
          >{{ headInfo.statusCN || headInfo.status }}</span
        >
      </div>

      <el-row>
        <el-col :span="24">
          <el-descriptions
            :column="5"
            direction="vertical"
            class="descriptions"
          >
            <el-descriptions-item label="创建时间">{{
              headInfo.createTime || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="提交时间">{{
              headInfo.submitTime || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="审核人">{{
              headInfo.reviewer || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="盘点单类型">
              {{ headInfo.stockCountType || "-" }}</el-descriptions-item
            >
            <el-descriptions-item label="SKU总数"
              >{{ headInfo.skuTotalNum || 0 }}
            </el-descriptions-item>
            <el-descriptions-item label="正确SKU数量">
              {{ headInfo.normalNum || 0 }}</el-descriptions-item
            >
            <el-descriptions-item label="盘亏的SKU数量"
              >{{ headInfo.shortNum || 0 }}
            </el-descriptions-item>
            <el-descriptions-item label="盘盈的SKU数量"
              >{{ headInfo.surplusNum || 0 }}
            </el-descriptions-item>
            <el-descriptions-item label="未盘的SKU数量">
              {{ headInfo.noNum || 0 }}</el-descriptions-item
            >
          </el-descriptions>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="never" style="margin-top: 20px">
      <com-search
        :search-option="searchOption"
        @search="search"
        @reset="reset"
        @handleRemoteSelectFocus="handleRemoteSelectFocus"
        @handleRemoteMethods="handleRemoteSelectMethods"
        @handleSelectChangeMethods="handleSelectChangeMethods"
      >
      </com-search>
      <el-table
        ref="multipleTable"
        stripe
        border
        style="width: 100%"
        height="450"
        :data="tableData"
        :header-cell-style="{ background: '#FAFAFA' }"
      >
        <el-table-column
          v-for="column in tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :align="column.align || 'center'"
          :min-width="column.minWidth"
          :fixed="column.fixed"
        >
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="recordTotal > 0"
        class="pb-lg flex justify-end"
        background
        layout="total,sizes,prev, pager, next, jumper"
        :current-page.sync="params.pageNum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        :total="recordTotal"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-card>
  </section>
</template>
<script>
import {
  inventoryDetailApi,
  inventoryDetailHeadApi,
  resultEnumApi,
} from "@/api/storage-center/inventory";
import { getSelectListBySkuOrBarcodeApi } from "@/api/common.js";
import { tableColumn } from "./column";
export default {
  name: "StorageCenterInventoryDetail",
  data() {
    return {
      tableColumn,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      isLoading: false,
      tableData: [],
      pageSize: 15,
      recordTotal: 0,
      page: 1,
      headInfo: {
        abnormalCount: 0,
        audituser: "",
        correctCount: 0,
        createtime: "",
        invtype: "",
        ordernumber: "",
        profitCount: 0,
        state: "",
        stateCN: "",
        submittime: "",
        totalCount: 0,
        wrongCount: 0,
      },
      searchOption: {
        labelWidth: "auto",
        searchList: [
          // 检索列表
          {
            label: "",
            type: "select-remote-select",
            value: "",
            prop: "productId",
            maxlength: 50,
            placeholder: "请输入产品条码/SKU",
            clearable: true,
            optionData: [],
            selectOption: {
              value: 1,
              prop: "productQueryType",
              optionData: [
                { value: 1, label: "产品条码" },
                { value: 0, label: "SKU" },
              ],
            },
          },
          {
            label: "盘点结果:",
            type: "select",
            value: "",
            prop: "result",
            optionData: [],
            clearable: true,
            placeholder: "请选择盘点结果",
          },
        ],
      },
    };
  },
  created() {
    this.getDetailHead();
    this.getList(); // 查看盘点单详情列表
    this.getResultList(); // 获取 盘点详情 结果 列表
  },
  methods: {
    /** 头部信息 */
    getDetailHead() {
      inventoryDetailHeadApi(this.$route.params.id)
        .then((res) => {
          this.headInfo = { ...this.headInfo, ...res };
        })
        .catch(() => {});
    },
    /**
     * 搜索
     * @param obj
     */
    search(obj) {
      console.log(obj);
      const params = { ...obj };
      delete params.productQueryType;
      this.params = {
        ...params,
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    /**
     * 重置， 清空
     */
    reset() {
      this.searchOption.searchList.map((item) => {
        item.value = "";
        if (item.prop == "productId") {
          item.selectOption.value = 1;
        }
      });
      this.params = {
        pageNum: 1,
        pageSize: this.params.pageSize,
      };
      this.handleCurrentChange(1); // 回到第一页数据
    },
    /** 盘点单详情*/
    getList() {
      inventoryDetailApi({
        ...this.params,
        stockCountId: this.$route.params.id,
      }).then((res) => {
        const { list, recordTotal } = res || {};
        this.tableData = list || [];
        this.recordTotal = recordTotal || 0;
      });
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.params.pageNum = currentPage;
      this.getList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.params.pageNum = 1;
      this.params.pageSize = pageSize;
      this.getList();
    },
    /**
     * 远程 左侧下拉框 类型type 1: sku, 2:barcode 做选择时，清空右侧框内内容
     * @param {*}
     */
    handleSelectChangeMethods() {
      this.searchOption.searchList.map((item) => {
        if (item.prop === "productId") {
          item.value = "";
        }
      });
    },
    /**
     * 获取类型，以及输入的值
     * @param {*} obj => value, type
     */
    handleRemoteSelectMethods(obj) {
      setTimeout(() => {
        this.getSelectListBySkuOrBarcode(obj.type, obj.value);
      }, 200);
    },
    handleRemoteSelectFocus(type) {
      this.getSelectListBySkuOrBarcode(type);
    },
    /**
     * SkuOrBarcode 列表
     */
    getSelectListBySkuOrBarcode(queryType, barcodeOrSku) {
      // auditedflg是否审核: 0或null:全查,1:已审核,2,未审核
      getSelectListBySkuOrBarcodeApi({
        pageNum: 1,
        queryType, // 商品搜索类型：0-sku；1-barcode；
        barcodeOrSku,
      }).then((res) => {
        const barcodeOrSkuResult = res.list.map((item) => {
          if (queryType === 1) {
            return { ...item, value: item.id, label: item.productbarcode };
          } else {
            return { ...item, value: item.id, label: item.sku };
          }
        });
        this.searchOption.searchList.map((item) => {
          if (item.prop === "productId") {
            item.optionData = barcodeOrSkuResult;
          }
        });
      });
    },
    /**
     * 盘点详情结果枚举
     */
    getResultList() {
      resultEnumApi().then((res) => {
        const result = res.map((item) => {
          return { ...item, label: item.name, value: item.type };
        });
        this.searchOption.searchList.map((item) => {
          if (item.prop === "result") {
            item.optionData = result;
          }
          return item;
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/index";
.inventory-detail {
  .card-wrap :deep(.el-card__body) {
    padding-bottom: 8px;
  }
  :deep(.el-descriptions .el-descriptions__cell) {
    width: 20%;
  }
  :deep(.el-descriptions .el-descriptions__label) {
    font-weight: 400;
    color: #636363;
  }
  :deep(.el-descriptions .el-descriptions__content) {
    font-weight: 600;
    color: #1f1f20;
  }
}
.img-wrap {
  margin: 35px 0;
}
.detail-content {
  .detail-content-title {
    font-size: 18px;
  }
  p {
    margin-right: $spaceLG;
  }
}
</style>

<template>
  <el-dialog
    class="order-detail-dialog"
    title="订单详情"
    width="80%"
    v-model="detailDialog"
    :close-on-click-modal="false"
    :before-close="closeDialog"
  >
    <div class="order-detail">
      <!-- 出库入库 订单详情 增值服务关联订单详情 -->
      <!-- 例： 出库： M100042023122800002 入库：ASN20240202100040001 -->
      <!-- orderType == 1: 入库 -->
      <el-tabs v-model="detailActiveName" type="card" v-if="orderType == 1">
        <el-tab-pane
          v-for="item in inboundOrderTable"
          :key="item.activeName"
          :label="item.tabName"
          :name="item.activeName"
        >
          <!-- 基础信息 -->
          <el-descriptions
            v-if="item.descriptions"
            labelClassName="label-desc"
            contentClassName="content-desc"
            :column="2"
          >
            <el-descriptions-item
              v-for="(desc, index) in item.inboundOrder"
              :key="index"
              :label="desc.label"
              >{{
                item.inboundOrderDetail[`${desc.prop}`] || "-"
              }}</el-descriptions-item
            >
          </el-descriptions>
          <!-- 包裹信息、收件信息、质检信息、货架信息 -->
          <el-row v-if="item.total">
            <el-col :span="24" style="text-align: right">
              Total Qty: {{ item.totalQty }}
            </el-col>
          </el-row>
          <el-table
            v-if="item.table"
            :data="item.tableData"
            :header-cell-style="{ background: '#FAFAFA' }"
            height="300"
            :border="true"
            style="width: 100%"
          >
            <el-table-column
              v-for="(column, index) in item.tableColumn"
              :key="index"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :width="column.width"
              :align="column.align || 'center'"
            >
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
          <!-- 物流信息 -->
          <el-form
            v-if="item.form"
            ref="logistics"
            label-width="150px"
            class="logsitcs-information"
            label-position="left"
            :model="item.logistics"
          >
            <el-form-item
              v-for="(formItem, index) in item.logisticsInfoFormData"
              :key="index"
              :label="formItem.label"
              :prop="formItem.prop"
              :class="formItem.class"
            >
              <el-input
                v-if="formItem.type == 'input'"
                v-model="item.logistics[`${formItem.prop}`]"
                disabled
              ></el-input>
              <el-input
                v-if="formItem.type == 'textarea'"
                type="textarea"
                v-model="item.logistics[`${formItem.prop}`]"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!-- orderType == 2: 出库 -->
      <template v-if="orderType == 2">
        <slot name="operationBtn"></slot>
        <el-tabs v-model="detailActiveName" type="card">
          <el-tab-pane
            v-for="item in outboundOrderTable"
            :key="item.activeName"
            :label="item.tabName"
            :name="item.activeName"
          >
            <!-- 出库商品信息 -->
            <el-table
              v-if="item.table"
              :data="item.tableData"
              :header-cell-style="{ background: '#FAFAFA' }"
              height="300"
              border
              style="width: 100%"
            >
              <el-table-column
                v-for="(column, index) in item.tableColumn"
                :key="index"
                :prop="column.prop"
                :label="column.label"
                :align="column.align || 'center'"
              >
                <template #default="{ row }">
                  <div v-if="column.prop == 'picture'">
                    <img
                      v-if="row.productimgurl"
                      :src="row.productimgurl"
                      width="40"
                      height="40"
                      style="margin: 0 auto"
                      alt="picture"
                    />
                  </div>
                  <div v-else>
                    {{ row[column.prop] }}
                  </div>
                </template>
              </el-table-column>
              <!-- 空数据 展示 -->
              <template #empty>
                <com-no-data :smallImg="true"></com-no-data>
              </template>
            </el-table>
            <!-- 收发件人信息 -->
            <el-form
              v-if="item.form"
              ref="orderInfo"
              :model="item.orderInfo"
              label-width="110px"
              label-position="left"
              class="logsitcs-information"
            >
              <template v-if="serviceType === 'Take a lot'">
                <el-form-item
                  v-for="(formItem, index) in item.takealotFormData"
                  :key="index"
                  :label="formItem.label"
                  :prop="formItem.prop"
                  :class="formItem.class"
                >
                  <el-input
                    v-if="formItem.type == 'input'"
                    v-model="item.orderInfo[`${formItem.prop}`]"
                    disabled
                  ></el-input>
                  <el-input
                    v-if="formItem.type == 'textarea'"
                    type="textarea"
                    v-model="item.orderInfo[`${formItem.prop}`]"
                    disabled
                  ></el-input>
                  <a
                    v-if="formItem.type == 'file'"
                    target="_blank"
                    class="block w-[100%] font-size-mini link clamp1"
                    :href="item.orderInfo[`${formItem.prop}`]"
                    >{{ item.orderInfo[`${formItem.name}`] }}</a
                  >
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item
                  v-for="(formItem, index) in item.formData"
                  :key="index"
                  :label="formItem.label"
                  :prop="formItem.prop"
                  :class="formItem.class"
                >
                  <el-input
                    v-if="formItem.type == 'input'"
                    v-model="item.orderInfo[`${formItem.prop}`]"
                    disabled
                  ></el-input>
                  <el-input
                    v-if="formItem.type == 'textarea'"
                    type="textarea"
                    v-model="item.orderInfo[`${formItem.prop}`]"
                    disabled
                  ></el-input>
                </el-form-item>
              </template>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </template>
      <!-- orderType == 3: 出库 + 导出 -->
      <el-tabs v-model="detailActiveName" type="card" v-if="orderType == 3">
        <el-tab-pane
          v-for="item in outboundExportOrderTable"
          :key="item.activeName"
          :label="item.tabName"
          :name="item.activeName"
        >
          <!-- 基础信息 -->
          <el-descriptions
            v-if="item.descriptions"
            labelClassName="label-desc"
            contentClassName="content-desc"
            :column="2"
          >
            <el-descriptions-item
              v-for="(desc, index) in item.outboundOrder"
              :key="index"
              :label="desc.label"
              >{{ item.inboundOrderDetail[`${desc.prop}`] || "-" }}
              {{ desc.price ? "元" : "" }}</el-descriptions-item
            >
          </el-descriptions>
          <!-- 包裹信息、收件信息、质检信息、货架信息 -->
          <el-table
            v-if="item.table"
            :data="item.tableData"
            :header-cell-style="{ background: '#FAFAFA' }"
            height="300"
            :border="true"
            style="width: 100%"
          >
            <el-table-column
              v-for="(column, index) in item.tableColumn"
              :key="index"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :align="column.align || 'center'"
            >
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
          <!-- 物流信息 -->
          <el-form
            v-if="item.form"
            ref="logistics"
            label-width="150px"
            class="logsitcs-information"
            label-position="left"
            :model="item.logistics"
          >
            <el-form-item
              v-for="(formItem, index) in item.logisticsInfoFormData"
              :key="index"
              :label="formItem.label"
              :prop="formItem.prop"
              :class="formItem.class"
            >
              <el-input
                v-if="formItem.type == 'input'"
                v-model="item.logistics[`${formItem.prop}`]"
                disabled
              ></el-input>
              <el-input
                v-if="formItem.type == 'textarea'"
                type="textarea"
                v-model="item.logistics[`${formItem.prop}`]"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <span v-if="orderType == 3">
        <el-button v-if="detailActiveName == 0" class="btn" @click="closeDialog"
          >关闭</el-button
        >
        <el-button
          v-if="detailActiveName == 1"
          type="primary"
          plain
          class="btn"
          icon="Upload"
          @click="exportDetail"
          >导出</el-button
        >
      </span>
      <el-button v-else class="btn" @click="closeDialog"> 关闭 </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { outboundgetDetailByIdApi, exportdetailApi } from "@/api/outbound"; // 出库 详情
import { viewInboundApi } from "@/api/inbound"; // 入库 详情
import { outboundreportDetailApi } from "@/api/report";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import {
  inboundOrderTable,
  outboundOrderTable,
  outboundExportOrderTable,
} from "./column";

import { watch } from "vue";

defineOptions({
  name: "order-detail-dialog",
});
const props = defineProps({
  orderType: {
    type: [String, Number], // 订单类型 1:入库 2：出库 3: 出库 + 导出
    default: () => {
      return 1;
    },
  },
  serviceType: {
    type: String,
    default: "",
  },
  id: [String, Number], // 行id
  dialogVisible: Boolean,
});
const emits = defineEmits(["openDialog"]);
const detailDialog = ref(false);
const detailActiveName = ref("0");

watch([() => props.id, () => props.dialogVisible], (newVal, oldVal) => {
  // const idNewVal = newVal[0];
  const dialogVisibleNewVal = newVal[1];
  // const idOldVal = oldVal[0];
  // if (idNewVal !== idOldVal) {
  // getData();
  // } else {
  detailActiveName.value = "0";
  // detailDialog.value = dialogVisibleNewVal;
  // }
  if (!dialogVisibleNewVal) {
    detailDialog.value = dialogVisibleNewVal;
  } else {
    getData();
  }
});
/**
 * 关闭弹窗
 */
const closeDialog = () => {
  detailDialog.value = false;
  emits("openDialog", false);
};
/**
 * 获取数据
 */
const getData = () => {
  // 订单类型 1:入库 2：出库 3: 出库 + 导出
  if (props.orderType == 1) {
    getInboundOrderDetail(props.id);
  } else if (props.orderType == 2) {
    getOutboundOrderDetail(props.id);
  } else if (props.orderType == 3) {
    getOutboundExportOrderDetail(props.id);
  }
};
/**
 * 出库订单详情
 */
const getOutboundOrderDetail = (id) => {
  outboundgetDetailByIdApi(id).then((res) => {
    outboundOrderTable.map((item) => {
      switch (item.label) {
        case "skuInfo":
          item.tableData = res.outboundorderdetailList;
          break;
        case "orderInfo":
          item.orderInfo = res.outboundAddressBo;
          break;
      }
    });
    detailDialog.value = true;
    emits("openDialog", true);
  });
};
/**
 * 入库订单详情
 */
const getInboundOrderDetail = (id) => {
  viewInboundApi(id).then((res) => {
    inboundOrderTable.map((item) => {
      switch (item.label) {
        case "basic":
          item.inboundOrderDetail = res.order;
          break;
        case "detaillist":
          item.tableData = res.boxInfoDetail?.boList;
          item.totalQty = res.boxInfoDetail?.boxInfoQty || 0;
          break;
        case "receivedlist":
          item.tableData = res.receivedlist;
          break;
        case "inspectionlist":
          item.tableData = res.inspectionlist;
          break;
        case "onshelflist":
          item.tableData = res.shelfrecordBO?.shelfRecord;
          item.totalQty = res.shelfrecordBO?.shelfRecordQty || 0;
          break;
        case "logistics":
          item.logistics = res.logistics;
          break;
      }
    });
    detailDialog.value = true;
    emits("openDialog", true);
  });
};
/**
 * 出库订单 + export
 */
const getOutboundExportOrderDetail = (id) => {
  outboundreportDetailApi({
    outboundorderid: id,
  }).then((res) => {
    outboundExportOrderTable.map((item) => {
      switch (item.label) {
        case "basic":
          item.inboundOrderDetail = res.cost;
          break;
        case "skuInfo":
          item.tableData = res.detail;
          break;
      }
    });
    detailDialog.value = true;
    emits("openDialog", true);
  });
};

/**
 * 明细导出
 */
const exportDetail = () => {
  exportdetailApi({ outboundorderid: props.id }).then((res) => {
    DOWNLOAD_FILE(res);
  });
};
</script>

<style lang="scss" scoped>
.picture-size {
  width: 40px;
  height: 40px;
  .el-image {
    width: 100%;
    height: 100%;
  }
}

:deep(.el-descriptions__label:not(.is-bordered-label)),
:deep(.el-descriptions__content:not(.is-bordered-label)) {
  font-size: 14px;
  color: #191919;
  display: inline-block;
  min-width: 200px;
}
:deep(.el-descriptions__content:not(.is-bordered-label)) {
  font-weight: bold;
  text-align: right;
}
.logsitcs-information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item {
    min-width: 45%;
    margin: 0 1% 22px;
    &.address {
      width: 100%;
    }
  }
}
.order-information {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    min-width: 33%;
  }
}
</style>

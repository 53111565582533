<template>
  <section>
    <!-- 库存查询 -->
    <table-layout
      :searchOption="searchOption"
      :table-option="tableOption"
      @search="search"
      @reset="reset"
      @current-change="handleCurrentChange"
      @handle-row-dbclick="handleRowDblClick"
      @size-change="handleSizeChange"
    >
      <template #upper-btn-operation>
        <div class="btn-layout">
          <el-button
            type="primary"
            icon="Upload"
            plain
            @click="exportTb('main-table')"
            >导出</el-button
          >
          <el-button
            type="primary"
            icon="Upload"
            plain
            @click="exportTb('sub-table')"
            >导出详情</el-button
          >
        </div>
      </template>
    </table-layout>
    <!-- 详情表 -->
    <template v-if="isShowDetail">
      <table-layout
        :searchOption="searchOptionDetail"
        :table-option="tableOptionDetail"
        @search="searchDetail"
        @reset="resetDetail"
        @current-change="handleCurrentChangeDetail"
        @size-change="handleSizeChangeDetail"
      >
      </table-layout>
    </template>
  </section>
</template>

<script>
import { getstoreselectApi } from "@/api/common.js";
import {
  getInventorylistApi,
  getInventoryDetaillistApi,
  getShelforZoneorLocationlistApi,
  exportInventoryApi,
  exportInventoryDetailApi,
} from "@/api/storage-center/stockquery.js";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";

import { tableOption, searchOptionDetail, tableOptionDetail } from "./column";

import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

import { useRoute } from "vue-router";

export default {
  name: "StorageCenterStockquery",
  setup() {
    const trackingInfo = useTrackingInfo();

    const route = useRoute();
    const curpath = route.path;
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "库存查询",
      });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });
    return {
      trackingInfo,
      tracking,
    };
  },
  data() {
    return {
      searchOption: {
        // labelWidth: "auto",
        // singleRow: true,
        searchList: [
          // 检索列表
          {
            label: "SKU:",
            type: "input",
            value: "",
            prop: "sku",
            maxlength: 100,
            placeholder: "请输入SKU",
          },
          {
            label: "仓库:",
            type: "select",
            value: "",
            prop: "storehouseid",
            optionData: [],
            clearable: true,
            placeholder: "请选择仓库",
          },
          {
            label: "商品名称:",
            type: "input",
            value: "",
            prop: "productname",
            maxlength: 100,
            placeholder: "请输入商品名称",
          },
        ],
      }, // 主 搜索栏
      tableOption, // 主 表格
      searchOptionDetail: {
        labelWidth: "auto",
        searchList: [
          // 检索列表
          {
            label: "SKU：",
            type: "input",
            value: "",
            prop: "sku",
            disabled: true,
          },
          {
            label: "仓库：",
            type: "input",
            value: "",
            prop: "store",
            disabled: true,
          },
          {
            label: "库区：",
            type: "select",
            value: "",
            prop: "zone",
            optionData: [],
            clearable: true,
          },
          {
            label: "货架：",
            type: "select",
            value: "",
            prop: "shelf",
            optionData: [],
            clearable: true,
          },
          {
            label: "批次号：",
            type: "input",
            value: "",
            prop: "asnno",
            maxlength: 100,
          },
          {
            label: "货位：",
            type: "select",
            value: "",
            prop: "location",
            optionData: [],
            clearable: true,
          },
        ],
      }, // 详情 搜索栏
      tableOptionDetail, // 详情 表格
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      paramsDetail: {
        sku: "",
        storehouseid: "",
        pageNum: 1,
        pageSize: 10,
      },
      productid: "",
      storehouseid: "",
      isShowDetail: false,
    };
  },
  mounted() {
    this.getstoreselect();
    this.getList();
  },
  methods: {
    /**
     * 初始化数据
     */
    getList() {
      this.isShowDetail = false;
      getInventorylistApi(this.params).then((res) => {
        this.tableOption.tableData = res.list;
        this.tableOption.page.total = res.recordTotal;
      });
    },
    /**
     * 条件检索
     * @param {*} obj
     */
    search(obj) {
      console.log(obj);
      this.params = { ...obj, pageNum: 1, pageSize: 10 };
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = 10;
      this.getList();
    },
    /**
     * 清空，重置
     */
    reset() {
      this.searchOption.searchList.map((item) => {
        item.value = "";
      });
      this.params = {
        pageNum: 1,
        pageSize: this.params.pageSize,
      };
      this.getList();
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.tableOption.page.currentPage = currentPage;
      this.params.pageNum = currentPage;
      this.getList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.params.pageNum = 1;
      this.params.pageSize = pageSize;
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = pageSize;
      this.getList();
    },

    /**
     *  双击展示详情 表
     */
    handleRowDblClick(row) {
      this.productid = row.productid;
      this.storehouseid = row.storehouseid;
      this.paramsDetail.sku = row.productid;
      this.paramsDetail.storehouseid = row.storehouseid;
      this.paramsDetail.pageNum = 1;
      this.paramsDetail.pageSize = 10;
      this.tableOptionDetail.page.currentPage = 1;
      this.tableOptionDetail.page.pageSize = 10;
      this.isShowDetail = true;
      this.searchOptionDetail.searchList.map((item) => {
        switch (item.prop) {
          case "sku":
            item.value = row.sku;
            break;
          case "store":
            item.value = row.warehouse;
            break;
        }
      });
      getShelforZoneorLocationlistApi({
        storehouseid: row.storehouseid,
        productid: row.productid,
      }).then((res) => {
        this.searchOptionDetail.searchList.map((item) => {
          switch (item.prop) {
            case "zone":
              res.zonelist.map((zone) => {
                zone["label"] = zone.name;
                zone["value"] = zone.id;
              });
              item.optionData = res.zonelist;
              break;
            case "shelf":
              res.shelflist.map((shelf) => {
                shelf["label"] = shelf.name;
                shelf["value"] = shelf.id;
              });
              item.optionData = res.shelflist;
              break;
            case "location":
              res.locationlist.map((location) => {
                location["label"] = location.name;
                location["value"] = location.id;
              });
              item.optionData = res.locationlist;
              break;
          }
        });
        this.getListDetail();
      });
    },
    /**
     * 以下双击 获取详情有关
     */
    /**
     * 获取详情列表
     */
    getListDetail() {
      getInventoryDetaillistApi(this.paramsDetail).then((res) => {
        this.tableOptionDetail.tableData = res.list;
        this.tableOptionDetail.page.total = res.recordTotal;
      });
    },
    /**
     * 条件检索
     * @param {*} obj
     */
    searchDetail(obj) {
      this.paramsDetail = {
        ...obj,
        pageNum: 1,
        pageSize: 10,
        sku: this.productid,
        storehouseid: this.storehouseid,
      };
      this.tableOptionDetail.page.currentPage = 1;
      this.tableOptionDetail.page.pageSize = 10;
      this.getListDetail();
    },
    resetDetail() {
      this.searchOptionDetail.searchList.map((item) => {
        if (!item.disabled) {
          item.value = "";
        }
      });
      this.paramsDetail = {
        pageNum: 1,
        pageSize: this.paramsDetail.pageSize,
        sku: this.productid,
        storehouseid: this.storehouseid,
      };
      this.getListDetail();
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChangeDetail(currentPage) {
      this.tableOptionDetail.page.currentPage = currentPage;
      this.paramsDetail.pageNum = currentPage;
      this.getListDetail();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChangeDetail(pageSize) {
      this.paramsDetail.pageNum = 1;
      this.paramsDetail.pageSize = pageSize;
      this.tableOptionDetail.page.currentPage = 1;
      this.tableOptionDetail.page.pageSize = pageSize;
      this.getListDetail();
    },

    /**
     * 获取仓库下拉框
     */
    getstoreselect() {
      getstoreselectApi().then((res) => {
        res.list.map((item) => {
          item["label"] = item.namecn;
          item["value"] = item.id;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "storehouseid") {
            item.optionData = res.list;
          }
        });
      });
    },
    // 导出
    async exportTb(dataType) {
      if (dataType === "main-table") {
        exportInventoryApi(this.params).then((res) => {
          DOWNLOAD_FILE(res);
        });
      } else if (dataType === "sub-table") {
        exportInventoryDetailApi(this.params).then((res) => {
          DOWNLOAD_FILE(res);
        });
      }
    },
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped></style>


import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

// 创建出库订单
export const outboundcreateApi = (data) => POST({ url: '/outbound/create', data, headersType: 'application/json' })

// 暂存出库订单
export const outboundDraftApi = (data) => POST({ url: '/outbound/tempSave', data, headersType: 'application/json' })

// 暂存出库单编辑页数据
export const outboundDraftDataApi = (params) => GET({ url: '/outbound/draftEditOrDetail', params})

// 出库订单预览
export const createPreviewApi = (data) => POST({ url: '/outbound/createPreview', data, headersType: 'application/json' })

// 截单
export const terminatedApi = (data) => POST({ url: '/outbound/terminated', data })

// 删除草稿
export const deleteApi = (data) => POST({ url: '/outbound/draftDel', data })

// 获取产品和可用库存
export const outboundgetBySkuApi = (params) => GET({ url:'/outbound/getBySku', params })

// 根据出库单id查看明细
export const outboundgetDetailByIdApi = (outboundorderid) => GET({ url: '/outbound/getDetailById', params: { outboundorderid }})

// 运单明细
export const expressdetailApi = (id) => GET({ url: '/outbound/expressdetail', params: { id }})

// 获取出库列表
export const outboundlistApi = (params) => GET({ url: '/outbound/list', params })

// 查看最新取件码
export const outboundGetNewPickCodeApi = (params) => GET({ url: '/outbound/getNewPickCode', params })

// 复制
export const copyOutboundApi = (params) => GET({ url: '/outbound/copyOutboundOrder', params})

// 获取 takelot 仓库
export const getTakealotDCListApi = () => GET({ url: '/outbound/getTakealotDCList'})

//  takealot  根据postcode和suburb 查询 仓库 id
export const getPickupStorehuseWithNewAddressApi =(params)=>GET({url: '/outbound/getPickupStorehuseWithNewAddress', params})

// 导出
export const singleExportApi = (params) => EXPORT_FILE_GET({ url: '/outbound/exportList', params })

// 全部导出

export const allExportApi = (params) => EXPORT_FILE_GET({ url: '/outbound/exportDataAll', params })

// 全部详情

export const singleExportDetailApi = (params) => EXPORT_FILE_GET({ url: '/outbound/exportDetailList', params })

// 下载面单
export const downloadApi = (params) => EXPORT_FILE_GET({ url: '/outbound/printWaybill', params })

// 下载模板
export const downloadTemplateApi = () => EXPORT_FILE_GET({ url: '/outbound/downloadTemplate'})

// 明细导出
export const exportdetailApi = () => EXPORT_FILE_GET({ url: '/outboundreport/exportdetail'})

// 上传 预定证明
// /outbound/uploadFile/takeALot
export const uploadFileTakeALotApi = (data) =>
    POST({ url: "/outbound/uploadFile/takeALot", data, config: {upload: true},  headersType: 'multipart/form-data' });
   
/**常用收件人管理 */
export const searchOption = {
    // 顶部表单检索内容,非必填
    // labelWidth: "140px",
    closeReset: true,
    searchList: [
        // 检索列表
        {
            type: "input",
            value: "",
            prop: "phoneorname",
            maxlength: 100,
            prefixIcon: 'Search',
            placeholder: '请输入姓名/电话',
            icon: 'Search'
        },
    ],
}

export const tableOption = {
    height: 550,
    selection: true,
    receiver: true,
    tableColumn: [
        {
            label: "Name",
            prop: "receiver",
            minWidth: "130",
            align: "left"
        },
        {
            label: "Company",
            prop: "receivecompany",
            minWidth: "150",
            align: "left"
        },
        {
            label: "Tel",
            prop: "receivetelephone",
            minWidth: "100",
            align: "left"
        },
        {
            label: "Postcode",
            prop: "receivepostcode",
            minWidth: "80",
            align: "left"
        },
        {
            label: "Suburb",
            prop: "receivesuburb",
            minWidth: "100",
            align: "left"
        },
        {
            label: "E-mail",
            prop: "receiveemail",
            minWidth: "100",
            align: "left"
        },
        {
            label: '操作',
            prop: 'operation',
            width: '80',
            align: "left"
        }
    ],

    // 表格数据，必填
    tableData: [],
    page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
    
}
/*
 * @Author: vere
 * @Date: 2024-02-02 15:22:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 15:47:03
 * @Description: 
 */
import trim from './trim'
import routePermission from './routePermission'
import loadMore from './select'
import inputNumber from './inputNumber'
import buttonPermission from './buttonPermission'
import inputLimit from './inputLimit'
import selectLoadmore from './loadmore'
import selectMaxlength from './selectMaxlength'

const directives = {
  trim,
  routePermission,
  loadMore,
  inputNumber,
  buttonPermission,
  inputLimit,
  selectLoadmore,
  selectMaxlength
}

export default {
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}
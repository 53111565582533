<template>
  <section>
    <!-- 增值服务 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
            />
          </template>
          <template #date1>
            <el-date-picker
              v-model="finishDateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg justify-between">
        <el-col :span="12">
          <el-button type="primary" plain @click="exportTb" icon="Upload">
            导出
          </el-button>
          <el-button
            v-buttonPermission="`${routePath}/Cancel`"
            type="danger"
            plain
            icon="Close"
            @click="showInvalid"
            >作废</el-button
          >
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button
            v-buttonPermission="`${routePath}/Add`"
            type="primary"
            icon="DocumentAdd"
            @click="showNext"
            >新建订单</el-button
          >
        </el-col>
      </el-row>
      <el-table
        stripe
        height="450"
        style="width: 100%; margin: 10px 0 20px"
        :header-cell-style="{ background: '#FAFAFA' }"
        :border="true"
        :data="tableOption.tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :width="column.width"
          :align="column.align || 'center'"
          :fixed="column.fixed"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'orderno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row, '1')"
              >
                <span class="link">{{ row.orderno }}</span>
              </el-link>
            </div>
            <div v-else-if="column.prop == 'inboundno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row, '2')"
              >
                <span class="link">{{ row.inboundno }}</span>
              </el-link>
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 关联订单详情 -->
    <order-detail-dialog
      :orderType="boundtype"
      :id="inboundorderid"
      :dialogVisible="detailDialog"
      @openDialog="openDialog"
    >
    </order-detail-dialog>

    <!-- 增值服务弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      class="addvalue-dialog"
      title="增值服务"
      v-model="dialogVisible_service"
      width="80%"
      :before-close="handleClose"
    >
      <el-row :gutter="30">
        <el-col :span="3" class="b-label font-size-medium flex align-center">
          {{ boundtype == 1 ? "关联入库单号:" : "关联出库单号" }}
        </el-col>
        <el-col :span="18">
          <el-link
            v-if="isEdit"
            :underline="false"
            type="primary"
            @click="showDetail(currentClickTbRow, '2')"
          >
            <span class="link font-size-medium">{{ serviceForm.orderno }}</span>
          </el-link>
          <span
            v-else
            class="link font-size-medium"
            style="font-weight: bold"
            >{{ serviceForm.orderno }}</span
          >
        </el-col>
      </el-row>
      <!-- 贴标服务 -->
      <div class="margin-distance">
        <el-row
          :gutter="30"
          type="flex"
          :class="['row-desc', 'align-center', 'margin-bottom']"
        >
          <el-col :span="3">
            <el-checkbox
              :disabled="isEdit"
              v-model="labelchoose"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">贴标服务</span>
          </el-col>
          <el-col :span="9">
            <span
              >支持外箱贴标、托盘贴标、SKU贴标，价格{{
                serviceForm.labelfee
              }}元/标
            </span>
          </el-col>
          <el-col :span="10">
            <span class="b-label">贴标数：</span>
            <el-input
              v-trim
              maxlength="5"
              v-inputLimit="0"
              :disabled="isEdit || !labelchoose"
              clearable
              style="width: 100px"
              v-model="serviceForm.labelnum"
            />
            <span class="b-label margin-left"
              >价格：{{ serviceForm.labelprice }}元</span
            >
          </el-col>
        </el-row>
        <el-row class="margin-bottom">
          <el-col :span="3" class="tip">备注：</el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="isEdit || !labelchoose"
              type="textarea"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求."
              v-model="serviceForm.labelremark"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="3" class="tip">附件：</el-col>
          <el-col :span="20" class="flex align-center flex-wrap">
            <el-upload
              ref="uploadRef"
              class="pic-card"
              accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :show-file-list="false"
              :disabled="isEdit || !labelchoose || isLoading"
              :action="uploadAction"
              :headers="uploadHeaders"
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
            >
              <el-button
                class="aliPay-btn"
                type="primary"
                icon="Upload"
                :disabled="isEdit || !labelchoose"
                :loading="isLoading"
                >上传附件</el-button
              >
            </el-upload>
            <span v-for="item of fileNameList" :key="item.id">
              <span style="margin: 5px" class="link">
                <a
                  target="_blank"
                  class="font-size-mini"
                  :href="item.url"
                  >{{ item.filename }}</a
                >
                <span
                  v-if="!isEdit"
                  @click="handleRemove(item)"
                  style="margin-top: 2px; color: #8f8f8f"
                  ><el-icon><CircleCloseFilled></CircleCloseFilled></el-icon
                ></span>
              </span>
            </span>
          </el-col>
        </el-row>
      </div>
      <!-- 重新装箱打包服务 -->
      <div class="margin-distance">
        <el-row
          :gutter="30"
          type="flex"
          align="middle"
          class="align-center margin-bottom"
        >
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              :disabled="isEdit"
              v-model="reboxchoose"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">重新装箱打包服务</span>
          </el-col>
          <el-col :span="9">
            <span
              >支持重新装箱和库内打包服务,价格{{ serviceForm.packfee }}元/箱
            </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <span class="b-label">装箱数：</span>
            <el-input
              v-trim
              maxlength="5"
              v-input-limit="0"
              :disabled="isEdit || !reboxchoose"
              clearable
              style="width: 100px"
              v-model="serviceForm.reboxednum"
            />
            <span class="b-label margin-left"
              >价格：{{ serviceForm.reboxprice }}元</span
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="tip">备注：</el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="isEdit || !reboxchoose"
              type="textarea"
              maxlength="200"
              show-word-limit
              style="width: 100%"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求."
              v-model="serviceForm.reboxedremark"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>

      <!-- 重新打板服务 -->
      <div class="margin-distance">
        <el-row type="flex" :gutter="30" class="align-center margin-bottom">
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              :disabled="isEdit"
              v-model="reboardchoose"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">重新打板服务</span>
          </el-col>
          <el-col :span="9">
            <span
              >包含拆板、重新打板、缠膜费用，价格{{ serviceForm.blankfee }}元/板
            </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <span class="b-label">打板数：</span>
            <el-input
              v-trim
              maxlength="5"
              v-input-limit="0"
              :disabled="isEdit || !reboardchoose"
              clearable
              style="width: 100px"
              v-model="serviceForm.reboardnum"
            />
            <span class="b-label margin-left"
              >价格：{{ serviceForm.reboardprice }}元</span
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="tip">备注：</el-col>
          <el-col :span="8">
            <el-input
              v-trim
              :disabled="isEdit || !reboardchoose"
              type="textarea"
              maxlength="200"
              show-word-limit
              style="width: 100%"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请在备注中说明需要张贴的标签类型以及其他要求."
              v-model="serviceForm.reboardremark"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>

      <!-- 保价 -->
      <div v-if="boundtype == 1" class="margin-distance">
        <el-row type="flex" align="middle" class="b-label align-center">
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              :disabled="isEdit"
              v-model="insuredchoose"
              style="margin-right: 10px"
            ></el-checkbox>
            <span>保价服务</span>
          </el-col>
          <el-col :span="21" class="flex align-center">
            <span class="flex-shrink0">订单货物总价</span>
            <el-input
              v-trim
              disabled
              style="width: 100px; margin: 0 10px; height: 32px"
              v-input-limit="2"
              v-model="serviceForm.skutotalprice"
            />
            <span class="flex-shrink0">元，保额</span>
            <el-input
              v-trim
              :disabled="isEdit || !insuredchoose"
              v-input-limit="2"
              maxlength="8"
              clearable
              style="width: 100px; margin: 0 10px"
              v-model="serviceForm.insuranceamount"
            />
            <span class="flex-shrink0">元，保价持续</span>
            <el-input
              v-trim
              @keyup="handleInput"
              v-input-limit="0"
              maxlength="2"
              :disabled="isEdit || !insuredchoose"
              clearable
              style="width: 100px; margin: 0 10px"
              v-model="serviceForm.month"
            />
            <span class="flex-shrink0"> 个月，保费</span>
            <el-input
              v-trim
              disabled
              style="width: 100px; margin: 0 10px"
              v-model="serviceForm.premium"
              v-input-limit="2"
              maxlength="8"
            />
            <span class="flex-shrink0">元/月，价格：</span>
            <span class="color-primary flex-shrink0"
              >{{ serviceForm.premiumtotalprice }} 元</span
            >
          </el-col>
        </el-row>
      </div>
      <!-- 扫描SN Code 服务 -->
      <div
        v-if="
          serviceForm.valueaddorderRequiredBo &&
          serviceForm.valueaddorderRequiredBo.showSNCodeService == 1
        "
        class="margin-distance"
      >
        <el-row type="flex" :gutter="30" class="align-center margin-bottom">
          <el-col :span="3" class="flex align-center">
            <el-checkbox
              disabled
              v-model="serviceForm.valueaddorderRequiredBo.showSNCodeService"
              :true-value="1"
              :false-value="0"
              style="margin-right: 15px"
            ></el-checkbox>
            <span class="b-label">扫描SN Code服务</span>
          </el-col>
          <el-col :span="9">
            <span
              >库内扫描SN Code，价格{{
                serviceForm.valueaddorderRequiredBo.sncodefee
              }}元/件
            </span>
          </el-col>
          <el-col :span="10" class="flex align-center">
            <span class="b-label">扫描数：</span>
            <span style="display: inline-block; width: 100px">{{
              serviceForm.valueaddorderRequiredBo.sncodeqty || 0
            }}</span>
            <span class="b-label margin-left"
              >价格：{{ sncodeTotalPrice || 0.0 }}元</span
            >
          </el-col>
        </el-row>
      </div>

      <!-- 总价 -->
      <el-row style="margin: 20px 20px 0 0">
        <template v-if="isEdit">
          <el-col :span="3" class="price-title tip"> 总价： </el-col>
          <el-col :span="18" class="price b-lable color-primary">
            ￥{{ serviceForm.valuetotalprice }}
          </el-col>
        </template>
        <template v-else>
          <el-col :span="3" class="price-title tip"> 总价： </el-col>
          <el-col :span="18" class="price b-lable color-primary">
            ￥{{ valuetotalprice }}
          </el-col>
        </template>
      </el-row>
      <template #footer>
        <el-button :type="isEdit ? '' : 'primary'" @click="confirmInfo">{{
          isEdit ? "关闭" : "确定"
        }}</el-button>
      </template>
    </el-dialog>
    <!-- 下一步 -->
    <el-dialog
      :close-on-click-modal="false"
      class="dialog"
      title="新建订单"
      v-model="dialogVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <el-form
        ref="orderForm"
        label-width="120px"
        label-position="right"
        :model="orderForm"
        :rules="orderRules"
        :inline="false"
      >
        <el-form-item prop="type" label="单据类型">
          <el-select
            v-model="orderForm.type"
            placeholder="请选择"
            style="width: 90%"
            @change="handleBoundtype"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-trim  @keyup.enter.native="nextStep" v-model="orderForm.type" maxlength="20" style="width: 90%" placeholder="" /> -->
        </el-form-item>
        <el-form-item prop="orderno" label="关联订单号">
          <el-input
            v-trim
            @keyup.enter="nextStep"
            v-model="orderForm.orderno"
            maxlength="50"
            style="width: 90%"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button class="btn" type="primary" @click="handleClose">
          取消
        </el-button>
        <el-button class="btn" type="primary" @click="nextStep">
          确认
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { useRoute } from "vue-router";
import {
  confirmAddApi,
  destoryApi,
  detailApi,
  searchAddApi,
  getstatuselectApi,
  nextstepApi,
  exportApi,
} from "@/api/addservers";
const onlineImgURL = "https://static.buffaloex.com/";
import { searchOption, tableOption } from "./column";
import { math } from "@/utils/math";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
export default {
  name: "ServicesOrder",
  setup() {
    const trackingInfo = useTrackingInfo();
    const route = useRoute();
    const curpath = route.path;
    const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

    const tracking = () => {
      trackingInfo.setTracking({
        duration: Date.now() - accessTime.value,
        pathname: curpath,
        menuname: "增值服务管理",
      });
    };
    onBeforeUnmount(() => {
      const path = trackingInfo.pathInfo?.path;
      const currentpath = trackingInfo.pathInfo?.currentpath;
      if (path == curpath && currentpath == curpath) {
        tracking();
      }
    });
    onActivated(() => {
      accessTime.value = Date.now();
    });
    onDeactivated(() => {
      tracking();
    });
    return {
      trackingInfo,
      tracking,
    };
  },
  data() {
    return {
      searchOption: {
        // 顶部表单检索内容,非必填
        labelWidth: "100px",
        searchList: [
          // 检索列表

          {
            label: "增值服务单号:",
            type: "input",
            value: "",
            prop: "orderno",
            clearable: true,
            maxlength: 50,
            placeholder: "请输入增值服务单号",
          },
          {
            label: "关联单据:",
            type: "input",
            value: "",
            prop: "inboundno",
            clearable: true,
            maxlength: 50,
            placeholder: "请输入关联单据",
          },
          {
            label: "状态:",
            type: "select",
            value: 0,
            prop: "status",
            optionData: [{ label: "待处理", value: 0 }],
            clearable: false,
          },
          {
            label: "创建时间:",
            type: "date",
            // dateType: "daterange",
            value: "",
            prop: "daterange",
            // clearable: true
          },
          {
            label: "完成时间:",
            type: "date1",
            // dateType: "daterange",
            value: "",
            prop: "daterange",
            // clearable: true
          },
        ],
      },
      choiceDate: "",
      tableOption, // table 主 列表数据
      params: {
        status: 0,
        pagenum: 1,
        pageSize: 10,
      },
      dateRange: [], // 创建时间
      finishDateRange: [], // 完成时间
      orderForm: {
        type: 1,
        orderno: "",
      },
      typeOptions: [
        {
          value: 1,
          label: "入库订单",
        },
        {
          value: 2,
          label: "出库订单",
        },
      ],
      fileList: [],
      fileNameList: [], //文件列表
      textarea2: "", //文本区域
      title: "",
      dialogVisible: false, // 添加/编辑
      dialogVisible_service: false, //增值服务弹框
      detailDialog: false, //view弹框
      detailDialog_outbound: false, // 出库订单详情
      tableDataDetail: [], // 出库订单详情
      order: {},
      detaillist: [],
      receivedlist: [],
      onshelflist: [],
      inspectionlist: [],

      DetailActiveName: "0",
      selectList: [], //选中数据
      currentClickTbRow: {},
      serviceForm: {
        //服务表单
        // createuserid: '',
        blankfee: "",
        fileids: "",
        inboundorderid: "",
        insuranceamount: "",
        insurancedeductibleexcess: "",
        labelfee: "",
        labelnum: "",
        labelprice: "",
        labelremark: "",
        month: "",
        orderno: "",
        packfee: "",
        premium: "",
        premiumpercent: "",
        premiumtotalprice: "",
        // priceruleid: '',
        reboardnum: "",
        reboardprice: "",
        reboardremark: "",
        reboxednum: "",
        reboxedremark: "",
        reboxprice: "",
        skutotalprice: "",
        // status: '',
        valuetotalprice: "",
        valueaddorderRequiredBo: {},
      },
      insuredchoose: false, //保价选择
      labelchoose: false, //贴标选择
      reboardchoose: false, //打板选择
      reboxchoose: false, //打包选择
      isEdit: false,

      statusList: [],
      tableData: [],
      recordTotal: 0,
      page: 1,

      isLoading: false,
      isCheck: false, //是否查看
      boundtype: 1,
      inboundorderid: "", // 关联订单id
      timer:null
    };
  },
  computed: {
    uploadAction() {
      return import.meta.env.VITE_BASE_URL + "/valueaddorder/uploadFile/label";
    },
    uploadHeaders() {
      const headers = {
        "Buffalo-ticket": getToken(),
      };
      return headers;
    },

    sncodeTotalPrice() {
      const sncodefee = this.serviceForm.valueaddorderRequiredBo.sncodefee;
      const sncodeqty = this.serviceForm.valueaddorderRequiredBo.sncodeqty;
      const total = math.multiplyTwo(sncodefee, sncodeqty).toFixed(2);
      return total;
    },
    orderRules() {
      // 校验新建
      return {
        orderno: [
          { required: true, message: " 请填写此选项!", trigger: "blur" },
          // {
          //   pattern: /^[0-9]+(.[0-9]{1,2})?$/,
          //   message: '限制输入到小数点后两位!',
          //   trigger: ['blur', 'change']
          // }
        ],
      };
    },
    routePath() {
      return this.$route.path;
    },
    valuetotalprice() {
      let peice_1 = 0;
      let peice_2 = 0;
      let peice_3 = 0;
      let peice_4 = 0;
      if (this.insuredchoose) {
        peice_1 = math.multiplyTwo(
          this.serviceForm.premium,
          this.serviceForm.month,
        ); //baojia价
      }
      if (this.labelchoose) {
        peice_2 = math.multiplyTwo(
          this.serviceForm.labelnum,
          this.serviceForm.labelfee,
        );
      }
      if (this.reboardchoose) {
        peice_3 = math.multiplyTwo(
          this.serviceForm.reboardnum,
          this.serviceForm.blankfee,
        );
      }
      if (this.reboxchoose) {
        peice_4 = math.multiplyTwo(
          this.serviceForm.reboxednum,
          this.serviceForm.packfee,
        );
      }
      this.serviceForm.valuetotalprice = (
        Number(peice_1) +
        Number(peice_2) +
        Number(peice_3) +
        Number(peice_4)
      ).toFixed(2); //表单总价
      return this.serviceForm.valuetotalprice;
    },
  },
  watch: {
    serviceForm: {
      handler(newValue, oldValue) {
        if (this.isEdit) {
        } else {
          this.serviceForm.labelprice = math.multiplyTwo(
            this.serviceForm.labelnum,
            this.serviceForm.labelfee,
          ); //贴标服务价格
          this.serviceForm.reboxprice = math.multiplyTwo(
            this.serviceForm.reboxednum,
            this.serviceForm.packfee,
          ); //打包服务价格
          this.serviceForm.reboardprice = math.multiplyTwo(
            this.serviceForm.reboardnum,
            this.serviceForm.blankfee,
          ); //打扮服务价格
          this.serviceForm.premiumtotalprice = math.multiplyTwo(
            this.serviceForm.premium,
            this.serviceForm.month,
          ); //保价服务价格
          this.serviceForm.premium = math.multiplyTwo(
            this.serviceForm.insuranceamount,
            this.serviceForm.premiumpercent,
          ); //保费
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getStatus();
    this.getList();
  },
  methods: {
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        ElMessage.error("文件大小不超过5M");
        return false
      }
      if (this.fileNameList.length === 5) {
        ElMessage.error("上传附件不能超过5个!");
        return false
      }
        
      this.isLoading = true;
      this.timer = setTimeout(() => {
        this.isLoading = false;
        this.$refs.uploadRef.abort();
        ElMessage.error("上传超时");
      }, 30000);
    },
    handleChange(file) {
      file.url =  URL.createObjectURL(file.raw);
    },
    handleSuccess(response, file, fileList) {
      this.isLoading = false;
      clearTimeout(this.timer);
      if (response.status !== "00000") {
        if (response.code === 3000012) {
          removeToken();
          router.replace("/login");
        }
        return ElMessage.error(response.message);
      } else {
        this.fileNameList.push({
          filelink: response.data.url,
          filename: file.name,
          url:file.url
        });
      }
    },
    handleError() {
      this.isLoading = false;
      clearTimeout(this.timer);
      return this.$message.error("上传失败");
    },
     //删除上传的一个附件
     handleRemove(val) {
      // console.log(item);
      let index = this.fileNameList.findIndex(
        (item) => item.filename == val.filename,
      );
      this.fileNameList.splice(index, 1);
    },
    /**
     * 时间筛选 限制在1年内
     */
    disabledDateFun(time) {
      const choiceDateTime = new Date(this.choiceDate).getTime();
      const minTime = new Date(choiceDateTime).setMonth(
        new Date(choiceDateTime).getMonth() - 12,
      );
      const maxTime = new Date(choiceDateTime).setMonth(
        new Date(choiceDateTime).getMonth() + 12,
      );
      // const min = minTime;
      // const newDate =
      //   new Date(new Date().toLocaleDateString()).getTime() +
      //   24 * 60 * 60 * 1000 -
      //   1;
      // const max = newDate < maxTime ? newDate : maxTime;
      // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
      // if (this.choiceDate) {
      return time.getTime() < minTime || time.getTime() > maxTime;
      // }
      // 若一个日期也没选中 则 返回 当前日期以前日期可选
      // return time.getTime() > newDate;
    },
    // 获取选择的时间，
    calendarChange(dates) {
      const minDate = dates[0];
      const maxDate = dates[1];
      this.choiceDate = minDate.getTime();
      if (maxDate) {
        this.choiceDate = "";
      }
    },
    //处理变为1-12的正整数
    handleInput() {
      // 使用正则表达式限制只能输入1-12的正整数
      this.serviceForm.month = Number(this.serviceForm.month); // 只保留数字
      const num = parseInt(this.serviceForm.month); // 转换为整数

      if (num < 1) {
        this.serviceForm.month = "1"; // 小于1时设置为1
      } else if (num > 12) {
        this.serviceForm.month = "12"; // 大于12时设置为12
      } else {
        this.serviceForm.month = num.toString(); // 在范围内时保持原值
      }
    },

    //获取type下拉框
    getStatus() {
      getstatuselectApi().then((res) => {
        this.statusList = res;
        res.map((item) => {
          item["label"] = item.name;
          item["value"] = item.type;
        });
        this.searchOption.searchList.filter((item) => {
          if (item.prop == "status") {
            item.optionData = res;
          }
        });
      });
    },
    /**
     * 条件检索
     * @param {*} obj
     */
    search(obj) {
      let params = { ...obj };
      if (this.dateRange?.length) {
        params.starttime = this.dateRange[0];
        params.endtime = this.dateRange[1];
      }
      if (this.finishDateRange?.length) {
        params.finishstarttime = this.finishDateRange[0];
        params.finishendtime = this.finishDateRange[1];
      }
      this.params = { ...params, pagenum: 1, pageSize: 10 };
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = 10;
      this.getList();
    },
    /**
     * 清空，重置
     */
    reset() {
      this.searchOption.searchList.map((item) => {
        item.value = "";
        if (item.prop == "status") {
          item.value = 0;
        }
      });
      this.dateRange = [];
      this.finishDateRange = [];
      this.params = {
        status: 0,
        pagenum: 1,
        pageSize: this.params.pageSize,
      };
      this.handleCurrentChange(1);
    },
    /**
     * 初始化数据,获取增值服务数据列表
     */
    getList(val) {
      if (val) {
        this.params.pagenum = val;
      }
      searchAddApi(this.params).then((res) => {
        this.tableData = res.list;
        this.recordTotal = res.recordTotal;
        this.tableOption.tableData = res.list;
        this.tableOption.page.total = res.recordTotal;
      });
    },
    /**
     * flag: 1
     */
    showDetail(row, flag) {
      this.currentClickTbRow = row;
      this.isCheck = true;
      this.insuredchoose = false;
      this.labelchoose = false;
      this.reboardchoose = false;
      this.reboxchoose = false;
      this.fileNameList = [];
      if (flag == 1) {
        this.boundtype = row.boundtype;
        detailApi({ id: row.id }).then((res) => {
          this.insuredchoose = res.valueaddorderVo.insuredchoose ? true : false;
          this.labelchoose = res.valueaddorderVo.labelchoose ? true : false;
          this.reboxchoose = res.valueaddorderVo.reboxchoose ? true : false;
          this.reboardchoose = res.valueaddorderVo.reboardchoose ? true : false;

          this.serviceForm.orderno = res.orderno;
          this.serviceForm.labelfee = res.labelfee;
          this.serviceForm.labelnum = res.labelnum;
          this.serviceForm.labelprice = res.labelprice;
          this.serviceForm.labelremark = res.labelremark;

          this.serviceForm.packfee = res.packfee;
          this.serviceForm.reboxednum = res.reboxednum;
          this.serviceForm.reboxprice = res.reboxprice;
          this.serviceForm.reboxedremark = res.reboxedremark;

          this.serviceForm.blankfee = res.blankfee;
          this.serviceForm.reboardprice = res.reboardprice;
          this.serviceForm.reboardnum = res.reboardnum;
          this.serviceForm.reboardremark = res.reboardremark;

          this.serviceForm.skutotalprice = res.skutotalprice;
          this.serviceForm.insuranceamount = res.insuranceamount;
          this.serviceForm.month = res.month;
          this.serviceForm.premium = res.premium;
          this.serviceForm.premiumtotalprice = res.premiumtotal; //保费

          this.serviceForm.premiumpercent = res.premiumpercent;
          this.serviceForm.valuetotalprice = res.valuetotalprice;
          this.serviceForm.valueaddorderRequiredBo =
            res.valueaddorderRequiredBo;
          // this.valuetotalprice = res.valuetotalprice

          this.fileNameList = res.filerecordList.map((item) => {
            return {
              filename: item.filename,
              filelink: item.filelink,
              url: item.filelink
            };
          });
          this.dialogVisible_service = true;
        });
      } else {
        // 查看 关联单据详情
        this.inboundorderid = row.inboundorderid;
        this.boundtype = row.boundtype;
        this.detailDialog = true;
      }
      this.isEdit = true;
    },
    openDialog(val) {
      this.detailDialog = val;
    },
    showNext() {
      this.isCheck = false;
      this.dialogVisible = true;
      this.isEdit = false; // 创建 ： false, 查看： true
      this.fileNameList = [];
      // this.dialogVisible_service = true
    },
    nextStep() {
      this.isEdit = false;
      // this.dialogVisible_service = true
      this.$refs["orderForm"].validate((valid) => {
        if (valid) {
          nextstepApi(this.orderForm)
            .then((res) => {
              this.serviceForm.orderno = res.orderno;
              this.serviceForm.inboundorderid = res.inboundorderid;
              this.serviceForm.labelfee = res.labelfee;
              this.serviceForm.labelnum = res.labelnum;
              this.serviceForm.labelprice = res.labelprice;
              this.serviceForm.labelremark = res.labelremark;

              this.serviceForm.packfee = res.packfee;
              this.serviceForm.reboxednum = res.reboxednum;
              this.serviceForm.reboxprice = res.reboxprice;
              this.serviceForm.reboxedremark = res.reboxedremark;

              this.serviceForm.blankfee = res.blankfee;
              this.serviceForm.reboardprice = res.reboardprice;
              this.serviceForm.reboardnum = res.reboardnum;
              this.serviceForm.reboardremark = res.reboardremark;

              this.serviceForm.skutotalprice = res.skutotalprice;
              this.serviceForm.insuranceamount = res.insuranceamount;
              this.serviceForm.month = res.month;
              this.serviceForm.premium = res.premium;
              this.serviceForm.premiumtotalprice = res.premiumtotal;

              this.serviceForm.insurancedeductibleexcess =
                res.insurancedeductibleexcess;

              this.serviceForm.premiumpercent = res.premiumpercent;
              this.dialogVisible_service = true;
            })
            .catch((err) => {});
        } else {
          // ElMessage.error('请完善用户信息')
          return false;
        }
      });
    },
    confirmInfo() {
      if (this.isCheck) return (this.dialogVisible_service = false);
      //如果所有勾选框都没有选中
      if (this.boundtype == 1) {
        if (
          !this.insuredchoose &&
          !this.reboxchoose &&
          !this.reboardchoose &&
          !this.labelchoose
        )
          return ElMessage.error("请至少选择一项!");
      } else {
        if (!this.reboxchoose && !this.reboardchoose && !this.labelchoose)
          return ElMessage.error("请至少选择一项!");
      }
      //如果勾选报价，则保额必填
      if (this.labelchoose && !this.serviceForm.labelnum)
        return ElMessage.error("请填写贴标数");
      if (this.reboxchoose && !this.serviceForm.reboxednum)
        return ElMessage.error("请填写装箱数");
      if (this.reboardchoose && !this.serviceForm.reboardnum)
        return ElMessage.error("请填写打板数");
      if (
        this.boundtype == 2 &&
        this.insuredchoose &&
        !this.serviceForm.insuranceamount
      )
        return ElMessage.error("请填写保额");

      if (this.boundtype == 1) {
        this.serviceForm.insuranceamount = Number(
          this.serviceForm.insuranceamount,
        ); // 只保留数字
        const num = parseInt(this.serviceForm.insuranceamount); // 转换为整数
        if (
          this.insuredchoose &&
          num <= this.serviceForm.insurancedeductibleexcess
        ) {
          ElMessage.error("金额需要大于不计免赔金额");
        } else if (
          this.insuredchoose &&
          num > Number(this.serviceForm.skutotalprice)
        ) {
          ElMessage.error("金额不能大于货物总价");
        } else {
          // this.serviceForm.insuranceamount = num.toString(); // 在范围内时保持原值

          this.insuredchoose
            ? (this.serviceForm.insuredchoose = 1)
            : (this.serviceForm.insuredchoose = 0);
          this.labelchoose
            ? (this.serviceForm.labelchoose = 1)
            : (this.serviceForm.labelchoose = 0);
          this.reboardchoose
            ? (this.serviceForm.reboardchoose = 1)
            : (this.serviceForm.reboardchoose = 0);
          this.reboxchoose
            ? (this.serviceForm.reboxchoose = 1)
            : (this.serviceForm.reboxchoose = 0);

          this.serviceForm.files = this.fileNameList;
          this.serviceForm.type = 1;
          //   this.$EventBus.$emit("showLoading");
          this.confirmAdd(this.serviceForm);
        }
      } else {
        this.serviceForm.type = 2;
        this.serviceForm.insuranceamount = 0;
        this.labelchoose
          ? (this.serviceForm.labelchoose = 1)
          : (this.serviceForm.labelchoose = 0);
        this.reboardchoose
          ? (this.serviceForm.reboardchoose = 1)
          : (this.serviceForm.reboardchoose = 0);
        this.reboxchoose
          ? (this.serviceForm.reboxchoose = 1)
          : (this.serviceForm.reboxchoose = 0);
        this.serviceForm.files = this.fileNameList;
        this.confirmAdd(this.serviceForm);
      }
    },
    confirmAdd(option) {
      confirmAddApi(option)
        .then((res) => {
          // console.log("---");
          //   ElMessage.success("新建订单成功!");
          this.dialogVisible = false;
          this.dialogVisible_service = false;
          this.handleClose(true);
          this.params.pagenum = 1;
          this.getList();

          // console.log("2---");
          //   this.$EventBus.$emit("hideLoading");
        })
        .catch((err) => {
          //   this.$EventBus.$emit("hideLoading");
        });
    },
    handleBoundtype(type) {
      this.boundtype = type;
    },
    showInvalid() {
      if (this.selectList.length != 1)
        return ElMessage.error("请选择一条数据!");
      this.actionId = this.selectList[0].id;
      ElMessageBox.confirm("是否作废该增值服务订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        destoryApi({ id: this.actionId }).then((res) => {
          //   ELMessage.success("作废订单成功!");
          this.getList();
        });
      });
    },
   
    handleSelectionChange(val) {
      console.log(val);
      this.selectList = val;
    },
    handleClose(clearable) {
      this.dialogVisible_service = false;
      this.detailDialog = false;
      this.detailDialog_outbound = false;
      this.boundtype = 1;
      this.orderForm = {
        orderno: "",
        type: 1,
      };
      this.isEdit = true;
      this.insuredchoose = false;
      this.reboxchoose = false;
      this.reboardchoose = false;
      this.labelchoose = false;
      this.serviceForm = {
        blankfee: "",
        fileids: "",
        inboundorderid: "",
        insuranceamount: "",
        insurancedeductibleexcess: "",
        labelfee: "",
        labelnum: "",
        labelprice: "",
        labelremark: "",
        month: "",
        orderno: "",
        packfee: "",
        premium: "",
        premiumpercent: "",
        premiumtotalprice: "",
        // priceruleid: '',
        reboardnum: "",
        reboardprice: "",
        reboardremark: "",
        reboxednum: "",
        reboxedremark: "",
        reboxprice: "",
        skutotalprice: "",
        // status: '',
        valuetotalprice: "",
      };
      if (this.dialogVisible || clearable) {
        this.dialogVisible = false;
        // this.$refs["orderForm"].resetFields();
      }
    },
    /**
     *  切换页码，请求分页数据
     * @param {*} currentPage  当前页
     */
    handleCurrentChange(currentPage) {
      this.tableOption.page.currentPage = currentPage;
      this.params.pagenum = currentPage;
      this.getList();
    },
    /**
     *  切换每页展示数据数量
     * @param {*}  pageSize 每页展示 pageSize 条
     */
    handleSizeChange(pageSize) {
      this.params.pagenum = 1;
      this.params.pageSize = pageSize;
      this.tableOption.page.currentPage = 1;
      this.tableOption.page.pageSize = pageSize;
      this.getList();
    },
    exportTb() {
      exportApi(this.params).then((res) => {
        DOWNLOAD_FILE(res);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.upload-input {
  display: none;
}
.flex-shrink0 {
  flex-shrink: 0;
}
.el-pagination {
  float: right;
  display: flex;
  margin-bottom: 10px;
}
.b-label {
  font-weight: 600;
  color: #1f1f20;
}
.price-title {
  font-size: 14px;
  color: #1f1f20;
}
.margin-distance {
  margin: 25px 0;
}
.margin-bottom {
  margin-bottom: 20px;
}
.margin-left {
  margin-left: 15px;
}
.price {
  font-size: 24px;
  font-weight: 900;
}
.tip {
  padding-left: 29px;
}
</style>

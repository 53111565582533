export const rules = reactive([
  {
  label: "产品条码:",
  type: "input",
  value: "",
  prop: "productbarcode",
  maxlength: 50,
  placeholder: '请输入产品条码',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[^\u4e00-\u9fa5（）【】，。？！￥、……——“”：；‘~·]+$/g,
      message: "禁止输入中文及中文符号!",
      trigger: ["blur", "change"],
    }
  ]
},{
  label: '产品类型(一级):',
  type: 'autocomplete',
  value: "",
  prop: 'onelevelname',
  placeholder: '请选择产品类型(一级)',
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '填报重量[kg]:',
  type: 'input',
  value: "",
  prop: 'fillweight',
  maxlength: '10',
  placeholder: '请输入填报重量[kg]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: 'SKU:',
  type: 'input',
  value: "",
  prop: 'sku',
  maxlength: '50',
  disabled: true,
  placeholder: '请输入SKU',
  rules: [{ required: true, message: " 请填写此选项!", trigger: "blur" }]
}, {
  label: '产品类型(二级):',
  type: 'autocomplete',
  value: "",
  prop: 'twolevelname',
  placeholder: '请选择产品类型(二级)',
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '填报长度[cm]:',
  type: 'input',
  value: "",
  prop: 'filllength',
  maxlength: '10',
  placeholder: '请输入填报长度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '中文名:',
  type: 'input',
  value: "",
  prop: 'chinesename',
  maxlength: '300',
  placeholder: '请输入中文名',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    // { pattern: /^[^a-zA-Z]*$/, message: '禁止输入英文!', trigger: ['blur', 'change'] }
  ]
}, {
  label: '产品类型(三级):',
  type: 'autocomplete',
  value: "",
  prop: 'threelevelname',
  placeholder: '请选择产品类型(三级)'
}, {
  label: '填报宽度[cm]:',
  type: 'input',
  value: "",
  prop: 'fillwidth',
  maxlength: '10',
  placeholder: '请输入填报宽度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '英文名:',
  type: 'input',
  value: "",
  prop: 'englishname',
  maxlength: '300',
  placeholder: '请输入英文名',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    // { pattern: /^[^\u4e00-\u9fa5]*$/, message: '禁止输入中文!', trigger: ['blur', 'change'] }
  ]
}, {
  label: 'HSCode:',
  type: 'input',
  value: "",
  prop: 'hscode',
  maxlength: '10',
  placeholder: '请输入HSCode',
  rules: [
    { required: false, pattern: /^\d+$/, message: " 请输入正整数!", trigger: "change" },
  ]
}, {
  label: '填报高度[cm]:',
  type: 'input',
  value: "",
  prop: 'fillheight',
  maxlength: '10',
  placeholder: '请输入填报高度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '具体描述:',
  type: 'input',
  value: "",
  prop: 'description',
  maxlength: '50',
  placeholder: '请输入具体描述'
}, {
  label: '原产地:',
  type: 'select',
  value: "",
  prop: 'countryoforigin',
  placeholder: '请选择原产地',
  options: [],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '质检重量[kg]:',
  type: 'input',
  value: "",
  prop: 'inspectweight',
  maxlength: '10',
  placeholder: '请输入质检重量[kg]',
  note: 'inspect',
  disabled: true,
}, {
  label: '产品单位:',
  type: 'select',
  value: "",
  prop: 'productunit',
  placeholder: '请选择产品单位',
  options: [],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '申报价值(USD):',
  type: 'input',
  value: "",
  prop: 'declaredvalue',
  maxlength: '10',
  placeholder: '请输入申报价值(USD)',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["change"],
    },
  ]
}, {
  label: '质检长度[cm]:',
  type: 'input',
  value: "",
  prop: 'inspectlength',
  maxlength: '10',
  placeholder: '请输入质检长度[cm]',
  note: 'inspect',
  disabled: true
}, {
  label: '品牌:',
  type: 'input',
  value: "",
  prop: 'brand',
  maxlength: '10',
  placeholder: '请输入品牌'
}, {
  label: '产品状态:',
  type: 'select',
  value: '',
  prop: 'productstatus',
  placeholder: '请选择产品状态',
  options: [{ label: '启用', value: '0' }, { label: '未启用', value: '1' }],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '质检宽度[cm]:',
  type: 'input',
  value: "",
  prop: 'inspectwidth',
  maxlength: '10',
  placeholder: '请输入质检宽度[cm]',
  note: 'inspect',
  disabled: true
}, {
  label: '带磁带电:',
  type: 'select',
  value: "",
  prop: 'withmagnetictapeflg',
  placeholder: '请选择产品状态',
  options: [{ label: '否', value: 0 }, { label: '是', value: 1 }],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '库存最低预警:',
  type: 'input',
  value: "",
  prop: 'earlywarningnumber',
  maxlength: '10',
  placeholder: '库存最低预警',
  earlywarningnumber: [
    {
      required: false,
      pattern: /^[0-9]*[0-9][0-9]*$/,
      message: "请输入大于等于0的整数!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '质检高度[cm]:',
  type: 'input',
  value: "",
  prop: 'inspectheight',
  maxlength: '10',
  placeholder: '请输入质检高度[cm]',
  note: 'inspect',
  disabled: true
}])

export const createrules = reactive([{
  label: "产品条码:",
  type: "input",
  value: "",
  prop: "productbarcode",
  maxlength: 50,
  placeholder: '请输入产品条码',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[^\u4e00-\u9fa5（）【】，。？！￥、……——“”：；‘~·]+$/g,
      message: "禁止输入中文及中文符号!",
      trigger: ["blur", "change"],
    }
  ]
}, {
  label: '产品类型(一级):',
  type: 'autocomplete',
  value: "",
  prop: 'onelevelname',
  placeholder: '请选择产品类型(一级)',
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '产品单位:',
  type: 'select',
  value: "",
  prop: 'productunit',
  placeholder: '请选择产品单位',
  options: [],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: 'SKU:',
  type: 'input',
  value: "",
  prop: 'sku',
  maxlength: '50',
  placeholder: '请输入SKU',
  rules: [{ required: true, message: " 请填写此选项!", trigger: "blur" }]
}, {
  label: '产品类型(二级):',
  type: 'autocomplete',
  value: "",
  prop: 'twolevelname',
  placeholder: '请选择产品类型(二级)',
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '产品状态:',
  type: 'select',
  value: '',
  prop: 'productstatus',
  placeholder: '请选择产品状态',
  options: [{ label: '启用', value: '0' }, { label: '未启用', value: '1' }],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '中文名:',
  type: 'input',
  value: "",
  prop: 'chinesename',
  maxlength: '300',
  placeholder: '请输入中文名',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    // { pattern: /^[^a-zA-Z]*$/, message: '禁止输入英文!', trigger: ['blur', 'change'] }
  ]
}, {
  label: '产品类型(三级):',
  type: 'autocomplete',
  value: "",
  prop: 'threelevelname',
  placeholder: '请选择产品类型(三级)'
}, {
  label: '填报重量[kg]:',
  type: 'input',
  value: "",
  prop: 'fillweight',
  maxlength: '10',
  placeholder: '请输入填报重量[kg]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '英文名:',
  type: 'input',
  value: "",
  prop: 'englishname',
  maxlength: '300',
  placeholder: '请输入英文名',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    // { pattern: /^[^\u4e00-\u9fa5]*$/, message: '禁止输入中文!', trigger: ['blur', 'change'] }
  ]
}, {
  label: 'HSCode:',
  type: 'input',
  value: "",
  prop: 'hscode',
  maxlength: '10',
  placeholder: '请输入HSCode',
  rules: [
    { required: false, pattern: /^\d+$/, message: " 请输入正整数!", trigger: "change" },
  ]
},{
  label: '填报长度[cm]:',
  type: 'input',
  value: "",
  prop: 'filllength',
  maxlength: '10',
  placeholder: '请输入填报长度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '原产地:',
  type: 'select',
  value: "",
  prop: 'countryoforigin',
  placeholder: '请选择原产地',
  options: [],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}, {
  label: '具体描述:',
  type: 'input',
  value: "",
  prop: 'description',
  maxlength: '50',
  placeholder: '请输入具体描述'
}, {
  label: '填报宽度[cm]:',
  type: 'input',
  value: "",
  prop: 'fillwidth',
  maxlength: '10',
  placeholder: '请输入填报宽度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '申报价值(USD):',
  type: 'input',
  value: "",
  prop: 'declaredvalue',
  maxlength: '10',
  placeholder: '请输入申报价值(USD)',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["change"],
    },
  ]
}, {
  label: '品牌:',
  type: 'input',
  value: "",
  prop: 'brand',
  maxlength: '10',
  placeholder: '请输入品牌'
}, {
  label: '填报高度[cm]:',
  type: 'input',
  value: "",
  prop: 'fillheight',
  maxlength: '10',
  placeholder: '请输入填报高度[cm]',
  rules: [
    { required: true, message: " 请填写此选项!", trigger: "blur" },
    {
      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
      message: "限制输入到小数点后两位!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '库存最低预警:',
  type: 'input',
  value: "",
  prop: 'earlywarningnumber',
  maxlength: '10',
  placeholder: '库存最低预警',
  earlywarningnumber: [
    {
      required: false,
      pattern: /^[0-9]*[0-9][0-9]*$/,
      message: "请输入大于等于0的整数!",
      trigger: ["blur", "change"],
    },
  ]
}, {
  label: '带磁带电:',
  type: 'select',
  value: "",
  prop: 'withmagnetictapeflg',
  placeholder: '请选择产品状态',
  options: [{ label: '否', value: 0 }, { label: '是', value: 1 }],
  rules: [
    { required: true, message: " 请选择此选项!", trigger: "change" },
  ]
}])




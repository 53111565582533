<template>
  <div class="com-search">
    <el-form
      v-if="searchOption && Object.keys(searchOption).length"
      :inline="true"
      :size="searchOption.size"
      :label-width="searchOption.labelWidth"
    >
      <!--
          searchList:
              label         标签名，必填
              type          表单类型，必填
              value         绑定值，必填
              prop          字段名，必填
              placeholder   提示语，非必填，默认请输入/请选择
              clearable     是否可清空，非必填，默认清空
              optionData    下拉菜单数据，type为select时填
              format        type为date时 显示在输入框中的格式
              valueFormat   type为date时  返回格式
              maxlength     type为input时 长度限制
         -->
      <el-form-item
        v-for="item in searchOption.searchList"
        :key="item.prop"
        :label="item.label"
      >
        <template v-if="item.type === 'select-input'">
          <el-input
            class="input-with-select"
            v-model="item.value"
            v-trim
            :prefix-icon="item.prefixIcon"
            :clearable="item.clearable || true"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            :disabled="item.disabled"
            :class="item.class"
          >
            <template #prepend>
              <el-select
                v-model="item.selectOption.value"
                fit-input-width
                :placeholder="item.selectOption.placeholder || ''"
                :clearable="item.selectOption.clearable"
                v-bind="item.selectOption.props"
              >
                <el-option
                  v-for="option in item.selectOption.optionData || []"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="option.disabled"
                />
              </el-select>
            </template>
          </el-input>
        </template>
        <template v-else-if="item.type === 'input'">
          <el-input
            v-model="item.value"
            v-trim
            :prefix-icon="item.prefixIcon"
            :clearable="item.clearable || true"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            :disabled="item.disabled"
          />
        </template>
        <template v-else-if="item.type === 'select'">
          <el-select
            class="select"
            fit-input-width
            v-model="item.value"
            :placeholder="item.placeholder || ''"
            :clearable="item.clearable"
            v-bind="item.props"
          >
            <el-option
              v-for="option in item.optionData || []"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled"
            />
          </el-select>
        </template>
        <template v-else-if="item.type === 'remote-select'">
            <el-select
              class="select"
              filterable
              remote
              reserve-keyword
              remote-show-suffix
              fit-input-width
              v-model="item.value"
              v-selectMaxlength="item.maxlength || 50"
              :placeholder="item.placeholder"
              :remote-method="(value) => handleRemoteMethods(value, item.prop)"
              @focus="remoteSelectFocus(item.prop)"
              style="width: 100%"
            >
            <el-option
                v-for="(element, i) in item.options"
                :label="element.label"
                :value="element.value"
                :key="i"
              />
            </el-select>

          </template>
        <template v-else-if="item.type === 'select-remote-select'">
          <div class="remote-select-section flex">
            <div class="el-input-group__prepend with-select">
              <el-select
                v-model="item.selectOption.value"
                fit-input-width
                :placeholder="item.selectOption.placeholder || ''"
                :clearable="item.selectOption.clearable"
                v-bind="item.selectOption.props"
                @change="handleSelectChangeMethods"
              >
                <el-option
                  v-for="option in item.selectOption.optionData || []"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="option.disabled"
                />
              </el-select>
            </div>
            <el-select
              filterable
              remote
              clearable
              fit-input-width
              class="remote-select"
              v-model="item.value"
              v-selectMaxlength="item.maxlength || 50"
              :placeholder="item.placeholder"
              :remote-method="(value) => handleRemoteMethods(value, item.selectOption.value)"
              @focus="remoteSelectFocus(item.selectOption.value)"
              @change="changeRemoteSelect($event, item.optionData)"
          >
            <el-option v-for="opt in item.optionData" :key="opt.value" :label="opt.label" :value="opt.value" />
          </el-select>
          </div>
        </template>

        <template v-else-if="item.type === 'autocomplete'">
          <el-autocomplete
            v-model="item.value"
            v-bind="item.attrs"
            :fetch-suggestions="queryAutoComplete"
            :placeholder="item.placeholder"
            :clearable="item.clearable"
            :maxlength="item.maxlength"
            @input="inputAutoComplete"
            @select="handleAutoComplete"
            @focus="inputAutoCompleteFocus(item.prop)"
          />
        </template>
        <template v-else-if="item.type === 'select-autocomplete'">
          <div
            class="el-input el-input-group el-input-group--prepend el-input--suffix input-with-select"
            style="flex-wrap: nowrap"
          >
            <div class="el-input-group__prepend autocomplete-with-select">
              <el-select
                v-model="item.selectOption.value"
                fit-input-width
                :placeholder="item.selectOption.placeholder || ''"
                :clearable="item.selectOption.clearable"
                v-bind="item.selectOption.props"
              >
                <el-option
                  v-for="option in item.selectOption.optionData || []"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="option.disabled"
                />
              </el-select>
            </div>
            <el-autocomplete
              class="select-autocomplete"
              v-model="item.value"
              v-bind="item.attrs"
              :fetch-suggestions="queryAutoComplete"
              :placeholder="item.placeholder"
              :clearable="item.clearable"
              :maxlength="item.maxlength"
              @input="inputAutoComplete"
              @select="handleAutoComplete"
              @focus="inputAutoCompleteFocus(item.prop)"
            />
          </div>
        </template>

        <template v-else-if="item.type === 'date'">
          <slot name="date"></slot>
        </template>
        <template v-else-if="item.type === 'date1'">
          <slot name="date1"></slot>
        </template>
        <template v-else-if="item.type === 'date2'">
          <el-date-picker
            v-model="item.value"
            :type="item.dateType || 'date'"
            :placeholder="item.placeholder || '请选择'"
            :default-time="item.value"
            :format="item.format || 'YYYY-MM-DD'"
            :value-format="item.valueFormat || 'YYYY-MM-DD'"
            :clearable="item.clearable"
            :start-placeholder="item.startPlaceholder || '开始'"
            :end-placeholder="item.endPlaceholder || '结束'"
          />
        </template>
        <!--
              ...
              后续可根据自己的项目添加其他筛选组件
             -->
      </el-form-item>
      <template>
        <slot name="about-time">
          
        </slot>
      </template>
      <template v-if="searchOption.singleRow">
        <el-row type="flex" justify="end">
          <el-form-item>
            <el-button type="primary" icon="Search" @click="searchClick">
              搜索
            </el-button>
            <el-button
              v-if="!searchOption.closeReset"
              icon="Refresh"
              @click="resetClick"
              >清空</el-button
            >
            <!-- 其它操作按钮 -->
            <slot name="specialBtns"></slot>
            <template
              v-if="searchOption.otherBtns && searchOption.otherBtns.length > 0"
            >
              <el-button
                v-for="(btn, index) in searchOption.otherBtns"
                :key="index"
                :type="btn.type || 'primary'"
                @click="otherBtnsClick(btn)"
                >{{ btn.title }}</el-button
              >
            </template>
          </el-form-item>
        </el-row>
      </template>
      <el-form-item v-else>
        <el-button type="primary" icon="Search" @click="searchClick">
          搜索
        </el-button>
        <el-button
          v-if="!searchOption.closeReset"
          icon="Refresh"
          @click="resetClick"
          >清空</el-button
        >
        <!-- 其它操作按钮 -->
        <slot name="specialBtns"></slot>
        <template
          v-if="searchOption.otherBtns && searchOption.otherBtns.length > 0"
        >
          <!--  -->
          <el-button
            v-for="(btn, index) in searchOption.otherBtns"
            :key="index"
            :type="btn.type || 'primary'"
            @click="otherBtnsClick(btn)"
            >{{ btn.title }}</el-button
          >
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive } from "vue";

defineOptions({
  name: "comSearch",
});

const props = defineProps({
  searchOption: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emits = defineEmits([
  "search",
  "reset",
  "otherBtnsClick",
  "remoteMethod",
  "remoteValue",
  "inputRemoteValue",
  "autoCompleteFocus",
  "handleQueryAutoComplete",
  "disabledDate",
  "calendarChange",
  "handleRemoteSelectFocus",
  "handleRemoteMethods",
  "handleSelectChangeMethods"
]);
/**
 * 搜索
 */
const searchClick = () => {
  const Obj = reactive({});
  props.searchOption.searchList.forEach((item) => {
    if (item.selectOption) {
      Obj[item.selectOption.prop] = item.selectOption.value;
    }
    if (item.value !== "" && item.value !== undefined) {
      Obj[item.prop] = item.value;
      // if (item.selectOption) {
      //   Obj[item.selectOption.prop] = item.selectOption.value;
      // }
    }
  });
  // console.log('obj=====', Obj)
  emits("search", Obj);
};
/**
 * 重置、清空
 */
const resetClick = () => {
  emits("reset");
};
/**
 * 除搜索，清空外的 其他操作按钮
 */
const otherBtnsClick = (btn) => {
  emits("otherBtnsClick", btn.prop);
};
/**
 * 远程搜索回掉
 */
const queryAutoComplete = (value, cb) => {
  emits("remoteMethod", cb);
  emits("handleQueryAutoComplete", { value, cb });
};
/**
 * 选择下拉框中的数据时 调用
 */
const handleAutoComplete = (value) => {
  emits("remoteValue", value);
};
/**
 * 输入时 调用
 */
const inputAutoComplete = (value) => {
  emits("inputRemoteValue", value);
};
/**
 * 获取焦点时 ，调用
 */
const inputAutoCompleteFocus = (value) => {
  emits("autoCompleteFocus", value);
};

/**
 * 远程  操作 select下拉框 
 * @param {*} value 输入的值
 * @param {*} prop 操作的对象
 */
// const handleRemoteSelectMethods = (value, type) => {
//     emits('handleRemoteSelectMethods',{value, type})
// }

/**
 * 获取焦点时, 获取当前操作的对象
 */
/**select-remote-select start */
const remoteSelectFocus = (type) => {
  emits('handleRemoteSelectFocus',type)
}
/**
 * 远程  操作 select下拉框 
 * @param {*} value 输入的值
 * @param {*} prop 操作的对象
 */
const handleRemoteMethods = (value, type) => {
  emits('handleRemoteMethods',{value, type})

}

const changeRemoteSelect = (val, list) => {
    console.log(val);

}
 /**
     * 模糊搜索前面， select 下拉框 选择
  */
  const handleSelectChangeMethods = (value) => {
  emits('handleSelectChangeMethods', value)
}
/**select-remote-select  end*/

/**
 * 日期范围
 */
const disabledDate = (time) => {
  emits("disabledDate", time);
};
const calendarChange = (dates) => {
  emits("calendarChange", dates);
};
</script>
<style lang="scss" scoped>
.com-search {
  :deep(.el-form) {
    &.el-form--inline .el-form-item {
      margin-right: 15px;
    }
    .input-with-select {
      .el-input-group__prepend {
        background-color: #fff;
        padding: 0;
        width: 140px;
      }
      &.longText .el-input-group__prepend {
        width: 180px;
      }
    }
    .input-with-select .el-input__wrapper {
      width: 222px;
    }

    .autocomplete-with-select {
      height: 32px;
      margin-top: 1.5px;
      .el-select__wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px 0 0 0 #dcdfe6 inset, 0 0px 0 0 #dcdfe6 inset,
          0 0px 0 0 #dcdfe6 inset;
      }
    }
    .select-autocomplete {
      width: 222px;
      .el-input__wrapper {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .el-input__wrapper,
    .select .el-select__wrapper,
    .el-date-editor {
      width: 282px;
    }
  }

  // remote-select 开始
  .with-select{
    width:140px;
    padding:0;
    background:#fff;
    :deep(.el-select__wrapper) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 1px 0 0 0 #dcdfe6 inset, 0 1px 0 0 #dcdfe6 inset, 0 -1px 0 0 #dcdfe6 inset;
    }
  }
  .remote-select{
    width: 222px;
    :deep(.el-select__wrapper) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
  }
// remote-select 结束
}
</style>
  
  
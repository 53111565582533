/*
 * @Author: vere
 * @Date: 2024-02-02 15:26:10
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 15:41:22
 * @Description: 
 * beforeMount：相当于Vue 2.X中的bind，当元素被插入到DOM前调用
 * mounted：相当于Vue 2.X中的inserted，当绑定元素的父组件被挂载后调用
 */

const routePermission = {
  mounted(el, binding) {
        const permission = binding.value
        const permissionList = JSON.parse(localStorage.getItem('routesPermission')) 
        // console.log('permission', permission)
        // console.log('permissionList', permissionList)
        if (permissionList && !permissionList.includes(permission)) {
          el.style.display = 'none'
          // console.log('隐藏');
        }
      }
}


export default routePermission



export const searchOption = {
    // labelWidth: "auto",
    // singleRow: true,
    searchList: [
        // 检索列表
        {
            label: "SKU:",
            type: "input",
            value: "",
            prop: "sku",
            maxlength: 100,
            placeholder: '请输入SKU'
        },
        {
            label: "仓库:",
            type: "select",
            value: "",
            prop: "storehouseid",
            optionData: [],
            clearable: true,
            placeholder: '请选择仓库'
        },
        {
            label: "商品名称:",
            type: "input",
            value: "",
            prop: "productname",
            maxlength: 100,
            placeholder: '请输入商品名称'
        },
    ],
}
export const tableOption = {
    height: 450,
    index: true,
    highlightCurrentRow: true,
    tableColumn: [
        {
            label: "SKU",
            prop: "sku",
            align: 'left'
        },
        {
            label: "商品名称",
            prop: "productname",
            align: 'left'
        },
        {
            label: "仓库",
            prop: "warehouse",
        },
        {
            label: "总量（库内）",
            prop: "stockquantity",
        },
        {
            label: "锁定数量",
            prop: "lockquantity",
        },
        {
            label: "可用数量",
            prop: "availableQty",
        },
        {
            label: "在途数量",
            prop: "transitquantity",
        },
    ],

    // 表格数据，必填
    tableData: [],
    page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
    
}

export const searchOptionDetail = {
    labelWidth: "auto",
    searchList: [
        // 检索列表
        {
            label: "SKU：",
            type: "input",
            value: "",
            prop: "sku",
            disabled: true
        },
        {
            label: "仓库：",
            type: "input",
            value: "",
            prop: "store",
            disabled: true
        },
        {
            label: "库区：",
            type: "select",
            value: "",
            prop: "zone",
            optionData: [],
            clearable: true,
        },
        {
            label: "货架：",
            type: "select",
            value: "",
            prop: "shelf",
            optionData: [],
            clearable: true,
        },
        {
            label: "批次号：",
            type: "input",
            value: "",
            prop: "asnno",
            maxlength: 100,
        },
        {
            label: "货位：",
            type: "select",
            value: "",
            prop: "location",
            optionData: [],
            clearable: true,
        },
    ],
}
export const tableOptionDetail = {
    height: 550,
    index: true,
    tableColumn: [
        {
            label: "库区",
            prop: "zone",
        },
        {
            label: "货架",
            prop: "shelf",
        },
        {
            label: "货位",
            prop: "location",
        },
        {
            label: "批次号（入库单号）",
            prop: "batchno",
            minWidth: "180"
        },
        {
            label: "库存（总和）",
            prop: "stockquantity",
        },
        {
            label: "锁定",
            prop: "lockquantity",
        },
        {
            label: "可用库存",
            prop: "availablestock",
        },
    ],

    // 表格数据，必填
    tableData: [],
    page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
    
}
import { reactive } from "vue"

/**入库计费明细报表 */

export const tableOption = reactive({
  height: 550,
  index: true, // 是否展示序号 非必填
  tableColumn: [
    {
      label: "完成时间",
      prop: "confirmtime",
      minWidth: "180",
      align:'left'
    },
    {
      label: "入库订单号/批次号",
      prop: "inboundorderno",
      minWidth: "200",
    },
    {
      label: "参考号",
      prop: "remarkno",
      minWidth: "180",
    },
    {
      label: "入库仓库",
      prop: "storehouse",
      minWidth: "160",
    },
    {
      label: "卸货单位",
      prop: "unloadingunit",
      minWidth: "100"
    },
    {
      label: "卸货数量",
      prop: "unloadingqty",
      minWidth: "100",
      align:"center"
    },
    {
      label: "收箱数",
      prop: "boxes",
      minWidth: "100",
      align:"center"
    },
    {
      label: "入库总费用（人民币）",
      prop: "totalcost",
      minWidth: "180",
      align:"right"
    },
    {
      label: "备注",
      prop: "remark",
      minWidth: "100",
      align:"center"
    },
  ],

  // 表格数据，必填
  tableData: [],
  operationColumn: {
    label: '费用详情',
    fixed: "right",  // 是否fixed
    minWidth: "60", // 最小宽度
    btns: [
      {
        title: "查看",
        prop: "detail",
        type: "primary",
        size: "small",
      },
    ],
  },
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  operationBtn: [
    {
      title: "导出",
      prop: "export",
    },
  ],
})


export const costTable = reactive([
  // 基础费用
  {
    tabName: "费用明细-基础费用",
    label: "basic",
    activeName: "0",
    height: 300,
    tableColumn: [
      {
        label: "卸货单位",
        prop: "unloadingunit",
      },
      {
        label: "单价（人民币）",
        prop: "price",
      },
      {
        label: "卸货数量",
        prop: "unloadingqty",
      },
      {
        label: "费用（人民币）",
        prop: "basicfee",
      },
    ],
    tableData: [],
  },
  // 整柜超限
  {
    tabName: "费用明细-整柜超限",
    label: "fullbox",
    activeName: "1",
    height: 300,
    tableColumn: [
      {
        label: "基础箱数",
        prop: "basicboxes",
      },
      {
        label: "卸货箱数",
        prop: "boxes",
      },
      {
        label: "超限箱数",
        prop: "overlimitboxes",
      },
      {
        label: "单价（元/箱）",
        prop: "extraprice",
      },
      {
        label: "费用（人民币）",
        prop: "extrafee",
      },
    ],
    tableData: [],
  },
  // 散箱超限
  {
    tabName: "费用明细-散箱超限",
    label: "loosebox",
    activeName: "2",
    height: 300,
    index: true,
    tableColumn: [
      {
        label: "超限箱号",
        prop: "boxno",
      },
      {
        label: "箱净重=箱内所有商品质检重量和（kg）",
        prop: "boxweight",
        minWidth:'200'
      },
    ],
    tableData: [],
  },
 
])








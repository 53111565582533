/*
 * @Author: vere
 * @Date: 2024-02-02 14:26:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-02 19:29:45
 * @Description: 
 */
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'default-passive-events'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


import '@/utils/browser-patch'
import '@/utils/upload'
import router from './router'

import 'virtual:uno.css'
import '@unocss/reset/tailwind.css'

// 需要放在 reset/tailwind.css 之后引入，以防止 tailwind 覆盖了 button 按钮样式
import './styles/index.scss'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
import operatorPermission from './directives/operatorPermission.js'
app.directive('permission', operatorPermission)

// 指令
import Directive from './directives'
app.use(Directive)
import '@/directives/loadmore'



// 防抖
// import setVueClickGlobalThrottle from "@/utils/vue-click-global-throttle";
// setVueClickGlobalThrottle(app);

// element-plus 中文展示
app.use(ElementPlus, {
  locale: zhCn,
})
// pinia
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia).use(ElementPlus).use(router).mount('#app')

  
// dayjs
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // 导入UTC插件
dayjs.extend(utc);
app.config.globalProperties.$dayjs = dayjs;

// 导入 SweetAlert2 CSS 样式表 弹框
import Swal from 'sweetalert2'
// 创建 Swal 对象并将其挂载到 Vue 原型上
app.config.globalProperties.$swal = Swal;

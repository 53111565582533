/**增值服务报表 */
export const searchOption = reactive({
    // 顶部表单检索内容,非必填
    // singleRow: true,
    labelWidth:'100px',
    searchList: [
      // 检索列表
      {
        label: "日期:",
        type: "date",
        value: '',
        prop: "daterange",
        // dateType: "daterange",
        // clearable: false,
        // pickerOptions: pickerOptions()
      },
      {
        label: "流水号:",
        type: "input",
        value: "",
        prop: "feecode",
        maxlength: 20,
        placeholder: '请输入流水号'
      },
      {
        label: "物流运单号:",
        type: "input",
        value: "",
        prop: "waybill",
        maxlength: 20,
        placeholder: '请输入物流运单号'
      },
      {
        label: "费用类型:",
        type: "select",
        value: "",
        prop: "changetype",
        optionData: [],
        placeholder: '请选择费用类型'
      },
      {
        label: "费用类型:",
        type: "select",
        value: "0",
        prop: "type",
        optionData: [{label: '全部', value: "0"},{label: '入账', value: "1"},{label: '出帐', value: "2"}, ],
        clearable: false
      },
      {
        label: "关联订单号:",
        type: "input",
        value: "",
        prop: "orderno",
        maxlength: 20,
        placeholder: '请输入关联订单号'
      },
    ]
  })
  export const tableOption = reactive({
    height: 450,
    index: true, // 是否展示序号 非必填
    tableColumn: [
      {
        label: "时间",
        prop: "createtime",
        minWidth: "200",
        align:'left'
      },
      {
        label: "费用类型",
        prop: "changetype",
        minWidth: "140",
        align:'left'
      },
      {
        label: "流水号",
        prop: "feecode",
        minWidth: "200",
        align:'left'
      },
      {
        label: "类型",
        prop: "type",
        minWidth: "100",
      },
      {
        label: "入账(CNY)",
        prop: "amountin",
        minWidth: "120",
        align:'right'
      },
      {
        label: "出账(CNY)",
        prop: "amountout",
        minWidth: "120",
        align:'right'
      },
      {
        label: "余额",
        prop: "balance",
        minWidth: "120",
        align:'right'
      },
      {
        label: "关联订单号",
        prop: "relateorderno",
        minWidth: "200",
        align:'left'
      },
      {
        label: "物流运单号",
        prop: "waybillno",
        minWidth: "200",
        align:'left'
      },
      {
        label: "备注",
        prop: "reason",
        minWidth: "160",
        align:'left'
      },
    ],
    // 表格数据，必填
    tableData: [],
    page: {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    },
  })
  
  
  
  
  
  
<template>
  <el-row>
    <el-col :span="24">
      <div class="request-example" v-if="titleZh">
        <span class="gray">{{ titleZh }}</span>
        <span class="black1">{{ titleEn }}</span>
      </div>
      <el-table :data="tableSource" border style="width: 100%;margin:0;">
        <el-table-column
          v-for="item in columns"
          :key="item.key"
          :prop="item.key"
          :label="item.label"
          :width="item.width"
        >
          <template #header>
            <div class="table-header">
              <span class="gray">{{ item.titleZh }}</span>
              <span class="black1">{{ item.titleEn }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    titleZh: {
      type: String,
      required: false,
    },
    titleEn: {
      type: String,
      required: false,
    },
    tableSource: Array,
    columns: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "../anchor.scss";
</style>
import dayjs from "dayjs"
export const searchOption = reactive({
    labelWidth: "80px",
    // singleRow: true,
    searchList: [
        // 检索列表
        {
            label: "",
            type: "select-input",
            value: "",
            prop: "orderno",
            maxlength: 30,
            placeholder: '请输入对应单号/参考号',
            selectOption: {
                value: "0",
                prop: "querytype",
                optionData: [{ value: "0", label: "入库单号(ASN)" },
                { value: "1", label: "交货物流单号" }, { value: "2", label: "参考号" },],
                clearable: false,
            },
        },
        {
            label: "仓库:",
            type: "select",
            value: "",
            prop: "warehouseid",
            optionData: [],
            clearable: true,
            placeholder: '请选择仓库'
        },

        {
            label: "创建时间:",
            type: "date",
            // dateType: "daterange",
            value: '',
            prop: "daterange",
            // clearable: false,
        },
    ],
})

export const inboundOrderTable = reactive([
    // 草稿
    {
        tabName: "草稿",
        label: "draft",
        count:0,
        activeName: "-5",
        height: 500,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "物流商",
                prop: "expresscompany",
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "运单状态",
                prop: "status",
                minWidth: '140'
            },
            {
                label: "仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "180", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "删除",
                    prop: "delete",
                    type: "primary",
                    plain: true,
                },
                {
                    title: "编辑",
                    prop: "edit",
                    type: "primary",
                    plain: true,
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 未收货
    {
        tabName: "未收货",
        label: "unreceived",
        count:0,
        activeName: "0",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "物流商",
                prop: "expresscompany",
                minWidth: '140'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "运单状态",
                prop: "status",
                minWidth: '140'
            },
            {
                label: "仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "260", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "作废",
                    prop: "cancel",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 已收货
    {
        tabName: "已收货",
        label: "received",
        count:0,
        activeName: "10",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "入库仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "客户名称",
                prop: "customer",
                minWidth: '140'
            },
            {
                label: "客户简称",
                prop: "customercode",
                minWidth: '140'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "220", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },

    },
    // 已质检
    {
        tabName: "已质检",
        label: "qualified",
        count:0,
        activeName: "20",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "入库仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "客户名称",
                prop: "customer",
                minWidth: '140'
            },
            {
                label: "客户简称",
                prop: "customercode",
                minWidth: '140'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "220", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 已上架
    {
        tabName: "已上架",
        label: "listed",
        count:0,
        activeName: "30",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "入库仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "客户名称",
                prop: "customer",
                minWidth: '140'
            },
            {
                label: "客户简称",
                prop: "customercode",
                minWidth: '140'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "220", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 已完成
    {
        tabName: "已完成",
        label: "completed",
        count:0,
        activeName: "90",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "入库仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "客户名称",
                prop: "customer",
                minWidth: '140'
            },
            {
                label: "客户简称",
                prop: "customercode",
                minWidth: '140'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "220", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
    // 作废订单
    {
        tabName: "作废订单",
        label: "qualified",
        count:0,
        activeName: "100",
        height: 550,
        index: true,
        tableColumn: [
            {
                label: "入库单",
                prop: "inboundorderno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "增值服务",
                prop: "valueaddorlist",
                minWidth: "200",
                align:'left'
            },
            {
                label: "入库分类",
                prop: "type",
                minWidth: '140'
            },
            {
                label: "预约箱数",
                prop: "boxqty",
                minWidth: '140'
            },
            {
                label: "创建人",
                prop: "createby",
                minWidth: '150',
                align:'left'
            },
            {
                label: "创建时间",
                prop: "createtime",
                minWidth: '200',
                align:'left'
            },
            {
                label: "预计到达时间",
                prop: "estinboundtime",
                minWidth: '120',
                align:'left'
            },
            {
                label: "参考号",
                prop: "remarkno",
                minWidth: "200",
                align:'left'
            },
            {
                label: "交货物流单号",
                prop: "referenceno",
                minWidth: '200',
                align:'left'
            },
            {
                label: "入库仓库",
                prop: "warehouse",
                minWidth: '160'
            },
            {
                label: "客户名称",
                prop: "customer",
                minWidth: '140'
            },
            {
                label: "客户简称",
                prop: "customercode",
                minWidth: '140'
            },
            {
                label: "入库类型",
                prop: "deliverytype",
                minWidth: '140'
            },
            {
                label: "备注",
                prop: "remark",
                minWidth: '150'
            },

        ],
        tableData: [],
        operationColumn: {
            minWidth: "220", // 最小宽度
            fixed: "right",
            btns: [
                {
                    title: "复制",
                    prop: "copy",
                    type: "primary",
                },
                {
                    title: "查看",
                    prop: "detail",
                    type: "primary",
                },
                {
                    title: "箱标",
                    prop: "label",
                    type: "primary",
                },
                {
                    title: "箱单",
                    prop: "count",
                    type: "primary",
                },

            ],
        },
        page: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
        },
    },
])

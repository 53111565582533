
import { POST, GET } from '@/utils/request'
/**  get请求用params , POST请求用data */

export const loginApi = (data) => POST({ url: '/login', data })
export const logoutApi = () => POST({ url: '/logout' })

export const rsaPublicKeyApi = () => GET({ url: '/getRsaPublicKey' })

// 修改密码
export const updatePwdApi = (params) => GET({ url: '/indexuser/updatePwd', params })
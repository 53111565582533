<template>
  <div>
    <el-card shadow="never">
      <div class="nav">
        <Steps ref="step" :tabIndex="Number(tabIndex)" @switchTab="switchTab"
          >导航</Steps
        >
      </div>
    </el-card>

    <div type="border-card">
      <!-- 入库信息 -->
      <div :disabled="false" label="入库信息" name="0" v-show="isActive(0)">
        <el-card shadow="never" class="card-margin">
          <el-row>
            <el-col :span="24">
              <div class="inbound-info-content mt-lg">
                <el-form
                  ref="createOrderForm"
                  :model="createOrderForm"
                  :rules="formRules"
                  label-width="200px"
                  label-position="top"
                >
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="目的仓库" prop="inboundstorehouseid">
                        <el-select
                          v-model="createOrderForm.inboundstorehouseid"
                          style="width: 100%"
                          placeholder="请选择目的仓库"
                          @change="handleStorehouseChange"
                        >
                          <el-option
                            v-for="item in locationStoreOptions"
                            :key="item.id"
                            :label="item.namecn"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="入库分类" prop="type">
                        <el-select
                          v-model="createOrderForm.type"
                          style="width: 100%"
                          placeholder="请选择入库分类"
                        >
                          <el-option
                            v-for="(item, index) in inBoundTypeOptions"
                            :key="index"
                            :label="item.name"
                            :value="item.type"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="物流商" prop="expresscompanyid">
                        <el-select
                          v-model="createOrderForm.expresscompanyid"
                          @change="expresscompanyChange"
                          style="width: 100%"
                          placeholder="请选择物流商"
                          :disabled="country == 1"
                        >
                          <el-option
                            v-for="(item, index) in providerOptions"
                            :key="index"
                            :label="item.name"
                            :value="item.type"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="入库类型" prop="deliverytype">
                        <el-select
                          clearable
                          v-model="createOrderForm.deliverytype"
                          style="width: 100%"
                          placeholder="请选择入库类型"
                          @change="deliverytypeChange"
                        >
                          <el-option
                            v-for="(item, index) in receiptTypeOptions"
                            :key="index"
                            :label="item.name"
                            :value="item.type"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item
                        :prop="disabled ? '' : 'referenceno'"
                        :label="`交货物流单号(${country == 1 ? '华南电商仓收货' : '海外仓收货'})`"
                      >
                        <el-input
                          v-trim
                          clearable
                          placeholder="请输入单号"
                          :disabled="disabled"
                          style="width: 100%"
                          maxlength="50"
                          v-model="createOrderForm.referenceno"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="estinboundtime" label="预计到仓时间">
                        <el-date-picker
                          clearable
                          v-model="createOrderForm.estinboundtime"
                          style="width: 100%"
                          type="date"
                          placeholder="请选择预计到仓时间"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="remarkno" label="参考号">
                        <el-input
                          v-trim
                          clearable
                          style="width: 100%"
                          maxlength="50"
                          placeholder="请输入参考号"
                          v-model="createOrderForm.remarkno"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                            <el-button style="margin-top: 40px;" type="primary">物流下单(退货)</el-button>
                        </el-col> -->
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <!-- 出库下单 -->
        <el-card class="box-card" shadow="never">
          <template #header>
            <el-row>
              <el-col :span="12">
                <span>产品信息</span>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button
                  style="margin: 0 20px"
                  type="primary"
                  plain
                  @click="Template"
                  icon="download"
                  >下载模板</el-button
                >
                <el-upload
                  style="float: right"
                  class="upload-demo"
                  action="action"
                  :auto-upload="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  :show-file-list="false"
                >
                  <el-button
                    icon="download"
                    type="primary"
                    plain
                    class="btn leftBtn"
                  >
                    批量导入
                  </el-button>
                </el-upload>
              </el-col>
            </el-row>
          </template>

          <el-form
            ref="goodItem"
            :rules="goodItemRules"
            :model="goodItem"
            label-position="top"
          >
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item prop="boxno" label="箱号">
                  <el-input
                    clearable
                    style="width: 100%"
                    maxlength="5"
                    v-model.trim="goodItem.boxno"
                    placeholder="请输入箱号"
                    v-input-number="0"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="sku" label="SKU">
                  <el-select
                    style="width: 100%"
                    ref="select"
                    filterable
                    remote
                    reserve-keyword
                    v-selectMaxlength="50"
                    v-model="goodItem.sku"
                    value-key="sku"
                    placeholder="请输入SKU"
                    :remote-method="getSku"
                    @change="selectSku"
                  >
                    <!--  -->
                    <el-option
                      v-for="item in skuList"
                      :key="item.sku"
                      :label="item.sku"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item prop="qty" :label="`产品数量（${unit}）`">
                  <el-input
                    v-trim
                    v-input-limit="0"
                    min="1"
                    maxlength="10"
                    clearable
                    v-model="goodItem.qty"
                    placeholder="请输入产品数量"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="add-btn">
                  <el-button type="primary" @click="AddGoodItem" icon="Plus">
                    <!-- <el-icon :size="14"><DocumentAdd /></el-icon> -->
                    添加
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            border
            :data="goodList"
            :header-cell-style="{ background: '#FAFAFA' }"
            style="margin: 10px 0"
            height="320"
          >
            <el-table-column
              prop="boxno"
              :resizable="false"
              label="箱号"
              align="center"
            />
            <el-table-column prop="sku" :resizable="false" label="SKU" />
            <el-table-column
              prop="qty"
              :resizable="false"
              label="产品数量"
              align="center"
            />
            <el-table-column
              prop="date"
              :resizable="false"
              label="操作"
              align="center"
            >
              <template #default="scope">
                <el-button size="small" @click="deleteItem(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>

          <el-row>
            <el-col :span="1" align="top"> 备注: </el-col>
            <el-col :span="10">
              <el-input
                v-trim
                type="textarea"
                placeholder="请输入"
                v-model="createOrderForm.remark"
                maxlength="30"
                show-word-limit
              >
              </el-input>
            </el-col>
          </el-row>
        </el-card>
      </div>

      <!-- Buffalo物流单 -->
      <div
        :disabled="false"
        :label="
          createOrderForm.deliverytype == 10
            ? 'Buffalo物流单(退货)'
            : 'Buffalo物流单'
        "
        name="1"
        v-show="isActive(1)"
      >
        <!-- 收件人信息 -->
        <el-form
          ref="inboundtrackingorder"
          :rules="senderInfoRules"
          :model="inboundtrackingorder"
          label-position="top"
        >
          <el-card shadow="never" class="card-margin">
            <!-- 正常下单的目的仓库 -->
            <template v-if="createOrderForm.deliverytype != 10">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="国内揽件仓库" prop="house">
                    <el-select
                      :disabled="createOrderForm.deliverytype == 10"
                      style="width: 100%"
                      v-model="inboundtrackingorder.house"
                      placeholder="请选择"
                    >
                      <el-option label="广州仓库" value="0"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="物流单号(国内转运仓收件)"
                    prop="trackingno"
                  >
                    <el-input
                      v-trim
                      v-model="inboundtrackingorder.trackingno"
                      maxlength="50"
                      placeholder="请输入物流单号"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="物流产品" prop="ordertype">
                    <el-select
                      style="width: 100%"
                      v-model="inboundtrackingorder.ordertype"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in orderType"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item
                    v-if="inboundtrackingorder.ordertype == 90"
                    label="Terms"
                    prop="largecargocategoryid"
                  >
                    <el-select
                      style="width: 100%"
                      v-model="inboundtrackingorder.largecargocategoryid"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in Terms"
                        :key="item.categoryid"
                        :label="item.name"
                        :value="item.categoryid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>

            <!-- 退货的目的仓库 -->
            <template v-else>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="目的仓库" prop="house">
                    <el-select
                      disabled
                      style="width: 100%"
                      v-model="returnHouse"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in locationStoreOptions"
                        :key="item.id"
                        :label="item.namecn"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-card>
          <!-- 发件人信息 -->
          <el-row>
            <el-col :span="24">
              <el-card id="receiverInfo" shadow="never">
                <template #header>
                  <div class="clearfix">
                    <div style="float: left">发件人信息</div>
                    <!-- 本期隐藏发件人按钮?? -->
                    <el-button
                      v-if="false"
                      type="primary"
                      style="
                        border-color: #fff;
                        color: #fff;
                        position: absolute;
                        right: 0;
                      "
                      @click="showAddress()"
                      >常用发件人</el-button
                    >
                  </div>
                </template>

                <template v-if="createOrderForm.deliverytype !== 10">
                  <el-row :gutter="20" class="row-item">
                    <el-col :span="12">
                      <el-form-item prop="sender" label="发件人英文名">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sender"
                          maxlength="50"
                          placeholder="限英文/数字/空格"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        prop="sendercompanyen"
                        label="发件人公司英文名"
                      >
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sendercompanyen"
                          maxlength="200"
                          placeholder="请输入发件人公司英文名"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row-item">
                    <el-col :span="12">
                      <el-form-item label="发件公司">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sendercompany"
                          maxlength="50"
                          placeholder="请输入发件人公司"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="城市">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sendercity"
                          maxlength="50"
                          placeholder="请输入城市"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row-item">
                    <el-col :span="12">
                      <el-form-item prop="sendertelephone" label="发件人电话">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sendertelephone"
                          maxlength="50"
                          placeholder="+27"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="社会信用代码">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.creditcode"
                          placeholder="请输入社会信用代码"
                          maxlength="50"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="row-item">
                      <el-form-item prop="senderaddress" label="发件地址(英文)">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.senderaddress"
                          placeholder="请输入发件地址(英文)"
                          maxlength="100"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" class="row-item">
                      <el-form-item label="发件地址(中文)">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.senderaddress2"
                          maxlength="100"
                          placeholder="请输入发件地址(中文)"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>

                <template v-else>
                  <el-row :gutter="20" class="row-item">
                    <el-col :span="12">
                      <el-form-item prop="sender" label="发件人英文名">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sender"
                          maxlength="50"
                          placeholder="限英文/数字/空格"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="sendercompanyen" label="公司名称">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.sendercompanyen"
                          maxlength="200"
                          placeholder="请输入公司名称"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12" class="row-item">
                      <el-form-item prop="sendertelephone" label="发件人电话">
                        <div class="flex-form-item">
                          <el-select
                            disabled
                            v-model="value"
                            placeholder="请选择"
                          >
                            <el-option label="+27" value="0"> </el-option>
                          </el-select>

                          <el-input
                            v-trim
                            v-model="inboundtrackingorder.sendertelephone"
                            maxlength="9"
                            placeholder="请输入发件人电话"
                          />
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Email">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.email"
                          placeholder="请输入Email"
                          maxlength="100"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row-item">
                    <el-col :span="12">
                      <el-form-item
                        id="citySuburb"
                        prop="citySuburb"
                        label="城市/Suburb"
                      >
                        <el-select
                          style="width: 100%"
                          v-model="inboundtrackingorder.citySuburb"
                          v-loadMore="loadmoreCitySuburb"
                          filterable
                          remote
                          :teleported="false"
                          reserve-keyword
                          placeholder="城市/Suburb"
                          @focus="onFocusSuburb"
                          :remote-method="queryCitySuburb"
                          :loading="loading"
                          @change="selectCitySuburb"
                        >
                          <el-option
                            v-for="item in SuburbOptions"
                            :key="item.id"
                            :label="getCitySuburbOptVal(item)"
                            :value="getCitySuburbOptVal(item)"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        id="postcode"
                        prop="postcode"
                        label="PostCode"
                      >
                        <el-select
                          style="width: 100%"
                          ref="select"
                          v-model="inboundtrackingorder.postcode"
                          filterable
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in postcodeOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="row-item">
                      <el-form-item prop="senderaddress" label="发件地址(英文)">
                        <el-input
                          v-trim
                          v-model="inboundtrackingorder.senderaddress"
                          maxlength="200"
                          placeholder="请输入发件地址(英文)"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
              </el-card>
            </el-col>
          </el-row>
        </el-form>
        <!-- 重量统计 -->
        <el-row>
          <el-col :span="24">
            <el-card id="weightInfo" shadow="never">
              <template #header>
                <div class="clearfix">
                  <div>
                    重量统计
                    <span class="totalWeight"
                      >总重量 : {{ totalWeight }} kg</span
                    >
                  </div>
                </div>
              </template>

              <el-row style="margin: 10px 0"></el-row>
              <el-table
                :header-cell-style="{ background: '#FAFAFA' }"
                :data="tableData_weight"
                border
                style="width: 100%"
                height="300"
              >
                <el-table-column
                  :resizable="false"
                  prop="boxno"
                  label="箱号"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  prop="estweight"
                  label="预计重量"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div label="增值服务" name="2" :disabled="false" v-show="isActive(2)">
        <el-card shadow="never" class="card-margin">
          <span class="addvalueService">增值服务</span>
          <add-value
            ref="addvalueform"
            :insuredshow="true"
            :skutotalprice="skutotalprice"
            :addvalueformInitData="addvalueformInitData"
            :sncodeqty="sncodeqty"
            @getAddValueForm="addvaluechange"
          />
        </el-card>
      </div>
      <div label="订单确认" name="3" :disabled="false" v-show="isActive(3)">
        <el-card shadow="never" class="card-margin">
          <div class="info">
            <el-row v-if="addressInfo.cnhuborderflg == 1">
              <el-col :span="20">
                <el-form
                  label-position="top"
                  :model="inboundtrackingorder"
                  ref="confirmOrderInfo"
                  label-width="180px"
                  class="demo-ruleForm"
                >
                  <el-form-item
                    :label="orderTitle"
                    prop="trackingno"
                    :rules="[{ required: true, message: '物流单号不能为空' }]"
                  >
                    <!-- <el-form-item label="物流单号（国内转运仓收件）" prop="trackingno" :rules="[
                  { required: true, message: '物流单号不能为空' },
                ]"> -->
                    <el-input
                      v-trim
                      disabled
                      style="width: 180px"
                      v-model="inboundtrackingorder.trackingno"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>

            <div class="key-title">请将包裹寄送至以下仓库:</div>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >仓库:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
                >{{ addressInfo.hubname }}</el-col
              >
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >仓库地址:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
                >{{ addressInfo.hubaddress }}</el-col
              >
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >收件人:</el-col
              >
              <el-col :span="20" class="text-[14px] text-[var(--title-color)]">
                <div class="font-semibold">
                  {{ addressInfo.hubreceiver }} 电话:{{ addressInfo.hubtel }}
                </div>
                <div v-if="addressInfo.cnhuborderflg !== 1">
                  {{ addressInfo.hubaddressen }} Consignee:
                  {{ addressInfo.hubreceiveren }} Tel:{{ addressInfo.hubtel }}
                </div>
              </el-col>
            </el-row>

            <el-divider class="divider" />
            <!-- <el-row v-if="addressInfo.cnhuborderflg !== 1">
            <el-col :span="4" class="text-[14px] text-[var(--title-color)]">{{ addressInfo.hubaddressen }} </el-col>
            <el-col :span="20" class="text-[14px] text-[var(--title-color)]">
              Consignee: {{ addressInfo.hubreceiveren }} Tel:{{addressInfo.hubtel }}11111</el-col>
          </el-row> -->

            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >预估运费:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                ￥ {{ orderPreview.estdeliveryfee }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >未扣运费合计({{
                  orderPreview.unpaiddeliveryfeeqty
                }}单):</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                ￥ {{ orderPreview.unpaiddeliveryfee }}
                <span
                  class="describe text-[14px] text-[var(--title-color)] font-normal"
                  >说明:出库后开始计费</span
                >
              </el-col>
            </el-row>

            <el-divider class="divider" />

            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >预估{{ addressInfo.hubname }}入库费:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                {{ orderPreview.estinboundfee }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >未扣入库费合计({{
                  orderPreview.unpaidinboundfeeqty
                }}单):</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                {{ orderPreview.unpaidinboundfee }}
              </el-col>
            </el-row>

            <el-divider class="divider" />

            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >预估{{ addressInfo.hubname }}增值服务费:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                ￥ {{ orderPreview.estvalueaddedfee }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >未扣入库增值服务费合计({{
                  orderPreview.unpaidvalueaddedfeeqty
                }}单):</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                ￥ {{ orderPreview.unpaidvalueaddedfee }}
              </el-col>
            </el-row>

            <el-divider class="divider" />

            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >账户余额:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                {{ orderPreview.balance }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >授信额度:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                {{ orderPreview.creditline }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="text-[14px] text-[var(--title-color)]"
                >当前必须充值金额:</el-col
              >
              <el-col
                :span="20"
                class="text-[14px] text-[var(--title-color)] font-semibold"
              >
                ￥ {{ orderPreview.needrechargeamount }}
                <span
                  class="describe text-[14px] text-[var(--title-color)] font-normal"
                >
                  说明：您的货品在海外仓入库前不会扣费，此处为预计扣款提示。
                </span>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div label="打印" name="4" :disabled="false" v-show="isActive(4)">
        <el-card shadow="never" class="card-margin">
          <div class="key-title box-info-title">箱标</div>
          <el-row :span="24" :gutter="10">
            <div>
              <el-radio-group v-model="Boxlabel">
                <el-radio :value="4">A4</el-radio>
                <el-radio :value="100">100*100</el-radio>
              </el-radio-group>
            </div>
            <div>
              <el-button
                style="margin-left: 20px"
                type="primary"
                icon="printer"
                @click="PrintLabel"
                >打印箱标</el-button
              >
            </div>
          </el-row>
        </el-card>
        <el-card shadow="never" class="card-margin">
          <div class="key-title box-info-title">箱单</div>
          <el-row :gutter="10">
            <div>
              <el-radio-group v-model="Packingslip">
                <el-radio :value="100">100*100</el-radio>
                <el-radio :value="60">100*60</el-radio>
                <el-radio :value="30">100*30</el-radio>
              </el-radio-group>
            </div>
            <div>
              <el-button
                style="margin-left: 20px"
                type="primary"
                icon="printer"
                @click="PrintList"
                >打印箱单</el-button
              >
            </div>
          </el-row>
        </el-card>
      </div>
      <el-card shadow="never" class="card-margin">
        <el-row justify="space-between">
          <el-col :span="12">
            <el-button v-if="tabIndex != 0" type="primary" @click="backStep"
              >返回上一步</el-button
            >
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button v-if="tabIndex < 3" type="primary" plain @click="Draft"
              >暂存</el-button
            >
            <el-button type="primary" @click="Save">确定</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <!-- 联系人弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      id="address"
      title="发件人信息"
      v-model="dialogVisible"
      width="50%"
    >
      <el-row>
        <el-form ref="form_" style="margin-bottom: 20px" :inline="true">
          <el-form-item>
            <el-input
              v-trim
              v-model="SearchForm.phoneorname"
              placeholder="请输入姓名/电话"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              style="width: 100px"
              class="btn"
              type="primary"
              @click="getAddressList()"
            >
              Search
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row>
        <template>
          <el-table
            height="400"
            :data="addressList"
            :header-cell-style="{ background: '#FAFAFA' }"
            border
            style="width: 100%"
            @row-dblclick="SelectItem"
          >
            <el-table-column prop="receiver" label="Name" width="120" />
            <el-table-column
              prop="receivecompany"
              label="Company"
              width="120"
            />
            <el-table-column prop="receivetelephone" label="Tel" width="120" />
            <el-table-column prop="receiveaddress" label="Address" />
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
          <el-pagination
            :page-size="15"
            background
            :total="recordTotal"
            :current-page="currentPage"
            @current-change="getAddressList"
          />
        </template>
      </el-row>
    </el-dialog>
    <!-- 导入文件的结果弹框 -->
    <!-- result -->
    <el-dialog
      :close-on-click-modal="false"
      class="resultDialog"
      title="Processing Results"
      v-model="ResultDialog"
      width="35%"
    >
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="12"> Processing Success:{{ meaasge_1 }} </el-col>
        <el-col :span="12"> Previously Processed:{{ meaasge_2 }} </el-col>
      </el-row>
      <el-row>
        <el-col :span="12"> Processing Failed: </el-col>
      </el-row>

      <el-row
        style="font-size: 20px; color: orange"
        type="flex"
        justify="center"
        align="middle"
      >
        <el-table
          border
          :data="resultList"
          :header-cell-style="{ background: '#FAFAFA' }"
          style="width: 100%; margin: 10px 0"
        >
          <el-table-column center label="Row" prop="row" width="55" />
          <el-table-column
            center
            prop="field"
            label="Specific Fee Description"
            width="220"
          />
          <el-table-column
            center
            prop="errorType"
            label="Error Type"
            width=""
          />
          <!-- 空数据 展示 -->
          <template #empty>
            <com-no-data :smallImg="true"></com-no-data>
          </template>
        </el-table>
      </el-row>
      <template #footer>
        <el-button class="btn" @click="ResultDialog = false"> Close </el-button>
      </template>
    </el-dialog>
    <!-- 提示信息弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      class="messageDialog"
      v-model="MessageDialog"
      width="35%"
    >
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="20">
          草稿订单的订单号为{{
            draftOrderno
          }}，您可以在入库单管理-草稿中找到该订单并继续编辑。
        </el-col>
      </el-row>

      <template #footer>
        <el-button class="btn" type="primary" @click="MessageDialog = false">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  getstoreselectApi,
  searchPostcodeApi,
  searchCitySuburbApi,
  searchPostCodeByCitySuburb,
  getSkulistApi,
  getExpresscompanyEnumApi,
  getInboundDeliveryTypeEnumApi,
  getInboundTypeEnumApi,
} from "@/api/common.js";
import {
  createInboundOrderApi,
  buffaloOrderInfoApi,
  getOrderTypeEnumApi,
  checkOrderConfirmApi,
  checkValueAddedApi,
  createInboundDraftApi,
  draftDataApi,
  printboxlabelApi,
  printlistApi,
  downloadtemplateApi,
} from "@/api/inbound.js";
import { outboundgetBySkuApi } from "@/api/outbound.js";
import { getListApi } from "@/api/receive.js";
import { baseURL } from "@/utils/baseURL";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import axios from "axios";
import Swal from "sweetalert2";
import AddValue from "@/components/add-value/index.vue";
import Steps from "./components/steps.vue";
import { useKeepAliveStore } from "@/stores/keepAliveStore.js";
import { getToken } from "@/utils/auth";
const onlineImgURL = "https://static.buffaloex.com/";
export default {
  name: "InboundCreate",
  components: { AddValue, Steps },
  setup() {
    const keepAliveStore = useKeepAliveStore();
    return {
      keepAliveStore,
    };
  },
  data() {
    return {
      deliveryType: "", // 入库类型
      country: 0, // 0:南非 1：中国
      unit: "件",
      orderTitle: "", //标题
      value: "0",
      returnHouse: "",
      tabIndex: "0",
      createOrderForm: {
        inboundstorehouseid: "", //目的仓库
        type: "", //入库分类
        expresscompanyid: "", //物流商
        deliverytype: "", //入库类型
        referenceno: "", //交货物流单号
        estinboundtime: "", //预计到仓时间（yyyy-MM-dd）
        remarkno: "", // 参考号
        remark: "", //备注
        inboundorderid: 0, //入库单id，暂存后会有
      },
      formRules: {
        inboundstorehouseid: [
          { required: true, message: "请选择目的仓库", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择入库分类", trigger: "change" },
        ],
        deliverytype: [
          { required: true, message: "请选择入库类型", trigger: "change" },
        ],
        expresscompanyid: [
          { required: true, message: "请选择物流商", trigger: "change" },
        ],
        referenceno: [
          { required: true, message: "请选填写单号", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "只能输入字母和数字!",
            trigger: ["blur", "change"],
          },
        ],
        estinboundtime: [
          { required: true, message: "请选择物流日期", trigger: "blur" },
        ],
      },
      // tab1
      goodList: [], //下单的商品列表
      goodItem: {
        boxno: "",
        sku: "",
        qty: "",
        itemId: "",
      }, //添加的一列商品
      ResultDialog: false, //导入结果弹框
      resultList: [],
      meaasge_1: "",
      meaasge_2: "",
      MessageDialog: false, //提示信息弹框
      draftOrderno: "",
      flag: 0,
      skuList: [], //查询到的sku列表
      orderTypeOptions: [
        { value: "1", label: "入库单号(ASN)" },
        { value: "2", label: "参考号" },
        { value: "3", label: "交货物流单号" },
      ], // 用户创建的sku
      fileList: [],
      // tab2
      orderType: [], //发货类型
      Terms: [], //terms列表
      parameter: {},
      searchCitySuburbParameter: {
        search: "",
        page: 1,
      },
      citySuburbPageInfo: {
        currentPagenum: 1,
        totalPageCount: 0,
      },
      addressList: [], //联系人地址列表
      SearchForm: {
        phoneorname: "",
        pageNum: 1,
      }, //查询联系人参数
      pagenum: 1, //邮编和城市页数
      SuburbOptions: [],
      postcodeOptions: [],
      loading: false,
      tableData_weight: [],
      dialogVisible: false,
      totalWeight: 0, //总重量
      searchForm: {
        condition: "",
      },
      currentPage: 1,
      recordTotal: 0,
      inboundtrackingorder: {
        house: "0",
        trackingno: "",
        countrycode: "",
        sendercity: "",
        sendercompany: "",
        creditcode: "",
        postcode: "",
      }, //上传的收件人信息
      // tab-3
      confirmOrderInfo: {
        orderNumber: "",
      },
      // tab-4
      Boxlabel: 4,
      Packingslip: 100,
      returnId: "",
      locationStoreOptions: [], //目的仓库列表
      inBoundTypeOptions: [], //入库分类列表
      providerOptions: [], //物流商列表
      receiptTypeOptions: [], //入库类型列表
      //增值服务tab
      fileNameList: [],
      textarea2: "", //
      //tab_增值服务
      valueaddorderVo: {
        //服务表单
        files: [],
        labelchoose: 0,
        labelnum: 0,
        labelremark: "",
        reboardchoose: 0,
        reboardnum: 0,
        reboardremark: "",
        reboxchoose: 0,
        reboxednum: 0,
        reboxedremark: "",
        insuredchoose: 0, //保价标识
        insuranceamount: "", //保额
        month: "", //报价持续月份
      },
      skutotalprice: "",
      //增值服务初始数据
      addvalueformInitData: {
        labelchoose: 0, //贴标选择
        reboardchoose: 0, //打板选择
        reboxchoose: 0, //打包选择
        insuredchoose: 0, //保价标识

        filerecordList: [], //文件列表
        labelnum: "", //贴标数量
        labelremark: "", //备注

        reboardnum: "", //重新打板数量
        reboardremark: "", //重新打板备注

        reboxednum: "", //重新装箱数量
        reboxedremark: "", //重新装箱备注

        insuranceamount: "", //保额
        month: "", //报价持续月份
      },

      //预览订单
      orderPreview: {
        balance: "",
        creditline: "",
        deliverytype: "",
        estdeliveryfee: "",
        estinboundfee: "",
        esttaxfee: "",
        estvalueaddedfee: "",
        expresscompanyid: "",
        needrechargeamount: "",
        referenceno: "",
        trackingno: "",
        trackingnofieldname: "",
        trackingnovalue: "",
        unpaiddeliveryfee: "",
        unpaiddeliveryfeeqty: "",
        unpaidinboundfee: "",
        unpaidinboundfeeqty: "",
        unpaidtaxfee: "",
        unpaidtaxfeeqty: "",
        unpaidvalueaddedfee: "",
        unpaidvalueaddedfeeqty: "",
      },
      isEdit: false,
      addressInfo: {},
      sncodeqty: 0, // SN Code 扫描个数
    };
  },
  computed: {
    // 增值服务 统计 sncode 扫描个数
    // sncodeqty() {
    //   if(this.deliveryType == 10) {
    //     const result = this.goodList.filter(item => {
    //     return item.snstatus === 1
    //   })
    //     console.log(result);
    //     const total = result.reduce((sum, item) => sum + (+item.qty), 0);
    //      console.log('total-', total);
    //      return total
    //   } else {
    //     return 0
    //   }

    // },
    // tab-1
    //添加商品校验
    goodItemRules() {
      return {
        sku: [
          {
            required: true,
            message: "此处不能空白",
            trigger: ["blur", "change"],
          },
        ],
        boxno: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          { min: 1, max: 5, message: "长度不能超过5位", trigger: "blur" },
        ],
        qty: [{ required: true, message: "此处不能空白", trigger: "blur" }],
      };
    },

    // 发件人信息校验规则
    senderInfoRules() {
      return {
        house: [{ required: true, message: "此处不能空白", trigger: "blur" }],
        trackingno: [
          { required: true, message: "此处不能空白", trigger: "blur" },
        ],
        sender: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^[^\u4e00-\u9fa5]*$/,
            message: "不能输入中文!",
            trigger: ["blur", "change"],
          },
        ],
        sendertelephone: [
          { required: true, message: "请输入数字", trigger: "blur" },
          {
            pattern: /^[0-9+\s]+$/,
            message: "请输入有效的电话号码",
            trigger: "blur",
          },
        ],
        sendercompanyen: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^[^\u4e00-\u9fa5]*$/,
            message: "不能输入中文!",
            trigger: ["blur", "change"],
          },
        ],
        senderaddress: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^[^\u4e00-\u9fa5]*$/,
            message: "不能输入中文!",
            trigger: ["blur", "change"],
          },
        ],
        senderaddress2: [
          { required: true, message: "此处不能空白", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5\d\s\p{P}]+$/u,
            message: "只能输入中文,数字和空格!",
            trigger: ["blur", "change"],
          },
        ],
        citySuburb: [
          { required: true, message: "此处不能空白", trigger: "blur" },
        ],
        postcode: [
          { required: true, message: "此处不能空白", trigger: "blur" },
        ],
        suburb: [{ required: true, message: "此处不能空白", trigger: "blur" }],
        ordertype: [
          { required: true, message: "此处不能空白", trigger: "change" },
        ],
        largecargocategoryid: [
          { required: true, message: "此处不能空白", trigger: "blur" },
        ],
      };
    },
    // 交货物流单号输入框是否可用false-不禁用true-禁用
    disabled() {
      if (this.createOrderForm.expresscompanyid == 3) {
        this.createOrderForm.referenceno = "";
        this.$refs["createOrderForm"].clearValidate();
        return true;
      }
    },
  },
  watch: {
    "createOrderForm.deliverytype"(newVal, old) {
      if (newVal == 10) {
        this.inboundtrackingorder.countrycode = "27";
      } else {
        this.inboundtrackingorder.countrycode = "";
      }
    },
  },
  mounted() {
    this.getstoreselect();
    this.getExpresscompanyEnum();
    this.getInboundDeliveryTypeEnum();
    this.getInboundTypeEnum();
    this.getOrderType();
    this.getDraftDetail();
  },
  methods: {
    getDraftDetail() {
      //编辑草稿-initData
      const orderid = this.$route.query ? this.$route.query.id : 0;
      if (orderid && orderid > 0) {
        draftDataApi({ inboundorderid: orderid }).then((res) => {
          if (res && res.inboundorderid && res.inboundorderid > 0) {
            if (res.inboundstorehouseid > 0) {
              this.createOrderForm.inboundstorehouseid =
                res.inboundstorehouseid + "";
              const result = this.locationStoreOptions.find(
                (item) => this.createOrderForm.inboundstorehouseid == item.id,
              );
              console.log(result);
              this.country = result.country;
            }
            this.createOrderForm.type = res.type;
            if (res.expresscompanyid !== 0) {
              this.createOrderForm.expresscompanyid = res.expresscompanyid;
            }
            this.createOrderForm.deliverytype = res.deliverytype;
            this.createOrderForm.referenceno = res.referenceno;
            this.createOrderForm.estinboundtime = res.estinboundtime;
            this.createOrderForm.remarkno = res.remarkno;
            this.createOrderForm.remark = res.remark;
            this.createOrderForm.inboundorderid = res.inboundorderid;

            if (res.inboundtrackingorder) {
              this.inboundtrackingorder = res.inboundtrackingorder;
              this.inboundtrackingorder.house = "0";
              this.inboundtrackingorder.citySuburb = this.getCitySuburbOptVal({
                city: res.inboundtrackingorder.sendercity,
                suburb: res.inboundtrackingorder.suburb,
              });
            }
            if (res.inboundboxesinfolist) {
              this.goodList = res.inboundboxesinfolist;
            }
          }
        });
      }
    },
    /**
     * 选择目的仓库
     * @param {*} val
     */
    handleStorehouseChange(val) {
      if (!val) return;
      const result = this.locationStoreOptions.find((item) => val == item.id);
      console.log(result);
      this.country = result.country;
      this.createOrderForm.expresscompanyid =
        result.country == 1 ? -1 : this.createOrderForm.expresscompanyid;
    },
    switchTab(index) {
      this.tabIndex = String(index);
    },
    isActive(index) {
      return index === Number(this.tabIndex);
    },
    // 选择物流商
    expresscompanyChange(val) {},
    // 入库类型
    deliverytypeChange(type) {
      this.deliveryType = type;
      console.log("入库类型====", type);
    },
    addvaluechange(param) {
      console.log("param=====", param);
      this.valueaddorderVo = {
        files: param.filerecordList,
        labelchoose: param.labelchoose ? 1 : 0,
        labelnum: Number(param.labelnum),
        labelremark: param.labelremark,
        reboardchoose: param.reboardchoose ? 1 : 0,
        reboardnum: Number(param.reboardnum),
        reboardremark: param.reboardremark,
        reboxchoose: param.reboxchoose ? 1 : 0,
        reboxednum: Number(param.reboxednum),
        reboxedremark: param.reboxedremark,
        insuredchoose: param.insuredchoose ? 1 : 0, //保价标识
        insuranceamount: param.insuranceamount, //保额
        month: param.month, //报价持续月份，
        // sncodeqty
      };
    },
    //获取发货类型
    getOrderType() {
      getOrderTypeEnumApi().then((res) => {
        this.orderType = res.ordertypeList;
        this.Terms = res.termsList;
      });
    },
    /**
     * 获取目的仓库
     */
    getstoreselect() {
      // null/-1查全部；0-海外仓；1-不良品仓；
      // null/-1 全部； 0 禁用 1 启用
      getstoreselectApi({ status: 1 }).then((res) => {
        this.locationStoreOptions = res.list;
      });
    },
    //获取物流商枚举
    getExpresscompanyEnum() {
      getExpresscompanyEnumApi().then((res) => {
        this.providerOptions = res;
      });
    },
    // 获取入库类型枚举
    getInboundDeliveryTypeEnum() {
      getInboundDeliveryTypeEnumApi().then((res) => {
        this.receiptTypeOptions = res;
      });
    },
    // 获取入库分类枚举
    getInboundTypeEnum() {
      getInboundTypeEnumApi().then((res) => {
        this.inBoundTypeOptions = res;
      });
    },
    //添加一条商品信息
    AddGoodItem() {
      this.$refs["goodItem"].validate((valid) => {
        if (valid) {
          this.AddGoodItemCom(this.goodItem);
          this.initGoodItem();
        } else {
          this.$message.error("请检查商品信息");
          return false;
        }
      });
    },
    AddGoodItemCom(gitem) {
      if (!gitem.boxno || !gitem.sku) {
        return;
      }

      let isExit = this.goodList.findIndex(
        (item) => item.sku == gitem.sku && item.boxno == gitem.boxno,
      );
      if (isExit != -1) {
        this.goodList[isExit].qty =
          Number(this.goodList[isExit].qty) + Number(gitem.qty);
      } else {
        this.flag++;
        this.goodList.push({
          boxno: gitem.boxno,
          sku: gitem.sku,
          // unit: this.goodItem.boxno,
          qty: gitem.qty,
          itemId: this.flag,
          // snstatus: gitem.snstatus
        });
      }
    },
    deleteItem(row) {
      if (row.itemId) {
        let index = this.goodList.findIndex(
          (item) => item.itemId == row.itemId,
        );
        this.goodList.splice(index, 1);
      } else {
        let index = this.goodList.findIndex((item) => item.boxno == row.boxno);
        this.goodList.splice(index, 1);
      }
    },
    //初始化填写信息
    initGoodItem() {
      this.goodItem = {
        sku: "",
        name: "",
        nameEn: "",
        unit: "",
        count: "",
        itemId: "",
      };
      this.$refs["goodItem"].resetFields();
    },
    selectCitySuburb(val) {
      const item = this.SuburbOptions.find(
        (item) => this.getCitySuburbOptVal(item) === val,
      );
      // this.inboundtrackingorder.postcode = ''
      this.inboundtrackingorder.suburb = item.suburb;
      this.inboundtrackingorder.city = item.city;
      this.inboundtrackingorder.sendercity = item.city;

      this.searchPostCodeByCitySuburb(item.city, item.suburb);
    },
    searchPostCodeByCitySuburb(city, suburb) {
      searchPostCodeByCitySuburb({ city, suburb }).then((postcodeList) => {
        this.postcodeOptions = postcodeList || [];
        // 默认选择第一个
        this.inboundtrackingorder.postcode = this.postcodeOptions[0] || "";
      });
    },
    // 邮编查询
    getPostCode(query) {
      if (query !== "") {
        this.parameter.search = query;
        this.parameter.type = "postcode";
        setTimeout(() => {
          searchPostcodeApi(this.parameter).then((res) => {
            this.postcodeOptions = res.list;
          });
        }, 200);
      }
    },
    queryCitySuburb(query) {
      if (query !== "") {
        this.searchCitySuburbParameter.search = query;
        this.searchCitySuburbParameter.page = 1;
        this.getSuburb();
      }
    },
    getCitySuburbOptVal(item) {
      return `${item.city},${item.suburb}`;
    },
    getSuburb() {
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = res.list;
      });
    },
    onFocusSuburb() {
      this.searchCitySuburbParameter.page = 1;
      this.citySuburbPageInfo.pagenum = 1;
      this.getSuburb();
    },
    onPostcode() {
      this.parameter.page = 1;
      this.pagenum = 1;
      this.parameter.type = "postcode";
      this.getPostCode();
    },
    // city/suburb
    loadmoreCitySuburb() {
      this.searchCitySuburbParameter.page = ++this.citySuburbPageInfo.pagenum;
      searchCitySuburbApi(this.searchCitySuburbParameter).then((res) => {
        this.SuburbOptions = [...this.SuburbOptions, ...res.list];
      });
    },

    /**
     *  获取sku, 以及单位
     * @param {sku item} item
     */
    selectSku(item) {
      this.goodItem.sku = item.sku;
      this.unit = item.unit || "件";
      // outboundgetBySkuApi({
      //   sku: item.sku,
      //   storehouseid: this.createOrderForm.inboundstorehouseid,
      // }).then((res) => {
      //   this.goodItem ={...res, ...this.goodItem};
      //   console.log('goodsItem', {...this.goodItem});
      // });
    },
    /**
     * 获取sku 列表 模糊查询
     * @param {sku 值} query
     */
    getSku(query) {
      // if (!this.createOrderForm.inboundstorehouseid) {
      //   return ElMessage.error("请先选择仓库!");
      // }
      this.skuList = [];
      setTimeout(() => {
        getSkulistApi({
          sku: query,
          auditedflg: 1, //查询通过审核的
        }).then((res) => {
          this.skuList = res.list || [];
        });
      }, 200);
    },
    /**
     * 下载模板
     */
    Template() {
      downloadtemplateApi().then((res) => {
        DOWNLOAD_FILE(res);
      });
    },
    /**
     * 导入文件
     * @param {*} file
     * @param {*} fileList
     */
    handleChange(file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let formData = new FormData();

      formData.append("file", file.raw);
      // formData.append('filename', file.name);
      axios
        .post(baseURL() + `/inbound/importinbounddetail`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Buffalo-ticket": getToken(),
          },
        })
        .then((res) => {
          if (res.data.status == "400") {
            loading.close();
            return ElMessageBox.confirm(res.data.message, "Error", {
              confirmButtonText: "确定",
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              type: "error",
            })
              .then(() => {})
              .catch(() => {});
            // return Swal.fire({
            //   icon: "error",
            //   title: "Error",
            //   text: res.data.message,
            //   allowOutsideClick: false,
            // });
          } else {
          }

          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.AddGoodItemCom(item);
            });
          }

          loading.close();
          //   }
        });
    },

    // 显示常用收件人
    showAddress() {
      (this.SearchForm = {
        phoneorname: "",
        pageNum: 1,
      }),
        (this.dialogVisible = true);
      this.getAddressList();
    },
    getAddressList(val) {
      if (val) {
        this.SearchForm.pageNum = val;
      }
      getListApi(this.SearchForm).then((res) => {
        this.addressList = res.list;
        this.recordTotal = res.recordTotal;
      });
    },
    //双击选择一项
    SelectItem(val) {
      this.receiverInfo.receiver = val.receiver;
      this.receiverInfo.receivecompany = val.receivecompany;
      this.receiverInfo.receivetelephone = val.receivetelephone;
      this.receiverInfo.receivecity = val.receivecity;
      this.receiverInfo.receiveemail = val.receiveemail;
      this.receiverInfo.receivepostcode = val.receivepostcode;
      this.receiverInfo.receivesuburb = val.receivesuburb;
      this.receiverInfo.receiveaddress = val.receiveaddress;
      this.receiverInfo.receiveaddress2 = val.receiveaddress2;
      this.dialogVisible = false;
    },
    //打印箱标
    PrintLabel() {
      printboxlabelApi({
        inboundorderid: this.returnId,
        size: this.Boxlabel,
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
    },
    PrintList() {
      printlistApi({
        inboundorderid: this.returnId,
        size: this.Packingslip,
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
    },
    //返回上一步
    backStep() {
      if (this.createOrderForm.expresscompanyid == -1 && this.tabIndex == "2") {
        this.tabIndex = "0";
      } else {
        this.tabIndex = this.tabIndex - 1 + "";
      }
    },
    createOrderFormValidate() {
      console.log(this.createOrderForm);
      this.$refs["createOrderForm"].validate((valid) => {
        if (valid) {
          if (this.goodList.length == 0)
            return this.$message.error("请完善商品信息");
          if (this.createOrderForm.expresscompanyid == -1) {
            this.toValueAddPage();
          } else {
            this.toBuffaloLogicPage();
          }
        } else {
          this.$message.error("请完善信息");
          return false;
        }
      });
    },
    toBuffaloLogicPage() {
      let options = this.createOrderForm;
      options.inboundtrackingorder = this.inboundtrackingorder;
      options.inboundboxesinfolist = this.goodList;
      buffaloOrderInfoApi(options).then((res) => {
        this.tabIndex = "1";
        this.tableData_weight = res.boxinfolist;
        this.totalWeight = res.boxinfolist.reduce((item, acc) => {
          return Number(acc.estweight) + item;
        }, 0);
        this.returnHouse = res.overseasstorehouseid + "";
      });
    },
    toValueAddPage() {
      var options = this.createOrderForm;
      options.inboundtrackingorder = this.inboundtrackingorder;
      options.inboundboxesinfolist = this.goodList;
      options.valueaddorderVo = this.valueaddorderVo;
      if (this.createOrderForm.deliverytype == 10) {
        options.inboundtrackingorder.sendertelephone =
          this.inboundtrackingorder.sendertelephone;
      }
      checkValueAddedApi(options).then((res) => {
        this.skutotalprice = res.skutotalprice;
        this.sncodeqty = res.valueaddorderRequiredBo?.sncodeqty || 0;
        this.tabIndex = "2";
        this.addvalueformInitData.valueaddorderRequiredBo =
          res.valueaddorderRequiredBo;
        if (res.valueaddorderVo) {
          const  filerecordList = res.valueaddorderVo.files.map((item) => {
            return {
              filename: item.filename,
              filelink: item.filelink,
              url: item.filelink
            };
          });
          this.addvalueformInitData.filerecordList = filerecordList;
          console.log(filerecordList);
          

          this.addvalueformInitData.labelchoose =
            res.valueaddorderVo.labelchoose &&
            res.valueaddorderVo.labelchoose == 1
              ? true
              : false;
          this.addvalueformInitData.reboardchoose =
            res.valueaddorderVo.reboardchoose &&
            res.valueaddorderVo.reboardchoose == 1
              ? true
              : false;
          this.addvalueformInitData.reboxchoose =
            res.valueaddorderVo.reboxchoose &&
            res.valueaddorderVo.reboxchoose == 1
              ? true
              : false;
          this.addvalueformInitData.insuredchoose =
            res.valueaddorderVo.insuredchoose &&
            res.valueaddorderVo.insuredchoose == 1
              ? true
              : false;

          this.addvalueformInitData.labelnum =
            res.valueaddorderVo.labelnum || "";
          this.addvalueformInitData.labelremark =
            res.valueaddorderVo.labelremark || "";
          

          this.addvalueformInitData.reboardnum =
            res.valueaddorderVo.reboardnum || "";
          this.addvalueformInitData.reboardremark =
            res.valueaddorderVo.reboardremark || "";

          this.addvalueformInitData.reboxednum =
            res.valueaddorderVo.reboxednum || "";
          this.addvalueformInitData.reboxedremark =
            res.valueaddorderVo.reboxedremark || "";

          this.addvalueformInitData.insuranceamount =
            res.valueaddorderVo.insuranceamount || "";
          this.addvalueformInitData.month = res.valueaddorderVo.month || "";

          this.$refs.addvalueform.calcprice();
        }
      });
    },
    toOrderCofirm() {
      let options = this.createOrderForm;
      options.inboundtrackingorder = this.inboundtrackingorder;
      options.inboundboxesinfolist = this.goodList;
      options.valueaddorderVo = this.valueaddorderVo;

      if (this.createOrderForm.deliverytype == 10) {
        options.inboundtrackingorder.sendertelephone =
          this.inboundtrackingorder.sendertelephone;
      }
      checkOrderConfirmApi(options).then((res) => {
        this.addressInfo = res;
        this.orderTitle = res.trackingnofieldname;
        this.orderPreview.balance = res.balance;
        this.orderPreview.creditline = res.creditline;
        this.orderPreview.deliverytype = res.deliverytype;
        this.orderPreview.estdeliveryfee = res.estdeliveryfee;
        this.orderPreview.estinboundfee = res.estinboundfee;
        this.orderPreview.esttaxfee = res.esttaxfee;
        this.orderPreview.estvalueaddedfee = res.estvalueaddedfee;
        this.orderPreview.expresscompanyid = res.expresscompanyid;
        this.orderPreview.needrechargeamount = res.needrechargeamount;
        this.orderPreview.referenceno = res.referenceno;
        this.orderPreview.trackingno = res.trackingno;
        this.orderPreview.trackingnofieldname = res.trackingnofieldname;
        this.orderPreview.trackingnovalue = res.trackingnovalue;
        this.orderPreview.unpaiddeliveryfee = res.unpaiddeliveryfee;
        this.orderPreview.unpaiddeliveryfeeqty = res.unpaiddeliveryfeeqty;
        this.orderPreview.unpaidinboundfee = res.unpaidinboundfee;
        this.orderPreview.unpaidinboundfeeqty = res.unpaidinboundfeeqty;
        this.orderPreview.unpaidtaxfee = res.unpaidtaxfee;
        this.orderPreview.unpaidtaxfeeqty = res.unpaidtaxfeeqty;
        this.orderPreview.unpaidvalueaddedfee = res.unpaidvalueaddedfee;
        this.orderPreview.unpaidvalueaddedfeeqty = res.unpaidvalueaddedfeeqty;
        this.tabIndex = "3";
      });
    },
    inboundtrackingorderValidate() {
      this.$refs["inboundtrackingorder"].validate((valid) => {
        if (valid) {
          this.toValueAddPage();
        } else {
          this.$message.error("请完善信息");
          return false;
        }
      });
    },
    addvalueformValidate() {
      this.$refs["addvalueform"].validateForm().then((res) => {
        if (res) {
          this.toOrderCofirm();
        } else {
          this.$message.error("请完善增值服务信息");
        }
      });
    },
    createInboundOrder() {
      let options = this.createOrderForm;
      options.inboundtrackingorder = this.inboundtrackingorder;
      options.inboundboxesinfolist = this.goodList;
      options.valueaddorderVo = this.valueaddorderVo;
      if (this.createOrderForm.deliverytype == 10) {
        options.inboundtrackingorder.sendertelephone =
          this.inboundtrackingorder.sendertelephone;
      }
      createInboundOrderApi(options).then((res) => {
        if (res.inboundorderid) {
          this.returnId = res.inboundorderid;
          this.$message.success("下单成功");
          this.tabIndex = "4";
        }
      });
    },
    //确定保存
    Save() {
      switch (this.tabIndex) {
        case "0":
          this.createOrderFormValidate();
          break;
        case "1":
          this.inboundtrackingorderValidate();
          break;
        case "2":
          this.addvalueformValidate();
          break;
        case "3":
          this.createInboundOrder();
          break;
        case "4":
          this.keepAliveStore.refresCurrentRoute({
            path: "/inbound/list",
          });
          break;
      }
    },
    //暂存
    Draft() {
      let options = this.createOrderForm;

      options.inboundtrackingorder = this.inboundtrackingorder;
      options.inboundboxesinfolist = this.goodList;
      options.valueaddorderVo = this.valueaddorderVo;
      if (this.createOrderForm.deliverytype == 10) {
        options.inboundtrackingorder.sendertelephone =
          this.inboundtrackingorder.sendertelephone;
      }
      createInboundDraftApi(options).then((res) => {
        if (res.inboundorderid) {
          this.createOrderForm.inboundorderid = res.inboundorderid;
        }
        if (res.inboundorderno) {
          this.draftOrderno = res.inboundorderno;
          this.MessageDialog = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  padding: 15px 0;
}
.card-margin {
  margin: 10px 0;
}
.add-btn {
  margin-top: 30px;
}
</style>
<style scoped lang="scss">
.upload-input {
  display: none;
}
//入库信息

#receiverInfo,
#weightInfo {
  .clearfix {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-form-item {
    margin-bottom: 5px;

    :deep(.el-form-item__label) {
      padding: 0;
    }
  }
}

#weightInfo :deep(.el-card__header),
#receiverInfo :deep(.el-card__header) {
  font-weight: 700;
  font-size: 20px;
  color: #1f1f20;
  border: none;
  padding-bottom: 0px;
}

#weightInfo {
  margin: 10px 0;
  .totalWeight {
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
    margin-left: 10px;
  }
}

.addvalueService {
  font-weight: 700;
  font-size: 20px;
  color: #1f1f20;
}
#weightInfo :deep(.el-card__body) {
  padding-top: 10px;
}

#address {
  :deep(.el-dialog__close) {
    display: none;
  }
}

.info {
  .point {
    margin: 0 auto;
    margin-top: 10px;
    background-color: rgba(2, 167, 240);
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .el-row {
    line-height: 2.5;
  }

  :deep(.el-dialog__close) {
    display: none;
  }

  .el-form-item {
    margin-bottom: 5px;

    :deep(.el-form-item__label) {
      padding: 0;
    }
  }

  .divider {
    margin: 8px 0;
  }
}

.key-title {
  font-weight: 700;
  font-size: 20px;
  color: #1f1f20;
  border: none;
}
.box-info-title {
  margin-bottom: 15px;
  font-size: 20px;
}
.leftBtn {
  :deep(.el-icon) {
    transform: rotate(90deg);
  }
}

.describe {
  color: #e37318;
}

.row-item {
  margin-bottom: 15px;
}

.flex-form-item {
  width: 100%;
  display: flex;
  :deep(.el-select) {
    width: 120px;
  }
}
</style>

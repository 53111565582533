<template>
  <section>
    <!-- 入库计费明细报表 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>

      <el-row class="mt-lg mb-lg">
        <el-button type="primary" plain icon="Upload" @click="exportData"
          >导出</el-button
        >
      </el-row>
      <el-table
        stripe
        height="450"
        style="width: 100%; margin: 10px 0 20px"
        :header-cell-style="{ background: '#FAFAFA' }"
        :data="tableOption.tableData"
        :border="true"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'inboundorderno'">
              <el-link
                type="primary"
                :underline="false"
                @click="showDetail(row)"
              >
                <span class="link">{{ row.inboundorderno }}</span>
              </el-link>
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="费用详情"
          fixed="right"
          width="100"
          align="center"
        >
          <template #default="scope">
            <el-button
              type="primary"
              link
              @click="columnClick('detail', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="tableOption.page.currentPage"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableOption.page.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 查看入库单详情 orderType == 1: 入库-->
    <order-detail-dialog
      :orderType="1"
      :id="inboundorderid"
      :dialogVisible="detailDialog"
      @openDialog="openDialog"
    >
    </order-detail-dialog>
    <!-- 费用详情 -->
    <el-dialog
      :close-on-click-modal="false"
      title="费用详情"
      v-model="feeDetailDialog"
      width="80%"
    >
      <el-tabs v-model="detailActiveName" type="card">
        <el-tab-pane
          v-for="item in costTable"
          :key="item.activeName"
          :label="item.tabName"
          :name="item.activeName"
        >
          <template v-if="item.activeName == 2">
            <el-row :gutter="20" class="tab3-content">
              <el-col :span="19">
                <el-row :gutter="20">
                  <el-col :span="6"
                    >单价：{{
                      inboundreportDetail.inboundcartonprice || "-"
                    }}元/超限箱</el-col
                  >
                  <el-col :span="6"
                    >箱数约束：{{
                      inboundreportDetail.cartonconstraint || "-"
                    }}</el-col
                  >
                  <el-col :span="6"
                    >重量约束：{{
                      inboundreportDetail.weightlimit || "-"
                    }}</el-col
                  >
                  <el-col :span="6"
                    >超限箱数：{{ inboundreportDetail.number || "-" }}</el-col
                  >
                </el-row>
              </el-col>
              <el-col :span="5">
                费用(人民币)：{{ inboundreportDetail.totalfee || "-" }}元
              </el-col>
            </el-row>
          </template>
          <el-table
            :data="item.tableData"
            height="300"
            border
            style="width: 100%"
          >
            <el-table-column
              v-if="item.index"
              type="index"
              label="序号"
              align="center"
              width="55"
            >
            </el-table-column>
            <el-table-column
              v-for="(column, index) in item.tableColumn"
              :key="index"
              :prop="column.prop"
              :label="column.label"
              align="center"
            >
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data :smallImg="true"></com-no-data>
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <el-button
          type="primary"
          plain
          icon="Upload"
          class="btn"
          @click="exportDetail"
          >导出</el-button
        >
      </template>
    </el-dialog>
  </section>
</template>
<script setup>
import { tableOption, costTable } from "./column";
import { reactive, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { inboundreportListApi, inboundreportDetailApi, inboundreportExportApi, inboundreportdetailExportApi } from "@/api/report";
import { getstoreselectApi } from "@/api/common";
import { isAllFieldsEmpty } from "@/utils/common";
import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
defineOptions({
  name: "ReportsInbound",
});

const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "完成时间:",
      type: "date",
      value: "",
      prop: "daterange",
    },
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "tempSelectVal",
      maxlength: 30,
      placeholder: "请输入内容",
      class: "longText",
      selectOption: {
        value: "inboundorderno",
        prop: "tempSelect",
        optionData: [
          { label: "入库订单号/批次号", value: "inboundorderno" },
          { label: "参考号", value: "remarkno" },
        ],
      },
    },
    {
      label: "入库仓库:",
      type: "select",
      value: "-1",
      prop: "storehouseid",
      optionData: [{ label: "全部", value: "-1" }],
      clearable: false,
    },
  ],
});
// 搜索入参
let params = reactive({
  pageNum: 1,
  pageSize: 10,
});
// 以下为弹框内容
const detailDialog = ref(false); // 订单详情  弹框 状态

const feeDetailDialog = ref(false); // 费用详情 弹框 状态

const detailActiveName = ref("0");

const id = ref(""); // 当前行id

const inboundorderid = ref(""); //  当前入库单id

const inboundreportDetail = ref({}); // 费用详情

const dateRange = ref([]); // 选择的时间范围

/**
 * 时间筛选 限制在1年内
 */
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
/**
 * 获取数据列表
 */
const getList = () => {
  inboundreportListApi(params).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  if (obj.tempSelectVal) {
    obj[obj.tempSelect] = obj.tempSelectVal;
  }
  delete obj.tempSelect;
  delete obj.tempSelectVal;
  if (dateRange.value && dateRange.value.length) {
    obj.startDate = dateRange.value[0];
    obj.endDate = dateRange.value[1];
  }
  if (obj.storehouseid === "-1") {
    delete obj.storehouseid;
  }
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = 10;
  params = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    if (item.prop == "storehouseid") {
      item.value = "-1";
    } else if (item.prop === "tempSelect") {
      item["value"] = "0";
    } else {
      item["value"] = "";
    }
  });
  dateRange.value = "";
  params = {
    pageNum: 1,
    pageSize: params.pageSize,
  };
  handleCurrentChange(1);
};

/**
 * 费用详情 tab
 * @param {*} id
 */
const getDetail = (id) => {
  inboundreportDetailApi({ id }).then((res) => {
    inboundreportDetail.value = res;
    costTable.map((item) => {
      switch (item.label) {
        case "basic":
          item.tableData = [
            {
              unloadingunit: res.unloadingunit,
              price: res.price,
              unloadingqty: res.unloadingqty,
              basicfee: res.basicfee,
            },
          ];
          break;
        case "fullbox":
          const fulleboxData = {
            basicboxes: res.basicboxes,
            boxes: res.boxes,
            overlimitboxes: res.overlimitboxes,
            extraprice: res.extraprice,
            extrafee: res.extrafee,
          };
          // 字段都为空
          if (isAllFieldsEmpty(fulleboxData)) {
            item.tableData = [];
          } else {
            // 存在非空字段
            item.tableData = [fulleboxData];
          }
          break;
        case "loosebox":
          item.tableData = res.infoBOS;
          break;
      }
      feeDetailDialog.value = true;
    });
  });
};

/**
 * 导出
 */
const exportData = () => {
  inboundreportExportApi(params).then(res => {
    DOWNLOAD_FILE(res)
  })
};
/**
 * 导出详情
 */
const exportDetail = () => {
  inboundreportdetailExportApi({
    id: id.value
  }).then(res => {
    DOWNLOAD_FILE(res)
  })
  
};
/**
 * 表格内操作按钮
 * @param {*} prop 查看按钮
 * @param {*} row  行数据
 */
const columnClick = (prop, row) => {
  detailActiveName.value = "0";
  id.value = row.id;
  getDetail(row.id);
};
/**
 * 点击入库单号
 */
const showDetail = (row) => {
  id.value = row.id;
  inboundorderid.value = row.inboundorderid;
  detailDialog.value = true;
};
const openDialog = (val) => {
  detailDialog.value = val;
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  tableOption.page.currentPage = currentPage;
  params.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = pageSize;
  params.pageNum = 1;
  params.pageSize = pageSize;
  getList();
};
/**
 * 获取仓库列表
 */
const getWarehouseList = () => {
  // type: null/-1查全部；0-海外仓；1-不良品仓；
  getstoreselectApi().then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = [{ label: "全部", value: "-1" }, ...res.list];
      }
    });
  });
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "入库计费明细",
  });
};
onMounted(() => {
  getWarehouseList(); // 获取仓库列表
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>
<style lang="scss" scoped>
.tab3-content {
  margin-bottom: 20px;
}
</style>

<template>
  <section>
    <!-- 流水查询 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="daterange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row class="mt-lg mb-lg">
        <el-button type="primary" plain icon="Upload" @click="operateHandle"
          >导出</el-button
        >
      </el-row>
      <el-table
        style="width: 100%; margin: 10px 0 20px"
        border
        stripe
        height="450"
        :header-cell-style="{ background: '#FAFAFA' }"
        :data="tableData"
      >
        <el-table-column type="index" width="60" label="序号" align="center" />
        <el-table-column
          v-for="column in tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="recordTotal > 0"
        class="justify-end"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="params.pageNum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        :total="recordTotal"
        @current-change="handleCurrentChangeDetail"
        @size-change="handleSizeChangeDetail"
      />
    </el-card>
  </section>
</template>

<script setup>
import { onMounted } from "vue";

import { useRoute } from "vue-router";

import dayjs from "dayjs";

import { getstoreselectApi } from "@/api/common.js";

import {
  getInventoryFlowlistApi,
  getInventoryflowTypeEnumApi,
  exportApi,
} from "@/api/storage-center/flowquery.js";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { tableColumn } from "./column";

import { useTrackingInfo } from "@/stores/tracking.js";

defineOptions({
  name: "StorageCenterFlowquery",
});

const route = useRoute();
const curpath = route.path;
const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "80px",
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "SKU:",
      type: "input",
      value: "",
      prop: "sku",
      maxlength: 50,
      placeholder: "请输入SKU",
    },
    {
      label: "类型:",
      type: "select",
      value: 0,
      prop: "type",
      optionData: [{ label: "All", value: 0 }],
      clearable: false,
    },
    {
      label: "日期:",
      type: "date",
      // dateType: "daterange",
      value: "",
      prop: "daterange",
      // clearable: false,
      // pickerOptions: pickerOptions()
    },
    {
      label: "仓库:",
      type: "select",
      value: "0",
      prop: "storehouseid",
      optionData: [{ label: "All", value: "0" }],
      clearable: false,
    },
    {
      label: "单据号:",
      type: "input",
      value: "",
      prop: "order",
      maxlength: 20,
      placeholder: "请输入单据号",
    },
    {
      label: "关联单据:",
      type: "input",
      value: "",
      prop: "relatedorder",
      maxlength: 30,
      placeholder: "请输入关联单据",
    },
  ],
});
// 搜索入参
const params = ref({
  startdate: dayjs().add(-1, "month").format("YYYY-MM-DD"),
  enddate: dayjs().format("YYYY-MM-DD"),
  storehouseid: "0",
  type: 0,
  pageNum: 1,
  pageSize: 10,
});

const daterange = ref([
  dayjs().add(-1, "month").format("YYYY-MM-DD"),
  dayjs().format("YYYY-MM-DD"),
]);

const tableData = ref([]);

const recordTotal = ref(0);

/**
 * 时间筛选 限制在6个月内
 */
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 6,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 6,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
onMounted(() => {
  getTypelect();
  getstoreselect();
  getList();
});
/**
 * 搜索栏
 * @param {*} obj
 */
const search = (obj) => {
  console.log(daterange.value, obj);
  obj.startdate = daterange.value[0];
  obj.enddate = daterange.value[1];
  // obj.storehouseid = obj.storehouseid == "" ? 0 : obj.storehouseid
  // obj.type = obj.type == "" ? 0 : obj.type
  params.value = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "type") item.value = 0;
    if (item.prop == "storehouseid") item.value = "0";
  });
  daterange.value = [
    dayjs().add(-1, "month").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ];
  params.value = {
    startdate: daterange.value[0],
    enddate: daterange.value[1],
    type: 0,
    storehouseid: "0",
    pageNum: 1,
    pageSize: params.value.pageSize,
  };
  getList();
};
/**
 * 初始化数据
 * @param {*} val  页码
 */
const getList = (val) => {
  if (val) {
    params.value.pageNum = val;
  }
  getInventoryFlowlistApi(params.value).then((res) => {
    tableData.value = res.list;
    recordTotal.value = res.recordTotal;
  });
};
/**
 * 导出
 */
const operateHandle = () => {
  exportApi(params.value).then((res) => {
    DOWNLOAD_FILE(res);
  });
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChangeDetail = (currentPage) => {
  params.value.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChangeDetail = (pageSize) => {
  params.value.pageNum = 1;
  params.value.pageSize = pageSize;
  getList();
};
/**
 * 获取type类型下拉框
 */
const getTypelect = () => {
  getInventoryflowTypeEnumApi().then((res) => {
    res = res.map((item) => {
      return { label: item.name, value: item.type };
    });
    searchOption.searchList.map((item) => {
      if (item.prop == "type") {
        item.optionData = [{ label: "All", value: "0" }, ...res] || [
          { label: "All", value: "0" },
        ];
      }
    });
  });
};
/**
 * 获取仓库下拉框
 */
const getstoreselect = () => {
  getstoreselectApi().then((res) => {
    let { list } = res;
    list = list.map((item) => {
      return { label: item.namecn, value: item.id };
    });
    searchOption.searchList.map((item) => {
      if (item.prop == "storehouseid") {
        item.optionData = [{ label: "All", value: 0 }, ...list] || [
          { label: "All", value: 0 },
        ];
      }
    });
  });
};

/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "流水查询",
  });
};

onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>

<style scoped></style>

import { useRouter, useRoute } from "vue-router"
import { ref } from "vue"
export const router = () => {
    const router = useRouter()
    return router
}

export const route = () => {
    const route = useRoute()
    return route
}
/**
 *  导出 所需要的参数
 * @param {*} params  search 参数
 * @param {*} status  导出 状态 all:全部状态导出
 */
export const getAllParams = (params, status) => {
    let copyparams = { ...params};
    delete copyparams.pageNum;
    delete copyparams.pagenum;
    delete copyparams.pageSize;
    if (status == "all") delete copyparams.status;
    const keys = Object.keys(copyparams);
    // 使用 map() 函数将每个字段转换为 "字段名=字段值" 格式并拼接起来
    let result = keys.map((key) => `${key}=${encodeURIComponent(copyparams[key])}`).join("&");
    return result;
  };
/**
 * 判断对象里面的字段是否都为空
 */
export const isAllFieldsEmpty = (obj) => {
    // 获取对象的所有键名
    let keys = Object.keys(obj);
  
    for (let key of keys) {
      if (
        obj[key] !== "" &&
        obj[key] != null &&
        typeof obj[key] !== "undefined"
      ) {
        return false; // 如果存在非空字段则返回false
      }
    }
    return true; // 若没有任何非空字段即字段都为空，则返回true
  };

/*
 * @Author: vere
 * @Date: 2024-01-29 17:24:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-04 15:27:22
 * @Description: 
 */

// 服务端接口baseURL
export const baseURL = () => {
  const baseurl = import.meta.env.VITE_BASE_URL
  return baseurl
}


export const baseImgURL = () => {
  let onlineImgURL = "https://static.buffaloex.com/";
  return onlineImgURL;
}

export const usageURL = () => {
  let baseUrl = "";
  switch (import.meta.env.MODE){
    case 'dev':
      baseUrl = 'http://192.168.0.107:6007';
      break;

    case 'test':
      baseUrl = 'http://192.168.0.46:6007';
      break;

    case 'test2':
        baseUrl = 'http://192.168.0.228:6007';
        break;
    case 'release':
        baseUrl = 'http://192.168.0.103:6007';
      break;
    
    case 'pre':
        baseUrl = 'http://preview-admin.buffaloex.com';
      break;

    case 'prod':
        baseUrl = 'https://index.buffaloex.com';
      break;

  }
  return baseUrl;
};

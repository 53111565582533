/**仓储计费报表 */
export const searchOption = reactive({
  // 顶部表单检索内容,非必填
  labelWidth: "100px",
  searchList: [
    // 检索列表

    {
      label: "增值服务单号:",
      type: "input",
      value: "",
      prop: "orderno",
      clearable: true,
      maxlength: 50,
      placeholder: '请输入增值服务单号'
    },
    {
      label: "关联单据:",
      type: "input",
      value: "",
      prop: "inboundno",
      clearable: true,
      maxlength: 50,
      placeholder: '请输入关联单据'
    },
    {
      label: "状态:",
      type: "select",
      value: 0,
      prop: "status",
      optionData: [{ label: '待处理', value: 0 }],
      clearable: false
    },
    {
      label: "创建时间:",
      type: "date",
      // dateType: "daterange",
      value: '',
      prop: "daterange",
      // clearable: true
    },
  ],
})
export const tableOption = reactive({
  height: 500,
  selection: true,
  index: true,
  tableColumn: [
    {
      label: "增值服务单号",
      prop: "orderno",
      minWidth: "180",
      align: 'left'
    },
    {
      label: "关联单据",
      prop: "inboundno",
      minWidth: "180",
      align: 'left'
    },
    {
      label: "状态",
      prop: "status",
      minWidth: "100"
    },
    {
      label: "创建人",
      prop: "createuser",
      minWidth: "130",
    },
    {
      label: "操作日期",
      prop: "createtime",
      minWidth: "180",
      align: 'left'
    },
    {
      label: "完成时间",
      prop: "finishtime",
      minWidth: "180",
      align: 'left'
    },
  ],
  // 表格数据，必填
  tableData: [],
  page: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
})


/** 角色权限管理*/
import { GET, POST } from '@/utils/request'

// 添加role
export const addRoleApi = (data) => POST({ url: '/role/addRole', data }) 

// 删除角色
export const deleteRoleApi = (data) => POST({ url: '/role/deleteRole', data }) 

// 保存role树状结构
export const saveroletreelistApi = (data) => POST({ url: '/role/saveroletreelist', data }) 

// 角色列表查询
export const rolesearchApi = (params) => GET({ url: '/role/rolesearch', params })

// Role树状结构
export const roletreelistApi = (params) => GET({ url: '/role/roletreelist', params})

<template>
  <section>
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
          @handleRemoteMethods="handleRemoteMethods"
          @handleRemoteSelectFocus="handleRemoteSelectFocus"
          @handleSelectChangeMethods="handleSelectChangeMethods"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row type="flex" class="create mt-lg mb-lg justify-between">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operateHandle('all-export')"
          >全部状态导出</el-button
        >
        <el-button
          type="primary"
          icon="DocumentAdd"
          @click="operateHandle('create')"
          >新建入库单</el-button
        >
      </el-row>
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-button
              type="primary"
              plain
              icon="Upload"
              @click="operateHandle('single-export')"
              >导出</el-button
            >
          </el-col>
        </el-row>
        <el-tab-pane
          v-for="(item, index) in inboundOrderTable"
          :key="index"
          :label="`${item.tabName}（${item.count}）`"
          :name="item.activeName"
        >
          <el-table
            :data="item.tableData"
            :header-cell-style="{ background: '#FAFAFA' }"
            :border="true"
            style="width: 100%"
            stripe
            height="400"
          >
            <el-table-column
              type="index"
              width="60"
              label="序号"
              align="center"
            />
            <el-table-column
              v-for="column in item.tableColumn"
              :key="column.prop"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :align="column.align || 'center'"
            >
              <template #default="{ row }">
                <div v-if="column.prop == 'valueaddorlist'">
                  <el-link
                    type="primary"
                    v-for="item in row.valueaddorlist"
                    :key="item.id"
                    :underline="false"
                    @click="showValueAddDetail(item.id)"
                  >
                    <span class="link">{{ item.orderno }}</span>
                  </el-link>
                </div>
                <div v-else>
                  {{ row[column.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              :width="item.operationColumn.minWidth"
            >
              <template #default="scope">
                <el-button
                  v-for="(btn, index) in item.operationColumn.btns"
                  :key="index"
                  :type="btn.type"
                  :plain="btn.plain || false"
                  link
                  @click="operateHandle(btn.prop, scope.row)"
                  >{{ btn.title }}</el-button
                >
              </template>
            </el-table-column>
            <!-- 空数据 展示 -->
            <template #empty>
              <com-no-data></com-no-data>
            </template>
          </el-table>
          <el-pagination
            v-if="item.page && item.page.total > 0"
            background
            class="justify-end"
            :current-page="item.page.currentPage"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="item.page.pageSize"
            layout="total,sizes,prev, pager, next, jumper"
            :total="item.page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 查看入库单详情 orderType == 1: 入库-->
    <order-detail-dialog
      :orderType="1"
      :id="inboundorderid"
      :dialogVisible="detailDialog"
      @openDialog="openDialog"
    >
    </order-detail-dialog>

    <el-dialog
      v-model="dialogVisible"
      width="70%"
      title="增值服务"
      class="dialog-min-width"
      :close-on-click-modal="false"
    >
      <add-value
        :insuredshow="true"
        :isEdit="false"
        :showOrder="true"
        :sncodeqty="sncodeqty"
        :skutotalprice="skutotalprice"
        :addvalueformInitData="serviceForm"
      >
        <template #closeBtn>
          <el-button @click="dialogVisible = false">关闭</el-button>
        </template>
      </add-value>
    </el-dialog>
  </section>
</template>

<script setup>
import { inboundOrderTable } from "./column";
import {
  getstoreselectApi,
  getSelectListBySkuOrBarcodeApi,
} from "@/api/common.js";
import {
  inboundListApi,
  cancelInboundApi,
  deleteInboundApi,
  copyInboundApi,
  singleExportApi,
  allExportApi,
  printboxlabelApi,
  printlistApi
} from "@/api/inbound.js";
import { detailApi } from "@/api/addservers";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import dayjs from "dayjs";
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  onActivated,
  onDeactivated,
} from "vue";
import { useRoute } from "vue-router";
import { useKeepAliveStore } from "@/stores/keepAliveStore.js";

import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

defineOptions({
  name: "InboundList",
});

const searchOption = reactive({
  labelWidth: "80px",
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "",
      type: "select-input",
      value: "",
      prop: "orderno",
      maxlength: 30,
      placeholder: "请输入对应单号/参考号",
      selectOption: {
        value: 0,
        prop: "querytype",
        optionData: [
          { value: 0, label: "入库单号(ASN)" },
          { value: 1, label: "交货物流单号" },
          { value: 2, label: "参考号" },
        ],
      },
    },
    {
      label: "仓库:",
      type: "select",
      value: "",
      prop: "warehouseid",
      optionData: [],
      clearable: true,
      placeholder: "请选择仓库",
    },

    {
      label: "创建时间:",
      type: "date",
      value: "",
      prop: "daterange",
    },
    {
      label: "",
      type: "select-remote-select",
      value: "",
      prop: "productId",
      maxlength: 50,
      placeholder: "请输入产品条码/SKU",
      clearable: true,
      optionData: [],
      selectOption: {
        value: 1,
        prop: "productQueryType",
        optionData: [
          { value: 1, label: "产品条码" },
          { value: 0, label: "SKU" },
        ],
      },
    },
  ],
});
const keepAliveStore = useKeepAliveStore();

const trackingInfo = useTrackingInfo();

const activeName = ref("-5"); // 展示第一个tab（草稿）

const dateRange = ref([]); // 选择的时间范围
const lastMonth = ref(""); // 默认开始时间
const nowMonth = ref(""); // 默认结束时间

const route = useRoute();

const curpath = route.path; // 获取当前url

const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

// 搜索入参
let params = reactive({
  status: "-5",
  pageNum: 1,
  pageSize: 10,
  starttime: dayjs().add(-2, "month").format("YYYY-MM-DD"),
  endtime: dayjs().format("YYYY-MM-DD"),
});

const dialogVisible = ref(false); // 增值服务详情 弹框状态
const serviceForm = ref({}); // 增值服务详情
const skutotalprice = ref(""); //  保价服务
const sncodeqty = ref(0); // 增值服务 扫描的个数

const detailDialog = ref(false); // 入库单产看详情 弹框
const inboundorderid = ref(""); // 入库单id

/**
 * 时间筛选 限制在1年内
 */
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 12,
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 12,
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};
/**
 * barcode/sku 筛选 模糊搜索
 */
const barcodeOrSkuResult = ref([]); // 下拉框产品条码/sku 列表

/**
 * 远程 左侧下拉框 类型type 1: sku, 2:barcode 做选择时，清空右侧框内内容
 * @param {*}
 */
const handleSelectChangeMethods = () => {
  searchOption.searchList.map((item) => {
    if (item.prop === "productId") {
      item.value = "";
    }
  });
};

/**
 * 远程 select 获取焦点 得到类型type
 * @param {*} type  商品搜索类型：0-sku；1-barcode；
 */
const handleRemoteSelectFocus = (type) => {
  getSelectListBySkuOrBarcode(type);
};
/**
 * 获取类型，以及输入的值
 * @param {*} obj => value, type
 */
const handleRemoteMethods = (obj) => {
  setTimeout(() => {
    getSelectListBySkuOrBarcode(obj.type, obj.value);
  }, 200);
};
/**
 * SkuOrBarcode 列表
 */
const getSelectListBySkuOrBarcode = (queryType, barcodeOrSku) => {
  // auditedflg是否审核: 0或null:全查,1:已审核,2,未审核
  getSelectListBySkuOrBarcodeApi({
    auditedflg: 1,
    pageNum: 1,
    queryType, // 商品搜索类型：0-sku；1-barcode；
    barcodeOrSku,
  }).then((res) => {
    barcodeOrSkuResult.value = res.list.map((item) => {
      if (queryType == 1) {
        return { value: item.id, label: item.productbarcode };
      } else {
        return { value: item.id, label: item.sku };
      }
    });
    searchOption.searchList.map((item) => {
      if (item.prop == "productId") {
        item.optionData = barcodeOrSkuResult.value;
      }
    });
  });
};

/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  obj.starttime = dateRange.value[0];
  obj.endtime = dateRange.value[1];
  inboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = 10;
  });
  delete obj.productQueryType;
  params = {
    ...obj,
    status: activeName.value,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "orderno") {
      item.selectOption.value = 0;
    }
    if (item.prop == "productId") {
      item.selectOption.value = 1;
    }
  });
  dateRange.value = [lastMonth.value, nowMonth.value];
  params = {
    status: activeName.value,
    pageNum: 1,
    pageSize: params.pageSize,
    starttime: lastMonth.value,
    endtime: nowMonth.value,
  };
  handleCurrentChange(1); // 回到第一页数据
};
/**
 * 获取 列表数据
 */
const getList = () => {
  inboundListApi(params).then((res) => {
    inboundOrderTable.map((item) => {
      item.tableData = res.list;
      item.page.total = res.recordTotal;
      switch (item.activeName) {
        case "-5":
          item.count = res.rednum_draft || 0;
          break;
        case "0":
          item.count = res.rednum_unreceived || 0;
          break;
        case "10":
          item.count = res.rednum_received || 0;
          break;
        case "20":
          item.count = res.rednum_inspected || 0;
          break;
        case "30":
          item.count = res.rednum_onshelf || 0;
          break;
        case "90":
          item.count = res.rednum_completed || 0;
          break;
        case "100":
          item.count = res.rednum_canceled || 0;
          break;
      }
    });
  });
};
/**
 * tab 点击切换
 */
const tabClick = (tab, event) => {
  tracking();
  params.pageNum = 1;
  params.pageSize = 10;
  params.status = tab.paneName;
  activeName.value = tab.paneName;
  accessTime.value = Date.now();
  inboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = 10;
  });
  getList();
};
/**
 * table 里面按钮操作
 */
const operateHandle = (type, item) => {
  let _params = JSON.parse(JSON.stringify(params));
  switch (type) {
    case "cancel":
      // 作废操作
      ElMessageBox.confirm("是否作废该订单？", {
        showClose: false,
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          // 判断订单状态
          cancelInboundApi({ inboundorderid: item.inboundorderid }).then(
            (res) => {
              ElMessage.success("入库单取消成功");
              getList();
            },
          );
        })
        .catch(() => {
          console.log("cancel");
        });
      break;
    case "delete":
      // 删除操作
      ElMessageBox.confirm("是否删除该草稿？", "提示", {
        showClose: false,
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          deleteInboundApi({ inboundorderid: item.inboundorderid }).then(
            (res) => {
              ElMessage.success("草稿已删除成功");
              getList();
            },
          );
        })
        .catch(() => {
          console.log("cancel");
        });
      break;
    case "edit":
      keepAliveStore.refresCurrentRoute({
        path: "/inbound/create",
        query: {
          id: item.inboundorderid + "",
        },
      });
      break;
    case "create":
      keepAliveStore.refresCurrentRoute({
        path: "/inbound/create",
      });
      // 新建入库单
      break;
    case "detail":
      // 查看入库单
      inboundorderid.value = item.inboundorderid;
      detailDialog.value = true;
      break;
    case "label":
      // 箱标
      printboxlabelApi({
        inboundorderid: item.inboundorderid,
        size: 4
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "count":
      // 箱单
      printlistApi({
        inboundorderid: item.inboundorderid,
        size: 100
      }).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "copy":
      copyInboundApi({
        id: item.inboundorderid,
      }).then((res) => {
        ElMessage.success("复制成功");
        getList();
      });
      break;
    case "all-export":
      delete _params.status;
      allExportApi(_params).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
    case "single-export":
      singleExportApi(params).then((res) => {
        DOWNLOAD_FILE(res);
      });
      break;
  }
};
const openDialog = (val) => {
  detailDialog.value = val;
};

/**
 * 获取增值服务详情
 * @param {*} row
 */
const showValueAddDetail = (id) => {
  detailApi({ id }).then((res) => {
    serviceForm.value = res;
    serviceForm.value.filerecordList = res.valueaddorderVo.files?.map((item) => {
      return {
        filename: item.filename,
        filelink: item.filelink,
        url: item.filelink
      };
    });
    sncodeqty.value = res.valueaddorderRequiredBo?.sncodeqty || 0;
    skutotalprice.value = res.skutotalprice || 0;
    dialogVisible.value = true;
  });
};

/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  inboundOrderTable.map((item) => {
    item.page.currentPage = currentPage;
  });
  params.pageNum = currentPage;
  getList();
};

/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  inboundOrderTable.map((item) => {
    item.page.currentPage = 1;
    item.page.pageSize = pageSize;
  });
  params.pageNum = 1;
  params.pageSize = pageSize;
  getList();
};

/**
 * 获取仓库下拉框
 */
const getstoreselect = () => {
  getstoreselectApi().then((res) => {
    res.list.map((item) => {
      item["label"] = item.namecn;
      item["value"] = item.id;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "warehouseid") {
        item.optionData = res.list;
      }
    });
  });
};

/**
 * 获取默认时间段
 */

const getDate = () => {
  lastMonth.value = dayjs().add(-2, "month").format("YYYY-MM-DD"); // 获取2个月前
  nowMonth.value = dayjs().format("YYYY-MM-DD"); // 当月
  dateRange.value = [lastMonth.value, nowMonth.value];
};

const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "入库单管理",
    tabname: getTabName,
  });
};
const getTabName = computed(() => {
  switch (activeName.value) {
    case "-5":
      return "草稿";
      break;
    case "0":
      return "未收货";
      break;
    case "10":
      return "已收货";
      break;
    case "20":
      return "已质检";
      break;
    case "30":
      return "已上架";
      break;
    case "90":
      return "已完成";
      break;
    case "100":
      return "作废订单";
      break;
  }
});

onMounted(() => {
  getDate();
  getstoreselect();
  getList();
});

onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>

<style scoped lang="scss">
.table-section {
  :deep(.flex-between) {
    margin: 0;
  }
}
</style>

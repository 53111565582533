
import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

/**
 * list
 * @param {*}  
 * @returns 
 */
//1 入库订单列表
export const inboundListApi = (params) => GET({ url: '/inbound/list', params })

//2 入库单作废
export const cancelInboundApi = (data) => POST({ url: '/inbound/cancel', data })

//3 删除草稿
export const deleteInboundApi = (data) => POST({ url: '/inbound/delete', data })

//4 查看
export const viewInboundApi = (inboundorderid) => GET({ url: '/inbound/view', params: { inboundorderid }})

// 5 复制
export const copyInboundApi = (params) => GET({ url: '/inbound/copyInboundOrder', params})


/**
 * create
 * @param {*} data 
 * @returns 
 */
// 创建入库订单
export const createInboundOrderApi = (data) => POST({ url:'/inbound/createorder', data, headersType: 'application/json' })

// 入库单暂存
export const createInboundDraftApi = (data) => POST({ url: '/inbound/createdraft', data, headersType: 'application/json' })

// 暂存单编辑页数据
export const draftDataApi = (params) => GET({ url: '/inbound/draftData', params})

// Buffalo物流单页信息
export const buffaloOrderInfoApi = (data) => POST({ url: '/inbound/checkBuffaloOrder', data, headersType: 'application/json' })

// 二期-发货类型和terms下拉框
export const getOrderTypeEnumApi = (params) => GET({ url: '/common/getOrderTypeEnum', params })

// check-订单确认（新建入库单‘订单确认’页信息)
export const checkOrderConfirmApi = (data) => POST({ url: '/inbound/checkOrderConfirm', data, headersType: 'application/json' })

// check-增值服务（新建入库单‘增值服务’页信息）
export const checkValueAddedApi = (data) => POST({ url: '/inbound/checkValueAdded', data, headersType: 'application/json' })

// 导出
export const singleExportApi = (params) => EXPORT_FILE_GET({ url: '/inbound/export', params })

// 全部导出

export const allExportApi = (params) => EXPORT_FILE_GET({ url: '/inbound/exportAll', params })

// 箱标
export const printboxlabelApi = (params) => EXPORT_FILE_GET({ url: '/inbound/printboxlabel', params })


// 箱单
export const printlistApi = (params) => EXPORT_FILE_GET({ url: '/inbound/printlist', params })

// 下载模板
export const downloadtemplateApi = () => EXPORT_FILE_GET({ url: '/inbound/downloadtemplate' })

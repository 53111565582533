<template>
  <section>
    <!-- 子账户管理 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @inputRemoteValue="inputRemoteValue"
          @remoteValue="remoteValue"
          @remoteMethod="remoteMethod"
          @search="search"
          @reset="reset"
        >
        </com-search>
      </div>

      <el-row type="flex" justify="end" class="mt-lg mb-lg">
        <!--  -->
        <el-button
          v-buttonPermission="`${routePath}/NewCreate`"
          type="primary"
          icon="DocumentAdd"
          @click="columnClick('create')"
          >创建用户</el-button
        >
      </el-row>

      <el-table
        :data="tableOption.tableData"
        :border="true"
        :header-cell-style="{ background: '#FAFAFA' }"
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="450"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
          <template #default="{ row }">
            <div v-if="column.prop == 'productstatus'">
              <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="0"
                @change="operateStatus(row)"
              />
            </div>
            <div v-else>
              {{ row[column.prop] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150" align="left">
          <template #default="scope">
            <el-button
              v-buttonPermission="`${routePath}/Resetpassword`"
              type="primary"
              link
              @click="columnClick('resetPwd', scope.row)"
              >重置密码</el-button
            >
            <el-button
              v-buttonPermission="`${routePath}/Edit`"
              type="primary"
              link
              @click="columnClick('edit', scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="tableOption.page.currentPage"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableOption.page.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>

    <!-- 添加/编辑弹框 -->
    <el-dialog
      class="address"
      width="30%"
      v-model="dialogVisible"
      :title="dialogTitle"
      :close-on-click-modal="false"
    >
      <el-form
        ref="userFormRef"
        label-width="90px"
        label-position="right"
        :model="userForm"
        :rules="rules"
        :inline="false"
      >
        <el-form-item prop="name" label="用户名:">
          <el-input
            v-trim
            style="width: 90%"
            maxlength="20"
            placeholder="请输入用户名"
            v-model="userForm.name"
            :disabled="dialogTitle === '编辑'"
          />
        </el-form-item>
        <el-form-item prop="email" label="邮箱:">
          <el-input
            v-trim
            style="width: 90%"
            maxlength="50"
            placeholder="请输入邮箱"
            v-model="userForm.email"
          />
        </el-form-item>
        <el-form-item prop="role" label="角色:">
          <el-select
            multiple
            style="width: 90%"
            placeholder="请选择角色"
            v-model="userForm.role"
          >
            <el-option
              v-for="(item, index) in rolesList"
              :key="index"
              :label="item.rolename"
              :value="item.roleid"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button class="btn" type="primary" @click="confirmInfo(userFormRef)">
          确定
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script setup>
import {
  searchuserApi,
  getAllroleApi,
  getRolelistApi,
  enableDisableApi,
  resetpasswordUSerApi,
  getUserApi,
  updateOrAddUserApi,
} from "@/api/management/subaccount";
import { tableOption } from "./column";
import { onMounted, markRaw, reactive, computed } from "vue";
import { WarningFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";
// import  {} from

defineOptions({
  name: "ManagementAccount",
});

const route = useRoute();
const searchOption = reactive({
  // 顶部表单检索内容,非必填
  // singleRow: true,
  searchList: [
    // 检索列表
    {
      label: "用户名:",
      type: "input",
      value: "",
      prop: "username",
      maxlength: 50,
      placeholder: "请输入用户名",
    },
    {
      label: "角色:",
      type: "autocomplete",
      value: "",
      prop: "role",
      maxlength: 50,
      placeholder: "请选择角色",
      clearable: true,
    },
    {
      label: "可用/禁用:",
      type: "select",
      value: "0",
      prop: "enabledisable",
      optionData: [
        { label: "全部", value: "0" },
        { label: "可用", value: "401" },
        { label: "禁用", value: "405" },
      ],
      clearable: false,
    },
  ],
});
// 搜索入参
let params = reactive({
  pageNum: 1,
  pageSize: 10,
  enabledisable: "0",
});

const roleid = ref(""); // 角色id

const rolesResult = ref([]); // 下拉框角色 列表

/**
 * 获取角色列表
 */
const getList = () => {
  searchuserApi(params).then((res) => {
    tableOption.tableData = res.list;
    tableOption.page.total = res.recordTotal;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  obj.role = roleid.value;
  params = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 重置， 清空
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item.value = "";
    if (item.prop == "enabledisable") {
      item.value = "0";
    }
  });
  roleid.value = "";
  params = {
    pageNum: 1,
    pageSize: params.pageSize,
    enabledisable: "0",
  };
  handleCurrentChange(1);
};
/**
 * 模糊搜索角色
 * @param {*} cb
 */
const remoteRoleValue = ref(""); //  角色输入框 内的 内容

const remoteMethod = (cb) => {
  getRoleList(remoteRoleValue.value);
  setTimeout(() => {
    cb(rolesResult.value);
  }, 1000);
};
/**
 * 获取 角色 id
 * @param {*} item  当前角色的 信息
 */
const remoteValue = (item) => {
  roleid.value = item.roleid;
};
/**
 * 获取当前 输入框 内容
 * @param {*}  value
 */
const inputRemoteValue = (value) => {
  remoteRoleValue.value = value;
  roleid.value = "";
};
/**
 * 模糊搜索 所获取的 角色 下拉框列表
 * @param {*} roleName
 */
const getRoleList = (roleName) => {
  getRolelistApi({
    pageNum: 1,
    role: roleName,
  }).then((res) => {
    const list = res.list || [];
    rolesResult.value = list.map((item) => {
      return { value: item.name, label: item.id, roleid: item.id };
    });
  });
};

/**
 * 获取所有角色
 */
const rolesList = ref([]);
const getRolesList = () => {
  getAllroleApi().then((res) => {
    rolesList.value = res;
  });
};

/**
 *  操作 禁用、可用 状态
 */
const enableDisableParams = ref({}); // 操作状态 参数
const operateStatus = (row) => {
  enableDisableParams.value.userid = row.userid;
  enableDisableParams.value.id = row.id;
  row.status === 1
    ? (enableDisableParams.value.status = 1)
    : (enableDisableParams.value.status = 2);
  enableDisableApi(enableDisableParams.value);
};

/**
 *  编辑/创建用户/重置密码
 * @param {*} prop  操作对象
 * @param {*} row 操作 数据
 */

const dialogTitle = ref("创建用户");
const dialogVisible = ref(false);
let userForm = reactive({
  name: "",
  email: "",
  id: 0,
  role: "",
  sales: false,
  userid: 0,
});
const rules = reactive({
  name: [
    {
      required: true,
      message: " Please fill in this option!",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: " Please fill in this option!",
      trigger: "blur",
    },
    // { type: 'email', message: 'Please enter a valid email address!', trigger: ['blur', 'change'] }
    // {validator: checkEmail, trigger: ['blur', 'change']},
    {
      pattern:
        /^[A-Za-z0-9]+([._%+-][A-Za-z0-9.]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/,
      message: "Please enter a valid email address!",
      trigger: ["blur", "change"],
    },
  ],
});
const columnClick = (prop, row) => {
  switch (prop) {
    case "resetPwd":
      ElMessageBox.confirm("密码将发送至您的邮箱", {
        showClose: false,
        type: "warning",
        icon: markRaw(WarningFilled),
      })
        .then(() => {
          resetpasswordUSerApi({
            email: row.email,
            userid: row.userid,
          }).then((res) => {
            ElMessage.success("密码重置成功");
          });
        })
        .catch(() => {
          console.log("cancel");
        });
      break;
    case "edit":
      dialogTitle.value = "编辑";
      getUserApi({
        userid: row.userid,
        id: row.id,
      }).then((res) => {
        userForm.name = res.username;
        userForm.email = res.email;
        userForm.sales = res.sales;
        userForm.userid = res.userid;
        userForm.role = res.roleList.map((item) => item.id);
        userForm.id = res.id;
        dialogVisible.value = true;
      });

      break;
    case "create":
      dialogTitle.value = "创建用户";
      userForm = reactive({
        name: "",
        email: "",
        id: 0,
        role: "",
        sales: false,
        userid: 0,
      });
      dialogVisible.value = true;
      break;
  }
};
/**
 *  编辑/创建用户 时 提交
 */
const userFormRef = ref();
const confirmInfo = async (userFormRef) => {
  if (!userFormRef) return;
  await userFormRef.validate((valid) => {
    if (valid) {
      userForm.role = userForm.role ? userForm.role.join(",") : "";
      userForm.sales = userForm.sales ? 1 : 0;
      updateOrAddUserApi(userForm)
        .then((res) => {
          userForm.id
            ? ElMessage.success("编辑用户成功!")
            : ElMessage.success("添加用户成功!");

          dialogVisible.value = false;
          getList();
        })
        .catch((err) => {
          dialogVisible.value = false;
        });
    }
  });
};

/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  tableOption.page.currentPage = currentPage;
  params.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  tableOption.page.currentPage = 1;
  tableOption.page.pageSize = pageSize;
  params.pageNum = 1;
  params.pageSize = pageSize;
  getList();
};

const routePath = computed(() => {
  return route.path;
});
onMounted(() => {
  getList(); // 用户列表
  getRolesList(); // 所有角色名
});
</script>

<style>
</style>
import { GET, POST } from '@/utils/request'
import {EXPORT_FILE_GET} from "@/utils/export-file.js";
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

// 审核状态下拉
export const auditStatusEnumListApi = () => GET({ url: '/customerrecharge/getAuditStatusEnum'}) 

// 充值类型下拉
export const rechargeTypeEnumListApi = () => GET({ url: '/customerrecharge/getRechargeTypeEnum'}) 

// search
export const rechargeListApi = (params) => GET({ url: '/customerrecharge/search', params}) 

// 查看凭证
export const viewFilesApi = (params) => GET({ url: '/customerrecharge/viewFiles', params}) 

// 销毁
export const destroyApi = (data) => POST({ url: '/customerrecharge/destroy', data}) 

// 导出
export const exportApi = (params) => EXPORT_FILE_GET({ url: '/customerrecharge/export', params })


// 充值
// 支付宝
export const createPayOrderApi = (data) => POST({ url: '/recharge/createPayOrder', data})

// 银联
export const submitPayBankOrder = (data) => POST({ url: '/recharge/bank', data})


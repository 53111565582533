<template>
  <section>
    <!-- 导入下单 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <el-form ref="formRef" :model="params" class="storehouse-choice" inline>
          <el-form-item
            label="出货仓库:"
            prop="storehouseId"
            :rules="[
              {
                required: true,
                message: '请选择出货仓库',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="params.storehouseId"
              class="select_w"
              placeholder="请选择出货仓库"
              @change="selectStorehouse"
            >
              <el-option
                v-for="item in warehouseList"
                :key="item.id"
                :label="item.namecn"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="upload-file">
              <el-upload
                v-if="uploadBtnShow"
                ref="upload"
                action="action"
                accept=".xls, .xlsx"
                :auto-upload="false"
                :on-change="handleChange"
                :show-file-list="false"
              >
                <el-button type="primary" plain icon="DocumentAdd"
                  >选择文件</el-button
                >
              </el-upload>
              <el-button
                v-else
                type="primary"
                plain
                icon="DocumentAdd"
                @click="importFile(formRef)"
                >选择文件</el-button
              >
            </div>

            <el-button
              type="primary"
              icon="Download"
              plain
              @click="downloadFile"
              >下载模板</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 20px">
        <el-row v-if="importRemindStatus">
          <el-col class="import-remind"
            >导入过程中请耐心等待，请勿离开此页面</el-col
          >
        </el-row>
        <el-row v-if="remindStatus">
          <el-col class="import-remind">{{ remindDesc }}</el-col>
        </el-row>
        <el-row>
          <el-col>失败列表</el-col>
        </el-row>
      </div>

      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center', background: '#FAFAFA' }"
        :cell-style="{ 'text-align': 'center' }"
        fit
        stripe
        style="width: 100%"
        border
        height="550"
      >
        <el-table-column
          width="150"
          prop="rowindex"
          label="行号"
        ></el-table-column>
        <el-table-column prop="message" label="失败原因"></el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
    </el-card>
  </section>
</template>

<script setup>
import axios from "axios";

import { useRoute } from "vue-router";
import { getstoreselectApi } from "@/api/common.js";
import { downloadTemplateApi } from "@/api/outbound";
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
import { getToken } from "@/utils/auth";
import { baseURL } from "@/utils/baseURL";

import { useTrackingInfo } from "@/stores/tracking.js"; // 埋点

defineOptions({
  name: "OutboundImport",
});
const route = useRoute();
const curpath = route.path;

const params = ref({
  storehouseId: "",
});
const faiedCount = ref(0); // 失败数量

const remindStatus = ref(false); // 提示状态

const remindDesc = ref(""); // 提示文本

const importRemindStatus = ref(false); // 导入时提示状态

const importRemind = ref(""); // 导入时提示文本

const tableData = ref([]); // table 数据

const warehouseList = ref([]); // 仓库列表

const uploadBtnShow = ref(false); // 选择文件状态展示

const formRef = ref();

const accessTime = ref(Date.now()); // 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

/**
 * 选择仓库，以此来展示按钮 是否 出现
 */
const selectStorehouse = (val) => {
  val ? (uploadBtnShow.value = true) : (uploadBtnShow.value = false);
};

/**
 * 导入文件
 */
const importFile = async (formRef) => {
  if (!formRef) return;
  formRef.validate((valid) => {
    if (!valid) {
      return false;
    }
  });
};

/**
 * 导入 import
 */
const handleChange = (file, fileList) => {
  const isLt5M = file.size / 1024 / 1024 < 1;
  if (!isLt5M) {
    return ElMessage.error("上传的文件大小不能超过1MB");
  }
  ElMessageBox.confirm("导入过程中请耐心等待，请勿离开此页面", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    showClose: false,
    closeOnClickModal: false,
  })
    .then(() => {
      tableData.value = [];
      remindStatus.value = false;
      importRemindStatus.value = true;
      const formData = new FormData();
      formData.append("file", file.raw);
      axios
        .post(
          baseURL() + `/outbound/importBatchOrder/${params.value.storehouseId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Buffalo-ticket": getToken() || '',
            },
          },
        )
        .then((res) => {
          if (res.data.status == "00000") {
            if (res.data.data.faiedCount > 0) {
              tableData.value = res.data.data.failData;
              const faiedCount = res.data.data.faiedCount;
              remindDesc.value = `失败${faiedCount}条！`;
              importRemindStatus.value = false;
              remindStatus.value = true;
            } else {
              const successCount = res.data.data.successCount;
              remindDesc.value = `成功${successCount}条！`;
              tableData.value = [];
              importRemindStatus.value = false;
              remindStatus.value = true;
            }
          } else {
            importRemindStatus.value = false;
            return ElMessage.error(res.data.message);
          }
        })
        .catch((err) => {
          importRemindStatus.value = false;
          ElMessage.error(err.message);
        });
    })
    .catch(() => {
      console.log("取消");
    });
};

/**
 * 下载模板
 */
const downloadFile = () => {
  downloadTemplateApi().then((res) => {
    DOWNLOAD_FILE(res);
  });
};
/**
 * 获取仓库列表
 */
const getWarehouseList = () => {
  // type: null/-1查全部；0-海外仓；1-不良品仓；
  // null/-1 全部； 0 禁用 1 启用
  getstoreselectApi({ type: -1, status: 1 }).then((res) => {
    warehouseList.value = res.list;
  });
};
/**
 * 埋点
 */
const tracking = () => {
  trackingInfo.setTracking({
    duration: Date.now() - accessTime.value,
    pathname: curpath,
    menuname: "导入下单",
  });
};
const routePath = computed(() => {
  return route.path;
});
onMounted(() => {
  getWarehouseList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path;
  const currentpath = trackingInfo.pathInfo?.currentpath;
  if (path == curpath && currentpath == curpath) {
    tracking();
  }
});
onActivated(() => {
  accessTime.value = Date.now();
});
onDeactivated(() => {
  tracking();
});
</script>

<style lang="scss" scoped>
.storehouse-choice {
  .el-form-item {
    margin-right: 15px;
    :deep(.el-form-item__content) {
      height: 32px;
      line-height: normal;
    }
  }
}
.import-remind {
  text-align: center;
  color: #f56c6c;
  font-weight: bold;
}
.upload-file {
  display: inline-block;
  margin-right: 12px;
  height: 32px;
  width: 118px;
}
.el-upload {
  height: 32px;
}
</style>


import { GET, POST } from '@/utils/request'
/**  get请求用params , POST请求用data  headersType: 'application/json'*/

// 添加地址列表
export const addreceiveApi = (params) => GET({ url: '/receiveaddress/addreceive', params})

// 修改地址列表
export const modifyreceiveApi = (params) => GET({ url: '/receiveaddress/modifyreceive', params })

// 删除地址列表
export const delreceiveApi = (id) => GET({ url: '/receiveaddress/delreceive', params: { id }})

// 获取地址列表
export const getListApi = (params) => GET({ url: '/receiveaddress/search', params })

// 获取postcode或suburb
// export const searchPostcodeApi = (options) => GET({ url: port.searchPostcode, params: options })


const inputNumber = {
    beforeMount: (el, binding) => {
        var flag = true;
        // 定位输入框
        let input =  el instanceof HTMLInputElement ? el : el.querySelector("input");
        // compositionstart -> 开始新的输入合成时会触发
        input.addEventListener('compositionstart', _ => {
            flag = false;
            // vnode.inputLocaking = false
        })
        // compostitonend -> 合成完成或取消时触发
        input.addEventListener('compostitonend', _ => {
            // vnode.inputLocaking = false
            flag = true;
            input.dispatchEvent(new Event('input'))
        })
        input.addEventListener('input', _ => {
            if(!flag) return;
            let oldValue = input.value
            let newValue = input.value
            // 保留小数点后几位
            if (binding.modifiers.float) {
                // 清除数字和‘.’以外的字符
                // newValue = newValue.replace(/^\d./g, '');
                // 只保留第一个‘.’，清除多余的
                newValue = newValue.replace(/\.{2,}/g, '.');
                // 第一个字符如果是‘.’，补充前缀0
                newValue = newValue.replace(/^\./g, '0.');
                // 0开头的只有保留第一个0，清除多余的
                
                newValue = newValue.replace(/^0{2,}/g, '0');
                // 两位数以上不能0开头
                if (/^0\d+/.test(newValue)) {
                    newValue = newValue.slice(1)
                }
                // 保证‘.’只出现一次，而不能出现两次以上
                newValue = newValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')

                // 保留几位小数
                if (typeof binding.value !== 'undefined') {
                    // 期望保留的最大小数位数
                    let pointKeep = 0
                    if (typeof binding.value === 'string' || typeof binding.value === 'number') {
                        pointKeep = parseInt(binding.value)
                    }
                    if (!isNaN(pointKeep)) {
                        if (!Number.isInteger(pointKeep) || pointKeep < 0) {
                            pointKeep = 0
                        }
                        const str = '^(\\d+)\\.(\\d{' + pointKeep + '}).*$'
                        const reg = new RegExp(str)
                        if (pointKeep === 0) {
                            // 不需要小数点
                            newValue = newValue.replace(reg, '$1')
                        } else {
                            // 通过正则表达式保留小数点后指定的位数
                            newValue = newValue.replace(reg, '$1.$2')
                        }
                        _.target.value = newValue
                        // console.log('float---', newValue,  _.target.value = newValue)
                    }
                }
            }else if(binding.modifiers.numberFloat2){
                // 小数点前保留几位，小数点保留2位
                if (isNaN(newValue)) {
                    newValue = String(newValue).replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                    newValue = newValue.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
                    newValue = newValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
                }
                
                if(typeof binding.value !== 'undefined') {
                     // 期望保留的最大位数
                     let numberKeep = 0
                     if (typeof binding.value === 'string' || typeof binding.value === 'number') {
                        numberKeep = parseInt(binding.value)
                     } 
                    if (newValue.charAt(0) === '0' && newValue.charAt(1) !== '.') {
                        newValue = "0";
                    }
                    if (newValue.charAt(0) === '.') {
                        newValue = "0." + newValue.split('.')[1] ;
                    }
                    if (Number(newValue) >Math.pow(10,numberKeep)) {
                        newValue = (newValue.split('.')[1] === undefined) ? newValue.substr(0,numberKeep): newValue.substr(0,numberKeep) + "." + newValue.split('.')[1];
                    }
                    if(newValue.indexOf('.')>0){
                        const str = '^(\\d+)\\.(\\d{2}).*$'
                        const reg = new RegExp(str)
                        newValue = newValue.replace(reg, '$1.$2')
                        // newValue=newValue.slice(0,newValue.indexOf('.')+ (2 + 1));
                    }

                }
                _.target.value = newValue
            //   console.log('5-----2-----', newValue, _.target.value)

            } else {
                // 只保留整数
                // console.log('0---new==', newValue)
                newValue = newValue.replace(/[^\d]/g, '') // 只能输入数字
                // console.log('1---new==', newValue)
                newValue = newValue ? parseInt(newValue) : '' // string 转 number
                // newValue = newValue ? newValue : ''
                _.target.value = newValue
                // console.log('2---new==', newValue)
            }
            // 最大最小值限制
            // if(!Number.isNaN(Number.parseFloat(newValue))) {
            //     if(typeof attrs.min === 'number'  && attrs.min > newValue) {
            //         newValue = attrs.min;
            //     }
            //     else if(typeof attrs.max === 'number' && attrs.max < newValue) {
            //         newValue = attrs.max;
            //     }
            // }
            // 判断是否需要更新，避免进入死循环
            if (+newValue !== +oldValue) {
                // console.log('4---new==', newValue)
                _.target.value = newValue
                input.value = newValue
                input.dispatchEvent(new Event('input'))
            }
        })
    }
}

export default inputNumber
/** 获取盘点state选项列表*/
import { GET, POST } from '@/utils/request'

// 禁用启用
export const enableDisableApi = (data) => POST({ url: '/indexuser/enableDisable', data })

// 修改用户信息时Role多选框数据
export const getAllroleApi = (params) => GET({ url: '/indexuser/getAllrole', params })

// role模糊搜索
export const getRolelistApi = (params) => GET({ url: '/indexuser/getRolelist', params, closeloading:true })

// 修改时查询user数据
export const getUserApi = (params) => GET({ url: '/indexuser/getUser', params })

// 重置密码
export const resetpasswordUSerApi = (data) => POST({ url: '/indexuser/resetpassword', data })

// 列表查询
export const searchuserApi = (params) => GET({ url: '/indexuser/search', params })

// 修改/添加
export const updateOrAddUserApi = (data) => POST({ url: '/indexuser/updateOrAdd', data })

// /overseasuser/updatePwd
export const updatePwdApi = (params) => GET({ url: '/indexuser/updatePwd', params })

<template>
  <section>
    <!-- 账户流水 -->
    <el-card class="table-area" shadow="never">
      <div class="table-filter">
        <com-search
          :searchOption="searchOption"
          @search="search"
          @reset="reset"
        >
          <template #date>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="-"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              start-placeholder="开始"
              end-placeholder="结束"
              :disabled-date="disabledDateFun"
              :clearable="false"
              @calendar-change="calendarChange"
            />
          </template>
        </com-search>
      </div>
      <el-row :gutter="20" class="justify-between" style="margin-top: 20px">
        <el-col :span="12">
          <div class="basic-background price-item">
            <p>账户额度：</p>
            <p  class="label-price">
              <span  class="lable">¥</span> <span class="price">{{ creditline }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-background price-item">
            <p>国际物流待付金额：</p>
            <p class="label-price">
              <span class="lable">¥</span> <span  class="price">{{ amountpayable }}</span>
            </p>
          </div>
        </el-col>
      </el-row>
      <el-row class="mt-lg mb-lg">
        <el-button
          type="primary"
          plain
          icon="Upload"
          @click="operationBtnExport"
          >导出</el-button
        >
      </el-row>
      <el-table
        :data="tableOption.tableData"
        :border="true"
        :header-cell-style="{ background: '#FAFAFA' }"
        style="width: 100%; margin: 10px 0 20px"
        stripe
        height="400"
      >
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column
          v-for="column in tableOption.tableColumn"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align || 'center'"
        >
        </el-table-column>
        <!-- 空数据 展示 -->
        <template #empty>
          <com-no-data></com-no-data>
        </template>
      </el-table>
      <el-pagination
        v-if="tableOption.page && tableOption.page.total > 0"
        background
        class="justify-end"
        :current-page="params.pageNum"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="params.pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="tableOption.page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </section>
</template>
      
  <script setup>
import { balancechangelistApi, exportApi } from "@/api/management/account-statement";
import { getBalanceChangeTypeEnumApi } from "@/api/common";
import { tableOption } from "./column";
import { onMounted } from "vue";
import dayjs from "dayjs";
import { useRoute } from 'vue-router'
import { useTrackingInfo } from '@/stores/tracking.js'
import { DOWNLOAD_FILE } from "@/utils/export-file.js";
defineOptions({
  name: "ManagementAccountStatement",
});

const route = useRoute()
const curpath = route.path; // 获取当前url
const accessTime = ref(Date.now())// 进入该页面的时间戳, 埋点时间

const trackingInfo = useTrackingInfo();

const searchOption = reactive({
    // 顶部表单检索内容,非必填
    // singleRow: true,
    labelWidth:'100px',
    searchList: [
      // 检索列表
      {
        label: "日期:",
        type: "date",
        value: '',
        prop: "daterange",
        // dateType: "daterange",
        // clearable: false,
        // pickerOptions: pickerOptions()
      },
      {
        label: "流水号:",
        type: "input",
        value: "",
        prop: "feecode",
        maxlength: 20,
        placeholder: '请输入流水号'
      },
      {
        label: "物流运单号:",
        type: "input",
        value: "",
        prop: "waybill",
        maxlength: 20,
        placeholder: '请输入物流运单号'
      },
      {
        label: "费用类型:",
        type: "select",
        value: "",
        prop: "changetype",
        optionData: [],
        placeholder: '请选择费用类型'
      },
      {
        label: "类型:",
        type: "select",
        value: "0",
        prop: "type",
        optionData: [{label: '全部', value: "0"},{label: '入账', value: "1"},{label: '出帐', value: "2"}, ],
        clearable: false
      },
      {
        label: "关联订单号:",
        type: "input",
        value: "",
        prop: "orderno",
        maxlength: 20,
        placeholder: '请输入关联订单号'
      },
    ]
  })
const params = ref({
  // 筛选参数
  startdate: "", // 开始时间
  enddate: "", // 结束时间
  pageNum: 1, // 请求 某页 数据
  pageSize: 10,
});

const lastMonth = ref(""); // 上月
const nowMonth = ref(""); // 下月
/**
 * 时间筛选 限制在1月内
 */
const dateRange = ref([]); // 选择的时间范围
const choiceDate = ref("");
const disabledDateFun = (time) => {
  const choiceDateTime = new Date(choiceDate.value).getTime();
  const minTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() - 1
  );
  const maxTime = new Date(choiceDateTime).setMonth(
    new Date(choiceDateTime).getMonth() + 1
  );
  const min = minTime;
  const newDate =
    new Date(new Date().toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;
  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate.value) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};
// 获取选择的时间，
const calendarChange = (dates) => {
  const minDate = dates[0];
  const maxDate = dates[1];
  choiceDate.value = minDate.getTime();
  if (maxDate) {
    choiceDate.value = "";
  }
};

/**
 * 获取初始化数据
 */
const creditline = ref("0"); // 账户额度
const amountpayable = ref("0"); // 国际物流待付金额

const getList = () => {
  balancechangelistApi(params.value).then((res) => {
    tableOption.tableData = res.list.list;
    tableOption.page.total = res.list.recordTotal;
    creditline.value = res.creditline;
    amountpayable.value = res.amountpayable;
  });
};
/**
 * 条件检索
 * @param {*} obj
 */
const search = (obj) => {
  obj.startdate = dateRange.value[0];
  obj.enddate = dateRange.value[1];
  params.value = {
    ...obj,
    pageNum: 1,
    pageSize: 10,
  };
  getList();
};
/**
 * 清空按钮
 */
const reset = () => {
  searchOption.searchList.map((item) => {
    item["value"] = "";
    if (item.prop == "type") {
      item.value = "0";
    }
  });
  dateRange.value = [lastMonth.value, nowMonth.value];
  params.value = {
    pageNum: 1,
    pageSize: params.value.pageSize,
    startdate: lastMonth.value,
    enddate: nowMonth.value,
  };
  handleCurrentChange(1);
};
/**
 * 表格上方的操作按钮(这里只有导出按钮) export
 */
const operationBtnExport = () => {
  exportApi(params.value).then(res => {
    DOWNLOAD_FILE(res)
  })
};
/**
 *  切换页码，请求分页数据
 * @param {*} currentPage  当前页
 */
const handleCurrentChange = (currentPage) => {
  params.value.pageNum = currentPage;
  getList();
};
/**
 *  切换每页展示数据数量
 * @param {*}  pageSize 每页展示 pageSize 条
 */
const handleSizeChange = (pageSize) => {
  params.value.pageNum = 1;
  params.value.pageSize = pageSize;
  getList();
};

/**
 * 类型费用下拉框
 */
const getFeetype = () => {
  getBalanceChangeTypeEnumApi().then((res) => {
    res.map((item) => {
      item["label"] = item.name;
      item["value"] = item.type;
    });
    searchOption.searchList.filter((item) => {
      if (item.prop == "changetype") {
        item.optionData = res;
      }
    });
  });
};

/**
 * 获取默认时间段
 */
const getDate = () => {
  const last = dayjs().add(-1, "month").format("YYYY-MM-DD"); // 获取上月
  const now = dayjs().format("YYYY-MM-DD"); // 当月
  lastMonth.value = last;
  nowMonth.value = now;
  params.value.startdate = last;
  params.value.enddate = now;
  dateRange.value = [last, now];
};
/**
 * 埋点
 */
 const tracking = () => {
  trackingInfo.setTracking({
      duration: Date.now() - accessTime.value,
      pathname: curpath,
      menuname: '账户流水',
    });
}

onMounted(() => {
  getDate();
  getFeetype();
  getList();
});
onBeforeUnmount(() => {
  const path = trackingInfo.pathInfo?.path
  const currentpath = trackingInfo.pathInfo?.currentpath
  if(path == curpath && currentpath == curpath) {
    tracking()
  }
});
onActivated(() => {
    accessTime.value = Date.now()
})
onDeactivated(() => {
    tracking()
})
</script>    
<style lang="scss" scoped>
.price-item{
    padding:20px;
    border-radius:4px;
    font-size:14px;
    color:#636363;
    .label-price {
        font-weight:700;
        color:#1F1F20;
        margin-top:12px;
    }
    .price{
        font-size:28px;
    }
    .label{
        font-size:16px;
    }

}
</style>
      
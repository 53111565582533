import { computed, nextTick } from "vue"
import { useRouter, useRoute } from 'vue-router'

export const useKeepAliveStore = defineStore(
  'keepAlive',
  () => {
    const router = useRouter()
    /* 
      kepp-alive 信息
      keepAliveIncludeComps： [{compName: '组件名称'， path: 路由路径}]
    */

    // 设置keep-alive缓存组件信息
    const keepAliveIncludeComps = ref([])

    // 组件名称
    const keepAliveComponentNames = computed(() => {
      const names = []

      keepAliveIncludeComps.value.forEach(item => {
        if(!names.includes(item.compName))  {
          names.push(item.compName)
        }
      })
      return names
    })

    // 增加一个缓存
    const addKeepAliveCompInfo = (data) => {
      const compNames = keepAliveIncludeComps.value.map(item => item.compName)
      const arr= data.filter(tag => {
        return !compNames.includes(tag.compName)
      });
      keepAliveIncludeComps.value = [...keepAliveIncludeComps.value, ...arr]
      // console.log(keepAliveIncludeComps.value)
    }

    // 清空缓存
    const clearKeepAliveCompInfo= () => {
      keepAliveIncludeComps.value = []
    }

    // 删除某个缓存通过route path
    const remoteItemByPath = (routePath) => {
      const path = routePath.split('?')[0]
      keepAliveIncludeComps.value = keepAliveIncludeComps.value.filter(item => {
        return item.path !== path
      })
    }
    // 先删除某个缓存， 刷新后再缓存
    const refresCurrentRoute = (routePath, callback) => {
      let path = ''

      if(typeof routePath === 'string') {
        path = routePath.split('?')[0] 
      } else if(typeof routePath === 'object') {
        path = routePath.path.split('?')[0]
      }

      keepAliveIncludeComps.value = keepAliveIncludeComps.value.filter(item => {
        return item.path !== path
      })
      nextTick(() => {
        if(typeof routePath === 'string' || typeof routePath === 'object') {
          router.push(routePath)
        }else if(typeof callback === 'function') {
          callback()
        }
      })
    }

    return {
      keepAliveComponentNames,
      addKeepAliveCompInfo,
      keepAliveIncludeComps,
      remoteItemByPath,
      refresCurrentRoute,
    }
  },
  {
    persist: true
  }
)

<template>
      <section>
        <!--    过滤 区域-->
        <el-card class="account-content" shadow="never">
          <el-form label-position="right" ref="FormRef" label-width="190px" :model="Form" size="large"   :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="用户名:" >
                  <el-input v-trim  disabled v-model="Form.username" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="国家:" prop="country">
                  <el-select disabled v-model="Form.country" style="width: 100%" placeholder="" clearable>
                    <!-- <el-option label="All" value="-1" /> -->
                    <el-option label="ZA" value="0" />
                    <el-option label="CN" value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="客户名称:" prop="customername">
                  <el-input v-trim  maxlength="50" v-model="Form.customername" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱:" prop="email">
                  <el-input v-trim  maxlength="100" v-model="Form.email" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="结算方式:">
                  <el-input v-trim  disabled v-model="Form.jiesuan" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="类型:" prop="type">
                  <el-select disabled v-model="Form.type" style="width: 100%" placeholder="" clearable>
                    <el-option label="bussiness" value="0" />
                    <el-option label="个人" value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="信用额度:">
                  <el-input v-trim  disabled  maxlength="6" v-model="Form.xingyong_e_du" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="营业执照/身份证:" prop="idnumber">
                  <el-input v-trim  maxlength="30" v-model="Form.idnumber" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="手机号码:" prop="phone">
                  <el-input v-trim  maxlength="11" v-model="Form.phone" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="认证文件:">
                  <a :href="Form.fileurl" target="_blank" class="link">查看</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="秘钥（非接口客户可忽略）:">
                  <el-input v-trim v-model="Form.apikey" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item class="btn-operation">
                  <el-button v-buttonPermission="`${routePath}/Save`"  style="width:150px" type="primary" size="large" @click="confirmInfo">确定</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
    </section>
  </template>
  <script>
  import { updateCustomerApi, getCustomerApi } from '@/api/management/account'
 
  export default {
    name: 'ManagementAccount',
    data() {
      return {
        Form: {
          apikey:'',
          country :'',
          customername:'',
          email:'',
          type:'',
          fileurl :'',
          id :'',
          idnumber :'',
          phone:'',
          userid :'',
        },
        jiesuan: '',
      }
    },
    computed: {
      rules() {
        // 校验新建
        return {
          country: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
          ],
          customername: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
          ],
          email: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
            { type: 'email', message: '请输入正确邮箱格式!', trigger: ['blur', 'change'] }
          ],
          type: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
          ],
          idnumber: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: ' 请填写此选项!', trigger: 'blur' },
            {
              pattern: /^[+\d]+$/,
              message: '请输入有效的数字!',
              trigger: 'blur'
            }
          ],
        }
      },
      routePath() {
        return this.$route.path
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        getCustomerApi().then((res) => {
          this.Form.country= res.guojia+''
          this.Form.jiesuan=res.jiesuan==0?'预充值':'账期'
          this.Form.username=res.username
          this.Form.customername=res.customername
          this.Form.email=res.email
          this.Form.type=res.type+''
          this.Form.fileurl=res.fileurl
          this.Form.id=res.id
          this.Form.idnumber=res.yingyezhizhao
          this.Form.phone=res.phone
          this.Form.userid=res.userid
          this.Form.xingyong_e_du=res.xingyong_e_du
          this.Form.apikey = res.apikey
        })
      },
      confirmInfo() {
        this.$refs['FormRef'].validate((valid) => {
          if (valid) {
            updateCustomerApi(this.Form).then(res => {
              this.$message.success('修改信息成功!')
              this.getList()
            })
          } else {
            this.$message.error('请完善用户信息')
            return false;
          }
        });
      }
    }
  }
  </script>
  <style scoped lang="scss">
  .account-content{
    // header: 60  tag: 46  Breadcrumb: 50, footer: 62
    min-height: calc(100vh - 218px)
  }
  </style>
  
  

const loadMore = {
  mounted(el, binding) {
    const selectDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
      if(!selectDom) return
      // 添加滚动事件
      selectDom.addEventListener('scroll', function () {
        // scrollHeight所有数据占据面的一个高度,，scrollTop滚动条滚走的区域高度，clientHeight可视区域面积高度
        // console.log('selectDom',selectDom.scrollHeight,selectDom.scrollTop,selectDom.clientHeight);
        const height = this.scrollHeight - this.scrollTop - this.clientHeight
        if (height < 1) {
          binding.value()
          this.scrollTop = this.scrollHeight - this.clientHeight
        }
      })
  }
}


export default loadMore

